import { action, makeObservable } from "mobx";
import { ApiResponse } from "../api/ApiService";
import { AreaApi, removeArticlesBody } from "../api/AreaApi";
import { DocumentationApi } from "../api/DocumentationApi";
import { InstalledArticleApi } from "../api/InstalledArticleApi";
import {
    IInstalledArticleAdd,
    IInstalledArticleInstall,
    IInstalledArticleRead,
    IInstalledArticleWrite,
    installedArticleRepo,
} from "../entities/InstalledArticle";
import { DataService } from "./abstract/DataService";
import { documentationService } from "./DocumentationService";

type WaitingForKey = "loadArticles" | "reloadUrl" | "install" | "addToDocumentation" | "updateInstalledArticle";

class InstalledArticleService extends DataService<WaitingForKey, IInstalledArticleRead, IInstalledArticleWrite> {
    apiService = new InstalledArticleApi();
    documentationApi = new DocumentationApi();
    repo = installedArticleRepo;

    areaApi = new AreaApi();

    constructor() {
        super({
            addToDocumentation: false,
            install: false,
            loadArticles: false,
            reloadUrl: false,
            updateInstalledArticle: false,
        });

        makeObservable(this, {
            addToDocumentation: action,
            addInstalledArticleToDocumentation: action,
        });
    }

    updateInstalledArticle(
        installedArticleId: number,
        data: Partial<IInstalledArticleWrite>
    ): Promise<ApiResponse<IInstalledArticleRead>> {
        return this.resolveAsAction({
            promise: () => this.apiService.put(`/${installedArticleId}`, data),
            waitingForKey: ["updateInstalledArticle"],
            action: (response) => {
                if (response.result !== null) {
                    this.mergeList(response.result);
                }

                return response;
            },
        });
    }

    removeArticlesByIdAndAreaId(
        areaId: number,
        body: removeArticlesBody
    ): Promise<ApiResponse<IInstalledArticleRead[]>> {
        return this.resolveAsAction({
            promise: () => this.areaApi.removeInstalledArticles(areaId, body),
            waitingForKey: "delete",
            action: (response) => {
                if (response.result !== null) {
                    for (const entity of this.list) {
                        if (entity.buildingAreaId === areaId && body.installedArticleIds.indexOf(entity.id) >= 0) {
                            this.drop(entity.id);
                        }
                    }
                }
                return response;
            },
        }).catch((error) => {
            throw error;
        });
    }

    createInstalledArticle(
        areaId: number,
        body: IInstalledArticleInstall
    ): Promise<ApiResponse<{ [p: string]: IInstalledArticleRead }>> {
        return this.resolveAsAction({
            promise: () => this.areaApi.createInstalledArticles(areaId, body),
            waitingForKey: ["create"],
            setWaitingForValueTo: areaId,
            action: (response) => {
                if (response.result !== null) {
                    this.mergeList(Object.values(response.result));
                }

                return response;
            },
        });
    }

    addToDocumentation(
        documentationId: number,
        body: IInstalledArticleInstall
    ): Promise<ApiResponse<IInstalledArticleRead>> {
        return this.resolveAsAction({
            promise: () => this.documentationApi.addArticleToDocumentation(documentationId, body),
            waitingForKey: "install",
            action: (result) => {
                if (result.result !== null) {
                    this.mergeList(result.result);
                }

                return result;
            },
            setWaitingForValueTo: documentationId,
        });
    }

    addInstalledArticleToDocumentation(
        id: number,
        body: IInstalledArticleAdd
    ): Promise<ApiResponse<IInstalledArticleRead>> {
        return this.resolveAsAction({
            promise: () => this.documentationApi.addInstalledArticleToDocumentation(id, body),
            waitingForKey: "create",
            action: (response) => {
                if (response.result !== null) {
                    this.mergeList(response.result);
                    documentationService.fetch(id);
                }

                return response;
            },
        });
    }
}

export const installedArticleService = new InstalledArticleService();
