import { ApiResponse } from "../api/ApiService";
import { CompanyApi } from "../api/CompanyApi";
import { DocumentationApi } from "../api/DocumentationApi";
import { MiscApi } from "../api/MiscApi";
import { companyRepo, ICompanyRead, ICompanyWrite } from "../entities/Company";
import { session } from "../session/Session";
import { isDefined } from "../utils/isDefined";
import { DataService } from "./abstract/DataService";

class CompanyService extends DataService<"all", ICompanyRead> {
    apiService = new MiscApi();
    documentationApi = new DocumentationApi();
    companyApi = new CompanyApi();
    repo = companyRepo;

    constructor() {
        super({
            all: false,
        });
    }

    async fetchAllAbsCompanies(): Promise<ApiResponse<ICompanyRead[]>> {
        return await this.resolveAsAction({
            promise: () => this.apiService.getAbsCompanies(),
            waitingForKey: ["fetch"],
            action: (result) => {
                if (result.result) {
                    this.mergeList(result.result);
                }
                return result;
            },
        });
    }

    async updateCompanyContactData(body: ICompanyWrite): Promise<ApiResponse<ICompanyWrite>> {
        return this.resolveAsAction({
            promise: () => this.companyApi.putCompanyCurrentUser(body),
            waitingForKey: "update",
            action: (result) => {
                if (isDefined(result.result)) {
                    this.mergeList(result.result);
                    session.updateCurrentCompany(result.result);
                }
                return result;
            },
        });
    }
}

export const companyService = new CompanyService();
