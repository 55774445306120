import { ApiResponse } from "../api/ApiService";
import { DocumentationApi, IDocumentationItemOfArticleOverview } from "../api/DocumentationApi";
import { DocumentationItemApi } from "../api/DocumentationItemApi";
import {
    documentationItemRepo,
    IDocumentationItemRead,
    IDocumentationItemUpdate,
    IDocumentationItemWrite,
} from "../entities/DocumentationItem";
import { DataService } from "./abstract/DataService";
type BatchUpdateDocumentationItems = { items: number[]; data: IDocumentationItemWrite | IDocumentationItemUpdate };
class DocumentationItemService extends DataService<
    "all",
    IDocumentationItemRead,
    IDocumentationItemWrite | IDocumentationItemUpdate
> {
    apiService = new DocumentationItemApi();
    docuApi = new DocumentationApi();
    repo = documentationItemRepo;

    constructor() {
        super({
            all: false,
        });
    }

    deleteDocumentationItems(
        docuId: number,
        documentationItemIds: number[]
    ): Promise<ApiResponse<Partial<IDocumentationItemRead>>> {
        return this.resolveAsAction({
            promise: () =>
                this.docuApi.deleteDocumentationItems(docuId, {
                    documentationItemIds: documentationItemIds,
                }),
            waitingForKey: "delete",
            setWaitingForValueTo: documentationItemIds,
            action: (response) => {
                if (response.response?.ok === true) {
                    this.drop(documentationItemIds);
                }

                return response;
            },
        });
    }

    async updateBatch(
        ids: number[],
        body: IDocumentationItemWrite | IDocumentationItemUpdate
    ): Promise<ApiResponse<IDocumentationItemRead[]>> {
        return this.resolveAsAction({
            promise: () =>
                this.apiService.put<IDocumentationItemOfArticleOverview[], BatchUpdateDocumentationItems>(`/update`, {
                    items: ids,
                    data: body,
                }),
            waitingForKey: "update",
            setWaitingForValueTo: ids,
            action: (result) => {
                if (result.result !== null) {
                    this.mergeList(result.result);
                }

                return result;
            },
        });
    }
}

export const documentationItemService = new DocumentationItemService();
