import moment from "moment";
import { useEffect, useState } from "react";

export function useRefreshImageUrl(url: string | undefined, reloadUrl: () => void, disableRefresh: boolean): string {
    const [renderedUrl, setRenderedUrl] = useState("");

    useEffect(() => {
        const urlString = url ?? "";
        if (urlString === "") {
            return;
        } else {
            const expiresInMiliseconds = getExpiresInMiliseconds(urlString);
            if (expiresInMiliseconds === null) {
                setRenderedUrl(urlString);
                return;
            }
            const requestThreshold = 3000;
            if (expiresInMiliseconds - requestThreshold <= 0) {
                !disableRefresh ? reloadUrl() : null;
            } else {
                setRenderedUrl(urlString);
            }
        }
    }, [url]);
    return renderedUrl;
}

function getExpiresInMiliseconds(url: string) {
    const urlParams = {
        expirationTimeInS: getUrlParam(url, "X-Amz-Expires") ?? "",
        dateCreated: getUrlParam(url, "X-Amz-Date") ?? "",
    };

    if (urlParams.expirationTimeInS === "" || urlParams.dateCreated === "") {
        return null;
    }

    const expireTimeInS = parseInt(urlParams.expirationTimeInS);
    const dateCreated = moment(urlParams.dateCreated);
    const expiresAt = dateCreated.add(moment.duration(expireTimeInS, "seconds"));
    const now = moment();

    return expiresAt.diff(now, "milliseconds");
}

function getUrlParam(url: string, param: string) {
    const urlParams = new URLSearchParams(url);
    return urlParams.get(param);
}
