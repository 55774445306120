import { designTheme, unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components";
import { isDefined } from "../../../../../utils/isDefined";
import { AnswerBaseProps } from "./QuestionRow";

type AnswerInputProps = AnswerBaseProps;

const AnswerInput: FunctionComponent<AnswerInputProps> = (props: AnswerInputProps) => {
    const getPlaceholder = () => {
        if (isDefined(props.hasDifferentValues)) return "???";
        if (isDefined(props.question.defaultAnswer) && props.question.defaultAnswer !== "")
            return `z.B. ${props.question.defaultAnswer.toString()}`;
        return "bitte eingeben";
    };
    return (
        <AnswerInputStyle
            error={props.error}
            type={props.question.questionType === "number" ? "number" : "text"}
            placeholder={props.disabled === true ? "" : getPlaceholder()}
            value={props.value}
            onChange={(e) => {
                props.onChange(e.target.value);
            }}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            readOnly={props.disabled}
        />
    );
};

export default AnswerInput;

//#region styles
export const AnswerInputStyle = styled.input<{ error?: boolean; readOnly?: boolean }>`
    border: 0;
    font-size: ${unitize(16)};
    background-color: transparent;
    font-weight: ${designTheme.typography.weight.bold};
    text-align: right;
    width: 100%;
    color: ${(props) => (props.error === true ? designTheme.color.warning : "inherit")};
    ${(props) =>
        props.readOnly === true
            ? css`
                  &:focus {
                      outline: none;
                  }
              `
            : css`
                  &:focus {
                      outline: 1px solid ${designTheme.color.primary};
                  }
              `};
    &::placeholder {
        color: ${designTheme.color.darkgrey};
        font-weight: ${designTheme.typography.weight.regular};
        opacity: 1;
    }
`;
//#endregion styles
