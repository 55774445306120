import { ICompanyRead, ICompanyWrite } from "../entities/Company";
import { ApiResponse, ApiService } from "./ApiService";
export class CompanyApi extends ApiService<ICompanyRead> {
    constructor() {
        super("/company");
    }

    putCompanyCurrentUser(body: ICompanyWrite): Promise<ApiResponse<ICompanyRead>> {
        return this.put(``, body);
    }
}
