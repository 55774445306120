/**
 * SideModal in buildingArea/{id} SubView: "ProductManagement" when click on "edit" in CardListInstalledArticles
 * & in documentations/{id} SubView: "Produkte" when clicked on 'edit' of CardArticle's Dropdown in ViewArticles
 *
 * no separate URL because:
 * - SubViews have also no separate URL (would be a too complicate routing structure)
 * and this SideModal should always only be visible when in the BackGround the SubView "ProductManagement" is opened
 */

import { SideModal } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import { ArticleType } from "../../../../entities/Article";
import { IInstalledArticleRead } from "../../../../entities/InstalledArticle";
import { WaitingForValue } from "../../../../stores/SuperStore";
import { getInstalledArticlePreviewName } from "../../../../utils/getInstalledArticlePreviewName";
import { layout } from "../../../../utils/layout";
import ColFormInstalledArticle from "./components/ColFormInstalledArticle";
import ColListInstalledArticles from "./components/ColListInstalledArticles";

export type InstalledArticle = Pick<
    IInstalledArticleRead,
    | "appId"
    | "identificationNumber"
    | "identificationCounter"
    | "serialNumber"
    | "comment"
    | "id"
    | "installationDate"
    | "absSerialNumber"
    | "identificationPlateNumber"
>;

enum ViewKey {
    ListInstalledArticles = "ListInstalledArticles",
    Form = "Form",
}

export interface InstalledArticlesEditProps {
    articleName: string;
    articleType: ArticleType;
    waitingForDeleteInstalledArticles: WaitingForValue;
    installedArticles: InstalledArticle[];
    onLastElementDeleted: () => void;
    deleteInstalledArticles: (Ids: number[]) => void;
    onClose: () => void;
}

/**
 * Parent Component
 */
const InstalledArticlesEdit: FunctionComponent<InstalledArticlesEditProps> = (props: InstalledArticlesEditProps) => {
    const [selectedInstalledArticle, setSelectedInstalledArticle] = useState(props.installedArticles[0]);
    const [tabTitle, setTabTitle] = useState(getInstalledArticlePreviewName(props.installedArticles[0]));
    const [activeView, setActiveView] = useState<string>(ViewKey.Form);

    useEffect(() => {
        // when user deletes item, props.installedArticles change, and a new item has to get selected
        if (props.installedArticles.length === 0) {
            props.onLastElementDeleted();
            return;
        }
        setTabTitle(getInstalledArticlePreviewName(props.installedArticles[0]));
    }, [props.installedArticles.length, props.installedArticles]);

    useEffect(() => {
        // update TabTitle if installedArticle changes
        setTabTitle(getInstalledArticlePreviewName(selectedInstalledArticle));
    }, [props.installedArticles, selectedInstalledArticle, setTabTitle]);

    const onDeleteClick = () => {
        props.deleteInstalledArticles([selectedInstalledArticle.id]);
    };

    return (
        <SideModal
            className="uf-sideModal"
            isOpen
            onCloseClick={props.onClose}
            style={{
                top: `${layout.header.height}px`,
                height: `calc(100% - ${layout.header.height}px)`,
            }}
            views={[
                {
                    key: ViewKey.ListInstalledArticles,
                    width: 360,
                    paddingSize: "none",
                    accordionTitle: {
                        text: "1. Artikel wählen",
                    },
                    hidden: props.installedArticles.length === 1,
                    component: (
                        <ColListInstalledArticles
                            {...props}
                            selectedInstalledArticle={selectedInstalledArticle}
                            setSelectedInstalledArticle={setSelectedInstalledArticle}
                        />
                    ),
                },
                {
                    key: ViewKey.Form,
                    width: 750,
                    accordionTitle:
                        props.installedArticles.length !== 1
                            ? {
                                  text: "2. Bearbeiten",
                              }
                            : undefined,
                    component: (
                        <ColFormInstalledArticle
                            {...props}
                            onDeleteClick={onDeleteClick}
                            tabTitle={tabTitle}
                            selectedInstalledArticle={selectedInstalledArticle}
                        />
                    ),
                },
            ]}
            activeView={activeView}
            onActiveViewChange={setActiveView}
            accordionHeader={AccordionHeader}
        />
    );
};

export default observer(InstalledArticlesEdit);

const AccordionHeader = <h3>Bearbeiten</h3>;
