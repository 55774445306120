import { IDeliveryNoteRead } from "../entities/DeliveryNote";
import { ApiResponse, ApiService } from "./ApiService";

export class DeliveryNoteApi extends ApiService<IDeliveryNoteRead> {
    constructor() {
        super("/delivery-notes");
    }

    getDeliveryNote(barcode?: string): Promise<ApiResponse<IDeliveryNoteRead[]>> {
        return this.get<IDeliveryNoteRead[]>(`?barcode=${barcode}`);
    }
}
