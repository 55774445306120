import { IDocumentationItemBase } from "../../../../../entities/DocumentationItem";
import { isDefined } from "../../../../../utils/isDefined";

// required info about Docu Items
type DocuItem = Pick<IDocumentationItemBase, "questionsAnswered" | "isSuccessful" | "lockReason">;

/**
 * not visible if
 * - one item ...
 *     - (has no lockReason AND is not locked)
 *     - OR (is successful)
 * */
export function isLockReasonRowVisible(docuItems: DocuItem[]): boolean {
    let visible = true;
    if (docuItems.length === 0) return false;
    for (const docuItem of docuItems) {
        if (
            (!isDefined(docuItem.lockReason) &&
                (docuItem.isSuccessful !== false || docuItem.questionsAnswered === false)) ||
            docuItem.isSuccessful === true
        ) {
            visible = false;
        }
    }
    return visible;
}
