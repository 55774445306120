import { RepoStore } from "../stores/RepoStore";

class CompanyRepo extends RepoStore<ICompanyRead> {}

export const companyRepo = new CompanyRepo();

interface ICompanyBase {
    id: number;
    name: string;
    address1?: string | null;
    address2?: string | null;
    postcode?: string | null;
    city?: string | null;
    country?: string | null;
    contactFirstName?: string | null;
    contactLastName?: string | null;
    email?: string;
    phone1?: string;
    phone2?: string | null;
    fax?: string | null;
    displayAssemblerInformation?: boolean | undefined;
    updatedAt?: Date | null;
    createdAt?: Date | null;
    companyId?: number;
    imageUrl?: string | null;
    uploadUrl?: string;
    imageFile?: File | Blob | string;
    imageFilename?: string;
    fileSize?: string;
    canUseAttachments?: boolean | null;
    canUseDraw?: boolean | null;
    canUseThirdPartyArticles?: boolean | null;
    imageSize?: number;
    userflowSignature?: null | string;
}

export type ICompanyRead = Pick<
    ICompanyBase,
    | "name"
    | "country"
    | "id"
    | "userflowSignature"
    | "contactFirstName"
    | "contactLastName"
    | "phone1"
    | "phone2"
    | "email"
    | "displayAssemblerInformation"
>;

export type ICompanyWrite = Pick<
    ICompanyBase,
    "contactFirstName" | "contactLastName" | "phone1" | "phone2" | "email" | "displayAssemblerInformation"
>;
