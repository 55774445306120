import React, { FunctionComponent } from "react";
import { HttpError } from "../../entities/ErrorResponse";
import { isNullish } from "../../utils/isNullish";
import Error400 from "./Error400";
import Error401 from "./Error401";
import Error403 from "./Error403";
import Error404 from "./Error404";
import Error500 from "./Error500";

const ErrorPage: FunctionComponent<HttpError> = (props?: HttpError) => {
    const getErrorPage = () => {
        if (isNullish(props)) return null;

        if (props.statusCode === 400) {
            return <Error400 />;
        }

        if (props.statusCode === 401) {
            return <Error401 />;
        }

        if (props.statusCode === 403) {
            return <Error403 />;
        }

        if (props.statusCode === 404) {
            return <Error404 />;
        }

        return <Error500 />;
    };

    return getErrorPage();
};

export default ErrorPage;
