import {
    Button,
    ColumnSizes,
    FieldEmpty,
    IconPlus,
    IconProduct,
    ListSortable,
    ListSortableRef,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import FieldLoading from "../../../components/FieldLoading";
import { AddArticleController } from "../../../components/ModalAddArticle/AddArticleController";
import ModalAddArticle from "../../../components/ModalAddArticle/ModalAddArticle";
import ModalDeliveryNote from "../../../components/ModalAddArticle/ModalDeliveryNote";
import ModalExampleDeliveryNote from "../../../components/ModalAddArticle/ModalExampleDeliveryNote";
import ModalSelectAddArticle from "../../../components/ModalAddArticle/ModalSelectAddArticle";
import ModalSelectFromDeliveryNote from "../../../components/ModalAddArticle/ModalSelectFromDeliveryNote";
import { installedArticleService } from "../../../services/InstalledArticleService";
import { getController } from "../../../stores/controller/ControllerFactory";
import { gtmAddArticle } from "../../../utils/gtmEventCollection";
import CardArticle from "../components/CardArticle";
import { DocumentationController } from "../DocumentationController";
import { DocumentController } from "../documentView/DocumentController";

const listColumnSizes: ColumnSizes = { xs: [6, 0, 0, 6], md: [4, 2, 2, 4] };

const ViewArticles: FunctionComponent = () => {
    const { controller: modalController } = getController(AddArticleController);
    const { controller } = getController(DocumentationController);
    const history = useHistory();
    const { url } = useRouteMatch();
    const listSortableRef = useRef<ListSortableRef | null>(null);

    //#region states

    // ModalSelectAddArticle lets you chose between the "normal" selection or switching to a new modal, where you enter a delivery note number
    const [isModalSelectAddArticleVisible, setIsModalSelectAddArticleVisible] = useState(false);
    // ModalAddArticle handles the normal way
    const [isModalAddArticleVisible, setIsModalAddArticleVisible] = useState(false);
    // in ModalSelectFromDeliveryNote you enter the delivery note number and trigger the api request
    const [isModalSelectFromDeliveryNoteVisible, setIsModalSelectFromDeliveryNoteVisible] = useState(false);
    // ModalExampleDeliveryNote displays an image of an exampele delivy note, so the user can see, where to find the actual delivery note number
    const [isModalExampleDeliveryNoteVisible, setIsModalExampleDeliveryNoteVisible] = useState(false);
    // ModalDeliveryNote finally displays all articles from the specific delivery note. Here the user can select/de-select articles and adjust their amount
    // (between 1 and the max amount of the delivery note)
    const [isModalDeliveryNoteVisible, setIsModalDeliveryNoteVisible] = useState(false);
    const [barcodeInput, setBarcodeInput] = useState("");

    const isLocked = controller.documentation?.isCompleted ?? false;

    //#endregion states

    const handleSelectAddArticleClick = () => {
        setIsModalSelectAddArticleVisible(false);
        setIsModalAddArticleVisible(true);
        gtmAddArticle(controller.documentation?.type, "manually");
    };

    const handleOnInfoIconClick = () => {
        setIsModalSelectFromDeliveryNoteVisible(false);
        setIsModalExampleDeliveryNoteVisible(true);
    };

    const handleOnCloseModalExampleDeliveryNote = () => {
        setIsModalExampleDeliveryNoteVisible(false);
        setIsModalSelectFromDeliveryNoteVisible(true);
    };

    const handleShowDeliveryNoteProducts = () => {
        setIsModalSelectAddArticleVisible(false);
        setIsModalSelectFromDeliveryNoteVisible(true);

        gtmAddArticle(controller.documentation?.type, "deliveryNote");
    };

    const handleSubmitModalSelectFromDeliveryNote = (barcode: string) => {
        controller.loadDeliveryNote(barcode);

        setBarcodeInput(barcode);
        setIsModalSelectFromDeliveryNoteVisible(false);
        setIsModalDeliveryNoteVisible(true);
    };

    //#region render
    return (
        <S.Component>
            {isModalSelectAddArticleVisible && (
                <ModalSelectAddArticle
                    onCloseClick={() => setIsModalSelectAddArticleVisible(false)}
                    onClick={handleSelectAddArticleClick}
                    handleShowDeliveryNoteProducts={handleShowDeliveryNoteProducts}
                    isOpen={isModalSelectAddArticleVisible}
                />
            )}

            {isModalAddArticleVisible && controller.buildingAreaId && (
                <ModalAddArticle
                    buildingAreaId={controller.buildingAreaId}
                    isInPage="documentation"
                    onCloseClick={() => setIsModalAddArticleVisible(false)}
                    documentationType={controller.documentation?.type}
                />
            )}
            {isModalSelectFromDeliveryNoteVisible && (
                <ModalSelectFromDeliveryNote
                    isInPage="documentation"
                    onCloseClick={() => setIsModalSelectFromDeliveryNoteVisible(false)}
                    documentationType={controller.documentation?.type}
                    onClick={handleShowDeliveryNoteProducts}
                    onInfoIconClick={handleOnInfoIconClick}
                    onSubmit={(values) => handleSubmitModalSelectFromDeliveryNote(values.barcode)}
                    isOpen={isModalSelectFromDeliveryNoteVisible}
                />
            )}
            {isModalExampleDeliveryNoteVisible && (
                <ModalExampleDeliveryNote
                    onCloseClick={handleOnCloseModalExampleDeliveryNote}
                    isOpen={isModalExampleDeliveryNoteVisible}
                />
            )}
            {isModalDeliveryNoteVisible && controller.currentId && controller.buildingAreaId && (
                <ModalDeliveryNote
                    buildingAreaId={controller.buildingAreaId}
                    docuId={controller.currentId}
                    onCloseClick={() => setIsModalDeliveryNoteVisible(false)}
                    isOpen={isModalDeliveryNoteVisible}
                    barcode={barcodeInput}
                />
            )}
            {!isLocked && (
                <S.AddButton>
                    <Button
                        disabled={
                            controller.isWaitingFor("loadArticles") ||
                            installedArticleService.isWaitingFor("create") ||
                            installedArticleService.isWaitingFor("delete") ||
                            installedArticleService.isWaitingFor("update") ||
                            installedArticleService.isWaitingFor("install") ||
                            controller.isWaitingFor("deleteDocumentationItem")
                        }
                    >
                        <button
                            className={"uf-addProduct"}
                            onClick={() =>
                                controller.documentation?.type === "maintenance"
                                    ? setIsModalAddArticleVisible(true)
                                    : setIsModalSelectAddArticleVisible(true)
                            }
                        >
                            Produkt hinzufügen <IconPlus />
                        </button>
                    </Button>
                </S.AddButton>
            )}
            {/*  List of Articles or placeholder */}
            {controller.waitingFor.loadArticles === true ? (
                <FieldLoading text="Produkte werden geladen..." />
            ) : controller.articles.length === 0 ? (
                <FieldEmpty icon={<IconProduct height={48} width={48} />} text="Kein Produkt vorhanden" />
            ) : (
                <ListSortable
                    ref={listSortableRef}
                    headerStyle={{ padding: unitize(10) }}
                    listStyle={{ display: "grid", gridGap: unitize(5) }}
                    columnSizes={listColumnSizes}
                    items={controller.articles}
                    columns={[
                        {
                            title: "Name",
                            sortProperty: "name",
                        },
                        {
                            title: "Hinzugefügt am",
                            sortProperty: "createdAt",
                        },
                        {
                            title: "Letzte Änderung",
                            sortProperty: "updatedAt",
                        },
                        {
                            title: "Anzahl",
                            sortFunction: (a, b) => a.documentationItems.length - b.documentationItems.length,
                        },
                    ]}
                >
                    {(sortedList) => (
                        <>
                            {modalController.waitingFor.addArticles === true && (
                                <FieldLoading height={unitize(110)} text="Artikel wird hinzugefügt ..." />
                            )}
                            {sortedList.map((article) => (
                                <CardArticle
                                    firstItem={article.id === controller.articles[0].id}
                                    key={article.id}
                                    disabled={controller.waitingFor.deleteDocumentationItem === article.id}
                                    hideDropdown={isLocked}
                                    article={article}
                                    columnSizes={listColumnSizes}
                                    onDelete={() => controller.deleteAllDocumentationItemsOfArticle(article)}
                                    onDocument={() => {
                                        const { controller: documentController } = getController(DocumentController);
                                        documentController.currentId = controller.currentId;
                                        documentController.setCurrentArticle(article.id);
                                        history.push(`${url}/document`);
                                    }}
                                />
                            ))}
                        </>
                    )}
                </ListSortable>
            )}
        </S.Component>
    );
};

export default observer(ViewArticles);

//#region styles
const S = {
    Component: styled.div``,
    AddButton: styled.div`
        margin-bottom: ${unitize(30)};
    `,
};
//#endregion styles
