import { ApiResponse } from "../api/ApiService";
import { ArticleApi } from "../api/ArticleApi";
import { articleAttachmentRepo, IArticleAttachmentRead, IArticleAttachmentWrite } from "../entities/ArticleAttachment";
import { isDefined } from "../utils/isDefined";
import { DataService } from "./abstract/DataService";

class ArticleAttachmentService extends DataService<
    "all" | "fetchArticleAttachments" | "deleteMultiple",
    IArticleAttachmentRead,
    IArticleAttachmentWrite
> {
    repo = articleAttachmentRepo;
    apiService = new ArticleApi();

    constructor() {
        super({ all: false, fetchArticleAttachments: false, deleteMultiple: false });
    }

    async uploadAttachment(articleId: number, attachment: File): Promise<void> {
        await this.resolveAsAction({
            promise: () => this.apiService.uploadAttachment(articleId, attachment),
            waitingForKey: "update",
            action: (response) => {
                if (response?.result !== null) {
                    this.mergeList(response?.result);
                }
                return response;
            },
        });
    }

    fetchArticleAttachments(articleId: number): Promise<ApiResponse<IArticleAttachmentRead[]>> {
        return this.resolveAsAction({
            promise: () => this.apiService.getArticleAttachments(articleId),
            waitingForKey: "fetchArticleAttachments",
            action: (result) => {
                if (isDefined(result.result)) {
                    this.mergeList(result.result);
                }
                return result;
            },
        });
    }

    deleteMultiple(articleId: number, attachmentIds: number[]): Promise<unknown> {
        return this.resolveAsAction({
            promise: () => this.apiService.deleteArticleAttachments(articleId, attachmentIds),
            waitingForKey: "deleteMultiple",
            action: (result) => {
                if (result.response?.ok === true) {
                    attachmentIds.forEach((id) => this.drop(id));
                }
                return result;
            },
        });
    }
}

export const articleAttachmentService = new ArticleAttachmentService();
