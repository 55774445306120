import { ColumnSizes, SideModal, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import FieldLoading from "../../../components/FieldLoading";
import { IBuildingAreaRead } from "../../../entities/BuildingArea";
import { DocumentationType } from "../../../entities/Documentation";
import { areaService } from "../../../services/AreaService";
import { dataLayerService } from "../../../services/DataLayerService";
import { getController } from "../../../stores/controller/ControllerFactory";
import { layout } from "../../../utils/layout";
import Edit from "../../BuildingArea/subviews/Edit";
import { BuildingController } from "../BuildingController";
import { CardBuildingArea } from "./CardBuildingArea";

interface ListBuildingAreasProps {
    sortedList: IBuildingAreaRead[];
    columnSizes: ColumnSizes;
}

/**
 * Inner Component: List without Header
 */
const ListBuildingAreas = observer((props: ListBuildingAreasProps) => {
    const { controller } = getController(BuildingController);
    const history = useHistory();
    const { url } = useRouteMatch();
    const [modalEdit, setModalEdit] = useState(false);
    const [sideModalBuildingAreaId, setSideModalBuildingAreaId] = useState<number | undefined>(undefined);

    const onNewDocumentationClick = useCallback((buildingAreaId: number, type: DocumentationType) => {
        const name = `${type === "assembly" ? "Montage" : "Wartung"} ${moment(new Date()).format("YYYY-MM-DD")}`;
        controller.createDocumentation(buildingAreaId, type, name).then((response) => {
            response.result?.id && history.push(`${url}/areas/${buildingAreaId}/documentations/${response.result.id}`);
        });
    }, []);

    const onBuildingAreaEdit = useCallback((buildingAreaId: number) => {
        setSideModalBuildingAreaId(buildingAreaId);
        setModalEdit(true);
    }, []);

    return (
        <>
            {controller.waitingFor.createBuildingArea && (
                <FieldLoading height={unitize(110)} text="Dachfläche wird erstellt ..." />
            )}
            {props.sortedList.map((buildingArea) => (
                <CardBuildingArea
                    key={buildingArea.id}
                    buildingArea={buildingArea}
                    disabled={areaService.waitingFor.delete === buildingArea.id}
                    columnSizes={props.columnSizes}
                    onDelete={() => areaService.deleteArea(buildingArea)}
                    onEdit={() => onBuildingAreaEdit(buildingArea.id)}
                    onNewDocumentationAssembly={() => {
                        onNewDocumentationClick(buildingArea.id, "assembly");
                        dataLayerService.emitClick({
                            category: "building",
                            action: "click",
                            label: `shortcut_assembly`,
                        });
                    }}
                    onNewDocumentationMaintenance={() => {
                        onNewDocumentationClick(buildingArea.id, "maintenance");
                        dataLayerService.emitClick({
                            category: "building",
                            action: "click",
                            label: `shortcut_maintenance`,
                        });
                    }}
                />
            ))}
            {modalEdit && sideModalBuildingAreaId !== undefined && (
                <SideModal
                    className="uf-sideModal"
                    isOpen
                    onCloseClick={() => setModalEdit(false)}
                    style={{
                        top: `${layout.header.height}px`,
                        height: `calc(100% - ${layout.header.height}px)`,
                    }}
                    views={[
                        {
                            key: "edit",
                            width: 750,
                            component: <Edit buildingAreaId={sideModalBuildingAreaId} />,
                        },
                    ]}
                />
            )}
        </>
    );
});

export default ListBuildingAreas;
