import { Button, ButtonClose, media, Modal, ModalFooter, unitize } from "@abs-safety/lock-book-web-ui";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import FormInput from "../../../components/FormInput";
import { ITransferKeyRead } from "../../../entities/TransferKey";
import { getController } from "../../../stores/controller/ControllerFactory";
import { BuildingListController } from "../../BuildingList/BuildingListController";

export type FormValues = Pick<ITransferKeyRead, "transferKey">;

interface ModalApplyObjectFormProps extends FormikProps<FormValues> {
    onCloseClick: () => void;
    isOpen: boolean;
    transferKey: string | null;
}

interface ModalApplyObjectProps {
    onCloseClick: () => void;
    isOpen: boolean;
    transferKey: string | null;
}

const ModalApplyObject: FunctionComponent<ModalApplyObjectProps> = (props: ModalApplyObjectProps) => {
    const { controller } = getController(BuildingListController);
    const [initialValues] = useState<FormValues>({
        transferKey: props.transferKey !== null ? props.transferKey : "",
    });

    const [validationSchema, setValidationSchema] = useState(Yup.object().shape<Partial<FormValues>>({}));
    const transferKeyRegex = /\w/;

    useEffect(() => {
        setValidationSchema(
            Yup.object().shape<Partial<FormValues>>({
                transferKey: Yup.string().matches(transferKeyRegex, {
                    message: "Dies ist kein gültiges Format für einen Transfer-Schlüssel",
                }),
            })
        );
    }, []);

    const onSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        controller.redeemTransferKey(values.transferKey, props.onCloseClick, helpers);
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values, helpers) => onSubmit(values, helpers)}
        >
            {(formikBag) => (
                <ObservedForm
                    onCloseClick={props.onCloseClick}
                    isOpen={props.isOpen}
                    transferKey={props.transferKey}
                    {...formikBag}
                />
            )}
        </Formik>
    );
};

const ModalApplyObjectForm: FunctionComponent<ModalApplyObjectFormProps> = (props: ModalApplyObjectFormProps) => {
    return (
        <Form className="uf-takeOverModal">
            <S.ModalApplyObject>
                <Modal onCloseClick={props.onCloseClick} isOpen={props.isOpen} size="sm">
                    <S.ButtonClose>
                        <ButtonClose onClick={props.onCloseClick} />
                    </S.ButtonClose>
                    <S.HeadlineTitle>
                        <S.HeadlineText>Objekt übernehmen</S.HeadlineText>
                    </S.HeadlineTitle>
                    <S.TextWrapper>
                        <S.Description>
                            {props.transferKey !== null
                                ? "Übernehme das Objekt mit diesem Transfer-Schlüssel"
                                : "Gib hier den Transfer-Schlüssel ein"}
                        </S.Description>
                    </S.TextWrapper>
                    <S.InputWrapper>
                        <FormInput
                            label="Transfer-Schlüssel"
                            name="transferKey"
                            type="text"
                            size="sm"
                            placeholder={"z.B. b44c6376a3ac4e55a51dc9b8fa948dc5"}
                        />
                    </S.InputWrapper>

                    <ModalFooter>
                        <Button>
                            <button type="submit">Objekt übernehmen</button>
                        </Button>
                    </ModalFooter>
                </Modal>
            </S.ModalApplyObject>
        </Form>
    );
};

const ObservedForm = observer(ModalApplyObjectForm);

export default observer(ModalApplyObject);

//#region styles
const S = {
    ModalApplyObject: styled.div`
        padding: 0;
        margin-top: 0;
    `,
    HeadlineTitle: styled.div`
        display: flex;
        justify-content: center;
    `,
    HeadlineText: styled.h2`
        display: block;
    `,
    TextWrapper: styled.div`
        display: flex;
        margin-top: ${unitize(24)};
        margin-bottom: ${unitize(14)};
        white-space: nowrap;
    `,
    Description: styled.p`
        font-size: ${unitize(19)};
    `,

    Button: styled.div`
        margin: ${unitize(10)};
        ${media("sm")} {
            margin: ${unitize(20)};
        }
    `,
    ButtonClose: styled.div`
        position: absolute;
        right: ${30 / 16}rem;
        top: ${30 / 16}rem;
    `,
    InputWrapper: styled.div`
        margin-bottom: ${unitize(11)};
        text-align: left;
        & p {
            font-size: ${unitize(14)};
        }
        &:focus {
            outline: none;
        }
    `,
};
//#endregion styles
