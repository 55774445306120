import { connect, FormikContextType } from "formik";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { DataLayerActions, dataLayerService } from "../services/DataLayerService";

// we could define which values the form has - but we don't need to know in this Component
type FormValues = Record<string, string>;

// interface FormAutoSaveProps {
interface OuterProps {
    /** after how many ms of not typing / changing form, the form should get submitted? (default: 1000) */
    action?: DataLayerActions;
}

type FormikPartProps = {
    // we could define as generic which values the form has - but we don't need to know in this Component
    formik: FormikContextType<FormValues>;
};

const getFormDiff = (prev: FormValues, current: FormValues): { key: string; val: string }[] => {
    return Object.entries(current).reduce((result: { key: string; val: string }[], [key, val]) => {
        if (val !== prev[key]) {
            result.push({
                key,
                val,
            });
        }
        return result;
    }, []);
};

const FormGtmIntegration: FunctionComponent<OuterProps & FormikPartProps> = (props: OuterProps & FormikPartProps) => {
    const firstRenderRef = useRef(true);
    const [prevValues, setPrevValues] = useState({ ...props.formik.values });
    const page = dataLayerService.currentPage;
    // whenever values of form change...
    useEffect(() => {
        const diff = getFormDiff(prevValues, props.formik.values);
        // ... and it's not when the form gets loaded for first time ...
        if (!firstRenderRef.current && diff.length > 0) {
            diff.forEach((item) => {
                dataLayerService.emitInput({
                    category: page,
                    action: props.action ?? "input",
                    label: item.key,
                    payload: item.val,
                });
            });

            setPrevValues({ ...props.formik.values });
        }
        firstRenderRef.current = false;
    }, [props.formik.values]);

    return null;
};

FormGtmIntegration.defaultProps = {
    action: "input",
};

export default connect<OuterProps, FormValues>(FormGtmIntegration);
