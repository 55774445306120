import { connect, FormikContextType } from "formik";
import { FunctionComponent, useEffect, useRef } from "react";
import { isDefined } from "../utils/isDefined";
import { isNullish } from "../utils/isNullish";
import { useDebounce } from "../utils/useDebounce";

// we could define which values the form has - but we don't need to know in this Component
type FormValues = { [key: string]: string };

// interface FormAutoSaveProps {
interface OuterProps {
    /** after how many ms of not typing / changing form, the form should get submitted? (default: 1000) */
    debounce?: number;
    onSuccess?: (values: FormValues) => void;
    onError?: (error: Error, values: FormValues) => void;
}

type FormikPartProps = {
    // we could define as generic which values the form has - but we don't need to know in this Component
    formik: FormikContextType<{ [key: string]: string }>;
};

/**
 * This React FunctionComponent can be placed inside a `<Formik>` node, to automatically trigger
 * the Formik onSubmit function when:
 * - form values changed and no changes are done for `{props.debounce}`ms (debounce treshold time)
 *
 * The debounce time is used, to collect changes and send them together instead of submitting changes on each Input.onChange
 */
const FormAutoSubmit: FunctionComponent<OuterProps & FormikPartProps> = (props: OuterProps & FormikPartProps) => {
    const firstRenderRef = useRef(true);
    // whenever values of form change...
    useEffect(() => {
        // ... and it's not when the form gets loaded for first time ...
        if (!firstRenderRef.current) {
            // ... submit form (debounced)
            submitFormDebounced();
        }
        firstRenderRef.current = false;
    }, [props.formik.values]);

    // submit form (debounced) (-> execute submitFn only when submitFormDebounced() wasn't called for a certain time)
    const submitFormDebounced = useDebounce(() => {
        // disable unused var rule, to show the possibility, in case we want to do sth. in future with the form values etc.
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        props.formik
            .submitForm()
            .then(() => {
                isDefined(props.onSuccess) && props.onSuccess(props.formik.values);
            })
            .catch((error: Error) => {
                if (isNullish(props.onError)) {
                    console.error("FormAutoSave: submitFn error", error);
                    return;
                }

                props.onError(error, props.formik.values);
            });
    }, props.debounce ?? 1000);

    return null;
};

export default connect<OuterProps, FormValues>(FormAutoSubmit);
