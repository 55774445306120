import { designTheme, IconWarnTriangle, Popup, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { IDocumentationItemQuestionWithAnswer } from "../../../../../entities/DocumentationItemQuestion";
import { isDefined } from "../../../../../utils/isDefined";
import { isNotEmptyString } from "../../../../../utils/isNotEmptyString";
import AnswerDropdown from "./AnswerDropdown";
import AnswerInput from "./AnswerInput";
import AnswerTextarea from "./AnswerTextarea";
import AnswerYesNo, { yesNoAnswerValueToText } from "./AnswerYesNo";
import QuestionAnswerContainer from "./QuestionAnswerContainer";

interface QuestionRowProps extends AnswerBaseProps {
    errorMessage?: string;
}

export interface AnswerBaseProps {
    question: IDocumentationItemQuestionWithAnswer;
    error?: boolean;
    /** If Merged Question consists of multiple Questions (multiple Docu Items selected) with different answers */
    hasDifferentValues?: boolean;
    value: string;
    onChange: (value: string) => void;
    onFocus: () => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

const getIsTextArea = (props: QuestionRowProps) =>
    (props.question.questionType === "number" || props.question.questionType === "text") &&
    isDefined(props.question.description) &&
    props.question.description.includes("textarea");

const QuestionRow: FunctionComponent<QuestionRowProps> = (props: QuestionRowProps) => {
    const [rangeText, setRangeText] = useState("");
    useEffect(() => {
        const minText = isDefined(props.question.min) ? `min. ${props.question.min}` : "";
        const maxText = isDefined(props.question.max) ? `max. ${props.question.max}` : "";
        if (isNotEmptyString(minText) || isNotEmptyString(maxText)) {
            setRangeText(" - " + [minText, maxText].filter((txt) => txt !== "").join(" / "));
        } else {
            setRangeText("");
        }
    }, [props.question.min, props.question.max]);

    const isTextarea = getIsTextArea(props);

    return (
        <>
            <QuestionAnswerContainer
                isTextarea={isTextarea}
                question={`
                        ${props.question.question}
                        ${rangeText}
                        ${props.question.isMandatory ? " *" : ""}
                    `}
                answer={
                    <>
                        <div>
                            {props.hasDifferentValues === true && <IconWarnWithPopup question={props.question} />}
                        </div>

                        {props.question.questionType === "yesno" && <AnswerYesNo {...props} />}

                        {props.question.questionType === "list" && <AnswerDropdown {...props} />}

                        {(props.question.questionType === "number" || props.question.questionType === "text") &&
                            (isTextarea ? <AnswerTextarea {...props} /> : <AnswerInput {...props} />)}
                    </>
                }
            />
            {props.error === true && <S.ErrorText error={props.errorMessage}>{props.errorMessage}</S.ErrorText>}
        </>
    );
};

export default observer(QuestionRow);

//#region other comps

const IconWarnWithPopup = (props: { question: IDocumentationItemQuestionWithAnswer }) => (
    <Popup
        position={{
            top: unitize(40),
            right: unitize(-25),
        }}
        toggleNode={
            <S.IconWarnTriangleWrapper>
                <IconWarnTriangle width={32} height={32} color={designTheme.color.warning} />
            </S.IconWarnTriangleWrapper>
        }
        popupContent={
            <S.PopupContent>
                <h4>Unterschiedliche Werte</h4>
                <p>Bei den ausgewählten Artikeln liegen unterschiedliche Antworten vor.</p>
                {isDefined(props.question.defaultAnswer) && (
                    <p>Der empfohlene Wert ist: {getDefaultAnswerName(props.question)}.</p>
                )}
                <p>
                    Beim <strong>Freigeben</strong> oder <strong>Sperren</strong> werden diese unterschiedlichen
                    Antworten berücksichtigt.
                </p>
            </S.PopupContent>
        }
    />
);

//#endregion other comps

//#region functions

function getDefaultAnswerName(question: IDocumentationItemQuestionWithAnswer): string | undefined {
    switch (question.questionType) {
        case "text":
        case "list":
            return `"${question.defaultAnswer?.toString()}"`;
        case "number":
            return question.defaultAnswer?.toString();
        case "yesno":
            return isNotEmptyString(question.defaultAnswer?.toString())
                ? `"${yesNoAnswerValueToText[question.defaultAnswer?.toString() as "0" | "1" | "2"]}"`
                : undefined;
    }
}

//#endregion functions

//#region styles
const S = {
    ErrorText: styled.p<{ error?: string }>`
        font-size: ${unitize(14)};
        font-style: italic;
        font-weight: ${designTheme.typography.weight.regular};
        color: ${(props) => (props.error === "Pflichtangabe" ? designTheme.color.error : designTheme.color.warning)};
        text-align: left;
        margin-top: ${unitize(5)};
    `,
    IconWarnTriangleWrapper: styled.div`
        padding: ${unitize(5)};
        cursor: pointer;
    `,
    PopupContent: styled.div`
        p:not(:last-of-type) {
            margin-bottom: 1.2em;
        }
    `,
};
//#endregion styles
