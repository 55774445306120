import * as Yup from "yup";
import { ApiConstraint } from "./apiConstraints";

/*
ignored constraint types:
- Choice, as it has a callback in php code, which is not available in constraints response
- IsTrue
- File, as it's complicated - maybe later
- LessThanOrEqual - TODO
*/

export function toYup(constraints: ApiConstraint[]): Yup.StringSchema | undefined {
    if (constraints.length === 0) {
        return undefined;
    }
    let result = Yup.string();
    constraints.forEach((constraint) => {
        const typeSegments = constraint.type.split("\\");
        const type = typeSegments[typeSegments.length - 1];
        const message = constraint.options.message ?? "";
        const htmlPattern = constraint.options.htmlPattern ?? "";

        switch (type) {
            case "NotBlank":
                result = result.required(
                    message !== "" ? constraint.options.message : "This value should not be blank."
                );
                break;
            case "UniqueUsername":
                // ignore
                break;
            case "Email":
                result = result.email(
                    message !== "" ? constraint.options.message : "This value is not a valid email address."
                );
                break;
            case "Regex":
                result = result.matches(
                    new RegExp(htmlPattern !== "" ? htmlPattern.substring(1, htmlPattern.length - 1) : ".*"),
                    message !== "" ? message : "This value is not valid."
                );
                break;
            case "NotNull":
                // ignore, as Form Inputs never set anything to null, and "" would be allowed
                break;
            case "Country":
                // ignore, as we sould make sure our country options (for SelectBox values) are correct and re-use them
                break;
            default:
                console.warn(`constraint type "${type}" is unexpected and is not handled by toYup.ts`);
                break;
        }
    });
    return result;
}
