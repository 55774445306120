import { Button, designTheme, IconCheck, ModalContent, ModalHeader, unitize } from "@abs-safety/lock-book-web-ui";
import { media } from "@abs-safety/lock-book-web-ui/dist/esm/shared/breakpoints";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { DocumentationType } from "../../../../entities/Documentation";
import { dataLayerService } from "../../../../services/DataLayerService";

interface Step2CompleteDocumentationProps {
    onClose: () => void;
    pdfUrl?: string;
    docuType: DocumentationType;
    onCalendarButtonClick: () => void;
}

const Step2CompleteDocumentation: FunctionComponent<Step2CompleteDocumentationProps> = (
    props: Step2CompleteDocumentationProps
) => {
    return (
        <S.Modal>
            <ModalHeader title="Dokumentation erfolgreich abgeschlossen" />
            <ModalContent>
                <S.ModalContentWrapper>
                    <S.CompletedIcon>
                        <IconCheck width={60} height={60} />
                    </S.CompletedIcon>
                    <S.ModalHeader>
                        <p>Jetzt schon an die nächste Wartung denken!</p>
                    </S.ModalHeader>
                    <S.InfoText>Übernimm die Wartung selbst und erhalte per E‑Mail einen Kalendereintrag.</S.InfoText>
                </S.ModalContentWrapper>
                <S.ButtonWrapper>
                    <Button>
                        <a
                            href={props.pdfUrl}
                            className="uf-openPdf"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() =>
                                dataLayerService.emitOpenPdf({
                                    category: props.docuType,
                                    docuType: props.docuType,
                                    documentType: "documentation",
                                })
                            }
                        >
                            PDF öffnen
                        </a>
                    </Button>
                    <Button>
                        <button
                            className="uf-maintenanceCalendarFileButton_complete"
                            onClick={props.onCalendarButtonClick}
                        >
                            Wartungserinnerung schicken
                        </button>
                    </Button>
                </S.ButtonWrapper>
            </ModalContent>
        </S.Modal>
    );
};

export default observer(Step2CompleteDocumentation);

//#region styles
const S = {
    CompletedIcon: styled.div`
        border-radius: ${unitize(138)};
        border: ${unitize(2)} solid ${designTheme.color.primary};

        display: flex;
        justify-content: center;
        align-items: center;
        margin: ${unitize(12)} auto;
        width: ${unitize(80)};
        height: ${unitize(80)};
        ${media("md")} {
            margin-top: ${unitize(10)};
            width: ${unitize(200)};
            height: ${unitize(200)};
        }
    `,
    Modal: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `,
    ModalContentWrapper: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: ${unitize(26)};
    `,
    ModalHeader: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: ${unitize(10)};
        color: ${designTheme.color.black};

        p {
            font-size: ${unitize(26)};
            font-weight: bold;
            line-height: ${unitize(30)};
        }
    `,
    InfoText: styled.p`
        font-size: ${unitize(19)};
        line-height: ${designTheme.typography.lineHeight.pBig};
        margin-top: ${unitize(15)};
        margin-bottom: ${unitize(10)};
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    `,
    ButtonWrapper: styled.div`
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: ${unitize(10)};
        margin-top: ${unitize(30)};
        ${media("md")} {
            gap: ${unitize(30)};
            flex-direction: row;
        }
    `,
};
//#endregion styles
