import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { DocumentationType } from "../../entities/Documentation";
import { DocumentationController } from "../../pages/Documentation/DocumentationController";
import { getController } from "../../stores/controller/ControllerFactory";
import { AddArticleController, Step } from "./AddArticleController";
import ModalTemplate from "./components/ModalTemplate";
import Step1 from "./components/Step1";
import Step2ArticleChosen from "./components/Step2ArticleChosen";

interface ModalAddArticleProps {
    onCloseClick: () => void;
    buildingAreaId: number;

    /** this Modal can be opened from different pages. Depending on page, the modal behaves differently */
    isInPage: "documentation" | "buildingArea";

    documentationType?: DocumentationType;
}

/**
 * A shared Modal which is used by Page BuildingArea and Page Documentation.
 * It's pretty complex and has therefore also its own Store: ModalStore.
 *
 * Features / Complexity:
 * - If it's displayed in Page Documentation, there is one more Tab (SubView) shown in
 *   Step1 ("Produktverwaltung")
 * - if the user selects (an) article(s) from this Tab (Maintenance Documentation Page only),
 *   a list of selected article(s) and corresponding installedArticle(s) will be displayed,
 *   user can adjust selection and add installedArticles/articles directly to docu
 * - If user selects articles from another tab those articles will be displayed and user can add
 *   them to the docu during the second step(Step2ArticleChosen)
 */
const ModalAddArticle: FunctionComponent<ModalAddArticleProps> = (props: ModalAddArticleProps) => {
    const { controller } = getController(DocumentationController);
    const { controller: modalController, useInit } = getController(AddArticleController);

    useInit(controller.currentId, props.buildingAreaId, props.isInPage, props.documentationType);

    return (
        <ModalTemplate onCloseClick={props.onCloseClick}>
            {modalController.activeStep === Step.Choose && <Step1 onCloseClick={props.onCloseClick} />}
            {modalController.activeStep === Step.ArticleChosen && (
                <Step2ArticleChosen onCloseClick={props.onCloseClick} />
            )}
        </ModalTemplate>
    );
};

export default observer(ModalAddArticle);
