import { Button, Card, Checkbox, ColumnSizes, designTheme, GridColumns, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { IInstalledArticleListCard } from "../../../entities/InstalledArticle";
import { getController } from "../../../stores/controller/ControllerFactory";
import { formatDate } from "../../../utils/formatDate";
import { getInstalledArticlePreviewName } from "../../../utils/getInstalledArticlePreviewName";
import { AreaController } from "../AreaController";
interface InstalledArticlesListCardProps {
    installedArticle: IInstalledArticleListCard;
    columnSizes: ColumnSizes;
    selectMode: "disabled" | "all" | "none";
    selected: boolean;
    onSelected: (checked: boolean) => void;
    onClick: () => void;
    onDeleteClick: () => void;
}

const InstalledArticlesListCard: FunctionComponent<InstalledArticlesListCardProps> = (
    props: InstalledArticlesListCardProps
) => {
    const { controller } = getController(AreaController);
    //#region event handlers

    const onCardClick = () => {
        if (props.selectMode === "disabled") {
            props.onClick();
        } else {
            props.onSelected(!props.selected);
        }
    };

    //#endregion event handlers

    return (
        <Card
            key={props.installedArticle.id}
            className={`card ${props.selected ? "selected" : ""}`}
            disabled={
                controller.waitingFor.deleteInstalledArticles !== false &&
                (controller.waitingFor.deleteInstalledArticles as number[]).indexOf(props.installedArticle.id) !== -1
            }
            onClick={onCardClick}
        >
            <GridColumns columnSizes={props.columnSizes} childrensDisplay="flex">
                <S.ColName>
                    {props.selectMode !== "disabled" && (
                        // stopPropagation, so it doesn't count as a card click
                        <span onClick={(e) => e.stopPropagation()}>
                            <Checkbox
                                checked={props.selected}
                                onChange={props.onSelected}
                                //onChange={(checked) => onCheckboxClick(props.installedArticle.id, checked)}
                            />
                        </span>
                    )}
                    {getInstalledArticlePreviewName(props.installedArticle)}
                </S.ColName>
                <div>{formatDate(props.installedArticle.installationDate)}</div>
                <div>{props.installedArticle.amountAssemblies}</div>
                <S.LastColumn>
                    <div>{props.installedArticle.amountMaintenances}</div>
                    <div>
                        {props.selectMode === "disabled" && (
                            <S.HiddenButton className="hidden-button">
                                <Button variant="text" color="decline">
                                    <button
                                        // hide on mobile as it's anyway only visible when hovering and mobile has no hover
                                        className="d-xs-none d-md-inline-flex"
                                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                                            props.onDeleteClick();
                                            // avoid that onCardClick triggers as well. Not perfect solution, as other eventListeners might miss the event
                                            // like window event listeners
                                            e.stopPropagation();
                                        }}
                                    >
                                        Löschen
                                    </button>
                                </Button>
                            </S.HiddenButton>
                        )}
                    </div>
                </S.LastColumn>
            </GridColumns>
        </Card>
    );
};

export default observer(InstalledArticlesListCard);

//#region styles
const S = {
    ColName: styled.div`
        font-weight: ${designTheme.typography.weight.bold};
        display: flex;
        align-items: center;
        /* WAITFOR LBWEBUI-328 and remove this workaround styles: */
        input[type="checkbox"] {
            margin-top: 0;
            margin-bottom: 0;
            margin-left: 0;
        }
        label {
            min-height: ${unitize(21)};
            min-width: ${unitize(31)};
        }
        /* END WAITFOR */
        label {
            margin: 0;
        }
    `,
    LastColumn: styled.div`
        display: flex;
        align-items: stretch;
        justify-content: space-between;
    `,
    HiddenButton: styled.div`
        display: none;
        padding: 0;
        margin-right: ${unitize(30)};
        float: right;
        button {
            height: unset;
        }
    `,
};
//#endregion styles
