import { Button, CardDark, unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent } from "react";

interface PopupUnansweredMandatoryQuestionsProps {
    onClose: () => void;
}

const PopupUnansweredMandatoryQuestions: FunctionComponent<PopupUnansweredMandatoryQuestionsProps> = (
    props: PopupUnansweredMandatoryQuestionsProps
) => {
    return (
        <CardDark style={{ position: "absolute", top: "100" }}>
            <h4>Nicht alle Pflichtfragen sind beantwortet</h4>
            <p>Alle Pflichtfragen müssen beantwortet sein, um Artikel freizugeben oder zu sperren.</p>
            <Button style={{ marginTop: unitize(48) }} variant="outline" color="white">
                <button onClick={props.onClose}>OK</button>
            </Button>
        </CardDark>
    );
};

export default PopupUnansweredMandatoryQuestions;
