import { ApiResponse } from "../api/ApiService";
import { AreaApi } from "../api/AreaApi";
import { BuildingApi } from "../api/BuildingApi";
import { IBuildingRead } from "../entities/Building";
import { areaRepo, IBuildingAreaRead, IBuildingAreaUpdate, IBuildingAreaWrite } from "../entities/BuildingArea";
import { notificationHandler } from "../session/NotificationHandler";
import { DataService } from "./abstract/DataService";
import { articleService } from "./ArticleService";

class AreaService extends DataService<"all", IBuildingAreaRead, IBuildingAreaUpdate | IBuildingAreaWrite> {
    repo = areaRepo;
    apiService = new AreaApi();
    buildingApi = new BuildingApi();

    constructor() {
        super({ all: false });
    }

    getAreasByBuildingId(buildingId: IBuildingRead["id"]): IBuildingAreaRead[] {
        return this.list.filter((item) => {
            return item.buildingId === buildingId;
        });
    }

    getAreaByBuildingAreaId(areaId?: null | number): string | undefined {
        return this.list.find((area) => area.id === areaId)?.name;
    }

    async fetchAreasByBuilding(id: number): Promise<ApiResponse<IBuildingAreaRead[]>> {
        return await this.resolveAsAction({
            promise: () => this.buildingApi.getBuildingAreas(id),
            waitingForKey: ["fetchList"],
            action: (result) => {
                if (result.result) {
                    this.mergeList(result.result);
                }
                return result;
            },
        });
    }

    async deleteArea(buildingArea: IBuildingAreaRead) {
        const articleOverview = await articleService.fetchArticlesByArea(buildingArea.id);
        const articleNumber = articleOverview.result?.map((res) => res.installedArticles).length;
        if (
            (articleNumber !== undefined && articleNumber > 0) ||
            buildingArea.amountAssemblies > 0 ||
            buildingArea.amountMaintenances > 0
        ) {
            return notificationHandler.addNotification({
                title: "Dachfläche kann nicht gelöscht werden",
                description: "Die Dachfläche enthält noch Produkte oder Dokumentationen.",
                type: "error",
            });
        }

        return this.resolveAsAction({
            promise: () => this.apiService.delete<IBuildingAreaRead>(`/${buildingArea.id}`),
            waitingForKey: "delete",
            setWaitingForValueTo: buildingArea.id,
            action: (result) => {
                if (result.response?.ok === true) {
                    this.drop(buildingArea.id);
                    this.repo.total--;
                }

                return result;
            },
        });
    }
}

export const areaService = new AreaService();
