import {
    BreadcrumbProps,
    IconArea,
    IconBuilding,
    IconHammer,
    IconList,
    IconWrench,
} from "@abs-safety/lock-book-web-ui";
import { breakpoints } from "@abs-safety/lock-book-web-ui/dist/esm/shared/breakpoints";
import React from "react";
import { useHistory } from "react-router-dom";
import { IBuildingRead } from "../entities/Building";
import { IBuildingAreaRead } from "../entities/BuildingArea";
import { IDocumentationRead } from "../entities/Documentation";
import { isNullish } from "./isNullish";

export enum PageDepth {
    BuildingList = 0,
    Building = 1,
    BuildingArea = 2,
    Documentation = 3,
}

export interface Controller {
    building?: Pick<IBuildingRead, "name" | "id">;
    buildingArea?: Pick<IBuildingAreaRead, "name" | "id">;
    documentation?: Pick<IDocumentationRead, "name" | "type">;
}

function getTitle(entity: { name?: null | string } | undefined, fallback: string) {
    if (isNullish(entity)) {
        return fallback;
    }
    if (isNullish(entity.name) || entity.name === "") {
        return "Unbenannt";
    }
    return entity.name;
}

export function useBreadcrumb(page: PageDepth, controller: Controller): BreadcrumbProps {
    const history = useHistory();
    const allItems: BreadcrumbProps["data"] = [
        {
            title: "Objektübersicht",
            icon: <IconList />,
            hideTextIfWindowSmallerThan: breakpoints.md,
            onClick: () => history.push("/buildings"),
        },
        {
            title: getTitle(controller.building, "lädt..."),
            currentView: page === PageDepth.Building,
            hideTextIfWindowSmallerThan: page === PageDepth.Building ? undefined : breakpoints.md,
            icon: <IconBuilding />,
            onClick: () => history.push(`/buildings/${controller.building?.id}`),
        },
        {
            title: getTitle(controller.buildingArea, "Dachfläche..."),
            currentView: page === PageDepth.BuildingArea,
            hideTextIfWindowSmallerThan: page === PageDepth.BuildingArea ? undefined : breakpoints.md,
            icon: <IconArea />,
            onClick: () => history.push(`/buildings/${controller.building?.id}/areas/${controller.buildingArea?.id}`),
        },
        {
            title: getTitle(controller.documentation, "Dokumentation..."),
            currentView: page === PageDepth.Documentation,
            icon: controller.documentation?.type === "assembly" ? <IconHammer /> : <IconWrench />,
        },
    ];
    switch (page) {
        case PageDepth.Building:
            return { data: allItems.slice(0, 2) };
        case PageDepth.BuildingArea:
            return { data: allItems.slice(0, 3) };
        case PageDepth.Documentation:
            return { data: allItems.slice(0, 4) };
        default:
            return { data: [] };
    }
}
