import { Button, designTheme, unitize } from "@abs-safety/lock-book-web-ui";
import { Form, Formik } from "formik";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import FormAutoSubmit from "../../../../components/FormAutoSubmit";
import FormGtmIntegration from "../../../../components/FormGtmIntegration";
import FormFieldsInstalledArticle from "../../../../components/ModalAddArticle/components/FormFieldsInstalledArticle";
import { getConstraints } from "../../../../constraints/constraints";
import { IInstalledArticleBase, InstalledArticlesFormValues } from "../../../../entities/InstalledArticle";
import { documentationItemService } from "../../../../services/DocumentationItemService";
import { notificationHandler } from "../../../../session/NotificationHandler";
import { session } from "../../../../session/Session";
import { getController } from "../../../../stores/controller/ControllerFactory";
import { apiConstraintErrorsToFormErrors } from "../../../../utils/apiConstraintErrorsToFormErrors";
import { gtmAutoSubmit } from "../../../../utils/gtmEventCollection";
import { isDefined } from "../../../../utils/isDefined";
import { ViewOfInstalledArticleForm } from "../../../../utils/isInstalledArticleFormFieldVisible";
import { isNullish } from "../../../../utils/isNullish";
import { processInstalledArticleFormData } from "../../../../utils/processInstalledArticleFormData";
import PopupDeleteArticle from "../../components/PopupDeleteArticle";
import { DocumentationController } from "../../DocumentationController";
import { DocumentController } from "../DocumentController";

interface ItemDetailsProps {
    selectedInstalledArticle: Pick<
        IInstalledArticleBase,
        | "id"
        | "identificationNumber"
        | "identificationCounter"
        | "absSerialNumber"
        | "comment"
        | "serialNumber"
        | "identificationPlateNumber"
        | "isDeleted"
    >;
}

const ItemDetails: FunctionComponent<ItemDetailsProps> = (props: ItemDetailsProps) => {
    const [popupDeleteArticleOpen, setPopupDeleteArticleOpen] = useState(false);
    const { controller: documentController } = getController(DocumentController);
    const { controller: documentationController } = getController(DocumentationController);

    const documentationCompleted = documentationController.documentation?.isCompleted;

    const onDeleteClick = () => {
        documentationItemService.delete(documentController.currentDocumentationItemIds[0]).then((res) => {
            if (!(res.response?.ok ?? false)) return;
            const article =
                documentationController.articles.find((article) => article.id === documentController.currentArticle) ??
                documentationController.articles[0];

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (article !== undefined) {
                documentController.setCurrentArticle(article.id);
                documentController.setCurrentDocumentationItemIds([article.documentationItems[0].id]);
            }

            const serialNumber = !isDefined(props.selectedInstalledArticle.absSerialNumber)
                ? props.selectedInstalledArticle.absSerialNumber
                : props.selectedInstalledArticle.serialNumber;

            notificationHandler.addNotification({
                title: "Artikel wurde gelöscht",
                description: `Artikel
                    "${article.name}" 
                      ${
                          isNullish(serialNumber)
                              ? ``
                              : `mit der Seriennummer "${props.selectedInstalledArticle.absSerialNumber}"`
                      } 
                     wurde erfolgreich aus der Dokumentation entfernt.`,
                type: "success",
            });
        });
        setPopupDeleteArticleOpen(false);
    };

    return (
        <S.Component>
            {popupDeleteArticleOpen && (
                <PopupDeleteArticle
                    onClose={() => setPopupDeleteArticleOpen(false)}
                    onDelete={onDeleteClick}
                    itemDeletedInProductManagement={props.selectedInstalledArticle.isDeleted}
                />
            )}
            <ObservedFormikForm {...props} />

            {documentationCompleted !== true && (
                <Button color="decline" size="small" variant="outline" style={{ marginTop: unitize(20) }}>
                    <button
                        type="button"
                        onClick={() => {
                            setPopupDeleteArticleOpen(true);
                        }}
                    >
                        Artikel aus Dokumentation entfernen
                    </button>
                </Button>
            )}
        </S.Component>
    );
};

const FormikForm = (props: ItemDetailsProps) => {
    const { controller: documentationController } = getController(DocumentationController);
    const { controller: documentController } = getController(DocumentController);

    const [validationSchema, setValidationSchema] = useState(
        Yup.object().shape<Partial<InstalledArticlesFormValues>>({})
    );

    const articleType = documentController.article.type;

    if (isNullish(articleType) || documentationController.documentation === undefined) {
        return null;
    }

    const documentationCompleted = documentationController.documentation.isCompleted;
    const inView: ViewOfInstalledArticleForm =
        documentationController.documentation.type === "assembly"
            ? "documentation/assembly"
            : "documentation/maintenance";

    useEffect(() => {
        setValidationSchema(
            Yup.object().shape<Partial<InstalledArticlesFormValues>>({
                identificationNumber: getConstraints("InstalledArticle", "identificationNumber"),
                identificationCounter: getConstraints("InstalledArticle", "identificationCounter"),
                absSerialNumber: getConstraints("InstalledArticle", "absSerialNumber")?.nullable(true),
                comment: getConstraints("InstalledArticle", "comment"),
                identificationPlateNumber: getConstraints("InstalledArticle", "identificationPlateNumber")?.nullable(
                    true
                ),
            })
        );
    }, [session.constraints]);

    return (
        <Formik
            key={props.selectedInstalledArticle.id}
            initialValues={
                {
                    identificationNumber: props.selectedInstalledArticle.identificationNumber,
                    identificationCounter: props.selectedInstalledArticle.identificationCounter,
                    absSerialNumber: props.selectedInstalledArticle.absSerialNumber,
                    comment: props.selectedInstalledArticle.comment,
                    serialNumber: props.selectedInstalledArticle.serialNumber,
                    identificationPlateNumber: props.selectedInstalledArticle.identificationPlateNumber,
                } as InstalledArticlesFormValues
            }
            validationSchema={validationSchema}
            onSubmit={(values, helpers) => {
                const cleanedValues = processInstalledArticleFormData(values, inView, articleType);
                documentController
                    .updateInstalledArticle(props.selectedInstalledArticle.id, cleanedValues)
                    .catch((error) => apiConstraintErrorsToFormErrors(error, helpers.setErrors));
            }}
            enableReinitialize
        >
            {(formikBag) => (
                <Form style={{ maxWidth: unitize(720) }}>
                    {props.selectedInstalledArticle.isDeleted === true && (
                        <S.NotEditableText>
                            Artikel wurde aus der Produktverwaltung gelöscht, und kann nicht mehr bearbeitet werden.
                        </S.NotEditableText>
                    )}
                    {documentationCompleted === true && (
                        <S.NotEditableText>
                            Artikel kann nicht bearbeitet werden, wenn die Dokumentation abgeschlossen wurde.
                        </S.NotEditableText>
                    )}
                    <FormFieldsInstalledArticle
                        articleType={articleType}
                        view={inView}
                        amountInstalledArticles={1}
                        values={formikBag.values}
                        disabled={documentationCompleted === true || props.selectedInstalledArticle.isDeleted === true}
                    />

                    <FormAutoSubmit onSuccess={gtmAutoSubmit("docuitem")} />
                    <FormGtmIntegration action="edit_docuitem" />
                </Form>
            )}
        </Formik>
    );
};
const ObservedFormikForm = observer(FormikForm);

export default observer(ItemDetails);

//#region styles
const S = {
    NotEditableText: styled.p`
        margin: ${unitize(20)} 0;
        border-radius: ${designTheme.borderRadius};
        border: 1px solid ${designTheme.color.warning};
        color: ${designTheme.color.warning};
        padding: ${unitize(20)};
    `,
    Component: styled.div`
        margin: ${unitize(20)} 0;
    `,
    NamingPreview: styled.div`
        padding: ${unitize(20)};
        background-color: ${designTheme.color.lightestgrey};
        margin-bottom: ${unitize(30)};

        ul {
            margin-top: ${unitize(10)};
            margin-bottom: 0;
            padding-left: 0;
            list-style-position: inside;
        }
    `,
    NotFoundText: styled.h4`
        margin-top: ${unitize(20)};
    `,
};
//#endregion styles
