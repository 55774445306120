import { SideModal } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import FileEditForm from "../../../components/FileEditForm";
import FilesGrid from "../../../components/FilesGrid";
import { attachmentService } from "../../../services/AttachmentService";
import { getController } from "../../../stores/controller/ControllerFactory";
import { isDefined } from "../../../utils/isDefined";
import { layout } from "../../../utils/layout";
import { DocumentationController } from "../DocumentationController";

const ViewFiles: FunctionComponent = () => {
    const { controller } = getController(DocumentationController);
    const { path, url } = useRouteMatch();
    const history = useHistory();

    const isLocked = isDefined(controller.documentation) && controller.documentation.isCompleted;

    useEffect(() => {
        if (controller.files.length < 1) {
            controller.loadFiles();
        }
    }, []);

    return (
        <>
            {/* Subrouting */}
            <Switch>
                <Route path={`${path}/file/:id`}>
                    <SideModal
                        className="uf-sideModal"
                        isOpen
                        onCloseClick={() => history.push(`${url}`)}
                        style={{
                            top: `${layout.header.height}px`,
                            height: `calc(100% - ${layout.header.height}px)`,
                        }}
                        views={[
                            {
                                key: "file",
                                width: 750,
                                component: (
                                    <FileEditForm
                                        deleteFile={controller.deleteFile.bind(controller)}
                                        files={controller.files}
                                        updateFile={controller.updateFile.bind(controller)}
                                        waitingForFiles={controller.waitingFor.loadFiles}
                                        reloadUrl={() => controller.loadFiles(true)}
                                        waitingForReloadUrl={controller.waitingFor.reloadUrl}
                                    />
                                ),
                            },
                        ]}
                    />
                </Route>
            </Switch>
            <FilesGrid
                files={controller.files}
                isLocked={isLocked}
                waitingForLoadFiles={attachmentService.waitingFor.all !== false}
                waitingForUploadFile={attachmentService.waitingFor.upload}
                waitingForDeleteFile={attachmentService.waitingFor.delete}
                //cache it wrap it or bind it - else it will lose it's context
                onUploadFiles={controller.uploadFiles.bind(controller)}
                onDeleteFile={controller.deleteFile.bind(controller)}
                onUpdateFile={controller.updateFile.bind(controller)}
                loadFiles={() => controller.loadFiles.bind(controller)(true)}
            />
        </>
    );
};

export default observer(ViewFiles);
