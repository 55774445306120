import { IArticleBase } from "../../../entities/Article";

type ArticlePartial = Pick<IArticleBase, "type">;

/**
 * filter articles by article.type
 *
 * - empty articleTypeFilter: show all
 * - otherwise: show only articles from type in articleTypeFilter Set
 */
export const filterArticleByType = <T extends ArticlePartial>(articles: T[], articleTypeFilter: Set<string>): T[] =>
    articles.filter((a) => articleTypeFilter.size < 1 || articleTypeFilter.has(a.type));
