import { unitize } from "@abs-safety/lock-book-web-ui";
import { Form, FormikProps } from "formik";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import FormAutoSubmit from "../../../../../components/FormAutoSubmit";
import FormGtmIntegration from "../../../../../components/FormGtmIntegration";
import FormFieldsInstalledArticle from "../../../../../components/ModalAddArticle/components/FormFieldsInstalledArticle";
import { ArticleType } from "../../../../../entities/Article";
import { InstalledArticlesFormValues } from "../../../../../entities/InstalledArticle";
import { gtmAutoSubmit } from "../../../../../utils/gtmEventCollection";

type FormInstalledArticleProps = FormikProps<InstalledArticlesFormValues> & {
    articleType: ArticleType;
};

const FormInstalledArticle: FunctionComponent<FormInstalledArticleProps> = (props: FormInstalledArticleProps) => {
    return (
        <Form style={{ marginTop: unitize(50) }}>
            <FormFieldsInstalledArticle
                articleType={props.articleType}
                view="buildingArea"
                amountInstalledArticles={1}
                values={props.values}
            />
            <FormAutoSubmit onSuccess={gtmAutoSubmit("docuitem")} />
            <FormGtmIntegration />
        </Form>
    );
};

export default observer(FormInstalledArticle);
