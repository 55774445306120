import {
    Button,
    FormControl,
    IconCopy,
    Input,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import FieldLoading from "../../../components/FieldLoading";
import { dataLayerService } from "../../../services/DataLayerService";
import { documentationSharingHashService } from "../../../services/DocumentationSharingHashService";
import { notificationHandler } from "../../../session/NotificationHandler";
import { getController } from "../../../stores/controller/ControllerFactory";
import { formatDate } from "../../../utils/formatDate";
import { getSharingLinkOfHash } from "../../../utils/getSharingLinkOfHash";
import { DocumentationController } from "../DocumentationController";

interface ModalGenerateSharingLinkProps {
    onClose: () => void;
}

const ModalGenerateSharingLink: FunctionComponent<ModalGenerateSharingLinkProps> = (
    props: ModalGenerateSharingLinkProps
) => {
    const { controller } = getController(DocumentationController);
    const latestSharingLink =
        controller.documentation !== undefined
            ? documentationSharingHashService.getNewestSharingHashOf(controller.documentation.id, "documentation")
            : undefined;

    const copyLink = (trigger: "icon" | "button") => {
        if (latestSharingLink === undefined) {
            dataLayerService.emitClick({
                category: controller.documentation?.type ?? "documentation",
                action: "copy_pdf-sharing",
                label: trigger,
                payload: "failed",
            });
            return;
        }
        const link = getSharingLinkOfHash(latestSharingLink.hash);

        navigator.clipboard.writeText(link).then(() => {
            notificationHandler.addNotification({
                title: "PDF Link",
                description: "Der Link zu Deinem PDF wurde erfolgreich in die Zwischenablage kopiert",
                type: "success",
            });
        });

        dataLayerService.emitClick({
            category: controller.documentation?.type ?? "documentation",
            action: "copy_pdf-sharing",
            label: trigger,
            payload: "success",
        });
    };

    return (
        <S.ModalWrapper className={"uf-modalWrapper"}>
            <Modal
                isOpen={true}
                size="md"
                onCloseClick={() => {
                    props.onClose();
                }}
            >
                <ModalHeader title="PDF Link generieren" />
                {controller.waitingFor.createSharingHash ? (
                    <ModalContent>
                        <FieldLoading text="Link wird generiert" height={unitize(300)} />
                    </ModalContent>
                ) : (
                    <ModalContent>
                        <p>
                            Bitte kopiere die URL um den Link zu teilen. Dieser Link ist 60 Tage gültig.
                            <br />
                            Danach muss ein neuer Link generiert werden.
                        </p>

                        <S.CopyForm>
                            <FormControl>
                                <Input
                                    label="Ablaufdatum"
                                    type="text"
                                    value={latestSharingLink ? formatDate(latestSharingLink?.expiresAt) : ""}
                                    readOnly={true}
                                />
                            </FormControl>

                            <FormControl size="sm">
                                <S.PdfUrl>
                                    <Input
                                        label="URL zum Dokumentations-Link"
                                        type="text"
                                        value={latestSharingLink ? getSharingLinkOfHash(latestSharingLink.hash) : ""}
                                        readOnly={true}
                                    />
                                    <S.CopyIconWrapper
                                        className={"uf-copySharingIcon"}
                                        onClick={() => copyLink("icon")}
                                    >
                                        <IconCopy width={24} height={24} />
                                    </S.CopyIconWrapper>
                                </S.PdfUrl>
                            </FormControl>
                        </S.CopyForm>
                    </ModalContent>
                )}

                <ModalFooter>
                    <Button disabled={controller.waitingFor.createSharingHash === true}>
                        <button className={"uf-copySharingButton"} onClick={() => copyLink("button")}>
                            Link kopieren
                        </button>
                    </Button>
                    <Button variant="text" color="black">
                        <button onClick={props.onClose}>Abbrechen</button>
                    </Button>
                </ModalFooter>
            </Modal>
        </S.ModalWrapper>
    );
};

export default observer(ModalGenerateSharingLink);

//#region styles
const S = {
    CopyForm: styled.div`
        margin: ${unitize(30)} 0;
    `,
    PdfUrl: styled.div`
        display: flex;
        align-items: center;
        input {
            padding-right: ${unitize(20)};
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        label {
            width: 100%;
        }
        svg {
            position: absolute;
        }
    `,
    CopyIconWrapper: styled.div`
        cursor: pointer;
        height: ${unitize(24)};
        width: ${unitize(24)};
    `,
    ModalWrapper: styled.div`
        position: absolute;
        width: 100vw;
        height: 100vw;
        max-width: 100%;
        max-height: 100%;
        top: 0;
        left: 0;
    `,
};
//#endregion styles
