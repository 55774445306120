import { computed, makeObservable, observable, runInAction } from "mobx";
import { IEntityRead } from "../entities/Base";
import { isNullish } from "../utils/isNullish";

/**
 * helper method to use RepoStore with Entities that don't have an ID
 * @param value
 * @param object
 */
export const getRepoIndexFromString = <T extends IEntityRead>(value: string, object: T): T => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (object.id !== undefined) {
        return object;
    }
    object.id = Math.asinh(parseInt(value.replace(/[^A-Za-z0-9]/g, ""), 32));

    return object;
};

/**
 * Abstract base of all so-called Repos in Docu
 * Holds a list of entity instance of type as defined in the `Read` generic of the class
 * It's meant to be used as a singleton
 * @example
 * ```ts
 * class SomeRepo extends RepoStore<SomeEntityType> {}
 * export const someRepo = new SomeRepo();
 * ```
 */
export abstract class RepoStore<Read extends IEntityRead> {
    /**
     * The id list, defines mainly the sorting of our Items
     * @private
     */
    private _listIds: number[] = [];
    /**
     * The record of our items. Defined to use like Record<IEntityRead['id'], IEntityRead>
     * @private
     */
    private _listObject: Record<number, Read | undefined> = {};

    constructor() {
        makeObservable<RepoStore<Read>, "_listIds" | "_listObject" | "_total">(this, {
            _listIds: observable,
            _listObject: observable,
            list: computed,
            _total: observable,
        });
    }
    get listObject(): Record<number, Read | undefined> {
        return this._listObject;
    }

    get list(): Read[] {
        return this.getMultiple(this._listIds);
    }

    /**
     * sets the complete list, overrides old data
     */
    set list(value: Read[]) {
        const newList: Record<number, Read> = {};
        const newListIds: number[] = [];

        for (const read of value) {
            newList[read.id] = read;
            newListIds.push(read.id);
        }
        this.setListObject(newList, newListIds);
    }

    /**
     * length of _listIds
     */
    get length(): number {
        return this._listIds.length;
    }

    private _total = 0;

    get total(): number {
        return this._total;
    }

    /**
     * wraps an action call
     */
    set total(value: number) {
        runInAction(() => {
            this._total = value;
        });
    }

    /**
     * returns an instance from list by id or undefined
     * @example
     * ```ts
     * someRepo.get(1) // returns instance with ID=1 or undefined
     * ```
     */
    get(id?: number): Read | undefined {
        if (isNullish(id)) return;
        return this.listObject[id];
    }

    /**
     * returns multiple instances from list by given ids (if defined)
     * result can be smaller than the length of the given ids because not defined instances won't be added to the result
     * @example
     * ```ts
     * someRepo.getMultiple([1, 2,...,99]) // returns an array of instances with given IDs (can have a length of 0 if non are found)
     * ```
     */
    getMultiple(ids: (number | undefined)[]): Read[] {
        return ids.reduce((arr: Read[], id) => {
            if (isNullish(id)) return arr;
            const match = this.listObject[id];
            if (match !== undefined) {
                arr.push(match);
            }
            return arr;
        }, []);
    }

    /**
     * drop one or more items from list by given id/ids
     * @example
     * ```ts
     * someRepo.drop(1) // drops instance with id 1
     * someRepo.drop([1,2,...]) // drops all existing instances by given ids
     * ```
     */
    drop(id?: number | number[]): void {
        if (isNullish(id)) {
            return;
        }

        if (!Array.isArray(id)) {
            id = [id];
        }

        const tempListObj = { ...this.listObject };
        const tempListIds = [...this._listIds];
        for (const number of id) {
            delete tempListObj[number];

            const index = tempListIds.indexOf(number);
            if (index >= 0) {
                tempListIds.splice(index, 1);
            }
        }

        this.setListObject(tempListObj, tempListIds);
    }

    /**
     * prepends a new item to list, ignores if it already exists in id list, but will not double the actual instances in the record.
     * @example
     * ```ts
     * // given list [1,2,3]
     * someRepo.prependList({id:3}) // possible result [3,1,2,3]
     * ```
     */
    prependList(items?: Read | Read[] | null): void {
        if (isNullish(items)) {
            return;
        }

        if (!Array.isArray(items)) {
            items = [items];
        }

        this.extendList(items, true, false);
    }

    /**
     * appends a new item to list, ignores if it already exists in id list, but will not double the actual instances in the record.
     * @example
     * ```ts
     * // given list [1,2,3]
     * someRepo.prependList({id:1}) // possible result [1,2,3,1]
     * ```
     */
    appendList(items?: Read | Read[] | null): void {
        if (isNullish(items)) {
            return;
        }
        if (!Array.isArray(items)) {
            items = [items];
        }

        this.extendList(items, false, false);
    }

    /**
     * merges new items to list, replaces already existing instances with same id
     * @param items
     * @param prepend can be set to true, results to a behaviour like `this.prependList`
     * @example
     * ```ts
     * // given list [1,2,3]
     * someRepo.prependList({id:1}) // possible result [1(new),2,3]
     * ```
     */
    mergeList(items?: Read | Read[], prepend = false): void {
        if (isNullish(items)) {
            return;
        }

        if (!Array.isArray(items)) {
            items = [items];
        }

        this.extendList(items, prepend, true);
    }

    /**
     * base method for list handling, used by this.prependList, this.appendList, this.mergeList
     */
    private extendList(items: Read[], prepend: boolean, merge: boolean) {
        const tempListObj = { ...this.listObject };
        const tempListIds = [...this._listIds];
        const newListIds: number[] = [];

        for (const item of items) {
            if (tempListObj[item.id] !== undefined && merge) {
                tempListObj[item.id] = { ...tempListObj[item.id], ...item };
                continue;
            } else {
                tempListObj[item.id] = item;
            }

            const index = tempListIds.indexOf(item.id);

            if (index >= 0 && merge) tempListIds.splice(index, 1);

            newListIds.push(item.id);
        }

        if (prepend) {
            this.setListObject(tempListObj, [...newListIds, ...tempListIds]);
        } else {
            this.setListObject(tempListObj, [...tempListIds, ...newListIds]);
        }
    }

    /**
     * overrides the whole list object and id list array
     */
    private setListObject(value: Record<number, Read | undefined>, ids: number[]) {
        runInAction(() => {
            this._listObject = value;
            this._listIds = ids;
            this._total = ids.length;
        });
    }
}
