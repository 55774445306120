import { unitize } from "@abs-safety/lock-book-web-ui";
import styled from "styled-components";

export const ProductFormGrid = styled.div`
    display: grid;
    height: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 100px;
    column-gap: ${unitize(30)};
`;
