import Fuse from "fuse.js";
import { DocumentationController } from "../../../pages/Documentation/DocumentationController";
import { dataLayerService } from "../../../services/DataLayerService";
import { getController } from "../../../stores/controller/ControllerFactory";

export function fuzzySearchArticles<T>(articles: T[], searchInput: string): T[] {
    if (searchInput === "") return articles;
    const fuse = new Fuse(articles, {
        keys: ["name", "number"],
        threshold: 0.4,
        includeScore: true,
    });
    const { controller } = getController(DocumentationController);
    const result = fuse.search(searchInput).map((result) => result.item);
    const type = controller.documentation?.type ?? "documentation";

    dataLayerService.emitSearch({
        category: type,
        payload: { found: result.length },
        label: searchInput,
    });

    return result;
}
