import { Dropdown, IconFilter, IconFilterFilled } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { IDocumentationItemOfArticleOverview } from "../../../../../api/DocumentationApi";
import { getController } from "../../../../../stores/controller/ControllerFactory";
import { DocumentController } from "../../DocumentController";
import { Filter } from "../ColDocumentationItems";

interface FilterDocumentationItemsProps {
    allDocumentationItems: IDocumentationItemOfArticleOverview[];
    onFilter: (filteredDocumentationItems: IDocumentationItemOfArticleOverview[]) => void;
}

const FilterDocumentationItems: FunctionComponent<FilterDocumentationItemsProps> = (
    props: FilterDocumentationItemsProps
) => {
    const { controller: documentController } = getController(DocumentController);
    const [activeFilter, setActiveFilter] = useState<Filter | undefined>(undefined);

    useEffect(() => {
        // TODO use MOBX autorun or similar instead of listen in useEffect dependencies to store value
        props.onFilter(props.allDocumentationItems);
        setActiveFilter(undefined);
    }, [documentController.article, documentController.documentationItemsByArticle.length]);

    const applyFilter = (filter: Filter | undefined) => {
        // use if / else bc state is too slow
        if (filter === activeFilter) {
            setActiveFilter(undefined);
            props.onFilter(props.allDocumentationItems);
        } else {
            switch (filter) {
                case "successful":
                    props.onFilter(
                        props.allDocumentationItems.filter((item) => item.isSuccessful && item.questionsAnswered)
                    );
                    break;
                case "locked":
                    props.onFilter(
                        props.allDocumentationItems.filter((item) => !item.isSuccessful && item.questionsAnswered)
                    );
                    break;
                case "notDocumented":
                    props.onFilter(props.allDocumentationItems.filter((item) => !item.questionsAnswered));
                    break;
                case undefined:
                default:
                    props.onFilter(props.allDocumentationItems);
            }
            setActiveFilter(filter);
        }
    };

    return (
        <Dropdown
            dropdownAlign="right"
            items={[
                {
                    checkmark: activeFilter === "notDocumented",
                    text: "Offen",
                    onClick: () => {
                        applyFilter("notDocumented");
                    },
                },
                {
                    checkmark: activeFilter === "successful",
                    text: "Freigegeben",
                    onClick: () => {
                        applyFilter("successful");
                    },
                },
                {
                    checkmark: activeFilter === "locked",
                    text: "Gesperrt",
                    onClick: () => {
                        applyFilter("locked");
                    },
                },
                {
                    hidden: activeFilter === undefined,
                    text: "Filter zurücksetzen",
                    onClick: () => {
                        applyFilter(undefined);
                    },
                },
            ]}
            width={266}
            toggleNode={
                <>
                    {activeFilter ? (
                        <IconFilterFilled />
                    ) : (
                        <S.ToggleIconOnHover>
                            <IconFilter />
                            <IconFilterFilled />
                        </S.ToggleIconOnHover>
                    )}
                </>
            }
            toggleNodeWhenOpened={<IconFilterFilled />}
        />
    );
};

export default FilterDocumentationItems;

//#region styles
const S = {
    ToggleIconOnHover: styled.div`
        line-height: 0;

        @media (hover: hover) {
            &:hover {
                svg:first-child {
                    display: none;
                }

                svg:last-child {
                    display: block;
                }
            }
        }

        svg:last-child {
            display: none;
        }
    `,
};
//#endregion styles
