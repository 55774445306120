import { ModalHeader } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useState } from "react";
import { articleService } from "../../../services/ArticleService";
import { getController } from "../../../stores/controller/ControllerFactory";
import FieldLoading from "../../FieldLoading";
import { AddArticleController, SubViewKey } from "../AddArticleController";
import Step1AvailableArticles from "./Step1AvailableArticles";
import Step1NewArticles from "./Step1NewArticles";

interface Step1Props {
    onCloseClick: () => void;
}

const Step1: FunctionComponent<Step1Props> = (props: Step1Props) => {
    const [search, setSearch] = useState<string>("");
    const { controller: modalController } = getController(AddArticleController);
    return (
        <>
            <ModalHeader
                title={`Produkt ${modalController.isInPage === "documentation" ? "hinzufügen" : "anlegen"}`}
                subtitle={
                    modalController.documentationType === "maintenance" &&
                    modalController.subView === SubViewKey.Available
                        ? ""
                        : "Schritt 1/2"
                }
            />
            <>
                {modalController.subView === SubViewKey.Available &&
                    (modalController.isWaitingFor("loadBuildingAreaArticleOverview") ? (
                        <FieldLoading text="Produktverwaltung wird geladen" />
                    ) : (
                        <Step1AvailableArticles
                            articles={modalController.buildingAreaArticleOverview}
                            searchValue={search}
                            onSearchChange={setSearch}
                            onCloseClick={props.onCloseClick}
                        />
                    ))}
                {[
                    SubViewKey.FallProtection,
                    SubViewKey.Custom,
                    SubViewKey.Misc,
                    SubViewKey.Climb,
                    SubViewKey.Drainage,
                    SubViewKey.Ventilation,
                ].indexOf(modalController.subView as SubViewKey) > -1 && (
                    <Step1NewArticles
                        articles={articleService.nonDeletedArticles}
                        searchValue={search}
                        onSearchChange={setSearch}
                        onCloseClick={props.onCloseClick}
                    />
                )}
            </>
        </>
    );
};

export default observer(Step1);
