import { Button, ButtonClose, media, Modal, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import styled from "styled-components";

interface ModalSelectAddArticleProps {
    onCloseClick: () => void;
    onClick: () => void;
    handleShowDeliveryNoteProducts: () => void;
    isOpen: boolean;
}

const ModalSelectAddArticle: FunctionComponent<ModalSelectAddArticleProps> = (props: ModalSelectAddArticleProps) => {
    return (
        <S.ModalSelectAddArticle>
            <Modal onCloseClick={props.onCloseClick} isOpen={props.isOpen} size="sm">
                <div className={"uf-addProductsChoice"}>
                    <S.ButtonClose>
                        <ButtonClose onClick={props.onCloseClick} />
                    </S.ButtonClose>
                    <S.HeadlineTitle>
                        <S.HeadlineText>Produkte anlegen</S.HeadlineText>
                    </S.HeadlineTitle>
                    <S.TextWrapper>
                        <S.Description>
                            Lege ein Produkt an oder füge Produkte anhand einer Lieferscheinnummer hinzu
                        </S.Description>
                    </S.TextWrapper>
                    <S.ButtonGroup>
                        <S.Button>
                            <Button>
                                <button className={"uf-productSelect"} onClick={props.onClick}>
                                    Produktauswahl
                                </button>
                            </Button>
                        </S.Button>
                        <S.Button>
                            <Button>
                                <button className={"uf-deliveryNote"} onClick={props.handleShowDeliveryNoteProducts}>
                                    Lieferschein
                                </button>
                            </Button>
                        </S.Button>
                    </S.ButtonGroup>
                </div>
            </Modal>
        </S.ModalSelectAddArticle>
    );
};

export default observer(ModalSelectAddArticle);

//#region styles
const S = {
    ModalSelectAddArticle: styled.div`
        padding: 0;
        margin-top: 0;
    `,
    HeadlineTitle: styled.div`
        display: flex;
        text-align: center;
        justify-content: center;
        width: 90%;
    `,
    HeadlineText: styled.h2`
        display: inline-block;
        text-align: center;
        margin-left: 8%;
    `,
    TextWrapper: styled.div`
        height: 100%;
        margin-top: ${unitize(20)};
    `,
    Description: styled.p`
        text-align: center;
        font-size: ${unitize(18)};
    `,
    ButtonGroup: styled.div`
        display: flex;
        flex-direction: column;
        ${media("sm")} {
            margin-top: 0;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            margin-top: auto; // set to bottom of container
        }
    `,
    Button: styled.div`
        margin: ${unitize(10)};
        ${media("sm")} {
            margin: ${unitize(20)};
        }
    `,
    ButtonClose: styled.div`
        position: absolute;
        right: ${unitize(30)};
        top: ${unitize(30)};
    `,
};
//#endregion styles
