import { Button, ButtonClose, media, Modal, ModalFooter, unitize } from "@abs-safety/lock-book-web-ui";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import React, { FunctionComponent, ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { IDeliveryNoteRead } from "../../entities/DeliveryNote";
import { DocumentationType } from "../../entities/Documentation";
import { NotFoundError } from "../../entities/ErrorResponse";
import FormValues from "../../pages/BuildingArea/components/InstalledArticlesEdit/components/ColFormInstalledArticle";
import { DocumentationController } from "../../pages/Documentation/DocumentationController";
import { notificationHandler } from "../../session/NotificationHandler";
import { session } from "../../session/Session";
import { getController } from "../../stores/controller/ControllerFactory";
import { gtmAddArticle } from "../../utils/gtmEventCollection";
import FormInput from "../FormInput";

type FormValues = Pick<IDeliveryNoteRead, "barcode">;

interface ModalSelectFromDeliveryNoteFormProps extends FormikProps<FormValues> {
    onCloseClick: () => void;
    isOpen: boolean;
    onInfoIconClick: () => void;
}

interface ModalSelectFromDeliveryNoteProps {
    onCloseClick: () => void;
    onClick: () => void;
    onInfoIconClick: () => void;
    onSubmit: (values: FormValues) => void;
    isOpen: boolean;

    /** this Modal can be opened from different pages. Depending on page, the modal behaves differently */
    isInPage?: "documentation" | "buildingArea";
    documentationType?: DocumentationType;
    icon?: ReactNode;
}

const ModalSelectFromDeliveryNote: FunctionComponent<ModalSelectFromDeliveryNoteProps> = (
    props: ModalSelectFromDeliveryNoteProps
) => {
    const { controller } = getController(DocumentationController);
    const [initialValues] = useState<FormValues>({
        barcode: "",
    });

    const [validationSchema, setValidationSchema] = useState(Yup.object().shape<Partial<FormValues>>({}));
    const absSerialRegex = /^[\d]{8}$/;

    useEffect(() => {
        setValidationSchema(
            Yup.object().shape<Partial<FormValues>>({
                barcode: Yup.string().matches(absSerialRegex, {
                    //TODO api: add constraint
                    message: "Dies ist kein gültiges Format einer Lieferscheinnummer",
                }),
            })
        );
    }, []);

    const onSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        controller
            .loadDeliveryNote(values.barcode)
            .then(() => props.onSubmit(values))
            .catch((error) => {
                if (error instanceof NotFoundError) {
                    helpers.setErrors({
                        barcode: "Diese Lieferscheinnummer ist uns nicht bekannt",
                    });
                } else if (error instanceof Error) {
                    notificationHandler.addNotification({
                        title: "Fehler",
                        description: "Es ist ein Fehler aufgetreten",
                        type: "error",
                    });
                }
            });
    };

    return isEmpty(session.constraints) ? (
        <p>Dachfläche wird geladen...</p>
    ) : (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values, helpers) => onSubmit(values, helpers)}
        >
            {(formikBag) => (
                <ObservedForm
                    onCloseClick={props.onCloseClick}
                    onInfoIconClick={props.onInfoIconClick}
                    isOpen={props.isOpen}
                    {...formikBag}
                />
            )}
        </Formik>
    );
};

const ModalSelectFromDeliveryNoteForm: FunctionComponent<ModalSelectFromDeliveryNoteFormProps> = (
    props: ModalSelectFromDeliveryNoteFormProps
) => {
    const { controller } = getController(DocumentationController);
    return (
        <Form>
            <S.ModalSelectFromDeliveryNote>
                <Modal onCloseClick={props.onCloseClick} isOpen={props.isOpen} size="sm">
                    <S.ButtonClose>
                        <ButtonClose onClick={props.onCloseClick} />
                    </S.ButtonClose>
                    <S.Headline>
                        <S.HeadlineText>Lieferschein eingeben</S.HeadlineText>
                        <S.Logo
                            src={"/Info Icon.svg"}
                            className={"uf-deliveryNoteInfo"}
                            onClick={props.onInfoIconClick}
                        />
                    </S.Headline>
                    <S.InputWrapper>
                        <FormInput
                            label="Lieferschein Nummer"
                            name="barcode"
                            type="text"
                            size="sm"
                            placeholder={"z.B. 12345678"}
                        />
                    </S.InputWrapper>

                    <ModalFooter>
                        <Button
                            disabled={
                                !props.dirty || !props.isValid || controller.waitingFor.fetchDeliveryNote === true
                            }
                        >
                            <button
                                className={"uf-deliveryNoteAdd"}
                                type="submit"
                                onClick={() => gtmAddArticle(controller.documentation?.type, "deliveryNote_next")}
                            >
                                Weiter
                            </button>
                        </Button>
                        <Button variant="text" color="black">
                            <button type="button" onClick={props.onCloseClick}>
                                Abbrechen
                            </button>
                        </Button>
                    </ModalFooter>
                </Modal>
            </S.ModalSelectFromDeliveryNote>
        </Form>
    );
};

const ObservedForm = observer(ModalSelectFromDeliveryNoteForm);

export default observer(ModalSelectFromDeliveryNote);

//#region styles
const S = {
    ModalSelectFromDeliveryNote: styled.div`
        position: absolute;
        display: flex;
        width: 100%;
        min-width: ${unitize(500)};
        margin: ${unitize(24)};
        color: #262626;
    `,
    Headline: styled.div`
        display: flex;
        position: relative;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        width: ${unitize(500)};
        padding-bottom: ${unitize(24)};
    `,
    Logo: styled.img`
        cursor: pointer;
        width: ${unitize(25)};
        ${media("sm")} {
            padding-top: ${unitize(6)};
            width: ${unitize(30)};
        }
    `,
    HeadlineText: styled.h2`
        display: inline;
        /* vertical-align: center; */
        margin-right: ${unitize(15)};
        font-size: ${unitize(22)};
        ${media("sm")} {
            font-size: ${unitize(32)};
        }
    `,
    InputWrapper: styled.div`
        margin-bottom: ${unitize(-3)};
        text-align: left;
        & p {
            font-size: ${unitize(14)};
        }
        &:focus {
            outline: none;
        }
    `,
    ButtonClose: styled.div`
        display: none;
        ${media("sm")} {
            position: absolute;
            right: ${30 / 16}rem;
            top: ${30 / 16}rem;
        }
    `,
};
//#endregion styles
