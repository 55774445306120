import { CallStackViolation } from "../entities/ErrorResponse";
import { isDefined } from "./isDefined";

type IViolationByStackProps = { includes?: string[]; excludes?: string[]; message: string };

/**
 * `handleViolationByStack` is used to protect certain functions from being used in the wrong context.
 * It uses the `Error.stack` to determine whether a function in the stack is called or not.
 * @example A function should be called only in functionComponents
 * const someFunction1 = () => {
 *     handleViolationByStack({excludes: ['functionComponents'],
 *     message: 'someFunction1 should only be called inside a functionComponent'})
 * }
 * @example A function shouldn't be called in functionComponents
 * const someFunction2 = () => {
 *     handleViolationByStack({includes: ['functionComponents'],
 *     message: 'someFunction2 should not be called inside a functionComponent'})
 * }
 *
 * @throws CallStackViolation
 * @param props
 */
export const handleViolationByStack = (props: IViolationByStackProps): void => {
    if ((process.env.REACT_APP_ENVIRONMENT ?? "prod") !== "local") return;

    const violationError: Error = new CallStackViolation(`Call stack violation: ${props.message}`);
    const includes = props.includes;
    const excludes = props.excludes;

    if (isDefined(includes)) {
        includes.forEach((incl) => {
            if (!(violationError.stack?.includes(incl) ?? false)) {
                throw violationError;
            }
        });
    }
    if (isDefined(excludes)) {
        excludes.forEach((excl) => {
            if (violationError.stack?.includes(excl) ?? false) {
                throw violationError;
            }
        });
    }
};
