import { action, makeObservable, observable, runInAction } from "mobx";
import { miscApi } from "../../api/MiscApi";
import { IArticleCategoryRead } from "../../entities/ArticleCategory";
import { isDefined } from "../../utils/isDefined";

class AdminStore {
    articleCategories: IArticleCategoryRead[] = [];
    adminPageIsOpened = false;
    /** where to go, when user clicks back to Docu */
    backToDocuPath: string | null = null;

    constructor() {
        makeObservable(this, {
            articleCategories: observable,
            backToDocuPath: observable,
            adminPageIsOpened: observable,
            saveBackToDocuPath: action,
            setAdminPageIsOpened: action,
        });
    }

    async init() {
        this.loadArticleCategories();
    }

    getArticleCategoryName(categoryId: number) {
        return this.articleCategories.find((cat) => cat.id === categoryId)?.name ?? "?";
    }

    setAdminPageIsOpened(val: boolean) {
        this.adminPageIsOpened = val;
    }

    saveBackToDocuPath() {
        const path = window.location.pathname;
        if (/^\/buildings/.test(path)) {
            this.backToDocuPath = path;
        } else {
            console.log(
                `current path "${path}", won't be saved as backToDocuPath, because it doesn't start with "/buildings"`
            );
        }
    }

    private loadArticleCategories() {
        miscApi.getArticleCategories().then((res) => {
            runInAction(() => {
                if (isDefined(res.result)) {
                    this.articleCategories = res.result;
                }
            });
        });
    }
}

export const adminStore = new AdminStore();
