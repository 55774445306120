import { debounce, DebouncedFunc } from "lodash";
import { useCallback } from "react";

/**
 * A wrapper around lodash's debounce() function, which wraps it in an useCallback.
 * In this way the debounced function (return value) keeps it's reference,
 * and each re-rendering of a component doesn't cause the creation of another debounced function. */
/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
export function useDebounce<T extends (...args: any) => ReturnType<T>>(callback: T, wait: number): DebouncedFunc<T> {
    return useCallback(debounce(callback, wait), []);
}
