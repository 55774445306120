import { ColumnSizes, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import FieldLoading from "../../../components/FieldLoading";
import { IBuildingAttachmentRead } from "../../../entities/BuildingAttachment";
import { attachmentService } from "../../../services/AttachmentService";
import { getController } from "../../../stores/controller/ControllerFactory";
import { BuildingController } from "../BuildingController";
import CardFile from "./CardFile";

interface ListFilesProps {
    sortedList: IBuildingAttachmentRead[];
    columnSizes: ColumnSizes;
}

/**
 * Inner Component: List without Header
 */
const ListFiles = observer((props: ListFilesProps) => {
    const { controller } = getController(BuildingController);
    const history = useHistory();
    const { url } = useRouteMatch();
    return (
        <>
            {controller.waitingFor.uploadBuildingAttachment && (
                <FieldLoading height={unitize(48)} text="Datei wird hochgeladen..." />
            )}
            {controller.waitingFor.loadFiles && !attachmentService.length ? (
                <FieldLoading text="Dateien werden geladen..." />
            ) : (
                props.sortedList.map((file) => (
                    <CardFile
                        key={file.id}
                        file={file}
                        columnSizes={props.columnSizes}
                        disabled={attachmentService.waitingFor.delete === file.id}
                        onCardClick={() => history.push(`${url}/file/${file.id}`)}
                        onDelete={() => controller.deleteFile(file.id)}
                        onEdit={() => history.push(`${url}/file/${file.id}`)}
                    />
                ))
            )}
        </>
    );
});
export default ListFiles;
