import { IArticleBase } from "../../../entities/Article";
import { isNullish } from "../../../utils/isNullish";

type ArticlePartial = Pick<IArticleBase, "categoryId">;

export const filterArticleByCategory = <T extends ArticlePartial>(
    articles: T[],
    articleCategoryFilter: Set<number>
): T[] =>
    articles.filter(
        // show article if ...
        (a) =>
            // ... filter is inactive
            articleCategoryFilter.size < 1 ||
            // ... or: article has no category
            isNullish(a.categoryId) ||
            // ... or: article category matches filter
            articleCategoryFilter.has(a.categoryId)
    );
