import { IDocumentationSharingHashRead, IDocumentationSharingHashWrite } from "../entities/DocumentationSharingHash";
import { ApiResponse, ApiService } from "./ApiService";

export class DocumentationSharingHashApi extends ApiService<
    IDocumentationSharingHashRead,
    IDocumentationSharingHashWrite
> {
    postDocumentationSharingHash(
        body: IDocumentationSharingHashWrite
    ): Promise<ApiResponse<IDocumentationSharingHashRead>> {
        return this.post(`/documentation-sharing-hashes`, body);
    }
}
