import { isDefined } from "../utils/isDefined";

const env = process.env;
const rootPath = process.env.REACT_APP_API_URL;

export enum HTTPMethod {
    CONNECT = "CONNECT",
    DELETE = "DELETE",
    GET = "GET",
    HEAD = "HEAD",
    OPTIONS = "OPTIONS",
    POST = "POST",
    PUT = "PUT",
    TRACE = "TRACE",
    PATCH = "PATCH",
}

export type apiEndpoint = (typeof env)["REACT_APP_API_URL"] | (typeof env)["REACT_APP_HUB_API_URL"];

export interface ParamObject {
    [key: string]: string | number | (string | number)[] | boolean;
}

export type ParamBag = string | ParamObject | string[];

export interface fetchOptions<T>
    extends Pick<
        RequestInit,
        | "cache"
        | "credentials"
        | "headers"
        | "integrity"
        | "keepalive"
        | "mode"
        | "redirect"
        | "referrer"
        | "referrerPolicy"
        | "signal"
        | "window"
    > {
    route: number | string;
    body?: Record<string, unknown> | FormData | T;
    params?: ParamBag;
    refresh?: boolean;
    endpoint?: string;
    method: HTTPMethod;
}

type fetchOptionsMinimal<T> = Pick<fetchOptions<T>, "route" | "params">;

const headersDefault = {
    accept: "application/json",
    "Content-Type": "application/json",
};
const headersUpload = {
    accept: "application/json",
    // 'Content-Type': 'multipart/form-data' will be automatically generated by the browser
    // including the Boundary parameter
};

const headersString = {
    accept: "*",
};

const headersPagination = {
    accept: "application/ld+json",
    "Content-Type": "application/json",
};

const fetchDefault: fetchOptions<never> = {
    cache: undefined,
    headers: headersDefault,
    integrity: "",
    keepalive: false,
    method: HTTPMethod.GET,
    redirect: undefined,
    referrer: "",
    referrerPolicy: undefined,
    route: "",
    signal: undefined,
    window: undefined,
    mode: "cors",
    credentials: "include",
    refresh: true,
};

/**
 * represents the different available fetch methods in `Connection`
 */
export enum fetchTypes {
    fetch = "fetch",
    fetchString = "fetchString",
    fetchUpload = "fetchUpload",
}

/**
 * a wrapper class for `fetch`
 */
export class Connection {
    readonly _endpoint: apiEndpoint = rootPath;

    constructor(endpoint: apiEndpoint = rootPath) {
        this._endpoint = endpoint;
    }

    get endpoint(): NodeJS.ProcessEnv["REACT_APP_API_URL"] {
        return this._endpoint;
    }

    async fetch(options: fetchOptions<unknown>): Promise<Response> {
        const requestBody = isDefined(options.body) ? JSON.stringify(options.body) : undefined;
        const init: RequestInit = { ...fetchDefault, ...options, body: requestBody };

        const fetchRoute = this.assembleRoute(options.route, options.params);

        return await fetch(fetchRoute, init);
    }

    async fetchString(options: fetchOptionsMinimal<unknown>): Promise<Response> {
        return await fetch(this.assembleRoute(options.route, options.params), {
            method: HTTPMethod.GET,
            ...options,
            headers: headersString,
            credentials: "include",
            mode: "cors",
        });
    }

    async fetchUpload(options: fetchOptions<unknown>): Promise<Response> {
        const init: RequestInit = {
            ...fetchDefault,
            ...options,
            headers: headersUpload,
            body: options.body as FormData,
        };

        const fetchRoute = this.assembleRoute(options.route, options.params);

        return await fetch(fetchRoute, init);
    }

    async fetchHydra(options: fetchOptions<unknown>): Promise<Response> {
        return await this.fetch({
            ...options,
            headers: headersPagination,
        });
    }

    private assembleRoute(route?: fetchOptions<unknown>["route"], params?: fetchOptions<never>["params"]): string {
        route = route ?? "";
        let parsedParams = "";

        if (params !== undefined) {
            if (typeof params === "string") {
                parsedParams = params.indexOf("?") === 0 ? params : `?${params}`;
            } else if (Array.isArray(params)) {
                parsedParams = `?${params.join("&")}`;
            } else {
                let delimiter = "?";
                let i = 0;
                for (const key in params) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (!params.hasOwnProperty(key)) {
                        continue;
                    }
                    let val = params[key];
                    if (i > 0) {
                        delimiter = "&";
                    }

                    if (Array.isArray(val)) {
                        val = val.join(",");
                    }

                    parsedParams += `${delimiter}${key}=${val}`;
                    i++;
                }

                parsedParams.replace("&", "?");
            }
        }

        return `${this._endpoint}${route}${parsedParams}`;
    }
}
