import { ToastStack } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { notificationHandler } from "../session/NotificationHandler";

const Toasts: FunctionComponent = () => {
    return (
        <ToastStack
            onFadedOut={notificationHandler.removeNotification}
            onCloseClick={notificationHandler.removeNotification}
            toasts={notificationHandler.notifications.map((notification) => ({
                ...notification,
                color: notification.type === "success" ? "primary" : "decline",
            }))}
            offsetTop={65}
            fadeOutDuration={2}
        />
    );
};

export default observer(Toasts);
