import { ArticleType } from "../entities/Article";
import { InstalledArticlesFormValues } from "../entities/InstalledArticle";

export type ViewOfInstalledArticleForm = "buildingArea" | "documentation/assembly" | "documentation/maintenance";

export function isInstalledArticleFormFieldVisible(
    field: keyof InstalledArticlesFormValues,
    view: ViewOfInstalledArticleForm,
    articleType: ArticleType
): boolean {
    const climbOrAbs = articleType === "abs" || articleType === "climb";
    if (field === "absSerialNumber" && !climbOrAbs) {
        return false;
    }
    if (field === "serialNumber" && articleType === "abs" && view === "documentation/assembly") {
        return false;
    }
    return true;
}
