import * as Yup from "yup";
import { ApiConstraint } from "../entities/Constraint";
import { EntityName } from "../entities/EntityName";
import { session } from "../session/Session";
import { isNullish } from "../utils/isNullish";
import { toYup } from "./toYup";

/**
 * get Constraints for a certain property
 * (Constraint from API converted to Yup Schema)
 * */
export function getConstraints(entity: EntityName, property: string): Yup.StringSchema | undefined {
    return toYup(getApiConstraints(entity, property));
}

/**
 * get Constraints for this property from API
 * */
function getApiConstraints(entity: EntityName, property: string): ApiConstraint[] {
    const entityConstraints = session.constraints[`App\\Entity\\${entity}`];

    if (isNullish(entityConstraints)) {
        return [];
    }

    if (Array.isArray(entityConstraints)) {
        return [];
    }

    if (isNullish(entityConstraints[property])) {
        return [];
    }

    return entityConstraints[property];
}
