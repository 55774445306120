import { MainNavigation, SideModal, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { buildingService } from "../../services/BuildingService";
import { getController } from "../../stores/controller/ControllerFactory";
import MainPage from "../../templates/MainPage";
import { layout } from "../../utils/layout";
import { PageDepth, useBreadcrumb } from "../../utils/useBreadcrumb";
import { AreaController } from "./AreaController";
import Edit from "./subviews/Edit";
import ViewDocumentations from "./subviews/ViewDocumentations";
import ViewFiles from "./subviews/ViewFiles";
import ViewProductManagement from "./subviews/ViewProductManagement";

enum SubViewKey {
    Assemblies = "tab__assemblies",
    Maintenances = "tab__maintenances",
    Files = "tab__files",
    ProductManagement = "tab__product-management",
}

/**
 * Page: BuildingArea
 */
const BuildingArea: FunctionComponent = () => {
    const { controller, useInit } = getController(AreaController);
    const params: { buildingId: string; buildingAreaId: string } = useParams();

    const [subView, setSubView] = useState<SubViewKey>();
    const buildingId = parseInt(params.buildingId);
    const buildingAreaId = parseInt(params.buildingAreaId);
    const location = useLocation();
    const breadcrumb = useBreadcrumb(PageDepth.BuildingArea, controller);
    const [modalEdit, setModalEdit] = useState(false);

    // if there is a file in the url and we are not already in files subview: redirect!
    if (location.pathname.toString().includes("file") && subView !== SubViewKey.Files) {
        setSubView(SubViewKey.Files);
    }

    useInit(buildingId, buildingAreaId);

    useEffect(() => {
        if (buildingService.inMaintenanceTab === true && subView !== SubViewKey.Maintenances) {
            setSubView(SubViewKey.Maintenances);
        }
    }, [buildingAreaId, subView]);

    const onSubViewSelector = (id: SubViewKey) => {
        if (id !== SubViewKey.Maintenances) {
            buildingService.setInMaintenanceTab(false);
        } else {
            buildingService.setInMaintenanceTab(true);
        }
        setSubView(id);
    };

    return (
        <MainPage
            title={controller.buildingArea !== undefined ? controller.buildingArea.name : "lädt..."}
            breadcrumb={breadcrumb}
            onTitleClick={() => setModalEdit(true)}
            pageType={"area"}
        >
            {/* Tabs */}
            <MainNavigation
                activeId={subView}
                onActiveChange={(id) => onSubViewSelector(id as SubViewKey)}
                items={[
                    {
                        id: SubViewKey.Assemblies,
                        text: "Montagen",
                        view: (
                            <S.View>
                                <ViewDocumentations type="assembly" key="assemblies" />
                            </S.View>
                        ),
                    },
                    {
                        id: SubViewKey.Maintenances,
                        text: "Wartungen",
                        view: (
                            <S.View>
                                <ViewDocumentations type="maintenance" key="maintenances" />
                            </S.View>
                        ),
                    },
                    {
                        id: SubViewKey.Files,
                        text: "Plan",
                        view: (
                            <S.View>
                                <ViewFiles key="files" />
                            </S.View>
                        ),
                    },
                    {
                        id: SubViewKey.ProductManagement,
                        text: "Produktverwaltung",
                        view: (
                            <S.View>
                                <ViewProductManagement key="product-management" />
                            </S.View>
                        ),
                    },
                ]}
            />
            {modalEdit && (
                <SideModal
                    className="uf-sideModal"
                    isOpen
                    onCloseClick={() => setModalEdit(false)}
                    style={{
                        top: `${layout.header.height}px`,
                        height: `calc(100% - ${layout.header.height}px)`,
                    }}
                    views={[
                        {
                            key: "edit",
                            width: 750,
                            component: <Edit buildingAreaId={buildingAreaId} />,
                        },
                    ]}
                />
            )}
        </MainPage>
    );
};

export default observer(BuildingArea);

//#region styles
const S = {
    View: styled.div`
        margin-top: ${unitize(20)};
    `,
};
//#endregion styles
