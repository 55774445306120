import { ApiResponse } from "../api/ApiService";
import { AreaApi } from "../api/AreaApi";
import { AttachmentApi } from "../api/AttachmentApi";
import { BuildingApi } from "../api/BuildingApi";
import { fetchTypes } from "../api/Connection";
import { DocumentationApi } from "../api/DocumentationApi";
import { DocumentationItemApi } from "../api/DocumentationItemApi";
import {
    attachmentRepo,
    IBuildingAttachmentBatchUpdate,
    IBuildingAttachmentRead,
    IBuildingAttachmentUpdate,
    IBuildingAttachmentWrite,
} from "../entities/BuildingAttachment";
import { catchUploadError } from "../entities/ErrorResponse";
import { isDefined } from "../utils/isDefined";
import { DataService } from "./abstract/DataService";

type WaitingForKey =
    | "upload"
    | "updateBatch"
    | "getFilesByBuilding"
    | "getFilesByBuildingArea"
    | "loadFiles"
    | "getFilesByDocu";

class AttachmentService extends DataService<
    WaitingForKey,
    IBuildingAttachmentRead,
    IBuildingAttachmentWrite | IBuildingAttachmentUpdate
> {
    apiService = new AttachmentApi();
    buildingApi = new BuildingApi();
    documentationApi = new DocumentationApi();
    documentationItemApi = new DocumentationItemApi();
    buildingAreaApi = new AreaApi();
    repo = attachmentRepo;

    constructor() {
        super({
            upload: false,
            getFilesByBuilding: false,
            getFilesByBuildingArea: false,
            getFilesByDocu: false,
            loadFiles: false,
            updateBatch: false,
        });
    }

    getByAreaId(id: number): IBuildingAttachmentRead[] {
        return this.list.filter((item) => {
            return item.buildingAreaId === id;
        });
    }

    uploadBuildingAttachment(
        buildingId: number,
        body: IBuildingAttachmentWrite
    ): Promise<ApiResponse<IBuildingAttachmentRead, fetchTypes.fetchUpload>> {
        const formData = AttachmentService.toImageForm(body);
        return this.resolveAsAction({
            promise: () => this.buildingApi.upload<IBuildingAttachmentRead>(`/${buildingId}/attachments`, formData),
            waitingForKey: ["upload", "loadFiles"],
            action: (response) => {
                if (isDefined(response.result)) {
                    this.prependList(response.result);
                }

                return response;
            },
        }).catch((e) => catchUploadError(e, formData));
    }

    uploadAreaAttachment(
        areaId: number,
        body: IBuildingAttachmentWrite
    ): Promise<ApiResponse<IBuildingAttachmentRead, fetchTypes.fetchUpload>> {
        const formData = AttachmentService.toImageForm(body);
        return this.resolveAsAction({
            promise: () => this.buildingAreaApi.upload<IBuildingAttachmentRead>(`/${areaId}/attachments`, formData),
            waitingForKey: ["upload"],
            action: (response) => {
                if (isDefined(response.result)) {
                    this.prependList(response.result);
                }

                return response;
            },
        }).catch((e) => catchUploadError(e, formData));
    }

    updateAttachment(
        attachmentId: number,
        body: IBuildingAttachmentUpdate
    ): Promise<ApiResponse<IBuildingAttachmentRead>> {
        return this.resolveAsAction({
            promise: () => this.apiService.putAttachment(attachmentId, body),
            waitingForKey: "update",
            action: (response) => {
                if (isDefined(response.result)) {
                    this.mergeList(response.result);
                }

                return response;
            },
        });
    }

    updateAttachments(
        body: IBuildingAttachmentBatchUpdate,
        action?: (response: ApiResponse<IBuildingAttachmentRead[]>) => ApiResponse<IBuildingAttachmentRead[]>
    ): Promise<ApiResponse<IBuildingAttachmentRead[]>> {
        return this.resolveAsAction({
            promise: () => this.apiService.updateAttachments(body),
            waitingForKey: "updateBatch",
            action,
        });
    }

    async fetchByBuilding(buildingId: number): Promise<ApiResponse<IBuildingAttachmentRead[]>> {
        return await this.resolveAsAction({
            promise: () => this.buildingApi.getFiles(buildingId),
            waitingForKey: ["getFilesByBuilding", "loadFiles"],
            action: (response) => {
                if (isDefined(response.result)) {
                    this.mergeList(response.result.reverse());
                }

                return response;
            },
        });
    }

    async fetchByDocumentation(docuId: number): Promise<ApiResponse<IBuildingAttachmentRead[]>> {
        return await this.resolveAsAction({
            promise: () => this.documentationApi.getFiles(docuId),
            waitingForKey: ["getFilesByDocu", "loadFiles"],
            action: (response) => {
                if (isDefined(response.result)) {
                    this.mergeList(response.result.reverse());
                }

                return response;
            },
        });
    }

    async fetchByDocumentationItem(docuItemId: number): Promise<ApiResponse<IBuildingAttachmentRead[]>> {
        return await this.resolveAsAction({
            promise: () => this.documentationItemApi.getDocumentationItemAttachments(docuItemId),
            waitingForKey: ["getFilesByDocu", "loadFiles"],
            action: (response) => {
                if (isDefined(response.result)) {
                    this.mergeList(response.result);
                }

                return response;
            },
        });
    }

    uploadByDocumentation(
        docuId: number,
        body: IBuildingAttachmentWrite
    ): Promise<ApiResponse<IBuildingAttachmentRead, fetchTypes.fetchUpload>> {
        const formData = AttachmentService.toImageForm(body);
        return this.resolveAsAction({
            promise: () => this.documentationApi.upload<IBuildingAttachmentRead>(`/${docuId}/attachments`, formData),
            waitingForKey: ["getFilesByDocu", "loadFiles"],
            action: (response) => {
                if (isDefined(response.result)) {
                    this.mergeList(response.result);
                }

                return response;
            },
        }).catch((e) => catchUploadError(e, formData));
    }

    async uploadMultipleByDocumentationItem(docuItemId: number, attachments: IBuildingAttachmentWrite[]) {
        for (const attachment of attachments) {
            await this.uploadByDocumentationItem(docuItemId, attachment);
        }
    }

    fetchByBuildingArea(buildingId: number): Promise<ApiResponse<IBuildingAttachmentRead[]>> {
        return this.resolveAsAction({
            promise: () => this.buildingAreaApi.getFiles(buildingId),
            waitingForKey: ["getFilesByBuildingArea", "loadFiles"],
            action: (response) => {
                if (isDefined(response.result)) {
                    this.mergeList(response.result.reverse());
                }

                return response;
            },
        });
    }

    deleteDocuItemAttachment(id: number): Promise<ApiResponse<IBuildingAttachmentRead>> {
        return this.resolveAsAction({
            promise: () => this.apiService.deleteDocuItemAttachment(id),
            waitingForKey: "delete",
            setWaitingForValueTo: id,
            action: (response) => {
                if (response.response?.ok === true) {
                    this.drop(id);
                }

                return response;
            },
        });
    }

    private static toImageForm(body: IBuildingAttachmentWrite): FormData {
        const form = new FormData();
        form.append("imageFile", body.imageFile);

        return form;
    }

    private uploadByDocumentationItem(
        docuItemId: number,
        body: IBuildingAttachmentWrite
    ): Promise<ApiResponse<IBuildingAttachmentRead, fetchTypes.fetchUpload>> {
        const formData = AttachmentService.toImageForm(body);
        return this.resolveAsAction({
            promise: () =>
                this.documentationItemApi.upload<IBuildingAttachmentRead>(`/${docuItemId}/attachments`, formData),
            waitingForKey: ["upload"],
            action: (response) => {
                if (isDefined(response.result)) {
                    this.mergeList(response.result);
                }

                return response;
            },
        }).catch((e) => catchUploadError(e, formData));
    }
}

export const attachmentService = new AttachmentService();
