import { MainNavigation, unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { session } from "../../../session/Session";
import { getController } from "../../../stores/controller/ControllerFactory";
import { BuildingController } from "../BuildingController";
import DetailsDocumentation from "./DetailsDocumentation";
import EditCertificate from "./EditCertificate";
import PdfLinkDocumentation from "./PdfLinkDocumentation";

enum SubViewKey {
    DetailsDocumentation = "tab__details-documentaition",
    PdfLinkDocumentation = "tab__pdf-link-documentaiton",
    EditCertificate = "tab__documents-documentaition",
}

const BuildingDocumentationSideModal: FunctionComponent = () => {
    const { controller } = getController(BuildingController);
    const [subView, setSubView] = useState(SubViewKey.DetailsDocumentation);
    const { url } = useRouteMatch();
    const activeId = parseInt(url.substring(url.lastIndexOf("/") + 1));
    return (
        <MainNavigation
            activeId={subView}
            onActiveChange={(id) => setSubView(id as SubViewKey)}
            items={[
                {
                    id: SubViewKey.DetailsDocumentation,
                    text: "Details zur Dokumentation",
                    view: (
                        <S.View>
                            <DetailsDocumentation key="detailsDocumentation" activeId={activeId} />
                        </S.View>
                    ),
                },
                {
                    id: SubViewKey.PdfLinkDocumentation,
                    text: "PDF Link",
                    view: (
                        <S.View>
                            <PdfLinkDocumentation key="PdfLinkDocumentation" activeId={activeId} />
                        </S.View>
                    ),
                },
                {
                    id: SubViewKey.EditCertificate,
                    text: "Dokumente",
                    hidden: !session.hasAbsAdminRights,
                    view: (
                        <S.View>
                            <EditCertificate
                                key="EditCertificate"
                                documentationId={activeId}
                                buildingId={controller.currentId}
                            />
                        </S.View>
                    ),
                },
            ]}
        />
    );
};

export default BuildingDocumentationSideModal;

//#region styles
const S = {
    View: styled.div`
        margin-top: ${unitize(20)};
    `,
};
//#endregion styles
