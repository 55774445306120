import { RepoStore } from "../stores/RepoStore";

/**
 * based on lock-book-docu-api: api/src/Entity/Document.php
 */

export type DocumentType = "documentation" | "installation-certificate";

class DocumentRepo extends RepoStore<IDocumentRead> {}

export const documentRepo = new DocumentRepo();

interface IDocumentationBase {
    imageUrl: string;
    imageDownloadUrl: string;
    id: number;
    imageFile: File | null;
    path: string;
    type: DocumentType;
    updatedAt: Date;
    createdAt: Date;

    // many to one
    documentation: Date;
}

/** All Documentation properties from php entity file with `document:read` annotation */
export type IDocumentRead = Pick<
    IDocumentationBase,
    "imageUrl" | "imageDownloadUrl" | "id" | "path" | "type" | "updatedAt" | "createdAt"
>;
