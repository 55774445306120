import { runInAction } from "mobx";
import { ApiResponse } from "../api/ApiService";
import { BuildingApi } from "../api/BuildingApi";
import { buildingRepo, IBuildingCreate, IBuildingRead, IBuildingUpdate } from "../entities/Building";
import { DataService } from "./abstract/DataService";

class BuildingService extends DataService<
    "fetchContributors" | "fetchAttachments" | "inMaintenanceTab",
    IBuildingRead,
    IBuildingCreate | IBuildingUpdate
> {
    repo = buildingRepo;
    apiService = new BuildingApi();
    inMaintenanceTab?: boolean;

    constructor() {
        super({
            fetchAttachments: false,
            fetchContributors: false,
            inMaintenanceTab: false,
        });
    }

    deactivate(id: IBuildingRead["id"]): Promise<ApiResponse<never>> {
        return this.resolveAsAction({
            promise: () => this.apiService.deactivate(id),
            waitingForKey: "delete",
            action: (result) => {
                if (result?.response?.ok) {
                    this.repo.list = this.repo.list.filter((item) => {
                        this.repo.total--;
                        return item.id !== id;
                    });
                }

                return result;
            },
        });
    }

    uploadBuildingImage(buidlingId: number, file: File): Promise<ApiResponse<IBuildingRead>> {
        return this.resolveAsAction({
            promise: () =>
                this.apiService.uploadLogo(buidlingId, {
                    imageFile: file,
                }),
            waitingForKey: "update",
            action: (response) => {
                if (response.result !== null) {
                    this.mergeList(response.result);
                }
                return response;
            },
        });
    }

    removeBuildingImage(buidlingId: number): Promise<ApiResponse<IBuildingRead>> {
        return this.resolveAsAction({
            promise: () => this.apiService.removeLogo(buidlingId),
            waitingForKey: ["update"],
            setWaitingForValueTo: buidlingId,
            action: (response) => {
                if (response.result !== null) {
                    this.mergeList(response.result);
                }
                return response;
            },
        });
    }

    setInMaintenanceTab(value: boolean) {
        runInAction(() => {
            this.inMaintenanceTab = value;
        });
    }
}

export const buildingService = new BuildingService();
