import { Breadcrumb, BreadcrumbProps, HeadingViewLink, unitize } from "@abs-safety/lock-book-web-ui";
import { BreadcrumbEntryProps } from "@abs-safety/lock-book-web-ui/dist/esm/components/navigation/Breadcrumb/elements/BreadcrumbEntry";
import { media } from "@abs-safety/lock-book-web-ui/dist/esm/shared/breakpoints";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import ErrorPage from "../pages/errors/ErrorPage";
import { dataLayerService } from "../services/DataLayerService";
import { IPageTypes, session } from "../session/Session";

interface MainPageProps {
    title: string;
    onTitleClick?: () => void;
    breadcrumb?: BreadcrumbProps;
    children: React.ReactNode;
    /** only to set CSS value for Userflow (?) */
    pageType: IPageTypes;
}

const extendOnClickForDatalayer = (item: BreadcrumbEntryProps) => {
    // clone onClick method i it's defined - a reference would result in a loop
    const onClick =
        item.onClick !== undefined
            ? item.onClick.bind({})
            : () => {
                  return;
              };
    item.onClick = () => {
        onClick();
        dataLayerService.emitClick({
            category: dataLayerService.currentPage,
            action: "navigate",
            label: "breadcrumb",
            payload: item.title,
        });
    };

    return item;
};

const MainPage: FunctionComponent<MainPageProps> = (props: MainPageProps) => {
    const data = props.breadcrumb?.data;

    if (data !== undefined) {
        data.map(extendOnClickForDatalayer);
    }

    return session.pageError !== undefined ? (
        <ErrorPage {...session.pageError} />
    ) : (
        <S.MainPage className={`page_${props.pageType}`}>
            <>
                <S.Header className="uf-header">
                    {data !== undefined && (
                        <S.Breadcrumb className="uf-breadcrumb">
                            <Breadcrumb data={data} />
                        </S.Breadcrumb>
                    )}
                    <S.HeaderLeftWrapper className="truncate uf-title">
                        <HeadingViewLink onClick={props.onTitleClick}>{props.title} </HeadingViewLink>
                    </S.HeaderLeftWrapper>
                </S.Header>
                {props.children}
            </>
        </S.MainPage>
    );
};

export default observer(MainPage);

//#region styles
const S = {
    MainPage: styled.div`
        width: ${unitize(1440)};
        margin-left: auto;
        margin-right: auto;
        padding-bottom: ${unitize(80)};
        @media (max-width: ${unitize(1500)}) {
            width: 100%;
            padding-left: ${unitize(30)};
            padding-right: ${unitize(30)};
        }
        @media (max-width: ${unitize(1200)}) {
            width: 100%;
            padding-left: ${unitize(15)};
            padding-right: ${unitize(15)};
        }
    `,
    Header: styled.header`
        position: relative;
        padding-top: ${unitize(64)};
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        ${media("md")} {
            min-height: ${unitize(168)};
        }
    `,
    Breadcrumb: styled.div`
        position: absolute;
        top: ${unitize(18)};
        left: 0;
    `,
    Title: styled.h1`
        /* margin-top: ${unitize(64)}; */
        margin: 0;
    `,
    HeaderLeftWrapper: styled.div`
        display: flex;
        align-items: center;
        // TODO would be nicer if this would be done in WEBUI's HeadingViewLink component, as multiple lines break it anyway
        > h1 {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    `,
};
//#endregion styles
