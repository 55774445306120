import { unitize } from "@abs-safety/lock-book-web-ui";
import styled from "styled-components";

export const ProductFormFooter = styled.div`
    // TODO: would be better if inside grid layout instead of absolute positioned.
    // But tricky cause it needs to be at bottom of screen
    /* grid-column-end: span 2; */
    position: fixed;
    bottom: 30px;
    right: 40px;
    display: flex;
    justify-content: flex-end;
    gap: ${unitize(30)};
`;
