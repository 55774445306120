import { FunctionComponent, useEffect } from "react";
import { dataLayerService } from "../services/DataLayerService";
export type GtmInitProps = { gtmKeys: string | string[] };

const GtmIntegration: FunctionComponent<GtmInitProps> = ({ gtmKeys }: GtmInitProps) => {
    useEffect(() => {
        dataLayerService.init(gtmKeys);
        dataLayerService.trackHistory();
    });
    return null;
};

export default GtmIntegration;
