import { LockBookHeader, LockBookHeaderProps } from "@abs-safety/lock-book-header";
import { Button } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { adminStore } from "../pages/Admin/AdminStore";
import { session } from "../session/Session";
import { layout } from "../utils/layout";
import ModalContactPersonPDF from "./PDFSettings/ModalContactPersonPDF";

const Header: FunctionComponent = () => {
    const history = useHistory();

    const [openedModalPDFSettings, setOpenedModalPDFSettings] = useState(false);

    const environment = (process.env.REACT_APP_ENVIRONMENT ?? "prod") as LockBookHeaderProps["environment"];

    const onAdminAreaClicked = () => {
        adminStore.saveBackToDocuPath();
        history.push(`/admin`);
        return;
    };
    const onPdfSettingsClicked = () => {
        setOpenedModalPDFSettings(true);
    };

    const user: LockBookHeaderProps["user"] =
        session.currentUser !== undefined
            ? {
                  firstname: session.currentUser.firstName,
                  lastname: session.currentUser.lastName,
                  company: session.currentUser.company.name,
                  username: session.currentUser.username,
                  picture: session.currentUser.imageUrl,
              }
            : undefined;

    const applicationSettings: LockBookHeaderProps["applicationSettings"] =
        session.currentUser !== undefined && session.hasCompanyAdminRights && !adminStore.adminPageIsOpened
            ? {
                  menuTitle: "Einstellungen",
                  items: [
                      { text: "PDF Einstellungen", onClick: onPdfSettingsClicked },
                      { text: "Produkte anlegen", onClick: onAdminAreaClicked },
                  ],
              }
            : undefined;

    return (
        <>
            <S.FixedPosition>
                <LockBookHeader
                    environment={environment}
                    application="docu"
                    user={user}
                    customNodesRight={
                        adminStore.adminPageIsOpened && (
                            <Button size="small" variant="outline" color="white">
                                <Link to={adminStore.backToDocuPath ?? "/"}>zurück zu Docu</Link>
                            </Button>
                        )
                    }
                    applicationSettings={applicationSettings}
                />
            </S.FixedPosition>
            <S.PushContent />

            {openedModalPDFSettings && <ModalContactPersonPDF onClose={() => setOpenedModalPDFSettings(false)} />}
        </>
    );
};

export default observer(Header);

//#region styles
const S = {
    FixedPosition: styled.div`
        position: fixed;
        z-index: 11;
        width: 100%;
    `,
    PushContent: styled.div`
        height: ${layout.header.height}px;
    `,
};
//#endregion styles
