import { Checkbox, unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { ArticleType } from "../../../entities/Article";

interface ArticleTypeFilterProps {
    onCheckboxChange: (type: ArticleType, checked: boolean) => void;
    activeArticleTypeFilter: Set<ArticleType>;
    hideCustom?: boolean;
    hideClimb?: boolean;
    hideMisc?: boolean;
    hideDrainage?: boolean;
    hideVentilation?: boolean;
}

const ArticleTypeFilter: FunctionComponent<ArticleTypeFilterProps> = (props: ArticleTypeFilterProps) => {
    return (
        <>
            <S.Heading>Produkttyp</S.Heading>

            <Checkbox
                text={"ABS Produkte"}
                onChange={(checked) => props.onCheckboxChange("abs", checked)}
                checked={props.activeArticleTypeFilter.has("abs")}
                labelId={`check_abs`}
                small
            />

            {props.hideDrainage !== true && (
                <Checkbox
                    text={"Entwässerung"}
                    onChange={(checked) => props.onCheckboxChange("drainage", checked)}
                    checked={props.activeArticleTypeFilter.has("drainage")}
                    labelId={`check_drainage`}
                    small
                />
            )}
            {props.hideVentilation !== true && (
                <Checkbox
                    text={"Lüftung"}
                    onChange={(checked) => props.onCheckboxChange("ventilation", checked)}
                    checked={props.activeArticleTypeFilter.has("ventilation")}
                    labelId={`check_ventilation`}
                    small
                />
            )}
            {props.hideClimb !== true && (
                <Checkbox
                    text={"Steigtechnik"}
                    onChange={(checked) => props.onCheckboxChange("climb", checked)}
                    checked={props.activeArticleTypeFilter.has("climb")}
                    labelId={`check_climb`}
                    small
                />
            )}
            {props.hideMisc !== true && (
                <Checkbox
                    text={"Gesamtdach"}
                    onChange={(checked) => props.onCheckboxChange("misc", checked)}
                    checked={props.activeArticleTypeFilter.has("misc")}
                    labelId={`check_misc`}
                    small
                />
            )}
            {props.hideCustom !== true && (
                <Checkbox
                    text={"Eigene Produkte"}
                    onChange={(checked) => props.onCheckboxChange("custom", checked)}
                    checked={props.activeArticleTypeFilter.has("custom")}
                    labelId={`check_custom`}
                    small
                />
            )}
            <Checkbox
                text={"Fremdprodukte"}
                onChange={(checked) => props.onCheckboxChange("thirdparty", checked)}
                checked={props.activeArticleTypeFilter.has("thirdparty")}
                labelId={`check_thirdparty`}
                small
            />
        </>
    );
};

export default ArticleTypeFilter;

//#region styles
const S = {
    Heading: styled.h5`
        margin-bottom: ${unitize(5)};
        font-size: ${unitize(14)};
    `,
};
//#endregion styles
