import { unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent } from "react";
import styled from "styled-components";

interface FieldLoadingProps {
    text?: string;
    /** CSS value for height (default: unitize(230)) */
    height?: string;
}

const FieldLoading: FunctionComponent<FieldLoadingProps> = (props: FieldLoadingProps) => {
    const height = props.height ?? unitize(230);
    const text = props.text ?? "";

    return <S.FieldLoading height={height}>{text !== "" ? text : "loading..."}</S.FieldLoading>;
};

export default FieldLoading;

//#region styles
const S = {
    FieldLoading: styled.div<{ height: string }>`
        height: ${(props) => props.height};
        background-size: 250% 150%;
        border-radius: ${unitize(4)};
        display: flex;
        align-items: center;
        justify-content: center;
        color: #9c9c9c;
        font-size: ${unitize(19)};
        background-image: repeating-linear-gradient(to right, transparent 0%, white 10%, transparent 60%);
        animation: MoveBackgroundRight infinite ease-out 1.2s;
        @keyframes MoveBackgroundRight {
            0% {
                background-position: 0% 50%;
            }
            100% {
                background-position: 100% 50%;
            }
        }
    `,
};
//#endregion styles
