import { designTheme, ProgressCircle, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import PresignedImage from "../../../../components/PresignedImage";
import { getController } from "../../../../stores/controller/ControllerFactory";
import { isDefined } from "../../../../utils/isDefined";
import { DocumentationController } from "../../DocumentationController";
import { DocumentController } from "../DocumentController";

const AccordionTitleArticleViewCollapsed: FunctionComponent = () => {
    const { controller: documentController } = getController(DocumentController);
    const { controller } = getController(DocumentationController);
    const documentationItems = controller.articles.find(
        (a) => a.id === (documentController.currentArticle ?? -1)
    )?.documentationItems;
    const documentedDocumentationItems =
        documentationItems?.filter((item) => item.isSuccessful && item.questionsAnswered).length ?? 0;
    const lockedDocumentationItems =
        documentationItems?.filter((item) => !item.isSuccessful && item.questionsAnswered).length ?? 0;

    if (!isDefined(documentController.article) || !isDefined(documentController.article.imageUrl))
        return <span>1. Produkte</span>;
    return (
        <S.Component>
            <S.FirstGroup>
                <div>
                    <S.TitleStart className="truncate">1. Produkte:</S.TitleStart>
                </div>
                {isDefined(documentController.article.imageUrl) && (
                    <S.ImageWrapper>
                        <PresignedImage
                            url={documentController.article.imageUrl}
                            reloadUrl={() => controller.loadArticles(true)}
                            waitingForValue={controller.waitingFor.reloadUrlArticles}
                            style={{ width: "100%", height: "100%" }}
                            disableRefresh={true}
                        />
                    </S.ImageWrapper>
                )}
                <div className="truncate">
                    <span>{documentController.article.name}</span>
                </div>
            </S.FirstGroup>
            <S.ProgressCircleWrapper className="uf-status-circle">
                <ProgressCircle
                    totalCount={documentationItems?.length ?? 0}
                    documentedCount={documentedDocumentationItems}
                    lockedCount={lockedDocumentationItems}
                    size={40}
                />
            </S.ProgressCircleWrapper>
        </S.Component>
    );
};

export default observer(AccordionTitleArticleViewCollapsed);

//#region styles
const S = {
    Component: styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-column-gap: ${unitize(10)};
        display: hidden;
        width: 92%;
    `,
    TitleStart: styled.span`
        flex: 0 0 auto;
        font-weight: bold;
    `,
    ImageWrapper: styled.div`
        flex: 0 0 auto;
        margin-left: ${unitize(20)};
        margin-right: ${unitize(10)};
        border-radius: 50%;
        height: ${unitize(40)};
        width: ${unitize(40)};
        overflow: hidden;
        background-color: ${designTheme.color.lightestgrey};
    `,
    TitleName: styled.span`
        flex: 1 1 0;
    `,
    ProgressCircleWrapper: styled.div`
        margin-right: ${unitize(15)};
        span {
            font-size: ${unitize(18)};
            font-weight: bold;
        }
    `,
    FirstGroup: styled.div`
        display: flex;
        overflow: hidden;
    `,
};
//#endregion styles
