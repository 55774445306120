import { RepoStore } from "../stores/RepoStore";

/**
 * based on lock-book-docu-api: api/src/Entity/Building.php
 */

// related services
type BuildingArea = unknown;
type TransferKey = unknown;
type Documentations = unknown;
type InstalledArticle = unknown;
type BuildingAttachment = unknown;
type User = unknown;
type Company = unknown;

class BuildingRepo extends RepoStore<IBuildingRead> {}

export const buildingRepo = new BuildingRepo();

interface IBuildingBase {
    imageUrl?: string | null;
    uploadUrl: string;
    id: number;
    name?: string | null;
    address1?: string | null;
    address2?: string | null;
    postcode?: string | null;
    city?: string | null;
    country?: string | null;
    managerRole?: string | null;
    managerSalutation?: string | null;
    managerFirstName?: string | null;
    managerLastName?: string | null;
    managerEmail?: string | null;
    managerPhone1?: string | null;
    managerPhone2?: string | null;
    managerFax?: string | null;
    comment?: string | null;
    projectNumber?: string | null;
    buildingNumber?: string | null;
    absBuildingNumber?: string | null;
    imageFolderName?: string | null;
    imageFile?: File | null;
    imageFileName?: string | null;
    /** default: true */
    isActive?: boolean | null;
    updatedAt?: Date | null;
    createdAt?: Date | null;
    amountAssemblies: number;
    amountMaintenances: number;

    // one to many

    areas?: BuildingArea[];
    transferKeys?: TransferKey[];
    documentations?: Documentations[];
    installedArticles?: InstalledArticle[];
    attachments?: BuildingAttachment[];

    // many to one

    user?: User;
    company?: Company;

    companyId: number;
}

/** All Building properties from php entity file with `building:read` annotation */
export type IBuildingRead = Pick<
    IBuildingBase,
    | "imageUrl"
    | "id"
    | "name"
    | "address1"
    | "address2"
    | "postcode"
    | "city"
    | "country"
    | "managerRole"
    | "managerSalutation"
    | "managerFirstName"
    | "managerLastName"
    | "managerEmail"
    | "managerPhone1"
    | "managerPhone2"
    | "managerFax"
    | "comment"
    | "projectNumber"
    | "buildingNumber"
    | "absBuildingNumber"
    | "isActive"
    | "updatedAt"
    | "createdAt"
    | "amountAssemblies"
    | "amountMaintenances"
    | "companyId"
>;

/** All building properties from php entity file with `building:create` annotation. */
export type IBuildingCreate = Pick<
    IBuildingBase,
    | "name"
    | "address1"
    | "address2"
    | "postcode"
    | "city"
    | "country"
    | "managerRole"
    | "managerSalutation"
    | "managerFirstName"
    | "managerLastName"
    | "managerEmail"
    | "managerPhone1"
    | "managerPhone2"
    | "managerFax"
    | "comment"
    | "buildingNumber"
    | "absBuildingNumber"
>;

export type IBuildingUpdate = Pick<
    IBuildingBase,
    | "name"
    | "address1"
    | "address2"
    | "postcode"
    | "city"
    | "country"
    | "managerRole"
    | "managerSalutation"
    | "managerFirstName"
    | "managerLastName"
    | "managerEmail"
    | "managerPhone1"
    | "managerPhone2"
    | "managerFax"
    | "comment"
    | "buildingNumber"
    | "absBuildingNumber"
>;
