import { breakpoints, designTheme, MainNavigation, media, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { layoutService } from "../../../services/LayoutService";
import { getController } from "../../../stores/controller/ControllerFactory";
import { getArticleTypeName } from "../../../utils/getArticleTypeName";
import { isPartOfEnum } from "../../../utils/isPartOfEnum";
import { AddArticleController, SubViewKey } from "../AddArticleController";

const onTabItemClick = (subViewKey: string) => {
    const { controller: modalController } = getController(AddArticleController);
    if (isPartOfEnum(subViewKey, SubViewKey)) {
        modalController.setSubView(subViewKey);
        modalController.backToStep1();
        return;
    }
    throw Error("unknown subViewKey");
};

const Navigation: FunctionComponent = () => {
    const { controller: modalController } = getController(AddArticleController);
    return (
        <S.Component>
            {modalController.navigationVisible ? (
                <MainNavigation
                    small
                    vertical={layoutService.windowWidth >= breakpoints.lg}
                    activeId={modalController.subView}
                    onActiveChange={(id) => onTabItemClick(id as SubViewKey)}
                    key={modalController.navigationKey}
                    items={[
                        {
                            id: SubViewKey.FallProtection,
                            headline: "Neue Produkte",
                            text: "Absturzsicherung",
                            hidden: !modalController.isVisibleNavItem("abs"),
                        },
                        {
                            id: SubViewKey.Drainage,
                            text: getArticleTypeName("drainage").plural,
                            hidden: !modalController.isVisibleNavItem("drainage"),
                        },
                        {
                            id: SubViewKey.Ventilation,
                            text: getArticleTypeName("ventilation").plural,
                            hidden: !modalController.isVisibleNavItem("ventilation"),
                        },
                        {
                            id: SubViewKey.Climb,
                            text: getArticleTypeName("climb").plural,
                            hidden: !modalController.isVisibleNavItem("climb"),
                        },
                        {
                            id: SubViewKey.Misc,
                            text: getArticleTypeName("misc").plural,
                            hidden: !modalController.isVisibleNavItem("misc"),
                        },

                        {
                            id: SubViewKey.Custom,
                            text: getArticleTypeName("custom").plural,
                            hidden: !modalController.isVisibleNavItem("custom"),
                        },
                        {
                            id: SubViewKey.Available,
                            headline: "Angelegte Produkte",
                            text: "Produktverwaltung",
                            hidden: !modalController.isVisibleNavItem("available"),
                        },
                    ]}
                />
            ) : (
                <S.Loading>Produktkategorien laden ...</S.Loading>
            )}
        </S.Component>
    );
};

export default observer(Navigation);

//#region styles
const S = {
    Component: styled.div`
        flex: 0 0 auto;
        padding-left: ${unitize(30)};
        background-color: ${designTheme.color.lightestgrey};
        ${media("lg")} {
            min-width: ${unitize(280)};
            padding-top: ${unitize(102)};
            height: 100%;
        }
    `,
    Loading: styled.div`
        line-height: 1.365625;
        list-style: none;
        padding: 1rem 0;
        margin: 0;
        border-bottom: none;
        display: inline-grid;
        grid-auto-flow: column;
        grid-column-gap: 20px;
        font-weight: 600;
        font-size: 1rem;
        color: #9c9c9c;
        cursor: pointer;
        white-space: nowrap;
    `,
};
//#endregion styles
