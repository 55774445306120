import { FileTooLargeError } from "../../entities/ErrorResponse";

export const validateFileSize = (file: File): Error | true => {
    const maxUploadSizeInMb =
        process.env.REACT_APP_MAX_UPLOAD_SIZE_IN_MB !== undefined
            ? parseFloat(process.env.REACT_APP_MAX_UPLOAD_SIZE_IN_MB)
            : 20;

    const sizeInMb = file.size / 1024 / 1024;

    if (sizeInMb > maxUploadSizeInMb) {
        const fileName = file.name.length > 20 ? file.name.substr(0, 15 - 1) + "…" + file.name.slice(-5) : file.name;

        return new FileTooLargeError({
            name: `Fehler beim Hochladen der Datei`,
            message: `Leider kann Deine Datei (${fileName}) nicht hochgeladen werden, weil sie den Grenzwert für die Dateigröße von ${maxUploadSizeInMb}MB überschreitet.`,
        });
    }
    return true;
};
