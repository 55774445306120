import { Button, Card, designTheme, FormControl, IconTrash, unitize } from "@abs-safety/lock-book-web-ui";
import { Form, Formik } from "formik";
import React, { FunctionComponent, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import FormInput from "../../../../components/FormInput";
import FormSelect from "../../../../components/FormSelect";
import { config } from "../../../../config/config";
import { ArticleType, articleTypes, IArticleWrite } from "../../../../entities/Article";
import { IArticleAttachmentRead } from "../../../../entities/ArticleAttachment";
import { articleService } from "../../../../services/ArticleService";
import { notificationHandler } from "../../../../session/NotificationHandler";
import { session } from "../../../../session/Session";
import { getArticleTypeName } from "../../../../utils/getArticleTypeName";
import { isDefined } from "../../../../utils/isDefined";
import { useQuery } from "../../../../utils/useQuery";
import { validateFileSize } from "../../../../utils/validators/file";
import { adminStore } from "../../AdminStore";
import { ProductFormPageProps } from "../ProductFormPage";
import AttachmentPreview, { PreviewAttachment } from "./AttachmentPreview";
import { Label } from "./Label";
import PopupDeleteProduct from "./PopUpDeleteProduct";
import { ProductFormFooter } from "./ProductFormFooter";
import { ProductFormGrid } from "./ProductFormGrid";

interface ProductFormStep1Props {
    pageType: ProductFormPageProps["pageType"];
    fetchedAttachments: IArticleAttachmentRead[];
    initialValues?: FormValues;
    fetchedImageUrl?: string;
    newAttachments?: File[];
    newImage?: File;
    onNext: (params: OnNextParams) => void;
    onCancel: () => void;
}

export type OnNextParams = {
    formValues: FormValues;
    deleteFetchedImage: boolean;
    newImage?: File;
    newAttachments?: File[];
    attachmentsToDelete?: number[];
};

export type FormValues = Pick<IArticleWrite, "name" | "number" | "comment" | "categoryId" | "type">;
/** in Formik everyhing has to be a string */
type InternalFormValues = { [key in keyof FormValues]: string };

const ProductFormStep1: FunctionComponent<ProductFormStep1Props> = (props: ProductFormStep1Props) => {
    const [localImage, setLocalImage] = useState<File | undefined>(props.newImage);
    const [deleteExistingImage, setDeleteExistingImage] = useState(false);
    const [newAttachments, setNewAttachments] = useState<File[]>(props.newAttachments ?? []);
    const [attachmentsToDelete, setAttachmentsToDelete] = useState<number[]>([]);
    const [previewAttachment, setPreviewAttachment] = useState<PreviewAttachment | null>(null);
    const [popupDeleteProductOpen, setPopupDeleteProductOpen] = useState(false);
    const query = useQuery();
    const history = useHistory();

    const existingImageUrl = deleteExistingImage ? undefined : props.fetchedImageUrl;
    /** URL of either localImage or of existing / uploaded image. Computed - that's why no own state */
    const previewImageUrl = localImage !== undefined ? URL.createObjectURL(localImage) : existingImageUrl;

    const initialValues: InternalFormValues = {
        name: props.initialValues?.name ?? "",
        number: props.initialValues?.number ?? "",
        comment: props.initialValues?.comment ?? "",
        type: props.initialValues?.type ?? (session.hasAbsAdminRights ? "" : "custom"),
        categoryId: props.initialValues?.categoryId?.toString() ?? "",
    };

    const validationSchema = Yup.object().shape<Partial<InternalFormValues>>({
        name: Yup.string()
            .max(90, config.VALIDATION_ERROR_MSG_MAX_LENGTH)
            .required(config.VALIDATION_ERROR_MSG_REQUIRED),
        type: Yup.string().required(config.VALIDATION_ERROR_MSG_REQUIRED),
        categoryId: Yup.string().required(config.VALIDATION_ERROR_MSG_REQUIRED),
        comment: Yup.string().max(250, config.VALIDATION_ERROR_MSG_MAX_LENGTH),
        number: Yup.string().max(90, config.VALIDATION_ERROR_MSG_MAX_LENGTH),
    });

    /**
     * Validates files (type & size) and shows Error Toast if invalid.
     * @return array of Files which are valid
     */
    const validateFileChange = (event: React.ChangeEvent<HTMLInputElement>): File[] => {
        if (event.target.files === null) {
            return [];
        }
        const files = [...event.target.files];
        const validFiles = files.filter((file) => {
            const validated = validateFileSize(file);
            if (validated instanceof Error) {
                notificationHandler.addNotification({
                    title: validated.name,
                    description: validated.message,
                    type: "error",
                });
                return false;
            }
            return true;
        });
        return validFiles;
    };

    const onSubmit = (formValues: InternalFormValues) => {
        if (formValues.categoryId === undefined) {
            throw "categoryId is undefined";
        }
        props.onNext({
            formValues: {
                ...formValues,
                categoryId: parseInt(formValues.categoryId),
                type: formValues.type as ArticleType,
            },
            newImage: localImage,
            newAttachments: newAttachments,
            attachmentsToDelete: attachmentsToDelete,
            deleteFetchedImage: deleteExistingImage,
        });
    };

    const onImageInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const validFiles = validateFileChange(event);
        setLocalImage(validFiles[0]);
    };

    const onNewAttachmentClick = (attachment: File) => {
        const type = attachment.type.match(/pdf$/) !== null ? "pdf" : "image";
        const url = URL.createObjectURL(attachment);
        setPreviewAttachment({ url, type });
    };

    const onFetchedAttachmentClick = (attachment: IArticleAttachmentRead) => {
        const url = attachment.imageUrl ?? "";
        /** url ends with ".pdf" or has ".pdf" before URL Params starts? */
        const type = url.match(/\.pdf(\?|$)/) !== null ? "pdf" : "image";
        setPreviewAttachment({ url, type });
    };

    const onAttachmentFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const validFiles = validateFileChange(event);
        setNewAttachments((attachments) => [...attachments, ...validFiles]);
    };

    const onDeleteImage = () => {
        if (localImage !== undefined) {
            setLocalImage(undefined);
        } else if (props.fetchedImageUrl !== undefined) {
            setDeleteExistingImage(true);
        }
    };

    const onRemoveNewAttachment = (index: number) => {
        if (newAttachments.length !== 0) {
            setNewAttachments((state) => state.filter((_, i) => i !== index));
        }
    };

    const onDeleteAttachment = (id: number) => {
        if (props.fetchedAttachments.length !== 0) {
            setAttachmentsToDelete((state) => [...state, id]);
        }
    };

    const onCloseImagePreview = () => {
        setPreviewAttachment(null);
    };

    const deleteArticle = async () => {
        const paramId = query.get("id");
        if (paramId === null) return;
        setPopupDeleteProductOpen(false);
        history.push(`..`);
        return await articleService.deleteArticle(parseInt(paramId));
    };

    return (
        <>
            {popupDeleteProductOpen && (
                <PopupDeleteProduct onClose={() => setPopupDeleteProductOpen(false)} onDelete={deleteArticle} />
            )}
            {previewAttachment !== null && (
                <AttachmentPreview
                    previewAttachment={previewAttachment}
                    onCloseClick={() => onCloseImagePreview()}
                ></AttachmentPreview>
            )}
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {(formikBag) => (
                    <Form autoComplete="off">
                        <ProductFormGrid>
                            {/* left side of form */}
                            <div>
                                {session.hasAbsAdminRights && (
                                    <FormSelect
                                        label="Typ"
                                        name="type"
                                        options={articleTypes.map((type) => ({
                                            text: getArticleTypeName(type).singular,
                                            value: type,
                                        }))}
                                    />
                                )}
                                <FormInput label="Artikelname" name="name" type="text" />
                                <FormInput label="Artikelnummer" isOptional name="number" type="text" />

                                <FormSelect
                                    label="Kategorie"
                                    name="categoryId"
                                    options={adminStore.articleCategories.map((cat) => ({
                                        text: cat.name,
                                        value: cat.id.toString(),
                                    }))}
                                />
                                <FormInput label="Beschreibung" isOptional name="comment" type="textarea" />
                            </div>
                            {/* right side of form */}
                            <div>
                                <FormControl>
                                    <Label>
                                        <strong>Bild</strong> (optional)
                                    </Label>

                                    <S.ImageWrapper>
                                        {previewImageUrl === undefined ? (
                                            <S.PlaceholderImage />
                                        ) : (
                                            <S.ProductImage
                                                src={previewImageUrl}
                                                alt={localImage?.name ?? "Produktbild"}
                                            />
                                        )}
                                        {(isDefined(localImage) || isDefined(props.fetchedImageUrl)) && (
                                            <ImageDeleteButton onClick={onDeleteImage} />
                                        )}
                                    </S.ImageWrapper>
                                    <ButtonPickFile
                                        inputName="image"
                                        onFileChange={onImageInputChange}
                                        accept={config.UPLOAD_ACCEPT_ARTICLE_IMAGE}
                                    />
                                </FormControl>
                                <FormControl>
                                    <Label>
                                        <strong>Anhänge</strong> (optional)
                                    </Label>

                                    <S.AttachmentsList>
                                        {newAttachments.map((attachment, i) => (
                                            <AttachmentCard
                                                key={attachment.name + attachment.size}
                                                name={attachment.name}
                                                onClick={() => onNewAttachmentClick(attachment)}
                                                onDelete={(e) => {
                                                    onRemoveNewAttachment(i);
                                                    e.stopPropagation();
                                                }}
                                            />
                                        ))}
                                        {props.fetchedAttachments
                                            .filter((attachment) => !attachmentsToDelete.includes(attachment.id))
                                            .map((attachment) => (
                                                <AttachmentCard
                                                    key={attachment.id}
                                                    name={attachment.imageFilename ?? ""}
                                                    onClick={() => onFetchedAttachmentClick(attachment)}
                                                    onDelete={(e) => {
                                                        onDeleteAttachment(attachment.id);
                                                        e.stopPropagation();
                                                    }}
                                                />
                                            ))}
                                    </S.AttachmentsList>
                                    <ButtonPickFile
                                        inputName="attachment"
                                        onFileChange={onAttachmentFileInputChange}
                                        accept={config.UPLOAD_ACCEPT_ARTICLE_ATTACHMENT}
                                        multiple
                                    />
                                </FormControl>
                            </div>
                        </ProductFormGrid>
                        <ProductFormFooter>
                            {props.pageType === "edit" && (
                                <Button color="decline">
                                    <button onClick={() => setPopupDeleteProductOpen(true)} type="button">
                                        Löschen
                                    </button>
                                </Button>
                            )}
                            <Button color="black">
                                <button onClick={props.onCancel} type="button" className="uf-cancelCreateProduct">
                                    Abbrechen
                                </button>
                            </Button>
                            <Button disabled={!formikBag.isValid}>
                                <button type="submit" className="uf-createProductNext">
                                    Weiter
                                </button>
                            </Button>
                        </ProductFormFooter>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ProductFormStep1;

//#region sub components
const AttachmentCard = (props: {
    name: string;
    onDelete: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onClick: () => void;
}) => (
    <Card className="overflow-hidden" onClick={props.onClick}>
        <span className="truncate">{props.name}</span>
        <S.AttachmentDeleteButton type="button" onClick={props.onDelete} className="uf-deleteProductAttachment">
            <IconTrash height={20} width={20} color="black" />
        </S.AttachmentDeleteButton>
    </Card>
);

const ButtonPickFile = (props: {
    inputName: string;
    onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    accept: string;
    multiple?: boolean;
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    return (
        <div style={{ marginTop: unitize(10) }}>
            <S.AddAttachment
                type="file"
                accept={props.accept}
                name={props.inputName}
                onChange={props.onFileChange}
                multiple={props.multiple}
                ref={inputRef}
            />
            <Button size="small">
                <button type="button" className="uf-addFiles" onClick={() => inputRef.current?.click()}>
                    {props.multiple === true ? "Dateien" : "Datei"} auswählen
                </button>
            </Button>
        </div>
    );
};

const ImageDeleteButton = (props: { onClick: () => void }) => (
    <Button
        style={{ position: "absolute", bottom: unitize(20), left: unitize(155) }}
        color="white"
        noPadding
        size="small"
        variant="fill"
    >
        <button type="button" onClick={props.onClick} className="uf-deleteProductImage">
            <IconTrash />
        </button>
    </Button>
);
//#endregion sub components

//#region styles
const S = {
    ImageWrapper: styled.div`
        position: relative;
        height: 200px;
        width: 200px;
    `,
    AddAttachment: styled.input`
        display: none;
    `,
    PlaceholderImage: styled.img`
        display: block;
        background-color: #eeeeee;
        height: 100%;
        width: 100%;
    `,
    ProductImage: styled.img`
        display: block;
        object-fit: cover;
        object-position: 50% 50%;
        height: 100%;
        width: 100%;
    `,
    AttachmentsList: styled.div`
        display: grid;
        gap: ${unitize(10)};
        > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    `,
    AttachmentDeleteButton: styled.button`
        display: inline-flex;
        border-radius: 50%;
        background-color: white;
        height: ${unitize(40)};
        width: ${unitize(40)};
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        cursor: pointer;
        margin: -5px;
        :hover {
            background-color: ${designTheme.color.lightestgrey};
        }
    `,
};
//#endregion styles
