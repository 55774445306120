import { ApiResponse } from "../api/ApiService";
import { ArticleApi } from "../api/ArticleApi";
import { articleQuestionRepo, IArticleQuestionRead, IArticleQuestionWrite } from "../entities/ArticleQuestion";
import { isDefined } from "../utils/isDefined";
import { DataService } from "./abstract/DataService";

const articleApi = new ArticleApi();

class ArticleQuestionService extends DataService<
    "all" | "createBatch" | "fetchArticleQuestions" | "deleteMultiple" | "updateMultiple",
    IArticleQuestionRead,
    IArticleQuestionWrite
> {
    repo = articleQuestionRepo;
    articleApi = articleApi;

    /** @deprecated only to satisfy DataService. This Serice has no own api Endpoint */
    apiService = articleApi;

    constructor() {
        super({
            all: false,
            createBatch: false,
            fetchArticleQuestions: false,
            deleteMultiple: false,
            updateMultiple: false,
        });
    }

    createBatch(articleId: number, questions: IArticleQuestionWrite[]): Promise<ApiResponse<IArticleQuestionRead[]>> {
        return this.resolveAsAction({
            promise: () => this.articleApi.createArticleQuestions(articleId, questions),
            waitingForKey: "createBatch",
            action: (response) => {
                if (response.result !== null) {
                    this.mergeList(response.result);
                }
                return response;
            },
        });
    }

    getArticleQuestions(articleId: number): Promise<ApiResponse<IArticleQuestionRead[]>> {
        return this.resolveAsAction({
            promise: () => this.articleApi.getArticleQuestions(articleId),
            waitingForKey: "fetchArticleQuestions",
            action: (response) => {
                if (isDefined(response.result)) {
                    this.mergeList(response.result);
                }
                return response;
            },
        });
    }

    deleteMultiple(articleId: number, questionIds: number[]): Promise<unknown> {
        return this.resolveAsAction({
            promise: () => this.articleApi.deleteArticleQuestions(articleId, questionIds),
            waitingForKey: "deleteMultiple",
            action: (result) => {
                if (result.response?.ok === true) {
                    // currently another article could also reference same question, but it's ok to delete from
                    // repo list, because viewing questions of other articles will always fetch their questions again
                    questionIds.forEach((id) => this.drop(id));
                }
                return result;
            },
        });
    }

    updateMultiple(
        articleId: number,
        questions: IArticleQuestionWrite[]
    ): Promise<ApiResponse<IArticleQuestionRead[]>> {
        return this.resolveAsAction({
            promise: () => this.articleApi.patchArticleQuestions(articleId, questions),
            waitingForKey: "updateMultiple",
            action: (response) => {
                if (isDefined(response.result)) {
                    this.mergeList(response.result);
                }
                return response;
            },
        });
    }
}

export const articleQuestionService = new ArticleQuestionService();
