import { notificationHandler } from "../session/NotificationHandler";

export const copyPdfLink = (inputRef: React.RefObject<HTMLInputElement>): void => {
    const copyText = inputRef.current?.querySelector("input") ?? false;

    if (copyText === false) return;

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand("copy");

    /* Alert the copied text */
    notificationHandler.addNotification({
        title: "PDF Link",
        description: "Der Link zu Deinem PDF wurde erfolgreich in die Zwischenablage kopiert",
        type: "success",
    });
};
