import { IEntityRead, IEntityWrite } from "../entities/Base";
import { ConstraintViolationError, ForbiddenError, NotFoundError, UnauthorizedError } from "../entities/ErrorResponse";
import { apiEndpoint, Connection, fetchOptions, fetchTypes, HTTPMethod, ParamBag } from "./Connection";

/**
 * defines type of result by given fetchType
 */
type ResultObject<FetchType extends fetchTypes, T> = FetchType extends fetchTypes.fetchString ? string : T;

/**
 * the response object always returned from `ApiService` (even if it's a status 500)
 */
export type ApiResponse<T, FetchType extends fetchTypes = fetchTypes.fetch> = {
    response: Response | null;
    result: ResultObject<FetchType, T> | null;
};

/**
 * Abstract base of api services.
 * Uses `Connection`as fetcher service
 */
export class ApiService<Read extends IEntityRead, Write extends IEntityWrite = Read> {
    /**
     * define in constructor, if needed
     */
    readonly endpoint: apiEndpoint;
    fetcher: Connection;

    constructor(endpoint?: apiEndpoint, apiUrl: apiEndpoint = `${process.env.REACT_APP_API_URL}`) {
        this.endpoint = apiUrl;

        if (endpoint !== undefined) {
            this.endpoint = `${this.endpoint}${endpoint}`;
        }
        this.fetcher = new Connection(this.endpoint);
    }

    /**
     * calls an API GET request
     */
    async get<T = Read>(route = "", params?: ParamBag): Promise<ApiResponse<T>> {
        return await this.invoke<T>(fetchTypes.fetch, {
            method: HTTPMethod.GET,
            route: route,
            params: params,
        });
    }

    /**
     * calls an API GET request, returns result as HydraResponse
     */
    async getHydrate<T = Read>(route = "", params?: ParamBag): Promise<ApiResponse<T, fetchTypes.fetch>> {
        return await this.invoke<T, Write, fetchTypes.fetch>(fetchTypes.fetch, {
            method: HTTPMethod.GET,
            route: route,
            params: params,
        });
    }

    /**
     * calls an API PUT request
     */
    async put<T = Read, T2 = Write>(route = "", body?: T2, params?: ParamBag): Promise<ApiResponse<T>> {
        return await this.invoke<T, T2>(fetchTypes.fetch, {
            route: `${route}`,
            body,
            method: HTTPMethod.PUT,
            params,
        });
    }

    /**
     * calls an API POST request
     */
    async post<T = Read, T2 = Write>(route = "", body?: T2, params?: ParamBag): Promise<ApiResponse<T>> {
        return await this.invoke<T, T2>(fetchTypes.fetch, {
            route: `${route}`,
            body,
            method: HTTPMethod.POST,
            params,
        });
    }

    /**
     * calls an API POST request, uses FormData as body
     */
    async upload<T = Read, T2 = Write>(
        route = "",
        body: FormData,
        params?: ParamBag,
        method = HTTPMethod.POST
    ): Promise<ApiResponse<T, fetchTypes.fetchUpload>> {
        return await this.invoke<T, T2, fetchTypes.fetchUpload>(fetchTypes.fetchUpload, {
            route: `${route}`,
            body,
            method,
            params,
        });
    }

    /**
     * calls an API DELETE request
     */
    async delete<T = Read, T2 = Write>(route = "", params?: ParamBag): Promise<ApiResponse<T>> {
        return await this.invoke<never, T2>(fetchTypes.fetch, {
            route,
            method: HTTPMethod.DELETE,
            params,
        });
    }

    /**
     * Base method for API calls.
     * Uses 'Connection' for fetching.
     * Handles different response types.
     * @throws the correct HTTP-Error for 400er status.
     */
    async invoke<T = Read, T2 = Write, FetchType extends fetchTypes = fetchTypes.fetch>(
        fetchMethod: fetchTypes,
        options: fetchOptions<T2>
    ): Promise<ApiResponse<T, FetchType>> {
        const response = await this.fetcher[fetchMethod](options).catch((err) => {
            throw err;
        });

        if (response.type === "opaqueredirect") {
            return { response, result: null } as ApiResponse<T, FetchType>;
        }

        if (fetchMethod === fetchTypes.fetchString) {
            const text = await response.text();
            return { response, result: text } as ApiResponse<T, FetchType>;
        }

        if (response.status === 204) {
            // no body
            return { response, result: null };
        }

        const body = await response.json();
        if (!response.ok) {
            switch (response.status) {
                case 400:
                    // body contains information about violated fields
                    throw new ConstraintViolationError(body.message, response, body);
                case 401:
                    // body contains code and message on 401 error
                    throw new UnauthorizedError(body.message, response, body);
                case 403:
                    // body contains code and message on 403 error
                    throw new ForbiddenError(body.message, response, body);
                case 404:
                    // body contains code and message on 404 error
                    throw new NotFoundError(body.message, response, body);
                default:
                    throw new Error(body.message);
            }
        }

        return {
            response,
            result: body,
        } as ApiResponse<T, FetchType>;
    }
}
