import { action, makeObservable, observable } from "mobx";
import { useEffect, useRef } from "react";
import { isDefined } from "../../utils/isDefined";
import { SuperControllerStore } from "./SuperControllerStore";

type ControllerClass<T> = { controllerName: string } & (new (...args: never[]) => T);
type IRegisterType = SuperControllerStore<never>;
type IFactoryReturnType<T extends IRegisterType> = { controller: T; useInit: T["init"]; reset: T["init"] };
type ActiveRecord = Record<string, IRegisterType>;

/**
 * mounts, returns, unmounts and holds active instances of type `SuperControllerStore<any>`
 * @example get active controller or mount it
 *  controllerFactory.get(UninstancedControllerClass)
 * @example unmount active controller and it's hooks
 *  controllerFactory.umount(UninstancedControllerClass)
 */
export class ControllerFactory {
    private _actives: ActiveRecord = {};

    constructor() {
        makeObservable<this, "_actives" | "mount">(this, {
            _actives: observable,
            unmount: action,
            mount: action,
            get: action,
        });
    }

    private mount<T extends IRegisterType>(controller: ControllerClass<T>): T {
        this._actives[controller.controllerName] = new controller();
        return this._actives[controller.controllerName] as T;
    }

    get<T extends IRegisterType>(controller: ControllerClass<T>): T {
        const active = this._actives[controller.controllerName];
        if (!isDefined(active)) {
            return this.mount(controller);
        }
        return active as T;
    }

    unmount<T extends IRegisterType>(controller: ControllerClass<T>): void {
        const C = this._actives[controller.controllerName] as T | undefined;
        if (isDefined(C)) {
            C.unmount();
            delete this._actives[controller.controllerName];
        }
    }
}

/** only exported for test file */
export const controllerFactory = new ControllerFactory();

/**
 * @function
 * **Controller factory function**
 * @description takes a class that extends `SuperControllerStore<any>` and returns
 * - **controller** an instance of `SuperControllerStore<any>`
 * - **useInit**: a reactHook and decorator of `controller.init`
 *   that gets an existing controller or mounts a controller. It calls `controller.init`
 *   and handles controller unmounting on component unmount.
 *   Can only be called once per controller class until it unmounts again. Use it in a high level component like a page.
 * - **reset**: a decorator of `controller.init`
 *   that safely unmounts the current instance, mounts an new one and calls `controller.init` again.
 *   Useful for param changes, e.g.
 * @see SuperControllerStore.register
 * @example
 * const Page: FunctionComponent = () => {
 *    const didRenderOnce = useRef(false);
 *    const params: { id } = useParams();
 *    const { controller, useInit, reset } = getController(YourControllerClass);
 *    isDefined(controller.yourProperty) // true
 *
 *    useInit(id) // id: example for params of your controller.init method
 *    useEffect(() => {
 *        if (!didRenderOnce.current) {
            didRenderOnce.current = true;
            return;
          }
 *
 *        reset(id);
 *    }, [id])
 *
 *    return <>
 *        {controller.yourProperty}
 *        <ParamTrigger />
 *    </>
 * }
 * export default observer(Page);
 * @return {SuperControllerStore<any>, SuperControllerStore<any>['init'], SuperControllerStore<any>['init']}
 * @param controllerClass
 */
export function getController<T extends IRegisterType>(controllerClass: ControllerClass<T>): IFactoryReturnType<T> {
    const controller = controllerFactory.get(controllerClass);
    const useInit: T["init"] = (...props: Parameters<T["init"]>) => {
        const p = useRef<Parameters<T["init"]>>(props);
        useEffect(() => {
            if (controller.initialized) {
                throw new Error(`controller ${controllerClass.name} is already initialized`);
            }

            controller.initialized = true;
            controller.init(...p.current);

            return () => {
                controllerFactory.unmount(controllerClass);
            };
        }, []);
    };

    const reset: T["init"] = (...props: Parameters<T["init"]>) => {
        controllerFactory.unmount(controllerClass);

        const c2 = controllerFactory.get(controllerClass);
        c2.init(...props);
        c2.initialized = true;
    };

    return { controller, useInit, reset };
}
