/**
 * based on lock-book-docu-api: api/src/Entity/DocumentationItemAnswer.php
 */

import { RepoStore } from "../stores/RepoStore";
import { IDocumentationItemQuestionRead } from "./DocumentationItemQuestion";
import { IUserRead } from "./User";

class DocumentationItemAnswerRepo extends RepoStore<IDocumentationItemAnswerRead> {}

export const documentationItemAnswerRepo = new DocumentationItemAnswerRepo();

// related services
type DocumentationItemQuestion = IDocumentationItemQuestionRead;
type User = IUserRead;

export type Answer = { questionIds: number[]; answer: string };

interface DocumentationItemAnswerBase {
    /**
     * only for POST (to answer multiple questions at the same time)
     * example:
     * `[
     *  {"questionIds": "[3,6,9]", "answer": "Lock-B-3"},
     *  {"questionIds": "[2,4,7]", "answer": "1"}
     * ]`
     * */
    answers?: Answer[];
    id: number;
    answer?: string | null;
    createdAt?: Date;

    //one to one
    question?: DocumentationItemQuestion;
    questionId: number;

    //many to one
    user?: User;
}

export type IDocumentationItemAnswerRead = Pick<DocumentationItemAnswerBase, "id" | "answer">;

export type IDocumentationItemAnswerWrite = Pick<DocumentationItemAnswerBase, "answers">;

export type IMultipleQuestionAnswer = { questionIds: number[]; answer: string };
