import { IDocumentationItemAnswerRead, IDocumentationItemAnswerWrite } from "../entities/DocumentationItemAnswer";
import { ApiResponse, ApiService } from "./ApiService";

export class DocumentationItemAnswersApi extends ApiService<
    IDocumentationItemAnswerRead,
    IDocumentationItemAnswerWrite
> {
    constructor() {
        super(`/documentation-item-answers`);
    }

    answerMultipleArticleQuestions(body: IDocumentationItemAnswerWrite): Promise<ApiResponse<unknown>> {
        return this.post<unknown, IDocumentationItemAnswerWrite>("", body);
    }
}
