import { ButtonClose, Modal, unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent } from "react";
import styled from "styled-components";

interface AttachmentPreviewProps {
    onCloseClick: () => void;
    previewAttachment: PreviewAttachment;
}

export type PreviewAttachment = { url: string; type: "image" | "pdf" };

/** previews image or pdf in modal */
const AttachmentPreview: FunctionComponent<AttachmentPreviewProps> = (props: AttachmentPreviewProps) => {
    return (
        <Modal onCloseClick={props.onCloseClick} isOpen={true} size="lg">
            <S.ButtonClose>
                <ButtonClose onClick={props.onCloseClick} className="uf-closeImagePreview" />
            </S.ButtonClose>
            <S.ImageWrapper>
                {props.previewAttachment.type === "pdf" ? (
                    <S.Iframe src={props.previewAttachment.url} frameBorder="0" title="pdf as attachment"></S.Iframe>
                ) : (
                    <S.Image src={props.previewAttachment.url} />
                )}
            </S.ImageWrapper>
        </Modal>
    );
};

export default AttachmentPreview;

//#region styles
const S = {
    ImageWrapper: styled.div`
        /* make sure nothing overflows. Especially when image is not found, the alt text ignores width */
        overflow: hidden;
        height: 100%;
    `,
    Image: styled.img`
        height: 100%;
        width: 100%;
        object-fit: contain;
    `,
    Iframe: styled.iframe`
        height: 100%;
        width: 100%;
    `,
    ButtonClose: styled.div`
        position: absolute;
        right: ${unitize(30)};
        top: ${unitize(30)};
    `,
};
//#endregion styles
