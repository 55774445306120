import { action, computed, makeObservable, observable } from "mobx";
import { v4 } from "uuid";

interface Notification {
    type: "error" | "success";
    title?: string;
    description?: string;
    key: string;
}

interface IErrorHandler {
    notifications: Notification[];
    addNotification: (notification: Omit<Notification, "key">, uniqueExposing?: boolean) => void;
    removeNotification: (notification: string | Notification) => void;
}

class NotificationHandler implements IErrorHandler {
    private _notifications: Record<string, Notification> = {};
    constructor() {
        makeObservable<NotificationHandler, "_notifications">(this, {
            _notifications: observable,
            notifications: computed,

            addNotification: action,

            removeNotification: action,
        });
    }

    private static setNotificationKey(notification: Omit<Notification, "key">): Notification {
        return {
            ...notification,
            key: v4(),
        };
    }

    get notifications(): Notification[] {
        return Object.values(this._notifications);
    }

    addNotification = (notification: Omit<Notification, "key">): void => {
        const completeNote = NotificationHandler.setNotificationKey(notification);
        this._notifications[completeNote.key] = completeNote;
    };

    removeNotification = (notificationKey: string | Notification): void => {
        delete this._notifications[typeof notificationKey === "string" ? notificationKey : notificationKey.key];
    };
}

export const notificationHandler = new NotificationHandler();
