import { designTheme, unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { isDefined } from "../../../../../utils/isDefined";
import { AnswerBaseProps } from "./QuestionRow";

type AnswerInputProps = AnswerBaseProps;

const AnswerTextarea: FunctionComponent<AnswerInputProps> = (props: AnswerInputProps) => {
    const [height, setHeight] = useState(unitize(80));
    const textarea = useRef<HTMLTextAreaElement>(null);

    const getPlaceholder = () => {
        if (isDefined(props.hasDifferentValues)) return "???";
        if (isDefined(props.question.defaultAnswer) && props.question.defaultAnswer !== "")
            return `z.B. ${props.question.defaultAnswer.toString()}`;
        return "bitte eingeben";
    };

    useEffect(() => {
        setHeight(unitize(textarea.current?.scrollHeight ?? 0));
    }, [textarea, props.value]);

    return (
        <S.Text
            readOnly={props.disabled}
            ref={textarea}
            style={{ height: height, resize: "vertical" }}
            error={props.error}
            placeholder={props.disabled === true ? "" : getPlaceholder()}
            value={props.value}
            onChange={(e) => {
                let val = e.target.value;
                if (val.replace(/[\n\s]/g, "") === "") {
                    val = "";
                }

                props.onChange(val);
            }}
            onFocus={props.onFocus}
        />
    );
};

export default AnswerTextarea;

//#region styles
const S = {
    Text: styled.textarea<{ error?: boolean }>`
        font-family: "Roboto", sans-serif;
        border: 0;
        min-height: 5rem;
        margin: 1rem 1px 1px;
        padding: 0.3rem;
        font-size: ${unitize(16)};
        background-color: transparent;
        font-weight: ${designTheme.typography.weight.bold};
        width: 100%;
        color: ${(props) => (props.error === true ? designTheme.color.warning : "inherit")};
        ${(props) =>
            props.readOnly === true
                ? css`
                      &:focus {
                          outline: none;
                      }
                  `
                : css`
                      &:focus {
                          outline: 1px solid ${designTheme.color.primary};
                      }
                  `};
        &::placeholder {
            color: ${designTheme.color.darkgrey};
            font-weight: ${designTheme.typography.weight.regular};
            opacity: 1;
        }
    `,
};
//#endregion styles
