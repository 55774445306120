import { action, computed, makeObservable, observable } from "mobx";
import { ApiResponse } from "../../api/ApiService";
import { IArticleOverviewItemDocu, IDocumentationItemOfArticleOverview } from "../../api/DocumentationApi";
import { IBuildingRead } from "../../entities/Building";
import { IBuildingAreaRead } from "../../entities/BuildingArea";
import { IBuildingAttachmentRead, IBuildingAttachmentUpdate } from "../../entities/BuildingAttachment";
import { IDeliveryNoteRead } from "../../entities/DeliveryNote";
import { IDocumentRead } from "../../entities/Document";
import {
    IDocumentationCreateCertificate,
    IDocumentationRead,
    IDocumentationUpdate,
} from "../../entities/Documentation";
import { IDocumentationItemRead } from "../../entities/DocumentationItem";
import { IDocumentationSharingHashRead, IDocumentationSharingHashWrite } from "../../entities/DocumentationSharingHash";
import { areaService } from "../../services/AreaService";
import { articleService } from "../../services/ArticleService";
import { attachmentService } from "../../services/AttachmentService";
import { buildingService } from "../../services/BuildingService";
import { dataLayerService, EventData } from "../../services/DataLayerService";
import { deliveryNoteService } from "../../services/DeliveryNoteService";
import { documentationItemService } from "../../services/DocumentationItemService";
import { documentationService } from "../../services/DocumentationService";
import { documentationSharingHashService } from "../../services/DocumentationSharingHashService";
import { documentService } from "../../services/DocumentService";
import { session } from "../../session/Session";
import { SuperControllerStore } from "../../stores/controller/SuperControllerStore";
import { isDefined } from "../../utils/isDefined";
import { isNullish } from "../../utils/isNullish";

export class DocumentationController extends SuperControllerStore<
    | "all"
    | "reopenDocumentation"
    | "loadDocumentation"
    | "updateSignature"
    | "loadFiles"
    | "loadArticles"
    | "uploadFile"
    | "updateFile"
    | "updateDocumentation"
    | "completeDocumentation"
    | "deleteFile"
    | "deleteDocumentationItem"
    | "deleteAllDocumentationItemsOfArticle"
    | "reloadUrl"
    | "reloadUrlArticles"
    | "fetchDeliveryNote"
    | "createSharingHash"
    | "loadDocumentationSharingHashes"
    | "createCertificateModal"
    | "sendMaintenanceReminder"
    | "sendDocumentationOfferMails"
> {
    static controllerName = "DocumentationController";
    currentInstalledArticlesToEdit = 0; // TODO: delete unused
    buildingId?: number;
    buildingAreaId?: number;

    get articles(): IArticleOverviewItemDocu[] {
        return articleService.getSortedListDocu(this.currentId);
    }

    get building(): IBuildingRead | undefined {
        if (isNullish(this.buildingId)) return;
        return buildingService.get(this.buildingId);
    }

    get buildingArea(): IBuildingAreaRead | undefined {
        if (isNullish(this.buildingAreaId)) return;
        return areaService.get(this.buildingAreaId);
    }

    get documentation(): IDocumentationRead | undefined {
        return documentationService.get(this.currentId);
    }

    get files(): IBuildingAttachmentRead[] {
        return attachmentService.list.filter((item) => {
            return item.documentationId === this.currentId && (item.documentationItemId ?? false) === false;
        });
    }

    get lastDocumentTypeDocumentation(): IDocumentRead | undefined {
        return documentService.getLastDocumentByDocumentation(this.currentId, "documentation");
    }

    get documentationItems(): IDocumentationItemOfArticleOverview[] {
        return documentationItemService.list.filter((item) => item.documentationId === this._currentId);
    }

    get isCompleted(): boolean {
        const all = this.documentationItems;
        const missingAnswer = all.find(
            (item: IDocumentationItemRead) => isNullish(item.questionsAnswered) || !item.questionsAnswered
        );
        return isDefined(this.documentation) && this.articles.length > 0 && all.length > 0 && isNullish(missingAnswer);
    }
    constructor() {
        super(session, {
            all: false,
            completeDocumentation: false,
            deleteAllDocumentationItemsOfArticle: false,
            deleteDocumentationItem: false,
            deleteFile: false,
            loadArticles: false,
            loadDocumentation: false,
            loadFiles: false,
            reloadUrl: false,
            reloadUrlArticles: false,
            reopenDocumentation: false,
            updateDocumentation: false,
            updateFile: false,
            updateSignature: false,
            uploadFile: false,
            fetchDeliveryNote: false,
            createSharingHash: false,
            loadDocumentationSharingHashes: false,
            createCertificateModal: false,
            sendMaintenanceReminder: false,
            sendDocumentationOfferMails: false,
        });

        makeObservable(this, {
            articles: computed,
            buildingId: observable,
            buildingAreaId: observable,
            files: computed,
            lastDocumentTypeDocumentation: computed,
            documentationItems: computed,
            isCompleted: computed,
            uploadFiles: action,
        });
    }

    init(buildingId: number, buildingAreaId: number, documentationId: number): void {
        dataLayerService.lastDocumentationId = documentationId;

        this.buildingId = buildingId;
        this.buildingAreaId = buildingAreaId;
        this.currentId = documentationId;
        this.usePageData(
            () => buildingAreaId > 0 && documentationService.fetchByArea(buildingAreaId),
            () => buildingAreaId > 0 && areaService.getOrFetch(buildingAreaId),
            () => buildingId > 0 && buildingService.getOrFetch(buildingId),
            () => documentationId > 0 && attachmentService.fetchByDocumentation(documentationId)
        );

        if (this.lastDocumentTypeDocumentation?.imageDownloadUrl === undefined) {
            this.usePageData(() => documentService.fetchByDocumentation(documentationId));
        }
        if (this.articles.length < 1 && this.waitingFor.loadArticles !== documentationId) {
            this.usePageData(() => this.loadArticles());
        }

        const page = this.documentation?.type ?? "documentation";
        dataLayerService.emitHistory(location, dataLayerService.dataLayer, page);
    }

    loadDocumentation(buildingAreaId: number): Promise<ApiResponse<IDocumentationRead[]>> {
        return this.resolveAsAction({
            promise: () => documentationService.fetchByArea(buildingAreaId),
            waitingForKey: ["loadDocumentation"],
        });
    }

    getDeliveryNote(barcode?: string): IDeliveryNoteRead[] {
        return deliveryNoteService.list.filter((note) => note.barcode !== barcode).reverse();
    }

    updateDocumentation(data: IDocumentationUpdate): void {
        if (this.currentId === 0) {
            throw Error("Cannot update documentation, before documentationId is stored");
        }

        this.resolveAsAction({
            promise: () => documentationService.update(this.currentId, data),
            waitingForKey: "updateDocumentation",
        });
    }

    loadArticles(reloadUrl?: boolean): Promise<ApiResponse<IArticleOverviewItemDocu[]>> {
        if (this.currentId === 0) {
            throw Error("Cannot load articles, before documentationId is stored");
        }

        return this.resolveAsAction({
            promise: () => articleService.loadArticlesByDocu(this.currentId),
            waitingForKey: [reloadUrl === true ? "reloadUrlArticles" : "loadArticles"],
        });
    }

    loadDeliveryNote(barcode: string): Promise<ApiResponse<IDeliveryNoteRead[]>> {
        if (barcode === "") {
            throw Error("Cannnot find the delivery note");
        }
        return this.resolveAsAction({
            promise: () => deliveryNoteService.fetchDeliveryNote(barcode),
            waitingForKey: "fetchDeliveryNote",
        });
    }

    updateSignature({
        signature,
        useUserSignature = false,
    }: {
        signature: IDocumentationRead["signature"];
        useUserSignature: boolean;
    }): Promise<ApiResponse<IDocumentationRead>> {
        if (this.currentId === 0) {
            throw Error("Cannot add signature, before documentationId is stored");
        }

        return this.resolveAsAction({
            promise: () => documentationService.addSignature(this.currentId, signature, useUserSignature),
            waitingForKey: "updateSignature",
        });
    }

    completeDocumentation(): Promise<ApiResponse<IDocumentationRead>> {
        if (this.currentId === 0) {
            throw Error("Cannot complete documentation, before documentationId is stored");
        }

        return this.resolveAsAction({
            promise: () => documentationService.completeDocumentation(this.currentId),
            waitingForKey: "completeDocumentation",
        });
    }

    reopenDocumentation(): Promise<ApiResponse<IDocumentationRead>> {
        if (this.currentId === 0) {
            throw Error("Cannot complete documentation, before documentationId is stored");
        }

        return this.resolveAsAction({
            promise: () => documentationService.reopenDocumentation(this.currentId),
            waitingForKey: "reopenDocumentation",
            action: (response) => {
                const event: EventData = {
                    category: this.documentation?.type ?? "documentation",
                    action: "unlock_documentation",
                    label: "failed",
                    payload: this.currentId,
                };
                if (response.result !== null) {
                    event.label = "success";
                }

                dataLayerService.emitClick(event);

                return response;
            },
        });
    }

    loadFiles(reloadUrl?: boolean): Promise<ApiResponse<IBuildingAttachmentRead[]>> {
        if (this.currentId === 0) {
            throw Error("Cannot load files, before documentationId is stored");
        }

        return this.resolveAsAction({
            promise: () => attachmentService.fetchByDocumentation(this.currentId),
            waitingForKey: reloadUrl === true ? "reloadUrl" : "loadFiles",
        });
    }

    async uploadFiles(files: File[]): Promise<void> {
        if (this.currentId === 0) {
            throw Error("Cannot load files, before documentationId is stored");
        }
        for (const fileElement of files) {
            await this.resolveAsAction({
                promise: () => attachmentService.uploadByDocumentation(this.currentId, { imageFile: fileElement }),
                waitingForKey: "uploadFile",
            });
        }
    }

    deleteFile(buildingAttachmentId: number): void {
        this.resolveAsAction({
            promise: () => attachmentService.delete(buildingAttachmentId),
            waitingForKey: "deleteFile",
            setWaitingForValueTo: buildingAttachmentId,
        });
    }

    updateFile(buildingAttachmentId: number, data: IBuildingAttachmentUpdate): void {
        this.resolveAsAction({
            promise: () => attachmentService.update(buildingAttachmentId, data),
            waitingForKey: "updateFile",
            setWaitingForValueTo: buildingAttachmentId,
        });
    }

    createCertificate(
        data: IDocumentationCreateCertificate,
        documentationId: number
    ): Promise<ApiResponse<IDocumentationRead>> {
        return this.resolveAsAction({
            promise: () => documentationService.createCertificate(documentationId, data),
            waitingForKey: "updateDocumentation",
            action: (response) => {
                // update building.projectNumber
                buildingService.mergeList({
                    ...this.building,
                    projectNumber: data.projectNumber,
                } as IBuildingRead);
                return response;
            },
        });
    }

    createCertificateModal(
        data: IDocumentationCreateCertificate,
        documentationId: number
    ): Promise<ApiResponse<IDocumentationRead>> {
        return this.resolveAsAction({
            promise: () => documentationService.createCertificate(documentationId, data),
            waitingForKey: "createCertificateModal",
            action: (response) => {
                // update building.projectNumber
                buildingService.mergeList({
                    ...this.building,
                    projectNumber: data.projectNumber,
                } as IBuildingRead);
                return response;
            },
        });
    }

    createDocumentationSharingHash(
        body: IDocumentationSharingHashWrite
    ): Promise<ApiResponse<IDocumentationSharingHashRead>> {
        return this.resolveAsAction({
            promise: () => documentationSharingHashService.createDocumentationSharingHash(body),
            waitingForKey: "createSharingHash",
            action: (response) => {
                return response;
            },
        });
    }

    deleteAllDocumentationItemsOfArticle(article: IArticleOverviewItemDocu): void {
        if (this.currentId === 0) {
            throw Error("Cannot delete DocumentationItems, before documentationId is stored");
        }
        const documentationItemIds = article.documentationItems.map((item) => item.id);
        this.resolveAsAction({
            promise: () => documentationItemService.deleteDocumentationItems(this.currentId, documentationItemIds),
            waitingForKey: ["deleteDocumentationItem"],
            setWaitingForValueTo: article.id,
        });
    }

    loadDocumentationSharingHashes(docuId: number): void {
        this.resolveAsAction({
            promise: () => documentationSharingHashService.getDocumentationSharingHashes(docuId),
            waitingForKey: ["loadDocumentationSharingHashes"],
        });
    }

    sendMaintenanceReminder(docuId: number): void {
        this.resolveAsAction({
            promise: () => documentationService.sendMaintenanceReminder(docuId),
            waitingForKey: "sendMaintenanceReminder",
        });
    }

    sendDocumentationOfferMails(docuId: number): void {
        this.resolveAsAction({
            promise: () => documentationService.sendDocumentationOfferMails(docuId),
            waitingForKey: "sendDocumentationOfferMails",
        });
    }
}
