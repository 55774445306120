import { MainNavigation, unitize } from "@abs-safety/lock-book-web-ui";
import { Formik } from "formik";
import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { getConstraints } from "../constraints/constraints";
import { IBuildingRead } from "../entities/Building";
import { IBuildingAttachmentRead, IBuildingAttachmentUpdate } from "../entities/BuildingAttachment";
import { session } from "../session/Session";
import { WaitingForValue } from "../stores/SuperStore";
import FieldLoading from "./FieldLoading";
import FileEditFormForm from "./FileEditFormForm";

export interface FilesProps {
    waitingForFiles: WaitingForValue;
    updateFile: (buildingAttachmentId: number, data: IBuildingAttachmentUpdate) => void;
    deleteFile: (id: number) => void;
    files?: IBuildingAttachmentRead[];
    building?: IBuildingRead;
    waitingForReloadUrl?: WaitingForValue;
    reloadUrl: () => void;
}

// WAITFOR API: LBDOCU-480 "andrucken" property is still missing in Entity:
// https://abssafety.atlassian.net/browse/LBDOCU-480
type FormValuesWithOriginalValues = Pick<IBuildingAttachmentRead, "imageFilename" | "categoryId">;

/** all form values are strings (as Formik converts them, because HTML native e.taget.value is always string) */
export type FormValues = { [key in keyof FormValuesWithOriginalValues]: string };

/**
 * Component
 */
const FileEditForm: FunctionComponent<FilesProps> = (props: FilesProps) => {
    const params = useParams<{ id: string }>();
    const id = parseInt(params.id);

    /** e.g. '.jpg' (with dot). If FileName has no extension, than ''. Is used to re-attach to FileName (without extension in Form) when submitting */
    const [fileExtension, setFileExtension] = useState<string>("");
    const [file, setFile] = useState<IBuildingAttachmentRead | undefined>();
    const [initialValues, setInitialValues] = useState<FormValues | undefined>();

    const [validationSchema, setValidationSchema] = useState(Yup.object().shape<Partial<FormValues>>({}));
    useEffect(() => {
        if (file !== undefined) {
            // if file was already initialized, then don't re-asign form values (initialValues), otherwise
            // FormAutoSubmit would send another request, and it could potentially override the user's input.

            // but we have to pass new file.imageDownloadUrl to Form, as the download button needs new downloadUrl
            // which leads to new filename when downloading (S3Bucket imageDownloadUrl request has filename in reponse)
            const _file = props.files?.find((file) => file.id === id);
            setFile(_file);

            return;
        }

        const _file = props.files?.find((file) => file.id === id);
        setFile(_file);

        if (_file !== undefined) {
            //#region split FileName into pur name (FormInput) and fileExtension
            const indexLastDot = _file.imageFilename.lastIndexOf(".");
            let fileNamePure: string;
            if (indexLastDot === -1) {
                console.error("imageFilename has no file extension");
                fileNamePure = _file.imageFilename;
                setFileExtension("");
            } else {
                fileNamePure = _file.imageFilename.substring(0, indexLastDot);
                setFileExtension(_file.imageFilename.substring(indexLastDot));
            }
            //#endregion

            setInitialValues({
                imageFilename: fileNamePure,
                categoryId: _file.categoryId.toString(),
            });
        }
    }, [id, props.files]);

    useEffect(() => {
        setValidationSchema(
            Yup.object().shape<Partial<FormValues>>({
                imageFilename: getConstraints("BuildingAttachment", "imageFilename"),
            })
        );
    }, [session.constraints]);

    return (
        <>
            <MainNavigation
                items={[
                    {
                        id: "Datei",
                        text: "Datei",
                    },
                ]}
            />
            <S.Content>
                {props.waitingForFiles !== false || isEmpty(session.constraints) ? (
                    <FieldLoading text="Datei wird geladen..." />
                ) : file === undefined || initialValues === undefined ? (
                    <S.NotFoundText>Keine Datei zu der URL gefunden</S.NotFoundText>
                ) : (
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            props.updateFile(id, {
                                category: `/building-attachment-categories/${values.categoryId}`,
                                imageFilename: values.imageFilename + fileExtension,
                            });
                        }}
                    >
                        {(formikBag) => (
                            <FileEditFormForm {...formikBag} {...props} file={file} fileExtension={fileExtension} />
                        )}
                    </Formik>
                )}
            </S.Content>
        </>
    );
};

export default observer(FileEditForm);

//#region styles
const S = {
    Content: styled.div`
        margin-top: ${unitize(60)};
    `,
    NotFoundText: styled.h4`
        margin-top: ${unitize(20)};
    `,
    ButtonGroup: styled.div`
        margin-top: ${unitize(20)};
    `,
};
//#endregion
