// todo make errors consistent to api (or pipe api errors to make it consistent to web)

import { ApiResponse } from "../api/ApiService";
import { fetchTypes } from "../api/Connection";
import { notificationHandler } from "../session/NotificationHandler";

export class HttpError extends Error {
    statusCode: number;
    constructor(message: string, code: number, public response: Response, public result?: any) {
        super(message);
        this.statusCode = code;
    }
}

export class UnauthorizedError extends HttpError {
    constructor(message: string, public response: Response, public result?: any) {
        super(message, 401, response, result);
        this.name = "Unauthorized";
    }
}

export class ForbiddenError extends HttpError {
    constructor(message: string, public response: Response, public result?: any) {
        super(message, 403, response, result);
        this.name = "Forbidden";
    }
}

export class FileTooLargeError extends Error {
    constructor(props: { name: string; message: string }) {
        super(props.message);
        this.name = props.name;
    }
}

export class NotFoundError extends HttpError {
    constructor(message: string, public response: Response, public result?: any) {
        super(message, 404, response, result);
        this.name = "NotFound";
    }
}

export class ConstraintViolationError extends HttpError {
    constructor(message: string, public response: Response, public result?: any) {
        super(message, 400, response, result);
        this.name = "ConstraintViolation";
    }
}

export class CallStackViolation extends Error {
    constructor(message: string) {
        super(message);
        this.name = "CallStackViolation";
    }
}

export const catchUploadError = (e: Error, body: FormData): ApiResponse<any, fetchTypes.fetchUpload> => {
    if (e instanceof ConstraintViolationError) {
        notificationHandler.addNotification({
            type: "error",
            title: `Upload fehlgeschlagen (Datei: ${(body.get("imageFile") as File).name})`,
            description: e.message,
        });
        return {
            response: e.response,
            result: null,
        };
    }

    throw e;
};
