import { SideModal } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { documentationItemService } from "../../../services/DocumentationItemService";
import { installedArticleService } from "../../../services/InstalledArticleService";
import { getController } from "../../../stores/controller/ControllerFactory";
import { getInstalledArticlePreviewName } from "../../../utils/getInstalledArticlePreviewName";
import { layout } from "../../../utils/layout";
import { DocumentationController } from "../DocumentationController";
import AccordionTitleArticleViewCollapsed from "./components/AccordionTitleArticleViewCollapsed";
import { DocumentController } from "./DocumentController";
import ColArticles from "./subviews/ColArticles";
import ColDetails from "./subviews/ColDetails";
import ColDocumentationItems from "./subviews/ColDocumentationItems";

enum ViewKey {
    Articles = "Articles",
    DocuItems = "DocuItems",
    Details = "Details",
}

interface DocumentProps {
    onClose: () => void;
}

const Document: FunctionComponent<DocumentProps> = (props: DocumentProps) => {
    const params: {
        buildingId: string;
        buildingAreaId: string;
        documentationId: string;
    } = useParams();
    const documentationId = parseInt(params.documentationId);
    const { controller } = getController(DocumentationController);
    const { controller: documentController, useInit } = getController(DocumentController);
    const [activeView, setActiveView] = useState<string>(ViewKey.DocuItems);

    const installedArticleIdsOfSelection = documentationItemService
        .getMultiple(documentController.currentDocumentationItemIds)
        .map((docuItem) => docuItem.installedArticleId)
        .filter((id) => id !== undefined) as number[];

    const selectedInstalledArticles = installedArticleService.getMultiple(installedArticleIdsOfSelection);
    const selectedInstalledArticleNames = selectedInstalledArticles.map((installedArticle) =>
        getInstalledArticlePreviewName(installedArticle)
    );

    useInit(documentationId);

    useEffect(() => {
        documentController.updateDocumented();
    }, [documentationItemService.list]);

    useEffect(() => {
        const firstArticle = controller.articles.length ? controller.articles[0] : undefined;
        const selectedArticle = documentController.currentArticle ?? firstArticle?.id ?? undefined;
        if (selectedArticle !== documentController.currentArticle) {
            documentController.setCurrentArticle(selectedArticle);

            const selectedDocuItems: number[] = [];
            const firstDocuItemOfFirstArticle = firstArticle?.documentationItems[0];

            if (firstDocuItemOfFirstArticle !== undefined) {
                selectedDocuItems.push(firstDocuItemOfFirstArticle.id);
            }
            documentController.setCurrentDocumentationItemIds(selectedDocuItems);
        }
    }, [controller.articles]);

    return (
        <div className={"uf-documentModal uf-sideModal"}>
            <SideModal
                isOpen
                onCloseClick={props.onClose}
                style={{
                    top: `${layout.header.height}px`,
                    height: `calc(100% - ${layout.header.height}px)`,
                }}
                views={[
                    {
                        key: ViewKey.Articles,
                        width: viewWidths.Articles,
                        paddingSize: "none",
                        component: <ColArticles />,
                        accordionTitle: {
                            component: <strong>1. Produkte</strong>,
                            componentWhenCollapsed: <AccordionTitleArticleViewCollapsed />,
                        },
                    },
                    {
                        key: ViewKey.DocuItems,
                        width: viewWidths.DocuItems,
                        paddingSize: "none",
                        component: <ColDocumentationItems />,
                        accordionTitle: {
                            component: <strong>2. Artikel</strong>,
                            componentWhenCollapsed: (
                                <ArticleViewTitleCollapsed
                                    selectedInstalledArticleNames={selectedInstalledArticleNames}
                                />
                            ),
                        },
                    },
                    {
                        key: ViewKey.Details,
                        width: viewWidths.Details,
                        paddingSize: "none",
                        component: <ColDetails accordionBreakPoint={totalViewWidths} />,
                        accordionTitle: {
                            component: <strong>3. Bearbeiten</strong>,
                        },
                    },
                ]}
                activeView={activeView}
                onActiveViewChange={setActiveView}
                accordionHeader={AccordionHeader}
            />
        </div>
    );
};

export default observer(Document);

const AccordionHeader = <h3>Dokumentieren</h3>;

const viewWidths: { [key in ViewKey]: number } = {
    [ViewKey.Articles]: 375,
    [ViewKey.DocuItems]: 360,
    [ViewKey.Details]: 600,
};
const totalViewWidths = Object.values(viewWidths).reduce((a, b) => a + b);

const ArticleViewTitleCollapsed = (props: { selectedInstalledArticleNames: string[] }) => {
    return (
        <span>
            <strong>2. Artikel</strong>
            <span>
                {props.selectedInstalledArticleNames.length > 0
                    ? `: ${props.selectedInstalledArticleNames.join(", ")}`
                    : ""}
            </span>
        </span>
    );
};
