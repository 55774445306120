import { IInstalledArticleRead } from "../entities/InstalledArticle";

/**
 * get the display name of an installedArticles based on which of its properties are defined (by priority)
 */
export function getInstalledArticlePreviewName(installedArticle: Partial<IInstalledArticleRead>): string {
    const absSerialNumber = installedArticle.absSerialNumber ?? "";
    if (absSerialNumber !== "") return absSerialNumber;

    const serialNumber = installedArticle.serialNumber ?? "";
    if (serialNumber !== "") return serialNumber;

    const identificationPlateNumber = installedArticle.identificationPlateNumber ?? "";
    if (identificationPlateNumber !== "") return identificationPlateNumber;

    const identificationNumber = installedArticle.identificationNumber ?? "";
    const identificationCounter = installedArticle.identificationCounter ?? "";
    if (identificationNumber !== "" && identificationCounter !== "")
        return `${identificationNumber}${identificationCounter}`;

    if (identificationNumber !== "") return identificationNumber;

    const appId = installedArticle.appId ?? "";
    if (appId !== "") return "App ID " + installedArticle.appId;

    return "";
}
