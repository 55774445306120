type LegacyRole = "ROLE_COMPANY_OFFICE" | "ROLE_USER" | "ROLE_ADMIN" | "ROLE_SUPER_ADMIN";

export type Role = "ROLE_ABS_ADMIN" | "ROLE_ABS_OFFICE" | "ROLE_COMPANY_ADMIN" | "ROLE_COMPANY_TECH";

type HistoricalRoles = Role | LegacyRole;

const includedRoles: { [key in Role]: Role[] } = {
    ROLE_ABS_ADMIN: ["ROLE_ABS_OFFICE", "ROLE_COMPANY_ADMIN"],
    ROLE_ABS_OFFICE: ["ROLE_COMPANY_ADMIN"],
    ROLE_COMPANY_ADMIN: ["ROLE_COMPANY_TECH"],
    ROLE_COMPANY_TECH: [],
};

const legacyUserRoleToRole: { [key in LegacyRole]: Role } = {
    ROLE_SUPER_ADMIN: "ROLE_ABS_ADMIN",
    ROLE_ADMIN: "ROLE_COMPANY_ADMIN",
    ROLE_COMPANY_OFFICE: "ROLE_COMPANY_ADMIN",
    ROLE_USER: "ROLE_COMPANY_TECH",
};

function mapLegacyUserRoles(roles: HistoricalRoles[]): Role[] {
    return roles.map((role) => legacyUserRoleToRole[role as LegacyRole] ?? role);
}

/** doesn't include role itself & doesn't elimate duplicates */
export function getAllIncludedRolesOf(role: Role): Role[] {
    const children = includedRoles[role] ?? [];
    return [...children, ...children.flatMap((included) => getAllIncludedRolesOf(included))];
}

/** checks if requiredRole is part of role. Also true if they are the same */
export function hasRoleRequiredRole(role: Role, requiredRole: Role): boolean {
    if (role === requiredRole) return true;
    const allIncludedRoles = getAllIncludedRolesOf(role);
    return includedRoles[role] ? allIncludedRoles.includes(requiredRole) : false;
}

export function rolesCoverRequiredRole(roles: Role[], requiredRole: Role): boolean {
    return mapLegacyUserRoles(roles).filter((role) => hasRoleRequiredRole(role, requiredRole)).length > 0;
}
