import { debounce } from "lodash";
import { action, makeObservable, observable, runInAction } from "mobx";

class LayoutService {
    windowWidth = window.innerWidth;

    private updateWindowWidth = () => {
        runInAction(() => {
            this.windowWidth = window.innerWidth;
        });
    };

    private onResize = debounce(this.updateWindowWidth, 300);

    constructor() {
        makeObservable<LayoutService, "updateWindowWidth" | "onResize">(this, {
            windowWidth: observable,
            updateWindowWidth: action,
            onResize: action,
        });

        window.addEventListener("resize", this.onResize);
    }
}

export const layoutService = new LayoutService();
