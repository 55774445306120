import { Button, FieldEmpty, IconCopy, Input } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import moment from "moment";
import React, { FunctionComponent, useRef } from "react";
import styled from "styled-components";
import { transferKeyService } from "../../../services/TransferKeyService";
import { notificationHandler } from "../../../session/NotificationHandler";
import { getController } from "../../../stores/controller/ControllerFactory";
import { formatDate } from "../../../utils/formatDate";
import { getTransferKeyLink } from "../../../utils/getTransferKeyLink";
import { BuildingController } from "../BuildingController";
import DirectTransferIfAbsAdmin from "../components/DirectTransferIfAbsAdmin";

interface ObjectTransferProps {
    selectedBuildingId: number;
}

const ObjectTransfer: FunctionComponent<ObjectTransferProps> = (props: ObjectTransferProps) => {
    const { controller } = getController(BuildingController);
    const transferKeyRef = useRef<HTMLInputElement>(null);
    const transferKeyLinkRef = useRef<HTMLInputElement>(null);
    const transferKeyObject = transferKeyService.list.find(
        (res) => res.building === `/buildings/${props.selectedBuildingId}`
    );
    const transferKey = transferKeyObject?.transferKey;
    const transferKeyLink = transferKey !== undefined ? getTransferKeyLink(transferKey) : "";
    const transferKeyCreateDate =
        transferKeyObject !== undefined ? formatDate(new Date(transferKeyObject.createdAt)) : "";
    const transferKeyExpireDate =
        transferKeyObject !== undefined
            ? moment(new Date(transferKeyObject.createdAt)).add(30, "days").format("DD.MM.YYYY")
            : "";

    const copyTransferKey = (inputRef: React.RefObject<HTMLInputElement>, link: boolean): void => {
        const copyText = inputRef.current?.querySelector("input") ?? false;

        if (copyText === false) return;

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        document.execCommand("copy");

        /* Alert the copied text */
        notificationHandler.addNotification({
            title: `Transfer-Schlüssel ${link ? "Link" : ""}`,
            description: `Der Transfer-Schlüssel ${
                link ? "Link" : ""
            } für dein Objekt wurde erfolgreich in die Zwischenablage kopiert`,
            type: "success",
        });
    };

    return (
        <S.PdfLinkDocumentation>
            {transferKeyObject === undefined || !transferKeyObject.isActive ? (
                <>
                    <FieldEmpty icon={<></>} text="Kein aktiver Schlüssel vorhanden" />
                    <S.ButtonWrapper>
                        <Button size={"small"} variant={"outline"} color={"black"}>
                            <button onClick={() => controller.createTransferKey(props.selectedBuildingId)}>
                                Schlüssel generieren
                            </button>
                        </Button>
                    </S.ButtonWrapper>
                </>
            ) : (
                <>
                    <S.text>
                        <h4>Objekt an anderes Unternehmen weitergeben</h4>
                        <br />
                        <p>Um das Objekt weiterzugeben, versende den angezeigten Link oder teile den Schlüssel</p>
                    </S.text>

                    <Input type="text" label="Angelegt am" value={transferKeyCreateDate} readOnly={true} />
                    <Input type="text" label="Läuft ab am" value={transferKeyExpireDate} readOnly={true} />

                    <S.TransferKey ref={transferKeyRef}>
                        <Input label="Transfer-Schlüssel" type="text" value={transferKey} readOnly={true} />
                        {transferKey !== "" && (
                            <S.CopyIconWrapper onClick={() => copyTransferKey(transferKeyRef, false)}>
                                <IconCopy width={24} height={24} />
                            </S.CopyIconWrapper>
                        )}
                    </S.TransferKey>
                    <S.TransferKey ref={transferKeyLinkRef}>
                        <Input
                            label="Link zur Weitergabe des Objekts"
                            type="text"
                            value={transferKeyLink}
                            readOnly={true}
                        />
                        {transferKeyLink !== "" && (
                            <S.CopyIconWrapper onClick={() => copyTransferKey(transferKeyLinkRef, true)}>
                                <IconCopy width={24} height={24} />
                            </S.CopyIconWrapper>
                        )}
                    </S.TransferKey>
                    <S.ButtonWrapper>
                        <Button size={"small"} variant={"outline"} color={"black"}>
                            <button onClick={() => controller.createTransferKey(props.selectedBuildingId)}>
                                Neuen Schlüssel generieren
                            </button>
                        </Button>
                        <Button size={"small"} variant={"outline"} color={"black"}>
                            <button onClick={() => transferKeyService.deleteTransferKeys(transferKey)}>
                                Deaktivieren
                            </button>
                        </Button>
                    </S.ButtonWrapper>
                </>
            )}
            <DirectTransferIfAbsAdmin selectedBuildingId={props.selectedBuildingId} />
        </S.PdfLinkDocumentation>
    );
};

//#region styles
const S = {
    PdfLinkDocumentation: styled.div`
        padding-bottom: 75px;
    `,
    text: styled.div`
        margin-top: 16px;
        margin-bottom: 16px;
    `,
    FieldLoading: styled.div`
        margin-top: 26px;
    `,

    TransferKey: styled.div`
        display: flex;
        align-items: flex-start;
        position: relative;
        margin-top: 16px;
        input {
            padding-right: 20px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        label {
            width: 100%;
        }
        svg {
            position: absolute;
            top: 2rem;
        }
    `,
    CopyIconWrapper: styled.div`
        cursor: pointer;
        height: 24px;
        width: 24px;
    `,
    ButtonWrapper: styled.div`
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;
        button {
            margin-right: 20px;
        }
        button:last-child {
            margin-right: 0;
        }
    `,
};
//#endregion styles

export default observer(ObjectTransfer);
