/**
 * based on lock-book-docu-api: api/src/Entity/BuildingAttachment.php
 */

import { RepoStore } from "../stores/RepoStore";
import { IBuildingAreaRead } from "./BuildingArea";
import { IBuildingAttachmentCategoryRead } from "./BuildingAttachmentCategory";
import { IBuildingAttachmentVisibilityRead } from "./BuildingAttachmentVisibility";
import { IDocumentationRead } from "./Documentation";
import { IUserRead } from "./User";

class AttachmentRepo extends RepoStore<IBuildingAttachmentRead> {}

export const attachmentRepo = new AttachmentRepo();

type BuildingAttachmentCategory = IBuildingAttachmentCategoryRead;
type BuildingAttachmentVisibility = IBuildingAttachmentVisibilityRead;
type Building = unknown;
type DocumentationItem = unknown;

export type AttachmentType = "building" | "area" | "documentation" | "documentationitem";

interface IBuildingAttachmentBase {
    imageUrl: string;
    imageDownloadUrl: string; // WAITFOR LBDOCU-490
    uploadUrl: string;
    id: number;
    imageFile: File;
    imageFilename: string;
    fileSize: string;
    createdAt: Date;
    updatedAt: Date;
    /** can be a BuildingCategory object, or a string like '/building-attachment-categories/1' */
    category: BuildingAttachmentCategory | string;
    visibility: BuildingAttachmentVisibility;
    creator: IUserRead;
    building: Building;
    type: AttachmentType;
    categoryId: number;

    // DiscriminatorMap

    // area:
    buildingAreaId?: number;
    /** e.g. '/building-areas/63' in read
     * - pretty useless, as buildingAreaId provide same info */
    buildingArea?: IBuildingAreaRead;
    buildingAreaName?: string;

    // building:
    buildingId?: number;

    // documentation:
    // as well: buildingId;
    documentationId?: number;
    documentation?: IDocumentationRead;
    documentationName?: string;

    // documentationitem:
    // as well: buildingId;
    // as well: documentationId;
    // as well: documentationName;
    documentationItemId?: number;
    documentationItem?: DocumentationItem;
    order?: number;
    sorting: number;
}

export type IBuildingAttachmentRead = Pick<
    IBuildingAttachmentBase,
    | "imageUrl"
    | "imageDownloadUrl"
    | "id"
    | "imageFilename"
    | "createdAt"
    | "updatedAt"
    | "category"
    | "visibility"
    | "type"
    | "categoryId"
    | "buildingAreaId"
    | "buildingId"
    | "documentationItemId"
    | "documentationId"
    | "buildingAreaName"
    | "documentationName"
    | "creator"
    | "sorting"
>;

export type IBuildingAttachmentWrite = Pick<IBuildingAttachmentBase, "imageFile">;

export type IBuildingAttachmentUpdate = Partial<
    Pick<IBuildingAttachmentBase, "category" | "visibility" | "imageFilename" | "creator">
>;
export type IBuildingAttachmentBatchUpdate = (Partial<IBuildingAttachmentRead> & { id: number })[];
