import { Button, CardDark, Checkbox, unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent, useCallback, useState } from "react";

interface PopupWarnMultipleSelectionProps {
    onClose: (showNeverAgain: boolean) => void;
}

const PopupWarnMultipleSelection: FunctionComponent<PopupWarnMultipleSelectionProps> = (
    props: PopupWarnMultipleSelectionProps
) => {
    const [showNeverAgain, setShowNeverAgain] = useState(false);

    const onClose = useCallback(() => {
        props.onClose(showNeverAgain);
    }, [showNeverAgain]);

    const onCheckboxChange = useCallback((checked: boolean) => {
        setShowNeverAgain(checked);
    }, []);

    return (
        <CardDark style={{ position: "absolute", top: "100" }}>
            <h4>Auswirkungen der Mehrfachauswahl</h4>
            <p>
                Die folgenden Änderungen beziehen sich auf <strong>alle ausgewählten</strong> Artikel.
            </p>
            <p style={{ margin: "${unitize(35)} 0" }}>
                <Checkbox
                    white={true}
                    checked={showNeverAgain}
                    onChange={onCheckboxChange}
                    regularWeight={true}
                    text="Diesen Hinweis nicht mehr anzeigen"
                />
            </p>
            <Button style={{ marginBottom: unitize(-15) }} variant="outline" color="white">
                <button onClick={onClose}>Verstanden</button>
            </Button>
        </CardDark>
    );
};

export default PopupWarnMultipleSelection;
