import { IArticleOverviewItem } from "../../../api/ArticleApi";
import { DocumentationController } from "../../../pages/Documentation/DocumentationController";
import { getController } from "../../../stores/controller/ControllerFactory";
import { AddArticleController } from "../AddArticleController";

/**
 * (Only used when Modal is used inside a Documentation)
 * How many installedArticles of this article can be added to the Documentation?
 * Depends on the Documentation.type:
 * - assembly: installedArticles, which are not assembled yet
 * - maintenance: installedArticles, which are not part of THIS Documentation
 */
export function getAmountAvailableInstalledArticles(article: IArticleOverviewItem): number {
    const { controller: modalController } = getController(AddArticleController);
    const { controller: docuController } = getController(DocumentationController);
    if (modalController.documentationType === "assembly") {
        return article.installedArticles.filter((ia) => ia.amountAssemblies === 0).length;
    } else if (modalController.documentationType === "maintenance") {
        const articleInDocu = docuController.articles.find((a) => a.id === article.id);
        if (articleInDocu === undefined) {
            return article.installedArticles.length; // all are available
        }
        return article.installedArticles.length - articleInDocu.documentationItems.length;
    } else {
        console.error("unknown documentationType:", modalController.documentationType);
        return 0;
    }
}
