import { Button, ButtonClose, Modal, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { DocumentationController } from "../../pages/Documentation/DocumentationController";
import { articleService } from "../../services/ArticleService";
import { deliveryNoteService } from "../../services/DeliveryNoteService";
import { getController } from "../../stores/controller/ControllerFactory";
import FieldLoading from "../FieldLoading";
import { AddArticleController, deliveryNoteArticles } from "./AddArticleController";
import DeliveryNoteCardArticle from "./DeliveryNoteCardArticle";

interface ModalDeliveryNoteProps {
    onCloseClick: () => void;
    isOpen: boolean;
    barcode?: string;
    docuId: number;
    buildingAreaId: number;
}

const ModalDeliveryNote: FunctionComponent<ModalDeliveryNoteProps> = (props: ModalDeliveryNoteProps) => {
    const { controller } = getController(DocumentationController);
    const { controller: modalController, useInit } = getController(AddArticleController);
    const [userSelectionDeliveryNote, setUserSelectionDeliveryNote] = useState<deliveryNoteArticles[]>([]);
    const [currentEditableArticleId, setCurrentEditableArticleId] = useState<number>();
    const deliveryNoteArticles = controller.getDeliveryNote(props.barcode).sort((a, b) => a.id - b.id);

    useInit(props.docuId, props.buildingAreaId, "documentation");

    useEffect(() => {
        //fetching the delivery notes takes some time so we are setting the userSelectionDeliveryNote as soon as the data has been fetched
        setUserSelectionDeliveryNote(
            deliveryNoteArticles.map((dn) => ({
                id: dn.id,
                amount: dn.amount,
                articleId: dn.article.id,
            }))
        );
        setCurrentEditableArticleId(deliveryNoteArticles[0]?.id);
    }, [controller.waitingFor.fetchDeliveryNote]);

    const onCheckboxChange = useCallback(
        (
            id: deliveryNoteArticles["id"],
            articleAmount: deliveryNoteArticles["amount"],
            articleId: deliveryNoteArticles["articleId"]
        ) => {
            if (userSelectionDeliveryNote.map((dn) => dn.id).indexOf(id) > -1) {
                setUserSelectionDeliveryNote(userSelectionDeliveryNote.filter((dn) => dn.id !== id));
            } else {
                setUserSelectionDeliveryNote([
                    ...userSelectionDeliveryNote,
                    { id: id, articleId: articleId, amount: articleAmount },
                ]);
            }
        },
        [userSelectionDeliveryNote, currentEditableArticleId]
    );

    const onSelectedEditableChange = useCallback(
        (id: number) => {
            if (deliveryNoteArticles.length === 0) return;

            setCurrentEditableArticleId(id);
        },
        [deliveryNoteArticles]
    );

    const onCountChange = useCallback(
        (count: number, id: number) => {
            const updateAmountToCounterAmount = userSelectionDeliveryNote.map((dn) =>
                dn.id === id ? { ...dn, amount: count } : dn
            );
            setUserSelectionDeliveryNote(updateAmountToCounterAmount);
        },
        [userSelectionDeliveryNote, userSelectionDeliveryNote]
    );

    const addArticle = async () => {
        await modalController.addArticlesByDeliveryNote(userSelectionDeliveryNote);
        props.onCloseClick();
    };

    return (
        <S.ModalDeliveryNote>
            <Modal onCloseClick={props.onCloseClick} isOpen={props.isOpen} size="lg">
                <S.ButtonClose>
                    <ButtonClose onClick={props.onCloseClick} />
                </S.ButtonClose>
                <S.HeadlineTitle>
                    <S.HeadlineText>Produkte hinzufügen</S.HeadlineText>
                </S.HeadlineTitle>
                <S.TextWrapper>
                    <S.Description>
                        Dokumentierbare Produkte des Lieferscheins. <br />
                        Seilsysteme müssen manuell zur Dokumentation hinzugefügt werden.
                    </S.Description>
                </S.TextWrapper>
                <S.InformationWrapper>
                    {deliveryNoteService.waitingFor.fetchDeliveryNote !== false && <FieldLoading text="lade Artikel" />}

                    {deliveryNoteArticles.map((article) => (
                        <DeliveryNoteCardArticle
                            id={article.id}
                            article={article}
                            articleCount={Number(
                                userSelectionDeliveryNote.filter((dn) => dn.id === article.id)[0]?.amount
                            )}
                            whenArticleCountChanges={onCountChange}
                            articleNumber={article.articleNumber}
                            key={article.id}
                            amount={article.amount}
                            onCheckboxChange={onCheckboxChange}
                            selected={userSelectionDeliveryNote.map((item) => item.id).indexOf(article.id) > -1}
                            onSelectedEditableChange={onSelectedEditableChange}
                            currentEditableArticle={currentEditableArticleId === article.id}
                            reloadUrl={() => controller.loadArticles(true)}
                            waitingForReloadUrl={articleService.waitingFor.reloadUrl}
                            imageUrl={article.article.imageUrl}
                        />
                    ))}
                </S.InformationWrapper>
                <S.ButtonGroup>
                    <S.Button>
                        <Button>
                            <button className={"uf-deliveryAddArticles"} type="submit" onClick={addArticle}>
                                Hinzufügen
                            </button>
                        </Button>
                    </S.Button>
                </S.ButtonGroup>
            </Modal>
        </S.ModalDeliveryNote>
    );
};

export default observer(ModalDeliveryNote);

//#region styles
const S = {
    ModalDeliveryNote: styled.div`
        padding: 0;
        margin-top: 0;
    `,
    InformationWrapper: styled.div`
        display: flex;
        flex-direction: column;
        min-height: ${unitize(340)};
        overflow-y: auto;
    `,
    HeadlineTitle: styled.div`
        display: flex;
        width: 90%;
    `,
    HeadlineIcon: styled.span`
        display: inline-block;
    `,
    HeadlineText: styled.h2`
        display: inline-block;
    `,
    TextWrapper: styled.div`
        margin-top: ${unitize(20)};
        margin-bottom: ${unitize(20)};
    `,
    Description: styled.p`
        font-size: ${unitize(18)};
        line-height: 1.5;
    `,
    ButtonGroup: styled.div`
        display: flex;
        justify-content: flex-end;
        margin-top: auto; // set to bottom of container
    `,
    Button: styled.div`
        margin: ${unitize(20)};
    `,
    ButtonClose: styled.div`
        position: absolute;
        right: ${30 / 16}rem;
        top: ${30 / 16}rem;
    `,
};
//#endregion styles
