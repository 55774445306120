import { ApiResponse } from "../api/ApiService";
import { DocumentationApi } from "../api/DocumentationApi";
import { DocumentationSharingHashApi } from "../api/DocumentationSharingHashApi";
import { DocumentType } from "../entities/Document";
import {
    documentationSharingHashRepo,
    IDocumentationSharingHashRead,
    IDocumentationSharingHashWrite,
} from "../entities/DocumentationSharingHash";
import { DataService } from "./abstract/DataService";

class DocumentationSharingHashService extends DataService<
    "all",
    IDocumentationSharingHashRead,
    IDocumentationSharingHashWrite
> {
    apiService = new DocumentationApi();
    documentationSharingHashApi = new DocumentationSharingHashApi();
    repo = documentationSharingHashRepo;

    constructor() {
        super({ all: false });
    }

    getNewestSharingHashOf(docuId: number, type: DocumentType): IDocumentationSharingHashRead | undefined {
        return this.repo.list
            .filter((sharingHash) => sharingHash.documentationId === docuId && sharingHash.type === type)
            .sort((a, b) => sortNewestToOldest(a.createdAt, b.createdAt))[0];
    }

    getDocumentationSharingHashes(docuId: number): Promise<ApiResponse<IDocumentationSharingHashRead[]>> {
        return this.resolveAsAction({
            promise: () => this.apiService.getDocumentationSharingHashes(docuId),
            waitingForKey: "fetch",
            action: (response) => {
                if (response.result !== null) {
                    this.mergeList(response.result);
                }

                return response;
            },
        });
    }

    createDocumentationSharingHash(
        body: IDocumentationSharingHashWrite
    ): Promise<ApiResponse<IDocumentationSharingHashRead>> {
        return this.resolveAsAction({
            promise: () => this.documentationSharingHashApi.postDocumentationSharingHash(body),
            waitingForKey: "create",
            action: (response) => {
                if (response.result !== null) {
                    this.mergeList(response.result);
                }
                return response;
            },
        });
    }
}

export const documentationSharingHashService = new DocumentationSharingHashService();

// compare function
function sortNewestToOldest(date1: Date | string, date2: Date | string): number {
    return new Date(date2).getTime() - new Date(date1).getTime();
}
