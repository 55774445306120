import React, { FunctionComponent } from "react";
import styled, { CSSProperties } from "styled-components";
import { WaitingForValue } from "../stores/SuperStore";
import { useRefreshImageUrl } from "../utils/useRefreshImageUrl";
import FieldLoading from "./FieldLoading";

interface PresignedImageProps {
    url: string;
    reloadUrl: () => void;
    waitingForValue?: WaitingForValue;
    style?: CSSProperties;
    altText?: string;
    disableRefresh?: boolean;
}

/**
 * A simple Component as a Wrapper for `<img>s`, to reload images, when presignedUrl has expired.
 */
const PresignedImage: FunctionComponent<PresignedImageProps> = (props: PresignedImageProps) => {
    const url = useRefreshImageUrl(props.url, props.reloadUrl, (props.disableRefresh ?? false) || false);
    return (
        <S.PresignedImage style={props.style}>
            {props.waitingForValue === true ? (
                <FieldLoading text="Datei wird geladen..." height={"100%"} />
            ) : (
                <img src={url} alt={props.altText} loading="lazy" />
            )}
        </S.PresignedImage>
    );
};

export default PresignedImage;

//#region styles
const S = {
    PresignedImage: styled.div`
        flex: 1;
        overflow: hidden;
        > img {
            display: block;
            object-fit: cover;
            object-position: 50% 50%;
            width: 100%;
            height: 100%;
        }
    `,
};
//#endregion styles
