import moment from "moment";
import { isNullish } from "./isNullish";

export function formatDate(date: Date | null | undefined | string): string {
    if (isNullish(date)) {
        return "";
    }

    return moment(date).format("DD.MM.YYYY");
}
