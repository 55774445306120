import { IArticleBase } from "../../../entities/Article";
import { ArticlesVisibility } from "../../../entities/ArticleVisibility";
import { DocumentationType } from "../../../entities/Documentation";
import { isDefined } from "../../../utils/isDefined";

type ArticlePartial = Pick<IArticleBase, "id">;

export const filterArticlesByArticleVisibility = <T extends ArticlePartial>(
    articles: T[],
    articlesVisibility?: ArticlesVisibility,
    documentationType?: DocumentationType
): T[] => {
    if (isDefined(articlesVisibility) && isDefined(documentationType)) {
        return articles.filter((article) => articlesVisibility[article.id]?.[documentationType] ?? false);
    } else {
        // info not yet defined? -> don't show any article yet
        return [];
    }
};
