import { Checkbox, unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent, useCallback } from "react";
import styled from "styled-components";
import { IArticleCategoryRead } from "../../../entities/ArticleCategory";
import { getController } from "../../../stores/controller/ControllerFactory";
import FieldLoading from "../../FieldLoading";
import { AddArticleController } from "../AddArticleController";

const CategoryFilter: FunctionComponent = () => {
    const { controller: modalController } = getController(AddArticleController);
    const onCategoryFilterChange = useCallback(
        (category: IArticleCategoryRead, value: boolean) => {
            modalController.updateCategoryIdInFilter(category.id, value);
        },
        [modalController.updateCategoryIdInFilter]
    );

    return (
        <div className={"uf-productFilter"}>
            <S.Heading>Filtern</S.Heading>

            {modalController.waitingFor.loadArticleCategories !== false ? (
                <FieldLoading text="Kategorien werden geladen" height={unitize(300)} />
            ) : (
                <div>
                    {modalController.articleCategories.map((category) => (
                        <S.CheckboxWrapper key={category.id}>
                            <Checkbox
                                text={category.name}
                                onChange={(val) => onCategoryFilterChange(category, val)}
                                checked={modalController.categoryFilter.has(category.id)}
                                labelId={`check_${category.id}`}
                                small
                            />
                        </S.CheckboxWrapper>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CategoryFilter;

//#region styles
const S = {
    Heading: styled.h5`
        margin-bottom: ${unitize(5)};
        margin-top: ${unitize(20)};
        font-size: ${unitize(14)};
    `,
    CheckboxWrapper: styled.div`
        margin-bottom: ${unitize(5)};
    `,
};
//#endregion styles
