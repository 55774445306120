import { FieldEmpty, IconProduct, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import FieldLoading from "../../../../components/FieldLoading";
import { getController } from "../../../../stores/controller/ControllerFactory";
import { DocumentationController } from "../../DocumentationController";
import ArticleItem from "../components/ArticleItem";
import { DocumentController } from "../DocumentController";

const ColArticles: FunctionComponent = () => {
    const { controller: documentController } = getController(DocumentController);
    const { controller } = getController(DocumentationController);
    const [amountDocumented, setAmountDocumented] = useState(0);
    const [amountTotal, setAmountTotal] = useState(0);

    useEffect(() => {
        if (controller.documentation) {
            setAmountDocumented(controller.documentation.amountDocumented || 0);
            setAmountTotal(
                controller.articles.map((article) => article.documentationItems.length).reduce((p, c) => p + c, 0)
            );
        }
    }, [controller.documentation, controller.articles.length]);

    return (
        <S.Container>
            <S.Header className="hide-in-accordion">
                <h2>Dokumentieren</h2>
            </S.Header>
            <S.SubHeader className="hide-in-accordion">Produkte</S.SubHeader>

            <S.DocumentedProducts>
                {amountDocumented || 0} von {amountTotal || 0} Artikel dokumentiert
            </S.DocumentedProducts>
            {/*  List of Articles or placeholder */}
            {controller.waitingFor.loadArticles || !controller.articles ? (
                <FieldLoading text="Produkte werden geladen..." />
            ) : controller.articles.length === 0 ? (
                <FieldEmpty icon={<IconProduct height={48} width={48} />} text="Kein Produkt vorhanden" />
            ) : (
                controller.articles.map((article) => (
                    <ArticleItem
                        //adding length of article.documentationItems to key to trigger rerender if length changes
                        key={article.id + "_" + article.documentationItems.length}
                        article={article}
                        isSelected={documentController.currentArticle === article.id}
                        onClick={() => {
                            documentController.setCurrentDocumentationItemIds([
                                documentController.documentationItemsByArticle[0]?.id,
                            ]);
                            documentController.setCurrentArticle(article.id);
                        }}
                        firstItem={article.id === controller.articles?.[0].id}
                    />
                ))
            )}
        </S.Container>
    );
};

export default observer(ColArticles);

//#region styles
const S = {
    Container: styled.div`
        padding: 0 ${unitize(30)};
        .accordion & {
            padding: 0;
        }
    `,
    Header: styled.div`
        /* has to be same height as the other cols, so Content is aligned at same height */
        height: ${unitize(136)};
        padding-top: ${unitize(44)};
    `,
    SubHeader: styled.h3`
        margin-bottom: ${unitize(30)};
    `,
    DocumentedProducts: styled.p`
        margin-bottom: ${unitize(15)};
    `,
};
//#endregion styles
