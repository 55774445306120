import { unitize } from "@abs-safety/lock-book-web-ui";
import { autorun, runInAction } from "mobx";
import { observer } from "mobx-react";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import FieldLoading from "../../../../components/FieldLoading";
import { config } from "../../../../config/config";
import { IMultipleQuestionAnswer } from "../../../../entities/DocumentationItemAnswer";
import { IDocumentationItemQuestionWithAnswer } from "../../../../entities/DocumentationItemQuestion";
import { dataLayerService } from "../../../../services/DataLayerService";
import { documentationItemQuestionService } from "../../../../services/DocumentationItemQuestionService";
import { getController } from "../../../../stores/controller/ControllerFactory";
import { isDefined } from "../../../../utils/isDefined";
import { isNullish } from "../../../../utils/isNullish";
import { useDebounce } from "../../../../utils/useDebounce";
import { DocumentationController } from "../../DocumentationController";
import { DocumentController } from "../DocumentController";
import { isLockReasonRowVisible } from "./components/isLockReasonRowVisible";
import LockReasonRow from "./components/LockReasonRow";
import QuestionRow from "./components/QuestionRow";

/**
 * MergedQuestion is like the Question type which comes from API, but with additional values
 */
interface MergedQuestion extends IDocumentationItemQuestionWithAnswer {
    /** to know which Questions are merged (MergedQuestion to Questions) */
    questionIds: number[];

    /**
     * form value.
     * Initially from this.answer. But we store value (temp form value)
     * separatly from real answer (which is stored at DB) / (this is important
     * as all the answers of one MergedQuestion can be different - in which case we save an empty value with
     * an '???' placeholder).
     * Empty values (all null and undefined answers) will be converted to empty strings. */
    value: string;

    /**
     * If Merged Question consists of multiple Questions (multiple Docu Items selected)
     * with different answers */
    hasDifferentValues?: boolean;

    error?: boolean;
    errorMessage?: string;
}

/**
 * Component of all visible Questions
 */
const Questions: FunctionComponent = () => {
    const { controller: documentController } = getController(DocumentController);
    const { controller } = getController(DocumentationController);
    const documentationCompleted = controller.documentation?.isCompleted;
    const [mergedQuestions, setMergedQuestions] = useState<MergedQuestion[]>([]);

    /* stack of answers, prepared to submit */
    const [preparedAnswers, setPreparedAnswers] = useState<(IMultipleQuestionAnswer | undefined)[]>([]);

    const lockReasonVisible = isLockReasonRowVisible(documentController.documentationItems);

    useEffect(() => {
        // ... update mergedQuestions
        return autorun(() => {
            setMergedQuestions(getMergedQuestions());
        });
    }, []);

    useEffect(() => {
        documentController.setDocumentationItemQuestionsWithErrors([]);
    }, [documentController.currentArticle, documentController]);

    const prepareToSubmit = (questionIds: number[], value: string, questionRowIndex: number) => {
        const newAnswer: IMultipleQuestionAnswer = {
            answer: value,
            questionIds,
        };
        const tempState = [...preparedAnswers];
        tempState[questionRowIndex] = newAnswer;

        setPreparedAnswers(tempState);
        submit(tempState);
    };

    const submit = useDebounce((preparedAnswers: (IMultipleQuestionAnswer | undefined)[]) => {
        const answersToSubmit = preparedAnswers.filter(isDefined);

        // save to API (async, but we won't wait for response)
        documentController.answerQuestions(answersToSubmit, true);
        setPreparedAnswers([]);

        runInAction(() => {
            for (const mergedQuestion of answersToSubmit) {
                for (const questionId of mergedQuestion.questionIds) {
                    // 1. update docuItemQuestions (save answer)
                    const questions = documentationItemQuestionService.list
                        .filter((q) => q.id === questionId)
                        .map((q) => ({ ...q, answer: mergedQuestion.answer }));
                    documentationItemQuestionService.mergeList(questions);

                    // 2. update documentationItemQuestionsWithErrors: remove errors
                    documentController.setDocumentationItemQuestionsWithErrors(
                        documentController.documentationItemQuestionsWithErrors.filter(
                            (docuItemId) => docuItemId !== questionId
                        )
                    );

                    for (const question of questions) {
                        dataLayerService.emitEvent("submit", {
                            category: "document",
                            action: "answer_questions",
                            label: question.question,
                            payload: question.answer,
                        });
                    }
                }
            }
        });
    }, config.DEBOUNCE_WAIT_DOCUMENT_QUESTIONS);

    const onInputValueChange = (questionIds: number[], value: string, index: number) => {
        // make a clone
        const tempQuestions = [...mergedQuestions];

        // update value
        tempQuestions[index].value = value;

        // even if mergedQuestion had different values before, now (after onInput) they are the same
        tempQuestions[index].hasDifferentValues = false;

        // validate
        const { error, errorMessage } = validateQuestion(tempQuestions[index]);

        if (error) {
            documentController.setDocumentationItemQuestionsWithErrors(
                documentController.documentationItemQuestionsWithErrors.concat(questionIds)
            );
        } else {
            documentController.setDocumentationItemQuestionsWithErrors(
                documentController.documentationItemQuestionsWithErrors.filter(
                    (questionId) => questionIds.indexOf(questionId) < 0
                )
            );
        }

        // set error
        tempQuestions[index].error = error;
        tempQuestions[index].errorMessage = errorMessage;

        // update mergedQuestions
        setMergedQuestions(tempQuestions);
        prepareToSubmit(questionIds, value, index);
    };

    const onAnswerFocus = useCallback(
        (index: number) => {
            // validate
            const { error, errorMessage } = validateQuestion(mergedQuestions[index]);

            if (error && isDefined(errorMessage)) {
                // make a clone
                const tempQuestions = [...mergedQuestions];

                // set error
                tempQuestions[index].error = error;
                tempQuestions[index].errorMessage = errorMessage;

                // update mergedQuestions
                setMergedQuestions(tempQuestions);
            }
        },
        [mergedQuestions]
    );

    const isMandatoryQuestionNotAnswered = useCallback(
        (questionsWithErrors: Set<IDocumentationItemQuestionWithAnswer>, mergedQuestion: MergedQuestion) => {
            return (
                [...questionsWithErrors].filter((q) => q.id === mergedQuestion.id).length > 0 &&
                mergedQuestion.value === "" &&
                mergedQuestion.isMandatory
            );
        },
        []
    );

    return (
        <S.Container>
            {isNullish(documentController.currentArticle) ||
            documentController.waitingFor.deleteDocumentationItem !== false ? (
                <FieldLoading text="Fragenkatalog wird geladen..." />
            ) : documentController.currentDocumentationItemIds.length === 0 ? (
                <FieldLoading text="Keine Artikel ausgewählt" />
            ) : (
                <S.QuestionListWrapper>
                    {lockReasonVisible && <LockReasonRow />}
                    {mergedQuestions.map((item, index) => (
                        <QuestionRow
                            key={index}
                            question={item}
                            value={/^ *$/.test(item.value) ? "" : item.value}
                            hasDifferentValues={item.hasDifferentValues}
                            error={
                                isMandatoryQuestionNotAnswered(documentController.errorDocuItemsWithQuestion, item) ||
                                item.error
                            }
                            errorMessage={validateQuestion(item).errorMessage ?? item.errorMessage}
                            onFocus={() => onAnswerFocus(index)}
                            onChange={(value) => onInputValueChange(item.questionIds, value, index)}
                            disabled={documentationCompleted}
                        />
                    ))}
                </S.QuestionListWrapper>
            )}
        </S.Container>
    );
};

export default observer(Questions);

function validateQuestion(mergedQuestion: MergedQuestion): { error: boolean; errorMessage?: string } {
    const whiteSpaceRegEx = /^ *$/;
    // required
    if (
        (mergedQuestion.isMandatory && mergedQuestion.value === "") ||
        (mergedQuestion.isMandatory && whiteSpaceRegEx.test(mergedQuestion.value))
    ) {
        return {
            error: true,
            errorMessage: "Pflichtangabe",
        };
    }
    // max
    else if (
        isDefined(mergedQuestion.max) &&
        mergedQuestion.max > 0 &&
        parseInt(mergedQuestion.value) > mergedQuestion.max
    ) {
        return {
            error: true,
            errorMessage: "Angabe überhalb der maximalen Grenze",
        };
    }
    // min
    else if (
        isDefined(mergedQuestion.min) &&
        mergedQuestion.min > 0 &&
        parseInt(mergedQuestion.value) < mergedQuestion.min
    ) {
        return {
            error: true,
            errorMessage: "Angabe unterhalb der minimalen Grenze",
        };
    }
    return {
        error: false,
    };
}

/**
 * reads from documentController: `documentationItemQuestions` and `selectedDocumentationItemIds`
 * and returns MergedQuestions (depending on selected DocuItems)
 */
function getMergedQuestions(): MergedQuestion[] {
    const { controller: documentController } = getController(DocumentController);
    const onlyQuestionsOfSelectedItems = documentController.documentationItemQuestions.filter(
        (question) => documentController.currentDocumentationItemIds.indexOf(question.documentationItemId) !== -1
    );

    // use map to group same questions together
    // (should only happen, when multiple DocumentationItems are selected)
    // question.question (string) is the key, as we don't have any other key like id across
    // Questions to identify same question.
    const questionMap = new Map<string, MergedQuestion>();

    // iterate all questions (of selected docu items)
    onlyQuestionsOfSelectedItems.forEach((item) => {
        // did we save already a same question? ...
        const question = questionMap.get(item.question ?? "");
        if (isDefined(question)) {
            // ... yes -> merge questions
            if (
                question.value !==
                // sadly item.answer doesn't come always as string from API - that's why `toString()`
                (item.answer?.toString() ?? "")
            ) {
                // answers are different -> remove form value and update placeholder (defaultAnswer)
                question.value = "";
                question.hasDifferentValues = true;
            }
            // add this question.id to merged Question
            question.questionIds.push(item.id);
        } else {
            // The value of item.answer can be zero!
            const answer = item.answer !== null && item.answer !== undefined ? item.answer.toString() : "";
            // ... no -> save question
            questionMap.set(item.question ?? "", {
                ...item,
                // add this question.id to merged Question

                // if question is unanswered (null), the defaultAnswer should be used as the value
                // if user ever edited the answer (can also be ''), use the answer as the value (placeholder is visible if value = '')
                value: answer,
                questionIds: [item.id],
            });
        }
    });
    const arrayOfQuestionMap = Array.from(questionMap.values());
    return arrayOfQuestionMap.sort((a, b) => a.position - b.position);
}

//#region styles
const S = {
    Container: styled.div`
        width: 100%;
    `,
    QuestionListWrapper: styled.div`
        box-sizing: border-box;
        width: 100%;
    `,

    Placeholder: styled.p`
        padding: 0 ${unitize(15)};
    `,
};
//#endregion styles
