import { designTheme, unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent, useCallback, useMemo } from "react";
import styled from "styled-components";
import { ArticleType } from "../../../entities/Article";
import { IInstalledArticleRead, InstalledArticlesFormValues } from "../../../entities/InstalledArticle";
import {
    isInstalledArticleFormFieldVisible,
    ViewOfInstalledArticleForm,
} from "../../../utils/isInstalledArticleFormFieldVisible";
import { isNullish } from "../../../utils/isNullish";
import FormGroup from "../../FormGroup";
import FormGtmIntegration from "../../FormGtmIntegration";
import FormInput from "../../FormInput";

// type FormFieldsInstalledArticleProps = FormikProps<InstalledArticlesFormValues> & {
type FormFieldsInstalledArticleProps = {
    articleType: ArticleType;
    view: ViewOfInstalledArticleForm;
    /** form  can represents multiple entites */
    amountInstalledArticles: number;
    values: InstalledArticlesFormValues;
    disabled?: boolean;
};

const FormFieldsInstalledArticle: FunctionComponent<FormFieldsInstalledArticleProps> = (
    props: FormFieldsInstalledArticleProps
) => {
    const isVisible = useCallback(
        (field: keyof InstalledArticlesFormValues) => {
            return isInstalledArticleFormFieldVisible(field, props.view, props.articleType);
        },
        [props.view, props.articleType]
    );

    return (
        <>
            {isVisible("absSerialNumber") && props.amountInstalledArticles === 1 && (
                <FormInput
                    type="text"
                    placeholder="z. B. 1234567-0001"
                    name="absSerialNumber"
                    label="ABS Produktseriennummer"
                    disabled={props.disabled}
                />
            )}
            <FormGroup child1Flex="1" child2Flex="0 0 120px">
                <FormInput
                    type="text"
                    placeholder="z. B. „Etage“, „Plannummer“, etc ... "
                    name="identificationNumber"
                    label="Kennung"
                    disabled={props.disabled}
                />
                <FormInput
                    type="text"
                    placeholder="z. B. 001"
                    name="identificationCounter"
                    label="Zähler"
                    disabled={props.disabled}
                />
            </FormGroup>
            {props.amountInstalledArticles > 1 && props.values.identificationNumber && (
                <IdentificationNumberNamingPreview {...props} />
            )}
            {isVisible("serialNumber") && props.amountInstalledArticles === 1 && (
                <FormInput
                    type="text"
                    placeholder="z. B. 123456789-1234"
                    name="serialNumber"
                    label="Produktseriennummer"
                    disabled={props.disabled}
                />
            )}
            {props.amountInstalledArticles === 1 && (
                <FormInput
                    type="text"
                    placeholder="z. B. SXX-ABC-XYZ"
                    name="identificationPlateNumber"
                    label="ABS DMC-Label"
                    disabled={props.disabled}
                />
            )}
            <FormInput
                type="textarea"
                placeholder="Text eingeben"
                name="comment"
                label="Bemerkung"
                disabled={props.disabled}
            />

            <FormGtmIntegration action={"edit_docuitem"} />
        </>
    );
};

export default FormFieldsInstalledArticle;

const IdentificationNumberNamingPreview = (props: FormFieldsInstalledArticleProps) => {
    const previewNames = useMemo(
        () => getPreviewNames(props.amountInstalledArticles, props.values),
        [props.amountInstalledArticles, props.values]
    );
    return (
        <S_NamingPreview>
            {props.amountInstalledArticles === 1 && <h5>1 Produkt wird automatisch wie folgt benannt:</h5>}
            {props.amountInstalledArticles > 1 && (
                <h5>{props.amountInstalledArticles} Produkte werden automatisch wie folgt benannt:</h5>
            )}
            <ul>
                {previewNames.map((name) => (
                    <li key={name}>{name}</li>
                ))}
            </ul>
        </S_NamingPreview>
    );
};

/*
- identificationCounter: '004'
- identificationNumber: 'myName'
- amount: 8
- result: ['004', '005', '006', '…', '011'] // we display max. 5 lines
*/
function getPreviewNames(
    amountInstalledArticles: number,
    installedArticle: Pick<IInstalledArticleRead, "identificationCounter" | "identificationNumber">
): string[] {
    const name = installedArticle.identificationNumber;
    if (isNullish(name) || isNullish(amountInstalledArticles)) {
        return [];
    }
    const firstNumber =
        installedArticle.identificationCounter !== null &&
        installedArticle.identificationCounter !== undefined &&
        !Number.isNaN(parseInt(installedArticle.identificationCounter, 10))
            ? parseInt(installedArticle.identificationCounter)
            : 1;
    const leadingZeros = installedArticle.identificationCounter
        ? installedArticle.identificationCounter.length - firstNumber.toString().length
        : 0;
    const paddingZeros = "0".repeat(leadingZeros);
    if (amountInstalledArticles <= 5) {
        return [...Array(amountInstalledArticles).keys()].map((i) => `${name}${paddingZeros}${firstNumber + i}`);
    } else {
        return [
            ...[...Array(3).keys()].map((i) => `${name}${paddingZeros}${firstNumber + i}`),
            "…",
            `${name}${paddingZeros}${firstNumber + amountInstalledArticles - 1}`,
        ];
    }
}

//#region styles
const S_NamingPreview = styled.div`
    padding: ${unitize(20)};
    background-color: ${designTheme.color.lightestgrey};
    margin-bottom: ${unitize(30)};
    ul {
        margin-top: ${unitize(10)};
        margin-bottom: 0;
        padding-left: 0;
        list-style-position: inside;
    }
`;
//#endregion styles
