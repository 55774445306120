import { Button, ButtonClose, Input, Modal, ModalFooter, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";

interface ModalLockReasonProps {
    onCloseClick: () => void;
    onSubmit: (reason: string) => void;
}

const ModalLockReason: FunctionComponent<ModalLockReasonProps> = (props: ModalLockReasonProps) => {
    const [reason, setReason] = useState("");

    const onSubmit = () => props.onSubmit(reason);

    return (
        <S.ModalLockReason>
            <Modal onCloseClick={props.onCloseClick} isOpen={true} size="sm">
                <S.ButtonClose>
                    <ButtonClose onClick={props.onCloseClick} />
                </S.ButtonClose>
                <S.Headline>
                    <S.HeadlineText>Sperrgrund angeben</S.HeadlineText>
                </S.Headline>
                <S.Description>
                    Hier kannst Du den Sperrgrund eintragen. Beim Verwerfen gehen die Änderungen verloren.
                </S.Description>
                <S.InputWrapper>
                    <Input
                        onChange={(e) => setReason(e.target.value)}
                        label="Sperrgrund"
                        name="lockReason"
                        type="text"
                        placeholder="Sperrgrund angeben"
                    />
                </S.InputWrapper>
                <ModalFooter>
                    <Button disabled={reason === ""}>
                        <button type="submit" onClick={onSubmit}>
                            Speichern
                        </button>
                    </Button>
                    <Button disabled={reason !== ""} variant="text" color="primary">
                        <button type="button" onClick={props.onCloseClick}>
                            Später hinzufügen
                        </button>
                    </Button>
                </ModalFooter>
            </Modal>
        </S.ModalLockReason>
    );
};

export default observer(ModalLockReason);

//#region styles
const S = {
    ModalLockReason: styled.div`
        position: absolute;
        display: flex;
        width: 100%;
        min-width: ${unitize(500)};
        margin: ${unitize(24)};
        color: #262626;
    `,
    ButtonClose: styled.div`
        position: absolute;
        right: ${30 / 16}rem;
        top: ${30 / 16}rem;
    `,
    Headline: styled.div`
        display: flex;
        position: relative;
        /* align-items: center; */
        justify-content: flex-start;
        width: 100%;
        width: ${unitize(500)};
        padding-bottom: ${unitize(24)};
    `,
    HeadlineText: styled.h2`
        display: inline;
        margin-right: ${unitize(15)};
        font-size: ${unitize(32)};
    `,
    Description: styled.p`
        text-align: left;
        font-size: ${unitize(24)};
        margin-bottom: ${unitize(20)};
    `,
    InputWrapper: styled.div`
        margin-bottom: ${unitize(24)};
        text-align: left;
        & p {
            font-size: ${unitize(14)};
        }
        &:focus {
            outline: none;
        }
    `,
    ButtonGroup: styled.div`
        display: flex;
        justify-content: flex-end;
        margin-top: auto; // set to bottom of container
    `,
};
//#endregion styles
