import React, { FunctionComponent } from "react";
import ErrorPageTemplate from "./ErrorPageTemplate";

const Error404: FunctionComponent = () => {
    return (
        <ErrorPageTemplate
            headline="Oops, du bist leider nicht angemeldet."
            statusCode={401}
            text="Vielleicht möchtest Du zurück zu Lock Book. Wenn etwas kaputt ist, kannst Du auch ein Problem melden."
            secondBtn={{
                href: "http://support.lock-book.com/",
                text: "Problem melden",
                target: "_blank",
            }}
        />
    );
};

export default Error404;
