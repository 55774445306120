import { RepoStore } from "../stores/RepoStore";
import { ArticleType } from "./Article";
import { ICompanyRead } from "./Company";
import { DocumentationType } from "./Documentation";
import { IUserRead } from "./User";

export const allQuestionTypes = ["text", "yesno", "number", "list"] as const;
export type QuestionType = (typeof allQuestionTypes)[number];

class ArticleQuestionRepo extends RepoStore<IArticleQuestionRead> {}
export const articleQuestionRepo = new ArticleQuestionRepo();

export type QuestionId = IArticleQuestionBase["id"];

export interface IArticleQuestionBase {
    id: number;
    question: string;
    description?: string;
    documentationType?: DocumentationType;
    articleType: ArticleType;
    questionType: QuestionType;
    defaultAnswer?: string;

    // part of DB Table article_to_question and not article_question, but API provides this here as well

    isMandatory?: boolean;
    position: number;

    // depending on questionType

    items?: string[] | null;
    min?: number | null;
    max?: number | null;

    // Relationships

    customer?: ICompanyRead;
    customerId?: number;
    user?: IUserRead;
    userId?: number;
    articleToQuestions?: unknown;
}

export type IArticleQuestionRead = Pick<
    IArticleQuestionBase,
    | "id"
    | "isMandatory"
    | "question"
    | "description"
    | "documentationType"
    | "questionType"
    | "defaultAnswer"
    | "items"
    | "min"
    | "max"
    | "position"
>;

export type IArticleQuestionWrite = Pick<
    IArticleQuestionBase,
    | "question"
    | "isMandatory"
    | "description"
    | "documentationType"
    | "questionType"
    | "defaultAnswer"
    | "items"
    | "min"
    | "max"
    | "position"
> & {
    /** optional because of POST doesn't have id, but PUT has sometimes */
    id?: number;
};
