import { Button, GridColumns, Modal, ModalContent, ModalFooter, ModalHeader } from "@abs-safety/lock-book-web-ui";
import { Form, FormikProps } from "formik";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect } from "react";
import FormInput from "../../../components/FormInput";
import FormSelectCountry from "../../../components/FormSelectCountry";
import { session } from "../../../session/Session";
import { FormWrapperStyled } from "./Create";
import { FormValues } from "./Step1";

interface Step1FormProps extends FormikProps<FormValues> {
    onCloseClick: () => void;
}

const sessionLanguage = session.locale.toUpperCase();

const Step1Form: FunctionComponent<Step1FormProps> = (props: Step1FormProps) => {
    useEffect(() => {
        props.validateForm();
    }, []);

    return (
        <Form autoComplete={"off"}>
            <Modal size="lg" isOpen onCloseClick={props.onCloseClick}>
                <ModalHeader title="Objekt anlegen" subtitle="Schritt 1/2" />
                <ModalContent>
                    <FormWrapperStyled>
                        <FormInput
                            label="Objektname"
                            placeholder="Name eingeben"
                            name="name"
                            type="text"
                            size="sm"
                            disableAutoComplete={true}
                        />

                        <FormInput
                            label="Objektnummer"
                            name="buildingNumber"
                            type="text"
                            size="sm"
                            disableAutoComplete={true}
                            isOptional={true}
                        />

                        <FormSelectCountry
                            name={"country"}
                            label="Land"
                            locale={sessionLanguage}
                            errorMessage={props.errors.country}
                        />

                        <FormInput
                            label="Straße und Hausnummer"
                            name="address1"
                            type="text"
                            size="sm"
                            disableAutoComplete={true}
                        />

                        <FormInput
                            label="Adresszusatz"
                            name="address2"
                            type="text"
                            size="sm"
                            isOptional={true}
                            disableAutoComplete={true}
                        />

                        <GridColumns columnSizes={{ xs: [1], sm: [1, 1] }}>
                            <FormInput label="PLZ" name="postcode" type="text" size="sm" disableAutoComplete={true} />

                            <FormInput label="Ort" name="city" type="text" size="sm" disableAutoComplete={true} />
                        </GridColumns>
                    </FormWrapperStyled>
                </ModalContent>
                <ModalFooter>
                    <Button disabled={!props.isValid}>
                        <button type="submit" className={"uf-createBuildingNext"}>
                            Weiter
                        </button>
                    </Button>
                    <Button variant="text" color="black">
                        <button onClick={props.onCloseClick} type="button">
                            Abbrechen
                        </button>
                    </Button>
                </ModalFooter>
            </Modal>
        </Form>
    );
};

export default observer(Step1Form);
