import { media, ModalFooter, unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import FieldLoading from "../../FieldLoading";

interface Step1TemplateProps {
    articlesLoading: boolean;
    list: React.ReactNode;
    search: React.ReactNode;
    filter: React.ReactNode;
    footer: React.ReactNode;
}

const Step1Template: FunctionComponent<Step1TemplateProps> = (props: Step1TemplateProps) => {
    return (
        <>
            <S.SubView>
                <S.List className="uf-articleList">
                    {props.articlesLoading ? (
                        <FieldLoading text="Produkte werden geladen" height={unitize(300)} />
                    ) : (
                        props.list
                    )}
                </S.List>
                <S.SearchAndFilter>
                    <S.Search>{props.search}</S.Search>
                    <div className="d-xs-none d-md-block">{props.filter}</div>
                </S.SearchAndFilter>
            </S.SubView>
            <ModalFooter>{props.footer}</ModalFooter>
        </>
    );
};

export default Step1Template;

//#region styles
const S = {
    SubView: styled.div`
        display: flex;
        overflow: auto;
        flex-direction: column-reverse;
        ${media("md")} {
            flex-direction: row;
        }
    `,
    List: styled.div`
        flex: 1 1 auto;
        overflow-y: auto;
        margin-top: 0;
        margin-bottom: ${unitize(30)};
    `,
    SearchAndFilter: styled.div`
        ${media("md")} {
            flex: 0 0 ${unitize(230)};
            overflow-y: auto;
            margin-top: 0;
            margin-bottom: ${unitize(30)};
            padding-left: ${unitize(20)};
        }
    `,
    Search: styled.div`
        margin-bottom: ${unitize(30)};
    `,
};
//#endregion styles
