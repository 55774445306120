import { ApiResponse } from "../api/ApiService";
import { DeliveryNoteApi } from "../api/DeliveryNoteApi";
import { deliveryNoteRepo, IDeliveryNoteRead } from "../entities/DeliveryNote";
import { DataService } from "./abstract/DataService";

type WaitingForKey = "fetchDeliveryNote" | "reloadUrl";
class DeliveryNoteService extends DataService<WaitingForKey, IDeliveryNoteRead> {
    apiService = new DeliveryNoteApi();
    deliveryNoteApi = new DeliveryNoteApi();
    repo = deliveryNoteRepo;

    constructor() {
        super({
            fetchDeliveryNote: false,
            reloadUrl: false,
        });
    }

    fetchDeliveryNote(barcode?: string): Promise<ApiResponse<IDeliveryNoteRead[]>> {
        this.repo.list = [];
        return this.resolveAsAction({
            promise: () => this.deliveryNoteApi.getDeliveryNote(barcode),
            waitingForKey: ["fetchDeliveryNote"],
            action: (response) => {
                if (response.result) {
                    this.mergeList(response.result);
                }
                return response;
            },
        });
    }
}

export const deliveryNoteService = new DeliveryNoteService();
