import {
    Card,
    ColumnSizes,
    designTheme,
    GridColumns,
    IconBuilding,
    IconEdit,
    IconTrash,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { IBuildingRead } from "../../../entities/Building";
import { formatDate } from "../../../utils/formatDate";

interface CardBuildingProps {
    onCardClick: () => void;
    onDelete: () => void;
    onEdit: () => void;
    building: IBuildingRead;
    columnSizes: ColumnSizes;
    disabled?: boolean;
    assembly?: number | void;
    maintenance?: number | void;
}

//#region styles
const S = {
    Component: styled.div`
        cursor: pointer;
        min-height: ${unitize(48)};
        .card {
            display: flex;
            align-items: center;
        }
    `,
    Menu: styled.div`
        position: absolute;
        right: ${unitize(10)};
        top: ${unitize(12)};
    `,
    ColName: styled.div`
        display: flex;
        align-items: center;
    `,
    NameIcon: styled.span`
        margin-right: ${unitize(10)};
    `,
    OverflowEllipsis: styled.div`
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    `,
};
//#endregion styles

const CardBuilding: FunctionComponent<CardBuildingProps> = (props: CardBuildingProps) => {
    return (
        <S.Component>
            <Card
                className="card building-card uf-buildingCard"
                onClick={props.onCardClick}
                disabled={props.disabled}
                dropdownItems={[
                    {
                        icon: <IconEdit />,
                        text: "Bearbeiten",
                        onClick: props.onEdit,
                    },
                    {
                        icon: <IconTrash />,
                        text: "Löschen",
                        textColor: designTheme.color.error,
                        onClick: props.onDelete,
                    },
                ]}
                isMenuIconFullHeight={true}
            >
                <GridColumns
                    columnSizes={props.columnSizes}
                    // childrensDisplay="flex"
                    style={{ width: "100%", alignItems: "center" }}
                >
                    <S.OverflowEllipsis>
                        <S.ColName>
                            <S.NameIcon className="d-xs-none d-md-block">
                                <IconBuilding width={19.6} height={15} />
                            </S.NameIcon>
                            <S.OverflowEllipsis>{props.building.name}</S.OverflowEllipsis>
                        </S.ColName>
                    </S.OverflowEllipsis>

                    <S.OverflowEllipsis>{props.building.address1}</S.OverflowEllipsis>
                    <S.OverflowEllipsis>{props.building.postcode + " " + props.building.city}</S.OverflowEllipsis>
                    <div>{props.building.amountAssemblies}</div>
                    <div>{props.building.amountMaintenances}</div>
                    <div>{formatDate(props.building.createdAt)}</div>
                </GridColumns>
            </Card>
        </S.Component>
    );
};

export default observer(CardBuilding);
