import { IBuildingAttachmentRead } from "../entities/BuildingAttachment";
import {
    IDocumentationItemRead,
    IDocumentationItemUpdate,
    IDocumentationItemWrite,
} from "../entities/DocumentationItem";
import { ApiResponse, ApiService } from "./ApiService";

export class DocumentationItemApi extends ApiService<
    IDocumentationItemRead,
    IDocumentationItemWrite | IDocumentationItemUpdate
> {
    constructor() {
        super(`/documentation-items`);
    }

    getDocumentationItemAttachments(documentationItemId: number): Promise<ApiResponse<IBuildingAttachmentRead[]>> {
        return this.get(`/${documentationItemId}/attachments`);
    }
}
