import { action, computed, makeObservable, observable } from "mobx";
import { ApiResponse } from "../../../api/ApiService";
import { IArticleOverviewItemDocu } from "../../../api/DocumentationApi";
import { IBuildingAttachmentRead, IBuildingAttachmentUpdate } from "../../../entities/BuildingAttachment";
import {
    IDocumentationItemDocumentationOverview,
    IDocumentationItemRead,
    IDocumentationItemUpdate,
} from "../../../entities/DocumentationItem";
import { IMultipleQuestionAnswer } from "../../../entities/DocumentationItemAnswer";
import { IDocumentationItemQuestionWithAnswer } from "../../../entities/DocumentationItemQuestion";
import { IInstalledArticleRead, IInstalledArticleWrite } from "../../../entities/InstalledArticle";
import { articleService, getLastUpdate } from "../../../services/ArticleService";
import { attachmentService } from "../../../services/AttachmentService";
import { dataLayerService } from "../../../services/DataLayerService";
import { documentationItemAnswerService } from "../../../services/DocumentationItemAnswerService";
import { documentationItemQuestionService } from "../../../services/DocumentationItemQuestionService";
import { documentationItemService } from "../../../services/DocumentationItemService";
import { documentationService } from "../../../services/DocumentationService";
import { installedArticleService } from "../../../services/InstalledArticleService";
import { session } from "../../../session/Session";
import { SuperControllerStore } from "../../../stores/controller/SuperControllerStore";
import { isDefined } from "../../../utils/isDefined";
import { isNullish } from "../../../utils/isNullish";

const subStores = { documentationItemQuestionService };

export enum SubViewKey {
    ItemDetails = "document__tab__item-details",
    Questions = "document__tab__questions",
    Photos = "document__tab__photos",
}

export class DocumentController extends SuperControllerStore<
    | keyof typeof subStores
    | "updateDocumentationItems"
    | "answerQuestions"
    | "uploadFile"
    | "deleteFile"
    | "loadFiles"
    | "updateFile"
    | "deleteDocumentationItem"
    | "loadDocumentationItemQuestions"
    | "updateInstalledArticle"
> {
    static controllerName = "DocumentController";

    /** @see errorDocuItemsWithQuestion */
    documentationItemQuestionsWithErrors: IDocumentationItemQuestionWithAnswer["id"][] = [];
    subView: SubViewKey = SubViewKey.Questions;
    currentArticle?: number;
    currentDocumentationItemIds: number[] = [];

    get article(): IArticleOverviewItemDocu {
        return articleService.list.find((item) => item.id === this.currentArticle) as IArticleOverviewItemDocu;
    }

    get documentationItems(): IDocumentationItemRead[] {
        return documentationItemService.list.filter((item) => this.currentDocumentationItemIds.indexOf(item.id) >= 0);
    }

    get documentationItemQuestions(): IDocumentationItemQuestionWithAnswer[] {
        return documentationItemQuestionService.list.filter(
            (item) => this.currentDocumentationItemIds.indexOf(item.documentationItemId) >= 0
        );
    }

    /** all documentationItems of current article */
    get documentationItemsByArticle(): (IDocumentationItemRead & IDocumentationItemDocumentationOverview)[] {
        let docuItems: (IDocumentationItemRead & IDocumentationItemDocumentationOverview)[] = [];

        const articles = articleService
            .getCombinedListDocu(this.currentId)
            .filter((item) => item.id === this.currentArticle)
            .sort((a, b) => {
                return getLastUpdate(b.documentationItems) - getLastUpdate(a.documentationItems);
            });

        for (const article of articles) {
            docuItems = docuItems.concat(article.documentationItems);
        }

        return docuItems.map((item) => {
            return { ...item, ...documentationItemService.get(item.id) };
        });
    }

    /** a Map/Object to lookup per key visible DocumentationItem (key) how many attachments it has (value) */
    get attachmentAmountPerItem(): Record<number, number> {
        return Object.fromEntries(
            Object.values(this.documentationItemsByArticle).map((di) => [di.id, di.amountAttachments])
        );
    }

    get documentationItemAttachments(): IBuildingAttachmentRead[] {
        return attachmentService.list.filter(
            (attachment) => attachment.documentationItemId === this.currentDocumentationItemIds[0]
        );
    }

    /** all docuItems which were tried to submit but had validation errors to mark these docuItems (not questions) */
    get errorDocuItemsWithQuestion(): Set<IDocumentationItemQuestionWithAnswer> {
        return new Set(
            documentationItemQuestionService.list
                .filter((item) => this.documentationItemQuestionsWithErrors.indexOf(item.id) >= 0)
                .map((item) => {
                    return {
                        ...item,
                        answer: item.answer ?? "",
                    };
                })
        );
    }

    constructor() {
        super(session, {
            updateInstalledArticle: false,
            all: false,
            answerQuestions: false,
            deleteDocumentationItem: false,
            deleteFile: false,
            loadDocumentationItemQuestions: false,
            loadFiles: false,
            updateDocumentationItems: false,
            updateFile: false,
            uploadFile: false,
            documentationItemQuestionService: false,
        });

        makeObservable(this, {
            documentationItemQuestionsWithErrors: observable,
            subView: observable,
            attachmentAmountPerItem: computed,
            currentArticle: observable,
            currentDocumentationItemIds: observable,
            article: computed,
            documentationItems: computed,
            documentationItemQuestions: computed,
            documentationItemsByArticle: computed,
            documentationItemAttachments: computed,
            errorDocuItemsWithQuestion: computed,
            setDocumentationItemQuestionsWithErrors: action,
            setSubView: action,
            setCurrentArticle: action,
            setCurrentDocumentationItemIds: action,
            uploadFiles: action,
        });
    }

    init(documentationId: number): void {
        this.currentId = documentationId;
        this.updateDocumented(documentationId);
        this.loadDocumentationItemQuestions(documentationId);
        dataLayerService.emitHistory(location, dataLayerService.dataLayer, "document");

        this.register(() => {
            const page = documentationService.get(documentationId)?.type ?? "documentation";
            dataLayerService.emitHistory(location, dataLayerService.dataLayer, page);
        });
    }

    setDocumentationItemQuestionsWithErrors(ids: IDocumentationItemQuestionWithAnswer["id"][]): void {
        this.documentationItemQuestionsWithErrors = ids;
    }

    setSubView(value: SubViewKey): void {
        this.subView = value;
    }

    setCurrentArticle(articleID: number | undefined): void {
        const reset = this.currentArticle !== articleID;
        this.currentArticle = articleID;

        if (reset) {
            // whenever a new article gets selected, we select the first documentationItem
            this.currentDocumentationItemIds = [this.documentationItemsByArticle[0]?.id];
        }
    }

    setCurrentDocumentationItemIds(documentationItemIds: number[]): void {
        this.currentDocumentationItemIds = documentationItemIds;
    }

    async answerQuestions(answers: IMultipleQuestionAnswer[], reopenCompleted = false): Promise<void> {
        await this.resolveAsAction({
            promise: () => documentationItemAnswerService.answerQuestions(this.currentId, answers),
            waitingForKey: "answerQuestions",
            action: (result) => {
                if (!isNullish(result.response) && reopenCompleted) {
                    const itemIdsToChange = this.documentationItems.map((item) => item.id);
                    this.updateDocumentationItems(itemIdsToChange, {
                        questionsAnswered: false,
                    });
                }

                return result;
            },
        });
    }

    async uploadFiles(imageFiles: File[]): Promise<void> {
        if (this.currentDocumentationItemIds.length === 0) {
            throw Error("Cannot upload attachments, before selectedDocumentationItem is stored");
        }
        // attachments only shown when only one DocumentationItem selected / active
        const documentationItemId = this.currentDocumentationItemIds[0];

        await this.resolveAsAction({
            promise: () =>
                attachmentService.uploadMultipleByDocumentationItem(
                    documentationItemId,
                    imageFiles.map((file) => ({ imageFile: file }))
                ),
            waitingForKey: "uploadFile",
            setWaitingForValueTo: documentationItemId,
            action: () => {
                const docuItem = documentationItemService.get(documentationItemId);
                if (docuItem !== undefined) {
                    docuItem.amountAttachments += imageFiles.length;
                }
            },
        });
    }

    deleteFile(id: number): void {
        // attachments only shown when only one DocumentationItem selected / active
        const documentationItemId = this.currentDocumentationItemIds[0];

        this.resolveAsAction({
            promise: () => attachmentService.deleteDocuItemAttachment(id),
            waitingForKey: "deleteFile",
            setWaitingForValueTo: id,
            action: (res) => {
                const docuItem = documentationItemService.get(documentationItemId);
                if (docuItem !== undefined) {
                    --docuItem.amountAttachments;
                }
                return res;
            },
        });
    }

    updateFile(documentationItemAttachmentId: number, data: IBuildingAttachmentUpdate): void {
        this.resolveAsAction({
            promise: () => attachmentService.updateAttachment(documentationItemAttachmentId, data),
            waitingForKey: "updateFile",
        });
    }

    loadDocumentationItemQuestions(documentationId: number): void {
        this.usePageData(() => {
            return this.resolveAsAction({
                promise: () => documentationItemQuestionService.loadByDocumentation(documentationId),
                waitingForKey: ["loadDocumentationItemQuestions", "documentationItemQuestionService"],
            });
        });
    }

    loadDocumentationItemAttachments(): void {
        if (this.currentDocumentationItemIds.length < 1) {
            throw Error("Cannot load attachments, before selectedDocumentationItem is stored");
        }

        // attachments only shown when only one DocumentationItem selected / active
        const documentationItemId = this.currentDocumentationItemIds[0];

        if (this.documentationItemAttachments.length > 0) {
            return;
        }

        this.resolveAsAction({
            promise: () => attachmentService.fetchByDocumentationItem(documentationItemId),
            waitingForKey: "loadFiles",
        });
    }

    updateDocumentationItems(
        documentationItemIds: number[],
        data: IDocumentationItemUpdate
    ): Promise<ApiResponse<IDocumentationItemRead[]>> {
        return this.resolveAsAction({
            promise: () => documentationItemService.updateBatch(documentationItemIds, data),
            waitingForKey: "updateDocumentationItems",
            action: async (response) => {
                this.updateDocumented();
                documentationItemService.getMultiple(documentationItemIds).forEach((docuItem) => {
                    docuItem.lastUpdatedBy = this.session.currentUser;
                });
                return response;
            },
        });
    }

    async updateDocumented(id = this.currentId): Promise<void> {
        const itemsDocumented = documentationItemService.list.filter(
            (item) => item.documentationId === id && item.questionsAnswered === true
        );

        const docu = await documentationService.getOrFetch(id);

        if (isDefined(docu)) {
            documentationService.mergeList({ ...docu, amountDocumented: itemsDocumented.length });
        }
    }

    updateInstalledArticle(
        installedArticleId: number,
        data: Partial<IInstalledArticleWrite>
    ): Promise<ApiResponse<IInstalledArticleRead>> {
        return this.resolveAsAction({
            promise: () => installedArticleService.updateInstalledArticle(installedArticleId, data),
            waitingForKey: "updateInstalledArticle",
            action: (result) => {
                if (result.response?.ok === true && articleService.waitingFor.fetchList !== this.currentId) {
                    articleService.loadArticlesByDocu(this.currentId);
                }

                return result;
            },
        });
    }
}
