import { Button, CardDark, Checkbox, unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getLocalStorageValue, setLocalStorageValue } from "../../../utils/localStorage";

interface PopupDeleteArticleProps {
    onClose: () => void;
    onDelete: () => void;
    itemDeletedInProductManagement?: boolean;
}

const PopupDeleteArticle: FunctionComponent<PopupDeleteArticleProps> = (props: PopupDeleteArticleProps) => {
    const [showNeverAgain, setShowNeverAgain] = useState(false);
    const [shouldNeverBeShownAgain, setShouldNeverBeShownAgain] = useState(
        getLocalStorageValue("neverShowPopupWarnDeleteArticleInDocu") ?? false
    );

    const componentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (shouldNeverBeShownAgain) {
            props.onDelete();
            props.onClose();
        }
    }, []);

    // Click somewhere in window (besides in this component) => close Popup
    const windowClickListener = useCallback(() => {
        props.onClose();
    }, []);

    useEffect(() => {
        window.addEventListener("click", windowClickListener);
        return () => {
            window.removeEventListener("click", windowClickListener);
        };
    }, [windowClickListener]);

    const onDelete = useCallback(() => {
        props.onDelete();
        setLocalStorageValue("neverShowPopupWarnDeleteArticleInDocu", showNeverAgain);
        setShouldNeverBeShownAgain(showNeverAgain);
        props.onClose();
    }, [showNeverAgain]);

    const onCheckboxChange = useCallback(
        (checked: boolean) => {
            setShowNeverAgain(checked);
        },
        [showNeverAgain]
    );

    return shouldNeverBeShownAgain ? null : (
        // stopPropagation bc: if user clicks inside card, we do not want to close card, neither dropdown
        <S.Component ref={componentRef} onClick={(e) => e.stopPropagation()}>
            <CardDark>
                <h4>Produkt aus Dokumentation löschen?</h4>
                <p>
                    Soll das Produkt wirklich <strong>aus der Dokumentation</strong> gelöscht werden?
                </p>
                {props.itemDeletedInProductManagement !== true && (
                    <p>Das Produkt ist weiterhin in der Produktverwaltung verfügbar.</p>
                )}

                <p>
                    <Checkbox
                        white={true}
                        checked={showNeverAgain}
                        onChange={onCheckboxChange}
                        regularWeight={true}
                        text="Diesen Hinweis nicht mehr anzeigen"
                    />
                </p>
                <Button style={{ marginBottom: unitize(-15) }} variant="outline" color="white">
                    <button onClick={onDelete}>Löschen</button>
                </Button>
            </CardDark>
        </S.Component>
    );
    //#endregion render
};

export default PopupDeleteArticle;

//#region styles
const S = {
    Component: styled.div`
        position: absolute;
        top: ${unitize(120)};
        right: ${unitize(100)};
        z-index: 1;

        p {
            margin-bottom: ${unitize(30)};
        }
    `,
};
//#endregion styles
