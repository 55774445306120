import { unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { IBuildingCreate } from "../../../entities/Building";
import Step1 from "./Step1";
import Step2 from "./Step2";

interface CreateProps {
    setIsOpen: (isOpen: boolean) => void;
    /** what happens after User clicks on Create Button (additional) */
    onCreate: (data: IBuildingCreate) => void;
}

const Create: FunctionComponent<CreateProps> = (props: CreateProps) => {
    const [step1IsOpen, setStep1IsOpen] = useState(true);
    const [step2IsOpen, setStep2IsOpen] = useState(false);
    const [building, setBuilding] = useState<Partial<IBuildingCreate>>({});

    const onSubmit1 = (values: Partial<IBuildingCreate>) => {
        setStep1IsOpen(false);
        setStep2IsOpen(true);
        setBuilding((currentValues) => ({ ...currentValues, ...values }));
    };

    const onSubmit2 = (values: Partial<IBuildingCreate>) => {
        setStep1IsOpen(false);
        setStep2IsOpen(false);
        props.setIsOpen(false);
        props.onCreate({ ...building, ...values });
    };

    const onCloseClick = () => {
        props.setIsOpen(false);
    };

    const previousStep = (values: Partial<IBuildingCreate>) => {
        setStep1IsOpen(true);
        setStep2IsOpen(false);
        setBuilding((currentValues) => ({ ...currentValues, ...values }));
    };

    return (
        <>
            {step1IsOpen && <Step1 onSubmit={onSubmit1} formData={building} onCloseClick={onCloseClick} />}
            {step2IsOpen && (
                <Step2
                    onSubmit={onSubmit2}
                    formData={building}
                    onCloseClick={onCloseClick}
                    previousStep={previousStep}
                />
            )}
        </>
    );
};

export default observer(Create);

//#region styles
export const FormWrapperStyled = styled.div`
    width: ${unitize(690)};
    max-width: 100%;
`;
//#endregion styles
