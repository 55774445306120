import { designTheme, Dropdown, IconArrowRotation, unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { AnswerBaseProps } from "./QuestionRow";

type AnswerDropdownProps = AnswerBaseProps;

const AnswerDropdown: FunctionComponent<AnswerDropdownProps> = (props: AnswerDropdownProps) => {
    // toggle dropdown
    const [isOpen, setOpen] = useState(false);
    const onToggle = (isNowOpen: boolean) => {
        setOpen(isNowOpen);
    };

    return props.disabled === true ? (
        <S.ValueDisabled>{props.value}</S.ValueDisabled>
    ) : (
        <Dropdown
            items={(props.question.items ?? []).map((item) => {
                return {
                    text: item,
                    onClick: () => {
                        props.onChange(item);
                    },
                };
            })}
            dropdownAlign="right"
            width={270}
            onToggle={onToggle}
            toggleNode={
                <S.ValueWithIcon>
                    {props.hasDifferentValues === true ? (
                        <S.Placeholder>???</S.Placeholder>
                    ) : (
                        <span>{props.value !== "" ? props.value : <S.Placeholder>bitte auswählen</S.Placeholder>}</span>
                    )}
                    <IconArrowRotation rotate={isOpen} />
                </S.ValueWithIcon>
            }
        />
    );
};

export default AnswerDropdown;

//#region styles
const S = {
    ValueDisabled: styled.span`
        font-weight: ${designTheme.typography.weight.bold};
    `,
    ValueWithIcon: styled.span`
        display: flex;
        align-items: center;
        font-weight: ${designTheme.typography.weight.bold};
        gap: ${unitize(10)};
    `,
    Placeholder: styled.span`
        color: ${designTheme.color.darkgrey};
        font-weight: ${designTheme.typography.weight.regular};
    `,
};
//#endregion styles
