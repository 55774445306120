import { ArticleType } from "../entities/Article";

export const getArticleTypeName = (type: ArticleType): { plural: string; singular: string } => {
    const both = (value: string) => ({ plural: value, singular: value });
    switch (type) {
        case "abs":
            return both("ABS");
        case "climb":
            return both("Steigtechnik");
        case "custom":
            return { singular: "Eigenes Produkt", plural: "Eigene Produkte" };
        case "drainage":
            return both("Entwässerung");
        case "misc":
            return both("Gesamtdach");
        case "thirdparty":
            return both("Fremdprodukt");
        case "ventilation":
            return both("Lüftung");
        default:
            return both(type);
    }
};
