/**
 * based on lock-book-docu-api: api/src/Entity/BuildingAttachmentCategory.php
 */

import { RepoStore } from "../stores/RepoStore";
import { IBuildingAttachmentRead } from "./BuildingAttachment";

class AttachmentCategoryRepo extends RepoStore<IBuildingAttachmentCategoryRead> {}

export const attachmentCategoryRepo = new AttachmentCategoryRepo();

interface IBuildingAttachmentCategoryBase {
    id: number;
    category: string;
    attachments?: IBuildingAttachmentRead[];
}

export type IBuildingAttachmentCategoryRead = Pick<IBuildingAttachmentCategoryBase, "id" | "category">;

// not needed so far:
// export type IBuildingAttachmentCategoryWrite = Pick<
//   IBuildingAttachmentCategoryBase,
//   'category'
// >;
