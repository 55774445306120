export const hashCode = (
    input: number | string | Record<number | string, unknown> | unknown[],
    normalizeInput = false
): string => {
    if (typeof input === "number") {
        input = `${input}`;
    }
    if (typeof input === "object" || Array.isArray(input)) {
        input = Object.entries(input).flat().join("");
    }
    if (normalizeInput) {
        input = input.toLowerCase().replace(/[^A-Za-z0-9]/g, "");
    }

    return input
        .split("")
        .reduce((a, b) => {
            a = (a << 5) - a + b.charCodeAt(0);
            return a & a;
        }, 0)
        .toString(32);
};
