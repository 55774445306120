import { IArticleCategoryRead } from "../entities/ArticleCategory";
import { ArticlesVisibilityApiResponse } from "../entities/ArticleVisibility";
import { IBuildingAttachmentCategoryRead } from "../entities/BuildingAttachmentCategory";
import { ICompanyRead } from "../entities/Company";
import { IConstraintRead } from "../entities/Constraint";
import { ICurrentUser } from "../entities/User";
import { ApiResponse, ApiService } from "./ApiService";
import { fetchTypes, HTTPMethod } from "./Connection";

export class MiscApi extends ApiService<IBuildingAttachmentCategoryRead, IBuildingAttachmentCategoryRead> {
    getBuildingAttachmentCategories(): Promise<ApiResponse<IBuildingAttachmentCategoryRead[]>> {
        return this.get<IBuildingAttachmentCategoryRead[]>("/building-attachment-categories");
    }

    getConstraints(locale: string): Promise<ApiResponse<IConstraintRead>> {
        return this.invoke<IConstraintRead>(fetchTypes.fetch, {
            method: HTTPMethod.GET,
            route: `/constraints`,
            params: { locale: locale },
        });
    }

    getUser(): Promise<ApiResponse<ICurrentUser>> {
        return this.get<ICurrentUser>(`/user`);
    }

    getArticleCategories(): Promise<ApiResponse<IArticleCategoryRead[]>> {
        return this.get<IArticleCategoryRead[]>(`/article-categories`);
    }

    getArticlesVisibility(): Promise<ApiResponse<ArticlesVisibilityApiResponse>> {
        return this.get<ArticlesVisibilityApiResponse>(`/articles-visibility`);
    }

    getAbsCompanies(): Promise<ApiResponse<ICompanyRead[]>> {
        return this.get<ICompanyRead[]>(`/admin/abs-companies`);
    }
}

export const miscApi = new MiscApi();
