import {
    Button,
    Card,
    ColumnSizes,
    designTheme,
    IconPlus,
    ListSortable,
    ListSortableRef,
    LockBookSignetFull,
    media,
    SearchBar,
    SideModal,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { autorun } from "mobx";
import { Observer, observer } from "mobx-react";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import FieldLoading from "../../components/FieldLoading";
import { config } from "../../config/config";
import { IBuildingCreate, IBuildingRead } from "../../entities/Building";
import { buildingService } from "../../services/BuildingService";
import { dataLayerService } from "../../services/DataLayerService";
import { session } from "../../session/Session";
import { getController } from "../../stores/controller/ControllerFactory";
import { defaultSort, Sort } from "../../stores/controller/SuperControllerStore";
import MainPage from "../../templates/MainPage";
import { isNotEmptyString } from "../../utils/isNotEmptyString";
import { layout } from "../../utils/layout";
import { useDebounce } from "../../utils/useDebounce";
import { useQuery } from "../../utils/useQuery";
import Edit from "../Building/subviews/Edit";
import ModalApplyObject from "../Building/subviews/ModalApplyObject";
import { BuildingListController } from "./BuildingListController";
import CardBuilding from "./components/CardBuilding";
import Create from "./subviews/Create";

const listColumnSizes: ColumnSizes = { xs: [7, 0, 0, 0, 0, 5], sm: [3, 3, 3, 0, 0, 3], lg: [3, 2, 2, 1, 1, 3] };

/**
 * Page: BuildingList
 */
const BuildingList: FunctionComponent = () => {
    const { controller, useInit } = getController(BuildingListController);
    const { url } = useRouteMatch();
    const history = useHistory();
    const listSortableRef = useRef<ListSortableRef | null>(null);
    const [createOpen, setCreateOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [activeSearch, setActiveSearch] = useState(false);
    const [sort, setSort] = useState<Sort | undefined>(defaultSort);
    const [modalApplyObjectOpen, setModalApplyObjectOpen] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [sideModalSelectedBuildingId, setSideModalSelectedBuildingId] = useState<number | undefined>(undefined);
    const query = useQuery();
    const transferKey = query.get("transferkey");

    useInit({ searchValue, sort, page: 1 });

    const onWindowScroll = useCallback(() => {
        if (controller.allBuildingsLoaded) {
            return;
        }

        const scrollHeight = document.body.scrollHeight;
        const innerHeight = window.innerHeight;
        const scrollY = window.scrollY;

        // how many pixels before actual bottom, the loadMoreBuildings should trigger?
        // (this should always be at least 1, cause some values might have a float value (Browser zoom) and isAtBottom could then never be true)
        const pixelsBeforeBottom = 60;

        const isAtBottom = innerHeight + scrollY + pixelsBeforeBottom >= scrollHeight;
        if (isAtBottom && !buildingService.waitingFor.fetchList && !controller.allBuildingsLoaded) {
            controller.load({ searchValue, sort });
        }
    }, [searchValue, sort, controller]);

    useEffect(() => {
        return autorun(() => {
            if (isNotEmptyString(transferKey) && (session.hasAbsAdminRights || session.hasCompanyAdminRights)) {
                setModalApplyObjectOpen(true);
            }
        });
    }, [transferKey]);

    useEffect(() => {
        window.addEventListener("scroll", onWindowScroll);
        return () => {
            window.removeEventListener("scroll", onWindowScroll);
        };
    }, [onWindowScroll]);

    const onSearch = (val: string) => {
        setSearchValue(val);
        delayedSearch(val);
    };

    const editObject = (activeId: number) => {
        setSideModalSelectedBuildingId(activeId);
        setModalEdit(true);
    };

    const delayedSearch = useDebounce((q: string) => {
        setActiveSearch(q !== "");
        controller.search(q, () =>
            dataLayerService.emitSearch({
                category: "buildings",
                payload: {
                    found: buildingService.list.length,
                },
                label: searchValue,
            })
        );
    }, 500);

    const onSortChange = (property: keyof IBuildingRead, order: "ASC" | "DESC" | undefined) => {
        const newSort = order !== undefined ? { property, order } : defaultSort;
        setSort(newSort);
        controller.init({ searchValue, sort: newSort, page: 1 });
    };

    /** unset any sorting, when another item is created at Top of list  */
    const onCreate = (data: IBuildingCreate) => {
        controller.create(data).then(() => {
            listSortableRef.current?.clearSorting();
            setSort(defaultSort);
            controller.init({ searchValue, sort: defaultSort, page: 1 });
        });
    };

    //#region render
    return (
        <MainPage
            title={
                controller.accountHasNoBuildings === true && !activeSearch && controller.waitingFor.load === false
                    ? ``
                    : `Objektübersicht`
            }
            pageType={"buildings"}
        >
            {controller.accountHasNoBuildings === true && !activeSearch && controller.waitingFor.load === false ? (
                <S.EmptyBuildingList>
                    <S.SignetWrapper>
                        <LockBookSignetFull />
                    </S.SignetWrapper>
                    <h1>Willkommen bei Lock Book</h1>
                    <h3>Um zu beginnen, lege Dein erstes Objekt an</h3>
                    <Button disabled={buildingService.waitingFor.all === true}>
                        <button
                            onClick={() => {
                                setCreateOpen(true);
                            }}
                            className={"uf-createButton"}
                        >
                            Objekt anlegen
                            <IconPlus color={designTheme.color.white} />
                        </button>
                    </Button>
                    {(session.hasAbsAdminRights === true || session.hasCompanyAdminRights === true) ?? (
                        <Button disabled={buildingService.waitingFor.all === true}>
                            <button
                                className="uf-takeOver"
                                onClick={() => {
                                    setModalApplyObjectOpen(true);
                                }}
                            >
                                Objekt übernehmen
                            </button>
                        </Button>
                    )}
                </S.EmptyBuildingList>
            ) : (
                <>
                    <S.SubHeader>
                        <Button disabled={buildingService.waitingFor.all === true}>
                            <button
                                onClick={() => {
                                    setCreateOpen(true);
                                }}
                                className={"uf-createButton"}
                            >
                                Objekt anlegen
                                <IconPlus color={designTheme.color.white} />
                            </button>
                        </Button>
                        {(session.hasAbsAdminRights || session.hasCompanyAdminRights) && (
                            <Button disabled={buildingService.waitingFor.all === true}>
                                <button
                                    className="uf-takeOver"
                                    onClick={() => {
                                        setModalApplyObjectOpen(true);
                                    }}
                                >
                                    Objekt übernehmen
                                </button>
                            </Button>
                        )}
                        <S.SearchBar>
                            <SearchBar
                                placeholder="Objekt suchen"
                                value={searchValue}
                                onChange={onSearch}
                                onSubmit={onSearch}
                            />
                        </S.SearchBar>
                    </S.SubHeader>
                    <>
                        <ListSortable
                            ref={listSortableRef}
                            headerStyle={{
                                padding: unitize(10),
                                position: "sticky", // NOT SUPPORTED: IE11
                                top: layout.header.height + subHeaderHeight,
                                zIndex: 10,
                                backgroundColor: designTheme.color.lightestgrey,

                                /* make background wider, to cover some overlapping shadows from content below while scrolling */
                                marginLeft: unitize(-10),
                                marginRight: unitize(-10),
                                paddingLeft: unitize(10),
                                paddingRight: unitize(10),
                            }}
                            listStyle={{ display: "grid", gridGap: unitize(5) }}
                            columnSizes={listColumnSizes}
                            items={buildingService.list}
                            columns={[
                                {
                                    title: "Name",
                                    asyncSortingHook: (order) => onSortChange("name", order),
                                },
                                {
                                    title: "Straße",
                                    asyncSortingHook: (order) => onSortChange("address1", order),
                                },
                                {
                                    title: "Ort",
                                    asyncSortingHook: (order) => onSortChange("postcode", order),
                                },
                                {
                                    title: "Montagen",
                                    asyncSortingHook: (order) => onSortChange("amountAssemblies", order),
                                },
                                {
                                    title: "Wartungen",
                                    asyncSortingHook: (order) => onSortChange("amountMaintenances", order),
                                },
                                {
                                    title: "Hinzugefügt am",
                                    asyncSortingHook: (order) => onSortChange("createdAt", order),
                                },
                            ]}
                        >
                            {(sortedList) => (
                                <Observer>
                                    {() => (
                                        <>
                                            {controller.waitingFor.create === true && (
                                                <FieldLoading height={unitize(48)} text="Gebäude wird erstellt ..." />
                                            )}
                                            {controller.waitingFor.load === true &&
                                            controller.waitingFor.delete === false ? (
                                                <FieldLoading text="Objekte werden geladen..." />
                                            ) : sortedList.length === 0 &&
                                              controller.waitingFor.load === false &&
                                              controller.waitingFor.delete === false ? (
                                                <S.NothingFound>Keine Objekte in Suche gefunden</S.NothingFound>
                                            ) : (
                                                sortedList.map((building) => (
                                                    <CardBuilding
                                                        key={building.id}
                                                        building={building}
                                                        assembly={building.amountAssemblies}
                                                        maintenance={building.amountMaintenances}
                                                        columnSizes={listColumnSizes}
                                                        onCardClick={() => history.push(`${url}/${building.id}`)}
                                                        onDelete={() => controller.delete(building, searchValue, sort)}
                                                        onEdit={() => editObject(building.id)}
                                                        disabled={controller.waitingFor.delete === true}
                                                    />
                                                ))
                                            )}
                                        </>
                                    )}
                                </Observer>
                            )}
                        </ListSortable>
                        {controller.waitingFor.loadMore !== false && (
                            <S.LoadMoreAnimation>
                                <Card disabled className="card">
                                    <div style={{ textAlign: "center" }}>...lädt </div>
                                </Card>
                            </S.LoadMoreAnimation>
                        )}

                        {buildingService.list.length < buildingService.total &&
                            controller.waitingFor.loadMore === false &&
                            controller.waitingFor.load === false && (
                                // IT'S JUST A FALLBACK.
                                // Not really visible, if Infinite Scrolling works properly.
                                <S.FallbackLoadMoreBtnWrapper>
                                    <Button variant="text" color="black">
                                        <button
                                            onClick={() => {
                                                controller.load({ searchValue, sort });
                                            }}
                                            type="button"
                                        >
                                            mehr laden
                                        </button>
                                    </Button>
                                </S.FallbackLoadMoreBtnWrapper>
                            )}
                        {controller.allBuildingsLoaded && (
                            <S.EndlessScrollingEnd>
                                <span>Alle Objekte geladen</span>
                            </S.EndlessScrollingEnd>
                        )}
                    </>
                </>
            )}
            {createOpen && <Create setIsOpen={setCreateOpen} onCreate={onCreate} />}
            {modalApplyObjectOpen && (
                <ModalApplyObject
                    isOpen={modalApplyObjectOpen}
                    onCloseClick={() => setModalApplyObjectOpen(false)}
                    transferKey={transferKey}
                />
            )}
            {modalEdit && sideModalSelectedBuildingId !== undefined && (
                <SideModal
                    className="uf-sideModal"
                    isOpen={modalEdit}
                    onCloseClick={() => setModalEdit(false)}
                    style={{
                        top: `${layout.header.height}px`,
                        height: `calc(100% - ${layout.header.height}px)`,
                    }}
                    views={[
                        {
                            component: <Edit onError={() => Error} selectedBuildingId={sideModalSelectedBuildingId} />,
                            key: "edit",
                            width: 750,
                        },
                    ]}
                />
            )}
        </MainPage>
    );

    //#endregion render
};

export default observer(BuildingList);

//#region styles

/** height of SubHeader (Create Btn & Search) */
const subHeaderHeight = 78;

const S = {
    SubHeader: styled.div`
        /* NOT SUPPORTED: IE11 */
        position: sticky;
        top: ${layout.header.height}px;
        z-index: 10;
        display: flex;
        align-items: center;
        height: ${subHeaderHeight}px;
        background-color: ${designTheme.color.lightestgrey};
        column-gap: ${unitize(10)};

        /* make background wider, to cover some overlapping shadows from content below while scrolling */
        margin-left: -${unitize(10)};
        margin-right: -${unitize(10)};
        padding-left: ${unitize(10)};
        padding-right: ${unitize(10)};

        &:not(:first-of-type) {
            margin-top: ${unitize(30)};
        }
        > h2 {
            margin-right: ${unitize(20)};
        }
    `,
    SearchBar: styled.div`
        flex: 0 1 ${unitize(330)};
        margin-left: auto;
    `,
    EmptyBuildingList: styled.div`
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        h1,
        h3,
        Button {
            margin: 0 auto;
        }
        h3 {
            color: ${designTheme.color.grey};
            font-weight: ${designTheme.typography.weight.regular};
        }
        Button {
            width: fit-content;
            margin-top: ${unitize(60)};
        }
    `,
    Headline: styled.h1`
        font-size: ${designTheme.typography.size.h2}rem;
        padding-bottom: 0px;
        ${media("sm")} {
            font-size: ${designTheme.typography.size.h1}rem;
        }
    `,
    SubHeadline: styled.h3`
        font-size: ${designTheme.typography.size.h4}rem;
        ${media("sm")} {
            font-size: ${designTheme.typography.size.h3}rem;
        }
    `,
    SignetWrapper: styled.div`
        margin: 0 auto;
        margin-bottom: ${unitize(40)};
    `,
    LoadMoreAnimation: styled.div`
        display: grid;
        margin-top: ${unitize(5)};
        grid-gap: ${unitize(5)};
        .card {
            opacity: 0.6;
        }
    `,
    FallbackLoadMoreBtnWrapper: styled.div`
        display: flex;
        justify-content: center;
        margin-top: ${unitize(10)};
    `,
    EndlessScrollingEnd: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: ${unitize(30)};
        span {
            margin-left: ${unitize(20)};
            margin-right: ${unitize(20)};
            font-size: ${designTheme.typography.size.h4}rem;
            font-weight: ${designTheme.typography.weight.bold};
        }
        &::before,
        &::after {
            display: inline-block;
            content: " ";
            flex: 1;
            height: 1px;
            background-color: ${designTheme.color.darkgrey};
        }
    `,
    NothingFound: styled.div`
        padding: ${unitize(100)} 0;
        text-align: center;
        border: 1px solid ${designTheme.color.grey};
        border-radius: ${designTheme.borderRadius};
        color: ${designTheme.color.darkgrey};
        font-size: ${designTheme.typography.size.h4}rem;
    `,
};
//#endregion styles
