/**
 * Some CSS classes in Utility-First concept.
 *
 * Inspired by [tailwindcss](https://tailwindcss.com/docs/utility-first).
 * Class names should be same or similar, in case we want to implement Tailwind later.
 */

import { createGlobalStyle } from "styled-components";

const GlobalStyleApp = createGlobalStyle`
    .overflow-hidden {
        overflow: hidden;
    }
    .overflow-ellipsis {
        text-overflow: ellipsis;
    }
    .truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    textarea {
        font-family: "Roboto", sans-serif;
    }
`;

export default GlobalStyleApp;
