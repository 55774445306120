import {
    ColumnSizes,
    FieldEmpty,
    IconArea,
    ListSortable,
    ListSortableRef,
    SearchBar,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import FieldLoading from "../../../components/FieldLoading";
import { DocumentationType } from "../../../entities/Documentation";
import { areaService } from "../../../services/AreaService";
import { getController } from "../../../stores/controller/ControllerFactory";
import { BuildingController } from "../BuildingController";
import CardBuildingDocumentation from "../components/CardBuildingDocumentation";

interface PdfDocumentationProps {
    type: DocumentationType;
}

const listColumnSizes: ColumnSizes = { xs: [5, 2, 5, 0, 0, 0], sm: [4, 2, 3, 3, 0, 0], md: [3, 2, 2, 2, 2, 1] };

const PdfDocumentations: FunctionComponent<PdfDocumentationProps> = () => {
    const { controller } = getController(BuildingController);
    const history = useHistory();
    const { url } = useRouteMatch();
    const listSortableRef = useRef<ListSortableRef | null>(null);
    const [searchValue, setSearchValue] = useState("");
    const [filteredData, setFilteredData] = useState(controller.documentations);

    useEffect(() => {
        controller.loadDocumentations();
    }, []);

    const handleSearch = (value: string): void => {
        setSearchValue(value);

        let result = [];
        result = controller.documentations.filter((document) => {
            const docName = document.name;
            const area = areaService.get(document.buildingAreaId ?? 0);
            const type = document.type === "assembly" ? "montage" : "wartung";
            const lowerValue = value.toLowerCase();
            if (docName === undefined || docName === null) {
                return false;
            }

            return (
                docName.toLowerCase().search(lowerValue) > -1 ||
                type.search(lowerValue) > -1 ||
                (area !== undefined && area.name.toLowerCase().search(lowerValue) > -1)
            );
        });

        setFilteredData(result);
    };

    return (
        <S.ViewDocumentations>
            {controller.waitingFor.loadDocumentations === true &&
            controller.waitingFor.loadDocumentationLinks === true ? (
                <FieldLoading text={"Dokumentationen werden geladen"} />
            ) : controller.documentations.length === 0 && controller.waitingFor.createDocumentation === false ? (
                <FieldEmpty icon={<IconArea height={48} width={48} />} text="Keine Dokumentation vorhanden" />
            ) : (
                <>
                    <S.SearchBar>
                        <SearchBar placeholder="Dokument suchen" value={searchValue} onChange={handleSearch} />
                    </S.SearchBar>
                    <ListSortable
                        ref={listSortableRef}
                        headerStyle={{ padding: unitize(10) }}
                        listStyle={{ display: "grid", gridGap: unitize(5) }}
                        columnSizes={listColumnSizes}
                        items={searchValue !== "" ? filteredData : controller.documentations}
                        columns={[
                            {
                                title: "Name",
                                sortProperty: "name",
                            },
                            { title: "Typ", sortProperty: "type" },
                            {
                                title: "Dachfläche",
                                sortProperty: "buildingAreaId",
                            },
                            {
                                title: "Erstellt",
                                sortProperty: "createdAt",
                            },
                            {
                                title: "PDF Link",
                            },
                            {
                                title: "Aktion",
                            },
                        ]}
                    >
                        {(sortedList) => (
                            <>
                                {sortedList.map((documentation) => (
                                    <CardBuildingDocumentation
                                        key={documentation.id}
                                        documentation={documentation}
                                        disabled={controller.waitingFor.deleteDocumentation === documentation.id}
                                        columnSizes={listColumnSizes}
                                        onEdit={() => history.push(`${url}/documentations/${documentation.id}/edit`)}
                                        onDelete={() => controller.deleteDocumentation(documentation.id)}
                                        areaName={areaService.getAreaByBuildingAreaId(documentation.buildingAreaId)}
                                    />
                                ))}
                            </>
                        )}
                    </ListSortable>
                </>
            )}
        </S.ViewDocumentations>
    );
};

export default observer(PdfDocumentations);

//#region styles
const S = {
    ViewDocumentations: styled.div``,
    CreateButton: styled.div`
        margin-bottom: ${unitize(30)};
    `,
    SearchBar: styled.div`
        flex: 1 0 ${unitize(330)};
        width: ${unitize(330)};
        margin-left: auto;
        margin-bottom: ${unitize(20)};
    `,
};
//#endregion styles
