import { Formik } from "formik";
import React, { FunctionComponent, useEffect, useState } from "react";
import { isNullish } from "../../utils/isNullish";
import FieldLoading from "../FieldLoading";
import ModalNewCertificateInner, { ModalNewCertificateInnerBaseProps } from "./ModalNewCertificateInner";

export type FormValues = { projectNumber: string };

type ModalNewCertificateProps = ModalNewCertificateInnerBaseProps &
    FormValues & {
        onSubmit: (values: FormValues) => void;
    };

const ModalNewCertificate: FunctionComponent<ModalNewCertificateProps> = (props: ModalNewCertificateProps) => {
    const [initialValues, setInitialValues] = useState<FormValues | undefined>();

    useEffect(() => {
        setInitialValues({
            projectNumber: props.projectNumber,
        });
    }, [props.documentationId]);

    if (isNullish(initialValues)) {
        return <FieldLoading />;
    }

    return (
        <Formik initialValues={initialValues} onSubmit={props.onSubmit}>
            {(formikBag) => <ModalNewCertificateInner {...props} {...formikBag} />}
        </Formik>
    );
};

export default ModalNewCertificate;
