import { QuestionType } from "../entities/ArticleQuestion";

export const getQuestionTypeName = (type?: QuestionType): string => {
    switch (type) {
        case "text":
            return "Text";
        case "number":
            return "Zahl";
        case "yesno":
            return "Ja / Nein";
        case "list":
            return "Liste";
        default:
            return type ?? "";
    }
};
