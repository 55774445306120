import { IBuildingAreaRead, IBuildingAreaUpdate } from "../entities/BuildingArea";
import { IBuildingAttachmentRead } from "../entities/BuildingAttachment";
import { DocumentationType, IDocumentationRead, IDocumentationWrite } from "../entities/Documentation";
import { IInstalledArticleInstall, IInstalledArticleRead } from "../entities/InstalledArticle";
import { ApiResponse, ApiService } from "./ApiService";
import { IArticleOverviewItem } from "./ArticleApi";

export type removeArticlesBody = { installedArticleIds: number[]; deletionReason: string };

export class AreaApi extends ApiService<IBuildingAreaRead, IBuildingAreaUpdate> {
    constructor() {
        super(`/building-areas`);
    }

    fetchDocumentations(buildingAreaId: number): Promise<ApiResponse<IDocumentationRead[]>> {
        return this.get<IDocumentationRead[]>(`/${buildingAreaId}/documentations`);
    }

    createDocumentation(buildingAreaId: number, type: DocumentationType): Promise<ApiResponse<IDocumentationRead>> {
        return this.post<IDocumentationRead, IDocumentationWrite>(`/${buildingAreaId}/documentations`, { type });
    }

    getArticleOverview(buildingAreaId: number): Promise<ApiResponse<IArticleOverviewItem[]>> {
        return this.get<IArticleOverviewItem[]>(`/${buildingAreaId}/article-overview`);
    }

    removeInstalledArticles(
        buildingAreaId: number,
        body: removeArticlesBody
    ): Promise<ApiResponse<IInstalledArticleRead[]>> {
        return this.post<IInstalledArticleRead[], removeArticlesBody>(
            `/${buildingAreaId}/remove-installed-articles`,
            body
        );
    }

    getFiles(buildingAreaId: number): Promise<ApiResponse<IBuildingAttachmentRead[]>> {
        return this.get<IBuildingAttachmentRead[]>(`/${buildingAreaId}/attachments`);
    }

    // TODO: Alex wants maybe to change the response tomorrow (today is 2020-07-20)
    /**
     * InstalledArticles are instances of Articles, which are assigned to a BuildingArea,
     * but not to a certain Documentation.
     */
    createInstalledArticles(
        buildingAreaId: number,
        body: IInstalledArticleInstall
    ): Promise<ApiResponse<{ [p: string]: IInstalledArticleRead }>> {
        return this.post<{ [key: string]: IInstalledArticleRead }, IInstalledArticleInstall>(
            `/${buildingAreaId}/articles`,
            body
        );
    }
}
