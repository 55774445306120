import { Button, ModalContent, ModalFooter, ModalHeader, unitize } from "@abs-safety/lock-book-web-ui";
import { Form, Formik, FormikProps } from "formik";
import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { getConstraints } from "../../../constraints/constraints";
import {
    IInstalledArticleInstall,
    initialInstalledArticlesFormValues,
    InstalledArticlesFormValues,
} from "../../../entities/InstalledArticle";
import { DocumentationController } from "../../../pages/Documentation/DocumentationController";
import { session } from "../../../session/Session";
import { getController } from "../../../stores/controller/ControllerFactory";
import { apiConstraintErrorsToFormErrors } from "../../../utils/apiConstraintErrorsToFormErrors";
import { ViewOfInstalledArticleForm } from "../../../utils/isInstalledArticleFormFieldVisible";
import { processInstalledArticleFormData } from "../../../utils/processInstalledArticleFormData";
import FieldLoading from "../../FieldLoading";
import { AddArticleController } from "../AddArticleController";
import FormFieldsInstalledArticle from "./FormFieldsInstalledArticle";

interface Step2ArticleChosenProps {
    onCloseClick: () => void;
}

type FormValues = Pick<
    IInstalledArticleInstall,
    "serialNumber" | "identificationNumber" | "identificationCounter" | "comment"
>;

/**
 * Step 2 in Modal when User chose in Step 1 an Article (not installed yet)
 * After Step 1 Tab "Produktkatalog" / "Fremdprodukte" / "Eigene Produkte"
 */
const Step2ArticleChosen: FunctionComponent<Step2ArticleChosenProps> = (props: Step2ArticleChosenProps) => {
    const { controller } = getController(DocumentationController);
    const { controller: modalController } = getController(AddArticleController);
    const [validationSchema, setValidationSchema] = useState(Yup.object().shape<Partial<FormValues>>({}));

    useEffect(() => {
        setValidationSchema(
            Yup.object().shape<Partial<FormValues>>({
                identificationNumber: getConstraints("InstalledArticle", "identificationNumber"),
                identificationCounter: getConstraints("InstalledArticle", "identificationCounter"),
                comment: getConstraints("InstalledArticle", "comment"),
            })
        );
    }, [session.constraints]);

    return (
        <>
            {isEmpty(session.constraints) ? (
                <FieldLoading height={unitize(400)} text="lädt..." />
            ) : (
                <Formik
                    initialValues={initialInstalledArticlesFormValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, helpers) => {
                        if (modalController.articleChosen.article === undefined) {
                            return;
                        }
                        if (modalController.isInPage === "documentation" && controller.currentId > 0) {
                            const cleanedValues = processInstalledArticleFormData(
                                values,
                                controller.documentation?.type === "assembly"
                                    ? "documentation/assembly"
                                    : "documentation/maintenance",
                                modalController.articleChosen.article.type
                            );
                            modalController
                                .addArticleToDocumentation(controller.currentId, {
                                    amount: modalController.articleChosen.amount,
                                    articleId: modalController.articleChosen.article.id,
                                    ...cleanedValues,
                                })
                                .then(() => {
                                    props.onCloseClick();
                                })
                                .catch((error) => apiConstraintErrorsToFormErrors(error, helpers.setErrors));
                        } else if (modalController.isInPage === "buildingArea") {
                            if ((modalController.buildingAreaId ?? 0) < 1) {
                                throw Error(
                                    "tried to createInstalledArticles(), but no modalController.buildingAreaId defined"
                                );
                            }
                            const cleanedValues = processInstalledArticleFormData(
                                values,
                                "buildingArea",
                                modalController.articleChosen.article.type
                            );
                            modalController
                                .createInstalledArticles(modalController.buildingAreaId ?? 0, {
                                    amount: modalController.articleChosen.amount,
                                    articleId: modalController.articleChosen.article.id,
                                    ...cleanedValues,
                                })
                                .then(() => {
                                    props.onCloseClick();
                                })
                                .catch((error) => apiConstraintErrorsToFormErrors(error, helpers.setErrors));
                        } else {
                            console.error("ModalStore has neither documentationId nor buildingAreaId saved");
                        }
                    }}
                >
                    {(formikBag) => <ObservedForm {...formikBag} />}
                </Formik>
            )}
        </>
    );
};

type FormComponentProps = FormikProps<InstalledArticlesFormValues>;

/**
 * Sub Component
 */
const FormComponent: FunctionComponent<FormComponentProps> = (props: FormComponentProps) => {
    const { controller: modalController } = getController(AddArticleController);
    const multipleArticlesChosen = modalController.articleChosen.amount > 1;
    const isInView: ViewOfInstalledArticleForm =
        modalController.isInPage === "buildingArea"
            ? "buildingArea"
            : modalController.documentationType === "assembly"
            ? "documentation/assembly"
            : "documentation/maintenance";
    const articleType = modalController.articleChosen.article?.type;

    return (
        <>
            <ModalHeader
                title={`${modalController.articleChosen.article?.name ?? ""} ${
                    modalController.articleChosen.amount > 0 ? `(${modalController.articleChosen.amount}x)` : ""
                } ${modalController.isInPage === "documentation" ? "hinzufügen" : "anlegen"}`}
                subtitle="Schritt 2/2"
            />
            <ModalContent>
                <>
                    <Subheadline>
                        <h3>Optionale Angaben</h3>
                        {multipleArticlesChosen && <p>Die Seriennummer kann beim Dokumentieren ergänzt werden</p>}
                    </Subheadline>
                </>
                <Form style={{ maxWidth: unitize(720) }}>
                    {articleType !== undefined && (
                        <FormFieldsInstalledArticle
                            articleType={articleType}
                            view={isInView}
                            amountInstalledArticles={modalController.articleChosen.amount}
                            values={props.values}
                        />
                    )}
                </Form>
            </ModalContent>
            <ModalFooter>
                <Button disabled={modalController.waitingFor.addArticles === true}>
                    <button className={"uf-addArticleAdd"} onClick={props.submitForm}>
                        {modalController.isInPage === "documentation" ? "Produkt hinzufügen" : "Produkt anlegen"}
                    </button>
                </Button>
                <Button variant="text" color="black">
                    <button onClick={() => modalController.backToStep1()}>Zurück</button>
                </Button>
            </ModalFooter>
        </>
    );
};

const ObservedForm = observer(FormComponent);

export default observer(Step2ArticleChosen);

//#region styles
const Subheadline = styled.div`
    margin-top: ${unitize(20)};
    margin-bottom: ${unitize(30)};
    h1 {
        margin-bottom: ${unitize(10)};
    }
`;
//#endregion styles
