import { makeObservable, observable, runInAction } from "mobx";
import { UnauthorizedError } from "../entities/ErrorResponse";
import { isBoolean } from "../utils/isBoolean";

/**
 * `waitingFor` properties are usually a boolean, but can be also:
 * - number: in case we want to know which (id) item we are waiting for
 * - number[]: in case we are waiting for multiple ids
 * @see WaitingForBag
 */
export type WaitingForValue = number | boolean | number[];

/**
 * Record of WaitingForValue
 * @see WaitingForValue
 */
export type WaitingForBag<K extends string> = {
    [P in K]: WaitingForValue;
};

/**
 * Abstract base for MobX stores in Docu
 * Stores are meant to be used as a singleton in most cases
 * @example
 * ```ts
 * class SomeStore extends SuperStore<"methodOne"|"methodTwo"> {
 *   constructor() {
 *         super({
 *             all: false, // generically set by SuperStore
 *             methodOne: false,
 *             methodTwo: false,
 *         });
 *   }
 *   methodOne(id: number): void {
 *         this.resolveAsAction({
 *             promise: () => someAsyncMethod(),
 *             waitingForKey: "methodOne",
 *             setWaitingForValueTo: id, // defaults to true
 *             action: async (response) => {
 *                 //e.g. set value to an observable - that's why it's an action
 *             },
 *         });
 *     };
 * }
 * export const someStore = new SomeStore();
 * ```
 */
export abstract class SuperStore<WaitingForKey extends string> {
    /**
     * sets the constructor name to the instance-prop `name`.
     */
    readonly name: string;

    /**
     * Record off all `waitingFor` key-value-pairs defined the store instance
     * available WaitingForKeys have to be defined in the class generic type definition `SuperStore<WaitingForKey extends string>`
     */
    waitingFor = { all: false } as WaitingForBag<WaitingForKey | "all">;

    /**
     * sets this.name = this.constructor.name
     * @param waitingFor
     * @protected
     */
    protected constructor(waitingFor: WaitingForBag<WaitingForKey | "all">) {
        makeObservable(this, {
            waitingFor: observable,
        });

        this.name = this.constructor.name;

        runInAction(() => {
            this.waitingFor = { ...this.waitingFor, ...waitingFor };
        });
    }

    /**
     * helper to check if some waitingFor of key is "active" (non-boolean).
     * If you need to know it more specific, then check directly the waitingFor value (e.g. waitingFor.loadItem === 3)
     *
     * @param key waitingForKey to check
     */
    isWaitingFor(key: WaitingForKey): boolean {
        const waitingForVal = this.waitingFor[key];

        if (isBoolean(waitingForVal)) {
            return waitingForVal;
        }

        if (Array.isArray(waitingForVal)) {
            return waitingForVal.length > 0;
        }

        if (Number.isInteger(waitingForVal as unknown as number)) {
            return true;
        }

        throw Error("value has an unexpected type. It's no boolean, Array, nor an Integer");
    }

    /**
     * this method handles our waitingFor logic
     * the action method (if set) becomes wrapped by MobX's `runInAction` method to ensure correct handling (see: [runInAction](https://mobx.js.org/actions.html#runinaction))
     * @param params.promise {async method to be called}
     * @param params.waitingForKey {waitingForKey to set (available keys have to be defined in the generic type SuperStore<WaitingForKey extends string>)}
     * @param params.setWaitingForValueTo? {set to true or number, defaults to true, value will be set right before `promise` is called}
     * @param params.action {if it's set, it will be called after `promise` is resolved, sets the waitingForKey to false (if action isn't set, the key will also set to false after promise)}
     */
    async resolveAsAction<ActionResponse>(params: {
        promise: () => Promise<ActionResponse>;
        waitingForKey: WaitingForKey | WaitingForKey[];
        setWaitingForValueTo?: WaitingForValue;
        action?: (result: ActionResponse) => ActionResponse | Promise<ActionResponse>;
    }): Promise<ActionResponse> {
        let finished = false;
        this.setWaitingFor(params.waitingForKey, params.setWaitingForValueTo);

        const resolved = await params
            .promise()
            .then(async (result) => {
                if (params.action !== undefined) {
                    const action = params.action;
                    return await runInAction(async () => {
                        const response = await action(result);
                        this.setWaitingFor(params.waitingForKey, false);
                        finished = true;
                        return response;
                    });
                }
                return result;
            })
            .catch(async (e) => {
                if (e instanceof UnauthorizedError) {
                    window.location.assign(`${process.env.REACT_APP_HUB_URL}/login?redirect=${window.location.href}`);
                }

                this.setWaitingFor(params.waitingForKey, false);
                finished = true;
                throw e;
            });

        // finish definitely not truthy
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        !finished && this.setWaitingFor(params.waitingForKey, false);
        return resolved;
    }

    /**
     * just a wrapped MobX `runInAction`
     * @param callback
     */
    runAction<Result>(callback: () => Result): Result {
        return runInAction(callback);
    }

    /**
     * Method to set waitingFor keys and values
     * @param key
     * @param val
     * @protected
     */
    protected setWaitingFor(key: WaitingForKey | WaitingForKey[], val?: WaitingForValue): void {
        runInAction(() => {
            val = val === undefined ? true : val;
            if (this.waitingFor === false) {
                this.waitingFor = {} as WaitingForBag<WaitingForKey | "all">;
                return;
            }

            if (!Array.isArray(key)) {
                key = [key];
            }

            for (let i = 0; i < key.length; i++) {
                this.waitingFor[key[i]] = val;
            }
            this.waitingFor.all = val;
        });
    }
}
