import { action, makeObservable } from "mobx";
import { ApiResponse, ApiService } from "../../api/ApiService";
import { fetchTypes, ParamBag } from "../../api/Connection";
import { IEntityRead, IEntityWrite } from "../../entities/Base";
import { RepoStore } from "../../stores/RepoStore";
import { SuperStore, WaitingForBag } from "../../stores/SuperStore";
import { handleViolationByStack } from "../../utils/handleViolationByStack";
import { isDefined } from "../../utils/isDefined";
import { isNullish } from "../../utils/isNullish";

/**
 * The Data services are meant to be the connection between `ApiServices` and `RepoStores`
 * @see RepoStore
 * @see ApiService
 */
export abstract class DataService<
    W extends string,
    Read extends IEntityRead,
    Write extends IEntityWrite = Read
> extends SuperStore<W | "fetchList" | "fetch" | "delete" | "create" | "update"> {
    /**
     * the base RepoStore used in this Service (originally it was planned that a Service uses one APIService and on RepoStore - however, our Project doesn't allow this in every case)
     */
    abstract repo: RepoStore<Read>;
    /**
     * the base ApiService for this Service
     */
    abstract apiService: ApiService<Read, Write>;

    /**
     * shortcut for `this.repo.listObject`
     * @see RepoStore
     */
    get listObject(): this["repo"]["listObject"] {
        return this.repo.listObject;
    }

    /**
     * shortcut for `this.repo.list`
     * @see RepoStore
     */
    get list(): this["repo"]["list"] {
        return this.repo.list;
    }

    /**
     * shortcut for `this.repo.total`
     * @see RepoStore
     */
    get total(): this["repo"]["total"] {
        return this.repo.total;
    }

    /**
     * shortcut for `this.repo.total`
     * @see RepoStore
     */
    get length(): this["repo"]["length"] {
        return this.repo.total;
    }

    /**
     * shortcut for `this.repo.appendList`
     * @see RepoStore
     */
    get appendList(): this["repo"]["appendList"] {
        return this.repo.appendList.bind(this.repo);
    }

    /**
     * shortcut for `this.repo.drop`
     * @see RepoStore
     */
    get drop(): this["repo"]["drop"] {
        return this.repo.drop.bind(this.repo);
    }

    /**
     * shortcut for `this.repo.mergeList`
     * @see RepoStore
     */
    get mergeList(): this["repo"]["mergeList"] {
        return this.repo.mergeList.bind(this.repo);
    }

    /**
     * shortcut for `this.repo.prependList`
     * @see RepoStore
     */
    get prependList(): this["repo"]["prependList"] {
        return this.repo.prependList.bind(this.repo);
    }

    /**
     * shortcut for `this.repo.get`
     * @see RepoStore
     */
    get get(): this["repo"]["get"] {
        return this.repo.get.bind(this.repo);
    }

    /**
     * shortcut for `this.repo.getMultiple`
     * @see RepoStore
     */
    get getMultiple(): this["repo"]["getMultiple"] {
        return this.repo.getMultiple.bind(this.repo);
    }

    /**
     * @param waitingFor
     * @protected
     *
     * makes observables and computed in construction:
     *  update: action,
     *  delete: action,
     *  create: action,
     *  fetchList: action,
     *  fetch: action,
     */
    protected constructor(waitingFor: WaitingForBag<W>) {
        super({
            ...waitingFor,
            all: false,
            create: false,
            delete: false,
            fetch: false,
            fetchList: false,
            update: false,
        });

        makeObservable(this, {
            update: action,
            delete: action,
            create: action,
            fetchList: action,
            fetch: action,
        });
    }

    /**
     * gets entity instances from `this.repo` by given IDs
     * fetches fromm `this.apiService` if not already loaded
     *
     * @param ids
     * @param callback
     */
    async getOrFetchMultiple(ids: (number | undefined)[], callback?: (arg: Read[]) => void): Promise<Read[]> {
        const results: Read[] = [];

        for (const id of ids) {
            await this.getOrFetch(id, (res) => isDefined(res) && results.push(res));
        }

        if (isDefined(callback)) {
            callback(results);
        }

        return results;
    }

    /**
     * gets a single entity instance from `this.repo` by given ID
     * fetches from `this.apiService` if not already loaded
     * @param id
     * @param callback
     */
    async getOrFetch(id?: number, callback?: (arg?: Read) => void): Promise<Read | undefined> {
        handleViolationByStack({
            excludes: ["ComputedValue"],
            message: "DataService.getOrFetch is not allowed to be used inside computed values",
        });

        if (isNullish(id)) return;
        while (this.waitingFor.fetch === id) {
            await new Promise((resolve) => setTimeout(resolve, 0));
        }

        const result = this.get(id);

        if (result !== undefined) {
            isDefined(callback) && callback(result);
            return result;
        }

        return await this.fetch(id).then(() => {
            const res2 = this.get(id);
            isDefined(callback) && callback();
            return res2;
        });
    }

    /**
     * generic update method that takes an id and a body as defined as `Read` type
     */
    async update(id: number, body: Write): Promise<ApiResponse<Read>> {
        return this.resolveAsAction({
            promise: () => this.apiService.put<Read>(`/${id}`, body),
            waitingForKey: "update",
            setWaitingForValueTo: id,
            action: (result) => {
                if (result.result !== null) {
                    this.mergeList(result.result);
                }

                return result;
            },
        });
    }

    /**
     * generic update method that takes an id
     */
    async delete(id: Read["id"]): Promise<ApiResponse<Read>> {
        return this.resolveAsAction({
            promise: () => this.apiService.delete<Read>(`/${id}`),
            waitingForKey: "delete",
            setWaitingForValueTo: id,
            action: (result) => {
                if (result.response?.ok === true) {
                    this.drop(id);
                    this.repo.total--;
                }

                return result;
            },
        });
    }

    /**
     * generic update method that takes a body as defined as `Write` type
     */
    async create(body: Write): Promise<ApiResponse<Read>> {
        return this.resolveAsAction({
            promise: () => this.apiService.post<Read>("", body),
            waitingForKey: "create",
            action: (result) => {
                if (result.result !== null) {
                    this.prependList(result.result);
                }

                return result;
            },
        });
    }

    /**
     * fetches a hydrated list
     */
    async fetchList(params?: ParamBag, reset = false): Promise<ApiResponse<Read[], fetchTypes.fetch>> {
        return await this.resolveAsAction({
            promise: () => this.apiService.getHydrate<Read[]>("", params),
            waitingForKey: "fetchList",
            action: (result) => {
                if (result.result === null) return result;

                if (reset) {
                    this.repo.list = [];
                }
                this.repo.total = this.repo.total + result.result.length;
                this.mergeList(result.result);

                return result;
            },
        });
    }

    /**
     * fetches a single item by id
     */
    async fetch(id: number): Promise<ApiResponse<Read>> {
        return this.resolveAsAction({
            promise: () => this.apiService.get<Read>(`/${id}`),
            waitingForKey: "fetch",
            action: (result) => {
                if (result.result !== null) {
                    this.mergeList(result.result);
                }

                return result;
            },
            setWaitingForValueTo: id,
        });
    }
}
