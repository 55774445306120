import { IconDocumentWithExtension } from "@abs-safety/lock-book-web-ui";
import React from "react";
import { IBuildingAttachmentRead } from "../entities/BuildingAttachment";

type FileProps = Pick<IBuildingAttachmentRead, "imageFilename" | "imageUrl">;

/**
 * Depending on the file type it returns an `url` as thumbnail (for images) or a ReactElement (for documents).
 * Useful for WEBUI's `<CardFile>` Element.
 * */
export const fileToUrlOrThumbnailElement = (
    file: FileProps
): { url?: string; thumbnailElement?: React.ReactElement } => {
    if (hasImageExtension(file.imageFilename)) {
        return { url: file.imageUrl };
    } else {
        let extension = file.imageFilename.split(".").pop();
        if (extension === undefined || extension.length > 4) {
            extension = undefined;
        }
        return { thumbnailElement: <IconDocumentWithExtension size={80} ext={extension} /> };
    }
};

const hasImageExtension = (filename: string) => /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(filename);
