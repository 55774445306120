import { ArticleType } from "../entities/Article";
import { InstalledArticlesFormValues } from "../entities/InstalledArticle";
import { isInstalledArticleFormFieldVisible } from "./isInstalledArticleFormFieldVisible";

/**
 * Depending on view, and articleType, some form Values are not displayed and should be removed.
 * Other formValues have to be parsed (e.g. "" to null)
 */
export function processInstalledArticleFormData(
    formValues: InstalledArticlesFormValues,
    view: "buildingArea" | "documentation/assembly" | "documentation/maintenance",
    articleType: ArticleType
): Partial<InstalledArticlesFormValues> {
    const tempValues = { ...formValues };
    if (articleType !== "abs" && articleType !== "climb") {
        delete tempValues.absSerialNumber;
    }

    for (const key in formValues) {
        const installedArticleKey = key as keyof InstalledArticlesFormValues;
        if (!isInstalledArticleFormFieldVisible(installedArticleKey, view, articleType)) {
            delete tempValues[installedArticleKey];
        }
    }
    if (tempValues.identificationPlateNumber === "") {
        // property is stored in a relation, therefore API doesn't allow empty string
        tempValues.identificationPlateNumber = null;
    }
    if (tempValues.absSerialNumber === "") {
        // it has to be unique. And API sees "" as a valid value and wouldn't allow a second installedArticle to have this name
        tempValues.absSerialNumber = null;
    }
    return tempValues;
}
