/**
 * based on lock-book-docu-api: api/src/Entity/Documentation.php
 */

import { Signature } from "@abs-safety/lock-book-web-ui";
import { RepoStore } from "../stores/RepoStore";
import { IBuildingAreaRead } from "./BuildingArea";
import { IDocumentRead } from "./Document";
import { IDocumentationItemRead } from "./DocumentationItem";
import { IDocumentationSharingHashRead } from "./DocumentationSharingHash";
import { IUserRead } from "./User";

class DocumentationRepo extends RepoStore<IDocumentationRead> {}

export const documentationRepo = new DocumentationRepo();

// related services
type User = IUserRead;
type DocumentationItem = IDocumentationItemRead;

type Attachment = unknown;
type BuildingArea = IBuildingAreaRead;
type Subcontractor = unknown;
type Building = unknown;

export type DocumentationType = "assembly" | "maintenance";

export interface IDocumentationBase {
    projectNumber: string;
    id: number;
    type: DocumentationType;
    name?: string | null;
    comment?: string | null;
    date?: Date | null | string;
    imageFolderName?: string | null;
    signature?: string | Signature | null;
    signedAt?: Date | null;
    isCompleted: boolean;
    isActive: boolean;
    updatedAt?: Date | null;
    createdAt?: Date;
    amountDocumented: number;

    // one to many

    documents?: IDocumentRead[];
    documentationItemIds: number[];
    documentationItems?: DocumentationItem[];
    attachments?: Attachment[];
    sharingHash?: IDocumentationSharingHashRead[];

    // many to one

    buildingArea?: BuildingArea;
    subcontractor?: Subcontractor;
    user?: User;
    assembler?: User;
    building?: Building;
    lastUpdatedBy?: User;

    // getter

    buildingId?: number | null;
    buildingAreaId?: number | null;
    inProcess?: boolean | null;
}

/** All Documentation properties from php entity file with `documentation:read` annotation */
export type IDocumentationRead = Pick<
    IDocumentationBase,
    | "id"
    | "type"
    | "name"
    | "projectNumber"
    | "comment"
    | "date"
    | "imageFolderName"
    | "signature"
    | "signedAt"
    | "isCompleted"
    | "isActive"
    | "createdAt"
    | "updatedAt"
    | "documents"
    | "user"
    | "assembler"
    | "documentationItemIds"
    | "buildingAreaId"
    | "buildingId"
    | "inProcess"
    | "lastUpdatedBy"
    | "amountDocumented"
>;

/** All Documentation properties from php entity file with `documentation:write` annotation. */
export type IDocumentationWrite = Pick<IDocumentationBase, "type" | "name" | "comment" | "date" | "imageFolderName">;

/** All Documentation properties from php entity file with `documentation:drop-documentation-items` annotation. */
export type IDocumentationRemoveDocumentationItems = { documentationItemIds: number[] };

/** All Documentation properties from php entity file with `documentation:remove-documentation-items` annotation. */
export type IDocumentationCreateCertificate = Pick<IDocumentationBase, "projectNumber">;

/** All Documentation properties from php entity file with `documentation:update` annotation. */
export type IDocumentationUpdate = Partial<
    Pick<IDocumentationBase, "name" | "comment" | "date" | "signature" | "signedAt" | "isCompleted">
>;
