import { ApiResponse } from "../api/ApiService";
import { BuildingApi } from "../api/BuildingApi";
import { TransferKeyApi } from "../api/TransferKeyApi";
import { IBuildingRead } from "../entities/Building";
import {
    ITransferKeyBase,
    ITransferKeyCreate,
    ITransferKeyCreateRedeem,
    ITransferKeyRead,
    transferKeyRepo,
} from "../entities/TransferKey";
import { notificationHandler } from "../session/NotificationHandler";
import { getRepoIndexFromString } from "../stores/RepoStore";
import { DataService } from "./abstract/DataService";
class TransferKeyService extends DataService<"redeem" | "delete", ITransferKeyRead, ITransferKeyCreate> {
    repo = transferKeyRepo;
    apiService = new TransferKeyApi();
    buildingApi = new BuildingApi();

    constructor() {
        super({ redeem: false, delete: false });
    }

    async getTransferKeys(): Promise<ApiResponse<ITransferKeyRead[]>> {
        return this.resolveAsAction({
            promise: () => this.apiService.get<ITransferKeyRead[]>(``),
            waitingForKey: "fetch",
            action: (result) => {
                if (result.result !== null) {
                    this.repo.list = result.result.map((key) => getRepoIndexFromString(key.transferKey, key));
                }

                return result;
            },
        });
    }

    async redeem(id: string): Promise<ApiResponse<ITransferKeyRead>> {
        return this.resolveAsAction({
            promise: () => this.apiService.get<ITransferKeyRead>(`/${id}/redeem`),
            waitingForKey: "redeem",
            action: async (result) => {
                if (result.result !== null) {
                    this.mergeList(getRepoIndexFromString(result.result.transferKey, result.result));
                }

                if (result.response !== null && result.response.ok && result.result !== null) {
                    notificationHandler.addNotification({
                        title: "Objekt übernommen",
                        description: `Das Objekt ${result.result.buildingName} wurde erfolgreich übernommen.`,
                        type: "success",
                    });
                }
                return result;
            },
        });
    }

    async deleteTransferKeys(transferKey: string | undefined): Promise<ApiResponse<ITransferKeyRead>> {
        return this.resolveAsAction({
            promise: () => this.apiService.delete<ITransferKeyRead>(`/${transferKey}`),
            waitingForKey: "delete",
            action: (response) => {
                if (response.response?.ok !== undefined) {
                    this.repo.list = this.list.filter((item) => {
                        this.repo.total--;
                        return item.transferKey !== transferKey;
                    });
                }
                return response;
            },
        });
    }

    async create(body: ITransferKeyCreate): Promise<ApiResponse<ITransferKeyBase>> {
        return this.resolveAsAction({
            promise: () => this.apiService.post<ITransferKeyBase>("", body),
            waitingForKey: "create",
            action: (result) => {
                if (result.result !== null) {
                    this.prependList(getRepoIndexFromString(result.result.transferKey, result.result));
                }

                return result;
            },
        });
    }

    async createAndRedeem(body: ITransferKeyCreateRedeem): Promise<ApiResponse<ITransferKeyBase>> {
        return this.resolveAsAction({
            promise: () => this.apiService.post<IBuildingRead>("/direct-transfer", body),
            waitingForKey: "create",
            action: async (result) => {
                if (result.response !== null && result.response.ok && result.result !== null) {
                    notificationHandler.addNotification({
                        title: "Objekt transferiert",
                        description: `Das Objekt ${result.result.name} wurde erfolgreich transferiert.`,
                        type: "success",
                    });
                }
                return result;
            },
        }).catch((error) => {
            notificationHandler.addNotification({
                title: "Fehler beim Transfer",
                description: `Beim Transfer ist ein Fehler aufgetreten.`,
                type: "error",
            });
            return error;
        });
    }
}

export const transferKeyService = new TransferKeyService();
