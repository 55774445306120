import { designTheme, unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { formatDate } from "../../../../../utils/formatDate";
import { getInstalledArticlePreviewName } from "../../../../../utils/getInstalledArticlePreviewName";
import { InstalledArticle, InstalledArticlesEditProps } from "../InstalledArticlesEdit";

type ColListInstalledArticlesProps = Pick<
    InstalledArticlesEditProps,
    "articleName" | "installedArticles" | "waitingForDeleteInstalledArticles"
> & {
    selectedInstalledArticle: InstalledArticle;
    setSelectedInstalledArticle: (IArticle: InstalledArticle) => void;
};

const ColListInstalledArticles: FunctionComponent<ColListInstalledArticlesProps> = (
    props: ColListInstalledArticlesProps
) => {
    return (
        <S.View>
            {props.installedArticles && <S.Title>{props.articleName}</S.Title>}
            {props.installedArticles.map((installedArticle) => (
                <S.InstalledArticleItem
                    key={installedArticle.id + "_" + props.installedArticles.length}
                    className={[
                        props.selectedInstalledArticle.id === installedArticle.id ? "selected" : "",
                        props.waitingForDeleteInstalledArticles !== false &&
                        installedArticle.id !== undefined &&
                        (props.waitingForDeleteInstalledArticles as number[]).indexOf(installedArticle.id) !== -1
                            ? "disabled"
                            : "",
                    ].join("")}
                    onClick={() => props.setSelectedInstalledArticle(installedArticle)}
                >
                    <h5>{getInstalledArticlePreviewName(installedArticle)}</h5>
                    <p>
                        Erstellt am: <strong>{formatDate(installedArticle.installationDate)}</strong>
                    </p>
                </S.InstalledArticleItem>
            ))}
        </S.View>
    );
};

export default ColListInstalledArticles;

//#region styles
const S = {
    View: styled.div`
        padding-top: ${unitize(90)};
    `,
    Title: styled.h3`
        margin-left: ${unitize(10)};
        margin-bottom: ${unitize(70)};
    `,
    InstalledArticleItem: styled.div`
        padding: ${unitize(10)} ${unitize(15)};
        cursor: pointer;

        @media (hover: hover) {
            &:hover {
                background-color: ${designTheme.color.lightgrey};
            }
        }
        &.selected {
            background-color: ${designTheme.color.lightgrey};
        }
        &.disabled {
            pointer-events: none;
            opacity: 0.6;
            cursor: default;
        }
        h5 {
            font-size: ${designTheme.typography.size.h5};
            margin: 0 0 ${unitize(5)} 0;
        }
        p {
            font-size: ${unitize(14)};
            margin: 0;
        }
    `,
};
//#region styles
