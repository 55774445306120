import {
    Button,
    designTheme,
    FormControl,
    IconWarnTriangle,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { Form, FormikProps } from "formik";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { documentationService } from "../../services/DocumentationService";
import { isNullish } from "../../utils/isNullish";
import FieldLoading from "../FieldLoading";
import FormInput from "../FormInput";
import { FormValues } from "./ModalNewCertificate";

export interface ModalNewCertificateInnerBaseProps {
    onCloseClick: () => void;
    documentationId: number;
    /** when should the buttons be disabled */
    waitingForUpdateDocumentation: boolean;
}

type ModalNewCertificateInnerProps = ModalNewCertificateInnerBaseProps & FormikProps<FormValues>;

const ModalNewCertificateInner: FunctionComponent<ModalNewCertificateInnerProps> = (
    props: ModalNewCertificateInnerProps
) => {
    const documentation = documentationService.get(props.documentationId);

    if (documentation === undefined) {
        return <FieldLoading />;
    }
    return (
        <Form>
            <Modal isOpen size="md" style={{ height: unitize(440) }} onCloseClick={props.onCloseClick}>
                <ModalHeader
                    title={documentation.type === "assembly" ? "Installationszertifikat" : "Prüfbescheinigung"}
                />
                <ModalContent>
                    <p>Bitte trage hier die interne ABS Safety Projektnummer ein.</p>
                    <>
                        {isNullish(documentation.signature) && (
                            <S.NoSignatureWarning>
                                <IconWarnTriangle color={designTheme.color.warning} width={24} height={24} />
                                In dieser Dokumentation ist keine Unterschrift hinterlegt
                            </S.NoSignatureWarning>
                        )}
                    </>
                    <S.Form>
                        <FormControl>
                            <FormInput label="Projektnummer" type="text" name="projectNumber" />
                        </FormControl>
                    </S.Form>
                </ModalContent>
                <ModalFooter>
                    <Button disabled={props.waitingForUpdateDocumentation}>
                        <button type="submit">
                            {documentation.type === "assembly" ? "Zertifikat erstellen" : "Bescheinigung erstellen"}
                        </button>
                    </Button>
                    <Button variant="text" color="black" disabled={props.waitingForUpdateDocumentation}>
                        <button onClick={props.onCloseClick}>Abbrechen</button>
                    </Button>
                </ModalFooter>
            </Modal>
        </Form>
    );
};

export default observer(ModalNewCertificateInner);

//#region styles
const S = {
    Form: styled.div`
        margin-top: ${unitize(40)};
    `,
    NoSignatureWarning: styled.p`
        color: ${designTheme.color.warning};
        margin-top: ${unitize(20)};
        display: flex;
        align-items: center;
        > svg {
            margin-right: 0.3em;
        }
    `,
};
//#endregion styles
