import {
    IBuildingAttachmentBatchUpdate,
    IBuildingAttachmentRead,
    IBuildingAttachmentUpdate,
    IBuildingAttachmentWrite,
} from "../entities/BuildingAttachment";
import { ApiResponse, ApiService } from "./ApiService";

export class AttachmentApi extends ApiService<IBuildingAttachmentRead, IBuildingAttachmentWrite> {
    constructor() {
        super(`/building-attachments`);
    }

    deleteDocuItemAttachment(documentationItemAttachmentId: number): Promise<ApiResponse<IBuildingAttachmentRead>> {
        return this.delete(`/../building-attachments-type-documentation-item/${documentationItemAttachmentId}`);
    }

    putAttachment(
        documentationItemAttachmentId: number,
        data: IBuildingAttachmentUpdate
    ): Promise<ApiResponse<IBuildingAttachmentRead>> {
        return this.put(`/../building-attachments-type-documentation-item/${documentationItemAttachmentId}`, data);
    }

    updateAttachments(data: IBuildingAttachmentBatchUpdate): Promise<ApiResponse<IBuildingAttachmentRead[]>> {
        return this.post<IBuildingAttachmentRead[], IBuildingAttachmentBatchUpdate>(`/../update-attachments`, data);
    }
}
