import { Button, CardDark, Checkbox, unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent, useCallback, useState } from "react";

interface PopupAfterLockMultipleProps {
    onClose: (showNeverAgain: boolean) => void;
}

const PopupAfterLockMultiple: FunctionComponent<PopupAfterLockMultipleProps> = (props: PopupAfterLockMultipleProps) => {
    const [showNeverAgain, setShowNeverAgain] = useState(false);

    const onClose = useCallback(() => {
        props.onClose(showNeverAgain);
    }, [showNeverAgain]);

    const onCheckboxChange = useCallback((checked: boolean) => {
        setShowNeverAgain(checked);
    }, []);

    return (
        <CardDark>
            <h4>Sperrgrund</h4>
            <p>Du kannst den Sperrgrund bei jedem Artikel einzeln im Fragenkatalog eintragen</p>
            <p>
                <Checkbox
                    white={true}
                    checked={showNeverAgain}
                    onChange={onCheckboxChange}
                    regularWeight={true}
                    text="Diesen Hinweis nicht mehr anzeigen"
                />
            </p>
            <Button style={{ marginBottom: unitize(-15) }} variant="outline" color="white">
                <button onClick={onClose}>Verstanden</button>
            </Button>
        </CardDark>
    );
};

export default PopupAfterLockMultiple;
