import { Button, IconCopy, Input, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useRef } from "react";
import styled from "styled-components";
import { documentationSharingHashService } from "../../../services/DocumentationSharingHashService";
import { getController } from "../../../stores/controller/ControllerFactory";
import { copyPdfLink } from "../../../utils/copyPdfLink";
import { formatDate } from "../../../utils/formatDate";
import { getSharingLinkOfHash } from "../../../utils/getSharingLinkOfHash";
import { BuildingController } from "../BuildingController";

interface PdfLinkDocumentationProps {
    activeId: number;
}

const PdfLinkDocumentation: FunctionComponent<PdfLinkDocumentationProps> = (props: PdfLinkDocumentationProps) => {
    const { controller } = getController(BuildingController);
    const { activeId } = props;
    const hiddenFileInputRef = useRef<HTMLInputElement>(null);
    const latestSharingLink = documentationSharingHashService.getNewestSharingHashOf(activeId, "documentation");

    const latestSharingLinkExpiredDate =
        latestSharingLink !== undefined ? formatDate(new Date(latestSharingLink.expiresAt)) : undefined;

    const isLatestSharingLinkExpired =
        latestSharingLink !== undefined ? new Date(latestSharingLink.expiresAt).getTime() < Date.now() : false;

    useEffect(() => {
        if (latestSharingLink === undefined && !controller.docsInitialized) {
            controller.loadDocumentationSharingHashes();
        }
    }, []);

    return (
        <S.PdfLinkDocumentation>
            <S.text>
                <p>
                    Bitte kopiere die URL um den Link zu teilen. Dieser Link ist 60 Tage gültig.
                    <br />
                    Danach muss ein neuer Link generiert werden.
                </p>
            </S.text>
            <Input
                type="text"
                label="Ablaufdatum"
                value={latestSharingLinkExpiredDate !== undefined ? latestSharingLinkExpiredDate : undefined}
                readOnly={true}
            />
            <S.PdfUrl ref={hiddenFileInputRef}>
                <Input
                    label="URL zum Dokumentations Link"
                    type="text"
                    value={latestSharingLink !== undefined ? getSharingLinkOfHash(latestSharingLink.hash) : ""}
                    error={isLatestSharingLinkExpired}
                    errorMessage="Link ist nicht mehr gültig. Bitte generiere einen neuen Link."
                    readOnly={true}
                />
                {latestSharingLink !== undefined && (
                    <S.CopyIconWrapper onClick={() => copyPdfLink(hiddenFileInputRef)}>
                        <IconCopy width={24} height={24} />
                    </S.CopyIconWrapper>
                )}
            </S.PdfUrl>

            <S.ButtonWrapper>
                <Button size="small" variant="outline" color="black">
                    <button
                        className={"uf-generateSharingPdf"}
                        onClick={() =>
                            controller.createDocumentationSharingHash({
                                type: "documentation",
                                documentationId: activeId,
                            })
                        }
                    >
                        PDF Link generieren
                    </button>
                </Button>
            </S.ButtonWrapper>
        </S.PdfLinkDocumentation>
    );
};

export default observer(PdfLinkDocumentation);

//#region styles
const S = {
    PdfLinkDocumentation: styled.div``,
    text: styled.div`
        margin-top: ${unitize(16)};
        margin-bottom: ${unitize(16)};
    `,
    PdfUrl: styled.div`
        display: flex;
        align-items: flex-start;
        position: relative;
        margin-top: ${unitize(16)};
        input {
            padding-right: ${unitize(20)};
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        label {
            width: 100%;
        }
        svg {
            position: absolute;
            top: 2rem;
        }
    `,
    CopyIconWrapper: styled.div`
        cursor: pointer;
        height: ${unitize(24)};
        width: ${unitize(24)};
    `,
    ButtonWrapper: styled.div`
        display: flex;
        justify-content: flex-end;
        margin-top: ${unitize(10)};
    `,
};
//#endregion styles
