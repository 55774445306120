interface ArticleVisibility {
    assembly: boolean;
    maintenance: boolean;
}

/** key: article.id (as string) */
export type ArticlesVisibilityApiResponse = Record<string, ArticleVisibility | undefined>;

/** key: article.id */
export type ArticlesVisibility = Record<number, ArticleVisibility | undefined>;

/** parse keys of object from string to number */
export const parseArticlesVisibilityApiResponse = (response: ArticlesVisibilityApiResponse): ArticlesVisibility => {
    return Object.fromEntries(Object.entries(response).map(([key, value]) => [parseInt(key), value]));
};
