import { action, computed, makeObservable, observable, reaction, runInAction } from "mobx";
import moment from "moment";
import { AuthApi } from "../api/AuthApi";
import { miscApi } from "../api/MiscApi";
import { attachmentCategoryRepo, IBuildingAttachmentCategoryRead } from "../entities/BuildingAttachmentCategory";
import { ICompanyRead } from "../entities/Company";
import { IConstraintRead } from "../entities/Constraint";
import { DocumentationType } from "../entities/Documentation";
import { HttpError } from "../entities/ErrorResponse";
import { ICurrentUser } from "../entities/User";
import { rolesCoverRequiredRole } from "../entities/UserRole";
import { SuperStore } from "../stores/SuperStore";
import { cacheReferrer } from "../utils/cacheReferrer";
import { hashCode } from "../utils/generateHash";
import { isNullish } from "../utils/isNullish";

export type IPageTypes =
    | "buildings"
    | "building"
    | "area"
    | "documentation"
    | "document"
    | "init"
    | "admin"
    | DocumentationType;

export interface ISession {
    currentUser?: ICurrentUser;
    locale?: string;
    pageError?: HttpError;
}

class Session
    extends SuperStore<
        "loadConstraints" | "loadBuildingAttachmentCategories" | "loadCurrentUser" | "documentedBeforeSurvey"
    >
    implements ISession
{
    apiService = miscApi;
    userApi = new AuthApi();
    categories: IBuildingAttachmentCategoryRead[] = [];
    constraints: IConstraintRead = {};
    locale = "de";
    repo = attachmentCategoryRepo;

    /** usage: categoryNamesById.get(categoryId) // -> "CategoryName" */
    categoryNamesById = new Map<number, string>();

    constructor() {
        super({
            all: false,
            loadBuildingAttachmentCategories: false,
            loadConstraints: false,
            loadCurrentUser: false,
            documentedBeforeSurvey: false,
        });
        cacheReferrer();

        makeObservable<Session, "_currentUserHash" | "_currentUser" | "_pageError">(this, {
            categories: observable,
            constraints: observable,
            categoryNamesById: observable,
            locale: observable,
            _currentUserHash: observable,
            _pageError: observable,
            _currentUser: observable,
            hasCompanyAdminRights: computed,
            hasAbsAdminRights: computed,
            loadBuildingAttachmentCategories: action,
            applyLocale: action,
            loadConstraints: action,
            loadCurrentUser: action,
            shouldShowDocumentedBeforeSurvey: action,
            updateCurrentCompany: action,
            shouldShowSurvey: observable,
        });

        const dispose = reaction(
            () => this._currentUser,
            () => {
                this.shouldShowDocumentedBeforeSurvey();
                dispose();
            }
        );
    }

    shouldShowSurvey = false;

    private _pageError?: HttpError;
    get pageError(): HttpError | undefined {
        return this._pageError;
    }
    set pageError(value: HttpError | undefined) {
        runInAction(() => {
            this._pageError = value;
        });
    }

    private _currentUserHash?: string;

    get currentUserHash(): string | undefined {
        return this._currentUserHash;
    }

    private _currentUser?: ICurrentUser;

    get currentUser(): ICurrentUser | undefined {
        return this._currentUser;
    }

    set currentUser(user: ICurrentUser | undefined) {
        runInAction(() => {
            this._currentUser = user;

            if (user !== undefined) {
                // already planned for tools like instana
                this._currentUserHash = hashCode(
                    `${user.id}${user.email}${user.createdAt}${user.company}${user.signature}`,
                    true
                );
            }
        });
    }

    get hasCompanyAdminRights(): boolean {
        return rolesCoverRequiredRole(this.currentUser?.roles ?? [], "ROLE_COMPANY_ADMIN");
    }

    get hasAbsAdminRights(): boolean {
        return rolesCoverRequiredRole(this.currentUser?.roles ?? [], "ROLE_ABS_ADMIN");
    }

    loadBuildingAttachmentCategories = (): void => {
        this.resolveAsAction({
            promise: () => miscApi.getBuildingAttachmentCategories(),
            waitingForKey: "loadBuildingAttachmentCategories",
            action: (response) => {
                const result = response.result;
                if (result === null) {
                    return response;
                }

                this.categories = result;
                this.categories.forEach((category) => {
                    this.categoryNamesById.set(category.id, category.category);
                });
                return response;
            },
        });
    };

    applyLocale = (): void => {
        moment.locale(this.locale);
    };

    loadConstraints = (): void => {
        this.resolveAsAction({
            promise: () => miscApi.getConstraints(this.locale),
            waitingForKey: "loadConstraints",
            action: (response) => {
                const result = response.result;
                if (result === null) {
                    return response;
                }
                this.constraints = result;
                return response;
            },
        });
    };

    loadCurrentUser = (): void => {
        this.resolveAsAction({
            promise: () => this.apiService.getUser(),
            waitingForKey: "loadCurrentUser",
            action: async (response) => {
                const result = response.result;
                if (result === null) {
                    return response;
                }
                this.currentUser = result;

                return response;
            },
        });
    };

    shouldShowDocumentedBeforeSurvey = (): void => {
        this.resolveAsAction({
            promise: () => this.userApi.getDocumentedBeforeSurvey(),
            waitingForKey: "documentedBeforeSurvey",
            action: async (response) => {
                const result = response.result;
                if (result === null) {
                    return response;
                }
                this.shouldShowSurvey = result;
                return response;
            },
        });
    };

    updateCurrentCompany = (companyData: ICompanyRead): void => {
        if (isNullish(this.currentUser)) {
            return;
        }
        this.currentUser.company = {
            ...this.currentUser.company,
            ...companyData,
        };
    };
}

export const session = new Session();
