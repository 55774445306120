import { unitize } from "@abs-safety/lock-book-web-ui";
import { Formik } from "formik";
import React, { FunctionComponent, useEffect, useState } from "react";
import * as Yup from "yup";
import FieldLoading from "../../../components/FieldLoading";
import { getConstraints } from "../../../constraints/constraints";
import { IBuildingCreate } from "../../../entities/Building";
import { session } from "../../../session/Session";
import Step1Form from "./Step1Form";

interface Step1Props {
    onSubmit: (values: FormValues) => void;
    formData: Partial<IBuildingCreate>;
    onCloseClick: () => void;
}

export type FormValues = Pick<
    IBuildingCreate,
    "name" | "buildingNumber" | "address1" | "address2" | "postcode" | "city" | "country"
>;

const Step1: FunctionComponent<Step1Props> = (props: Step1Props) => {
    const companyCountry = session.currentUser?.company.country;
    const [initialValues] = useState<FormValues>({
        name: props.formData.name ?? "",
        buildingNumber: props.formData.buildingNumber ?? "",
        address1: props.formData.address1 ?? "",
        address2: props.formData.address2 ?? "",
        postcode: props.formData.postcode ?? "",
        city: props.formData.city ?? "",
        country: props.formData.country ?? companyCountry ?? "",
    });

    const [validationSchema, setValidationSchema] = useState(Yup.object().shape<Partial<FormValues>>({}));
    const [renderForm, setRenderForm] = useState(false);

    useEffect(() => {
        setValidationSchema(
            Yup.object().shape<Partial<FormValues>>({
                name: getConstraints("Building", "name"),
                buildingNumber: getConstraints("Building", "buildingNumber"),
                address1: getConstraints("Building", "address1"),
                address2: getConstraints("Building", "address2"),
                postcode: getConstraints("Building", "postcode"),
                city: getConstraints("Building", "city"),
                country: getConstraints("Building", "country"),
            })
        );
        setRenderForm(true);
    }, [session.constraints]);

    return !renderForm ? (
        <FieldLoading height={unitize(400)} text="lädt..." />
    ) : (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={props.onSubmit}>
            {(formikBag) => <Step1Form onCloseClick={props.onCloseClick} {...formikBag} />}
        </Formik>
    );
};

export default Step1;
