import { ApiResponse } from "../api/ApiService";
import { DocumentationApi } from "../api/DocumentationApi";
import { DocumentationItemApi } from "../api/DocumentationItemApi";
import {
    documentationItemQuestionRepo,
    IDocumentationItemQuestionWithAnswer,
    IDocumentationItemQuestionWrite,
} from "../entities/DocumentationItemQuestion";
import { DataService } from "./abstract/DataService";

class DocumentationItemQuestionService extends DataService<
    "all",
    IDocumentationItemQuestionWithAnswer,
    IDocumentationItemQuestionWrite
> {
    apiService = new DocumentationItemApi();
    docuApi = new DocumentationApi();
    repo = documentationItemQuestionRepo;

    constructor() {
        super({
            all: false,
        });
    }

    loadByDocumentation(documentationId: number): Promise<ApiResponse<IDocumentationItemQuestionWithAnswer[]>> {
        return this.resolveAsAction({
            promise: () => this.docuApi.getDocumentationItemQuestions(documentationId),
            waitingForKey: "fetch",
            action: (response) => {
                if (response.result) {
                    this.mergeList(
                        response.result.map((item) => {
                            const questionNormalized =
                                item.question
                                    ?.toString()
                                    .toLowerCase()
                                    .replace(/[^a-z0-9]/g, "") ?? "";

                            if (
                                item.answer === null &&
                                item.defaultAnswer !== null &&
                                item.defaultAnswer !== undefined &&
                                questionNormalized !== "artikelnummer"
                            ) {
                                item.answer = item.defaultAnswer.toString();
                            }

                            return item;
                        })
                    );
                }

                return response;
            },
        });
    }
}

export const documentationItemQuestionService = new DocumentationItemQuestionService();
