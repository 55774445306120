import { action, makeObservable, observable, runInAction } from "mobx";
import { IBuildingRead } from "../../entities/Building";
import { HttpError } from "../../entities/ErrorResponse";
import { ISession } from "../../session/Session";
import { SuperStore, WaitingForBag } from "../SuperStore";

/**
 * **SuperControllerStore**
 * - should be initialized by `getController` (ControllerFactory)
 * @example
 * type WaitingFor = "anyString1" | "anyStrin2";
 * export class MyController extends SuperControllerStore<WaitingFor> {
 *  constructor() {
 *      super(session, {
 *          anyString1: false,
 *          anyString2: false
 *      });
 *
 *      makeObservable(this, {...});
 *  }
 * }
 * @see getController
 */
export abstract class SuperControllerStore<WaitingForKey extends string> extends SuperStore<WaitingForKey> {
    readonly session: ISession;
    private beforeUnmount: Set<() => void> = new Set<() => void>();
    private _initialized = false;

    protected constructor(session: ISession, waitingFor: WaitingForBag<WaitingForKey | "all">) {
        super(waitingFor);

        makeObservable<this, "_currentId" | "_initialized">(this, {
            _currentId: observable,
            _initialized: observable,
            init: action,
        });

        this.session = session;
    }

    get initialized(): boolean {
        return this._initialized;
    }
    set initialized(value: boolean) {
        runInAction(() => {
            this._initialized = value;
        });
    }

    /**
     * method that's called by ControllerFactory's `useInit`
     * @see useInit
     * @param args
     */
    abstract init(...args: unknown[]): void;

    /**
     * method to register functions that should be called before the controller unmounts
     * - useful for mobx hooks, e.g.
     * - may be called in `controller.init`
     * @example
     * this.register(
     *   reaction(
     *     () => this.foo,
     *     (foo) => {
     *        alert(foo);
     *     }
     *    )
     * );
     * @param onUnmount
     * @protected
     */
    protected register(...onUnmount: (() => void)[]): void {
        onUnmount.forEach((u) => this.beforeUnmount.add(u));
    }

    /**
     * called by `ControllerFactory.unmount` before controller get deleted from factory record
     * - **you should not call it - let the factory do it**
     *
     * @see ControllerFactory.unmount
     * @protected
     */
    unmount(): void {
        this.beforeUnmount.forEach((method) => method());
        this.initialized = false;
    }

    /**
     * response errors of type HttpError will be passed to `ErrorPage`
     * @see ErrorPage
     * @param actions
     */
    usePageData(...actions: (() => unknown | Promise<unknown>)[]): void {
        runInAction(async () => {
            try {
                await Promise.all(actions.map((func) => func()));
            } catch (e) {
                if (e instanceof HttpError) {
                    this.session.pageError = e;
                    return;
                }

                throw e;
            }
        });
    }

    protected _currentId = 0;

    get currentId(): number {
        return this._currentId;
    }

    set currentId(id: number) {
        runInAction(() => {
            this._currentId = id;
        });
    }
}

export interface Sort {
    property: keyof IBuildingRead;
    order: "DESC" | "ASC";
}

/**
 * Sort list from newest (top) to oldest.
 * Biggest reason: when creating a new Building, the sorting will reset to default, and user wants to see created Building on top */
export const defaultSort: Sort | undefined = {
    property: "createdAt",
    order: "DESC",
};
