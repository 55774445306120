import { IBuildingAttachmentRead } from "../entities/BuildingAttachment";

type AttachmentPartial = Pick<IBuildingAttachmentRead, "id" | "sorting">;

export function getSortedAttachmentList<T extends AttachmentPartial>(attachments?: T[]): T[] | undefined {
    if (attachments === undefined) {
        return undefined;
    }
    return attachments.sort((a, b) => {
        if (a.sorting !== b.sorting) {
            return a.sorting - b.sorting;
        }
        return b.id - a.id;
    });
}
