import { Button, CardDark, Checkbox, unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface PopupDeleteProductProps {
    onClose: () => void;
    onDelete: () => void;
}

const PopupDeleteProduct: FunctionComponent<PopupDeleteProductProps> = (props: PopupDeleteProductProps) => {
    const [deletionConfirmed, setDeletionConfirmed] = useState(false);

    const componentRef = useRef<HTMLDivElement>(null);

    // Click somewhere in window (besides in this component) => close Popup
    const windowClickListener = useCallback(() => {
        props.onClose();
    }, []);

    useEffect(() => {
        window.addEventListener("click", windowClickListener);
        return () => {
            window.removeEventListener("click", windowClickListener);
        };
    }, [windowClickListener]);

    const onDelete = () => {
        props.onDelete();
        props.onClose();
    };

    const onCheckboxChange = useCallback(
        (checked: boolean) => {
            setDeletionConfirmed(checked);
        },
        [deletionConfirmed]
    );

    return (
        // stopPropagation bc: if user clicks inside card, we do not want to close card, neither dropdown
        <S.Component ref={componentRef} onClick={(e) => e.stopPropagation()}>
            <CardDark>
                <h4>Möchtest du dieses Produkt wirklich Löschen?</h4>
                <p>
                    Soll das Produkt wirklich <strong>dauerhaft</strong> gelöscht werden?
                </p>
                <p>Dieses Produkt wird dann nicht mehr zur Dokumentation verfügbar sein.</p>
                <p>
                    <Checkbox
                        white={true}
                        checked={deletionConfirmed}
                        onChange={onCheckboxChange}
                        regularWeight={true}
                        text="Dieses Produkt soll dauerhaft  gelöscht werden"
                    />
                </p>
                <Button style={{ marginBottom: unitize(-15) }} variant="outline" color="white">
                    <button onClick={onDelete} disabled={!deletionConfirmed} className="uf-deleteProduct">
                        Löschen
                    </button>
                </Button>
            </CardDark>
        </S.Component>
    );
    //#endregion render
};

export default PopupDeleteProduct;

//#region styles
const S = {
    Component: styled.div`
        position: absolute;
        top: ${unitize(120)};
        right: ${unitize(100)};
        z-index: 1;

        p {
            margin-bottom: ${unitize(30)};
        }
    `,
};
//#endregion styles
