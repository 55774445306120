import { IArticleRead, IArticleWrite } from "../entities/Article";
import { IArticleAttachmentRead } from "../entities/ArticleAttachment";
import { IArticleQuestionRead, IArticleQuestionWrite } from "../entities/ArticleQuestion";
import { catchUploadError } from "../entities/ErrorResponse";
import { IInstalledArticleAmounts, IInstalledArticleRead } from "../entities/InstalledArticle";
import { ApiResponse, ApiService } from "./ApiService";
import { fetchTypes, HTTPMethod } from "./Connection";

export interface IArticleOverviewItem extends IArticleRead {
    installedArticles: (IInstalledArticleRead & IInstalledArticleAmounts)[];
}

export class ArticleApi extends ApiService<IArticleRead, IArticleWrite> {
    constructor() {
        super(`/articles`);
    }

    async uploadAttachment(
        articleId: number,
        attachment: File
    ): Promise<ApiResponse<IArticleAttachmentRead, fetchTypes.fetchUpload>> {
        const formData = new FormData();
        formData.append("attachment", attachment);
        return await this.upload<IArticleAttachmentRead>(`/${articleId}/article-attachments`, formData).catch((e) =>
            catchUploadError(e, formData)
        );
    }

    createArticleQuestions(
        articleId: number,
        questions: IArticleQuestionWrite[]
    ): Promise<ApiResponse<IArticleQuestionRead[]>> {
        return this.post(`/${articleId}/article-questions`, questions);
    }

    getArticleQuestions(articleId: number): Promise<ApiResponse<IArticleQuestionRead[]>> {
        return this.get(`/${articleId}/article-questions`);
    }

    getArticleAttachments(articleId: number): Promise<ApiResponse<IArticleAttachmentRead[]>> {
        return this.get(`/${articleId}/article-attachments`);
    }

    deleteArticle(articleId: number): Promise<ApiResponse<IArticleRead>> {
        return this.delete(`/${articleId}`);
    }

    deleteArticleAttachments(articleId: number, attachmentIds: number[]): Promise<ApiResponse<unknown>> {
        return this.invoke(fetchTypes.fetch, {
            method: HTTPMethod.DELETE,
            route: `/${articleId}/article-attachments`,
            body: attachmentIds,
        });
    }

    deleteArticleQuestions(articleId: number, questionIds: number[]): Promise<ApiResponse<unknown>> {
        return this.invoke(fetchTypes.fetch, {
            method: HTTPMethod.DELETE,
            route: `/${articleId}/article-questions`,
            body: questionIds,
        });
    }

    patchArticleQuestions(
        articleId: number,
        questions: IArticleQuestionWrite[]
    ): Promise<ApiResponse<IArticleQuestionRead[]>> {
        return this.invoke(fetchTypes.fetch, {
            method: HTTPMethod.PATCH,
            route: `/${articleId}/article-questions`,
            body: questions,
        });
    }
}
