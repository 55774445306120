import { Button, MainNavigation, unitize } from "@abs-safety/lock-book-web-ui";
import { Formik } from "formik";
import React, { FunctionComponent } from "react";
import FieldLoading from "../../../../../components/FieldLoading";
import { ArticleType } from "../../../../../entities/Article";
import { InstalledArticlesFormValues } from "../../../../../entities/InstalledArticle";
import { getController } from "../../../../../stores/controller/ControllerFactory";
import { apiConstraintErrorsToFormErrors } from "../../../../../utils/apiConstraintErrorsToFormErrors";
import { processInstalledArticleFormData } from "../../../../../utils/processInstalledArticleFormData";
import { AreaController } from "../../../AreaController";
import { InstalledArticle, InstalledArticlesEditProps } from "../InstalledArticlesEdit";
import FormInstalledArticle from "./FormInstalledArticle";

type ColFormInstalledArticleProps = Pick<InstalledArticlesEditProps, "waitingForDeleteInstalledArticles"> & {
    tabTitle: string;
    selectedInstalledArticle: InstalledArticle;
    articleType: ArticleType;
    onDeleteClick: () => void;
};

const ColFormInstalledArticle: FunctionComponent<ColFormInstalledArticleProps> = (
    props: ColFormInstalledArticleProps
) => {
    const { controller } = getController(AreaController);
    return (
        <>
            <MainNavigation items={[{ id: "main", text: props.tabTitle }]} small />
            {props.waitingForDeleteInstalledArticles !== false &&
            props.selectedInstalledArticle.id !== 0 &&
            (props.waitingForDeleteInstalledArticles as number[]).indexOf(props.selectedInstalledArticle.id) !== -1 ? (
                <FieldLoading text="wird gelöscht...." height={unitize(400)} />
            ) : (
                <>
                    <Formik
                        // we need a key, so Component doesn't re-use its state when changing the selected InstalledArticle,
                        // which would lead to a values change which FormAutoSubmit would interpretate as a form values change
                        // and would therefore submit the form before the user has actually done something.
                        key={props.selectedInstalledArticle.id}
                        initialValues={
                            {
                                absSerialNumber: props.selectedInstalledArticle.absSerialNumber,
                                serialNumber: props.selectedInstalledArticle.serialNumber,
                                identificationNumber: props.selectedInstalledArticle.identificationNumber,
                                identificationCounter: props.selectedInstalledArticle.identificationCounter,
                                identificationPlateNumber: props.selectedInstalledArticle.identificationPlateNumber,
                                comment: props.selectedInstalledArticle.comment,
                            } as InstalledArticlesFormValues
                        }
                        onSubmit={(values, helpers) => {
                            const cleanedValues = processInstalledArticleFormData(
                                values,
                                "buildingArea",
                                props.articleType
                            );
                            controller
                                .updateInstalledArticle(props.selectedInstalledArticle.id, cleanedValues)
                                .catch((error) => apiConstraintErrorsToFormErrors(error, helpers.setErrors));
                        }}
                        enableReinitialize
                    >
                        {(formikBag) => <FormInstalledArticle {...formikBag} articleType={props.articleType} />}
                    </Formik>
                    <Button color="decline" size="small" variant="outline" style={{ marginTop: unitize(20) }}>
                        <button type="button" onClick={props.onDeleteClick}>
                            Artikel löschen
                        </button>
                    </Button>
                </>
            )}
        </>
    );
};

export default ColFormInstalledArticle;
