import { IDocumentationItemOfArticleOverview } from "../api/DocumentationApi";
import { RepoStore } from "../stores/RepoStore";
import { IBuildingAttachmentRead } from "./BuildingAttachment";
import { IDocumentationRead } from "./Documentation";
import { InstalledArticleDocumentationOverview } from "./InstalledArticle";
import { IUserRead } from "./User";

class DocumentationItemRepo extends RepoStore<IDocumentationItemOfArticleOverview> {}

export const documentationItemRepo = new DocumentationItemRepo();

// related services
type Documentation = IDocumentationRead;
type User = IUserRead;

type DocumentationItemQuestion = unknown;
type BuildingAttachment = IBuildingAttachmentRead;

export interface IDocumentationItemBase {
    id: number;
    questionsAnswered?: boolean;
    isSuccessful?: boolean;
    isDeleted?: boolean;
    documentationId?: number;
    installedArticleId?: number;
    amountAttachments: number;
    lockReason?: string | null;

    updatedAt?: Date | null;
    createdAt?: Date | null;
    lastUpdatedBy?: User;

    // one to many
    questions: DocumentationItemQuestion[];
    attachments: BuildingAttachment[];

    // many to one
    documentation: Documentation;
    installedArticle: InstalledArticleDocumentationOverview;
    // installedArticle: IInstalledArticleRead;
    user: User[];
}

/** All DocumentationItem properties from php entity file with `documentation-item:read` annotation */
export type IDocumentationItemRead = Pick<
    IDocumentationItemBase,
    | "id"
    | "questionsAnswered"
    | "isSuccessful"
    | "isDeleted"
    | "updatedAt"
    | "documentationId"
    | "installedArticleId"
    | "lastUpdatedBy"
    | "createdAt"
    | "lockReason"
>;

/** All DocumentationItem properties from php entity file with `documentation-overview` annotation */
export type IDocumentationItemDocumentationOverview = Pick<
    IDocumentationItemBase,
    "installedArticle" | "amountAttachments"
>;

/** All DocumentationItem properties from php entity file with `documentation-item:write` annotation */
export type IDocumentationItemWrite = Pick<
    IDocumentationItemBase,
    "questionsAnswered" | "isSuccessful" | "isDeleted" | "lockReason"
>;

/** All DocumentationItem properties from php entity file with `documentation-item:update` annotation */
export type IDocumentationItemUpdate = Pick<
    IDocumentationItemBase,
    "questionsAnswered" | "isSuccessful" | "isDeleted" | "lockReason"
>;
