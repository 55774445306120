/**
 * based on lock-book-docu-api: api/src/Entity/BuildingArea.php
 */

import { RepoStore } from "../stores/RepoStore";
import { IDocumentationRead } from "./Documentation";
import { IUserRead } from "./User";

class AreaRepo extends RepoStore<IBuildingAreaRead> {}

export const areaRepo = new AreaRepo();

// related services
type Building = unknown;
type Attachment = unknown;
type User = IUserRead;

interface IBuildingAreaBase {
    buildingId: number;
    id: number;
    name: string;
    isDeleted: boolean;
    updatedAt: Date | null;
    createdAt: Date | null;
    amountAssemblies: number;
    amountMaintenances: number;
    lastUpdatedBy?: User;
    building?: Building;
    documentations?: IDocumentationRead[];
    attachments?: Attachment[];
}

/** All Area properties from php entity file with `area:read` annotation */
export type IBuildingAreaRead = Pick<
    IBuildingAreaBase,
    | "buildingId"
    | "id"
    | "name"
    | "isDeleted"
    | "createdAt"
    | "updatedAt"
    | "amountAssemblies"
    | "amountMaintenances"
    | "lastUpdatedBy"
>;

/** All Area properties from php entity file with `area:update` annotation */
export type IBuildingAreaUpdate = Pick<IBuildingAreaBase, "name">;

/** According to Swagger for `POST /building-areas` */
export type IBuildingAreaWrite = Pick<IBuildingAreaBase, "buildingId" | "name">;
