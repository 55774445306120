import { unitize } from "@abs-safety/lock-book-web-ui";
import { Form, Formik, FormikProps } from "formik";
import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import FieldLoading from "../../../components/FieldLoading";
import FormAutoSubmit from "../../../components/FormAutoSubmit";
import FormGtmIntegration from "../../../components/FormGtmIntegration";
import FormInput from "../../../components/FormInput";
import { getConstraints } from "../../../constraints/constraints";
import { IBuildingAreaUpdate } from "../../../entities/BuildingArea";
import { areaService } from "../../../services/AreaService";
import { session } from "../../../session/Session";
import { gtmAutoSubmit } from "../../../utils/gtmEventCollection";

type FormValues = Pick<IBuildingAreaUpdate, "name">;

interface EditDetailsModalProps {
    buildingAreaId: number;
}

const EditDetails: FunctionComponent<EditDetailsModalProps> = (props: EditDetailsModalProps) => {
    const [initialValues, setInitialValues] = useState<FormValues | undefined>();
    const [validationSchema, setValidationSchema] = useState(Yup.object().shape<Partial<FormValues>>({}));

    useEffect(() => {
        if (initialValues) {
            return;
        }

        const buildingArea = areaService.get(props.buildingAreaId);

        if (buildingArea === undefined) {
            return;
        }

        setInitialValues({
            name: buildingArea.name,
        });
    }, [props.buildingAreaId]);

    useEffect(() => {
        setValidationSchema(
            Yup.object().shape<Partial<FormValues>>({
                name: getConstraints("BuildingArea", "name"),
            })
        );
    }, [session.constraints]);

    //#region render
    return areaService.waitingFor.fetch || isEmpty(session.constraints) ? (
        <FieldLoading text="Dachfläche wird geladen..." />
    ) : initialValues === undefined ? (
        <S.NotFoundText>Keine Dachfläche zu der URL gefunden</S.NotFoundText>
    ) : (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                areaService.update(props.buildingAreaId, values);
            }}
        >
            {(formikBag) => <ObservedForm {...formikBag} />}
        </Formik>
    );
};

/**
 * Form Component
 */

const FormComponent: FunctionComponent<FormikProps<FormValues>> = () => (
    <Form>
        <S.EditDetails>
            <FormInput type="text" name="name" label="Name der Dachfläche" size="sm" />
        </S.EditDetails>
        <FormAutoSubmit onSuccess={gtmAutoSubmit("area")} />
        <FormGtmIntegration />
    </Form>
);

const ObservedForm = observer(FormComponent);

export default observer(EditDetails);

//#region styles
const S = {
    EditDetails: styled.div`
        margin-top: ${unitize(60)};
    `,
    NotFoundText: styled.h4`
        margin-top: ${unitize(20)};
    `,
};
//#endregion styles
