export function isPropertyValue<T extends Record<string, unknown> | ArrayLike<T>>(
    possibleProperty: unknown,
    object: T
): possibleProperty is T[keyof T] {
    return Object.values(object).includes(possibleProperty);
}

/**
 * TypeGuard to check if a value is part of a Enum. If so it gets casted to the right Enum type.
 *
 * @example
 * ```ts
 * enum Device {
 *     phone = "PHONE",
 *     Pc = "PC",
 * }
 * function isDevice(value: string) {
 *     if (isPartOfEnum(value, Device)) {
 *         // value is now from type `Device`
 *     }
 * }
 * ```
 */
export const isPartOfEnum = isPropertyValue;
