import { Button, IconLock, MainNavigation, Popup, SideModal, unitize } from "@abs-safety/lock-book-web-ui";
import { media } from "@abs-safety/lock-book-web-ui/dist/esm/shared/breakpoints";
import { observer } from "mobx-react";
import React, { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { Connection } from "../../api/Connection";
import { dataLayerService } from "../../services/DataLayerService";
import { session } from "../../session/Session";
import { getController } from "../../stores/controller/ControllerFactory";
import MainPage from "../../templates/MainPage";
import { isDefined } from "../../utils/isDefined";
import { isNullish } from "../../utils/isNullish";
import { isPartOfEnum } from "../../utils/isPartOfEnum";
import { layout } from "../../utils/layout";
import { PageDepth, useBreadcrumb } from "../../utils/useBreadcrumb";
import { DocumentationController } from "./DocumentationController";
import Document from "./documentView/Document";
import Edit from "./subviews/Edit";
import ModalCompleteDocumentation from "./subviews/ModalCompleteDocumentation/ModalCompleteDocumentation";
import ModalGenerateSharingLink from "./subviews/ModalGenerateSharingLink";
import ViewArticles from "./subviews/ViewArticles";
import ViewFiles from "./subviews/ViewFiles";

enum SubViewKey {
    Articles = "tab__articles",
    Files = "tab__files",
}

const subViewMap = new Map<SubViewKey, ReactNode>([
    [SubViewKey.Articles, <ViewArticles key="Articles" />],
    [SubViewKey.Files, <ViewFiles key="Files" />],
]);

type OpenedModal = "ModalCompleteDocumentation" | "ModalNextMaintenance" | "ModalGenerateSharingLink";

/**
 * Page: Documentation
 */
const Documentation: FunctionComponent = () => {
    const { controller, useInit } = getController(DocumentationController);
    const params: {
        buildingId: string;
        buildingAreaId: string;
        documentationId: string;
    } = useParams();
    const buildingId = parseInt(params.buildingId);
    const buildingAreaId = parseInt(params.buildingAreaId);
    const documentationId = parseInt(params.documentationId);
    const { path, url } = useRouteMatch();
    const history = useHistory();
    const isLocked = controller.documentation?.isCompleted ?? false;
    const location = useLocation();
    const breadcrumb = useBreadcrumb(PageDepth.Documentation, controller);
    const pdfUrl = controller.lastDocumentTypeDocumentation?.imageUrl;

    const [activeSubView, setActiveSubView] = useState<SubViewKey>(SubViewKey.Articles);
    const [openedModal, setOpenedModal] = useState<OpenedModal | null>(null);

    const docuQuery = "?open=documentation-completion-modal";
    const docuQueryWithoutQuotation = docuQuery.replace(/['"]+/g, "");

    useInit(buildingId, buildingAreaId, documentationId);

    useEffect(() => {
        // if there is a file in the url and we are not already in files subview: redirect!
        if (location.pathname.toString().includes("file") && activeSubView !== SubViewKey.Files) {
            setActiveSubView(SubViewKey.Files);
        }

        if (location.search.toString().includes("?open") === true) {
            setOpenedModal("ModalCompleteDocumentation");
        }
    }, [
        activeSubView,
        controller.lastDocumentTypeDocumentation,
        controller.lastDocumentTypeDocumentation?.imageDownloadUrl,
        location.pathname,
        location.search,
    ]);

    //#endregion Init

    const onNavigationItemClick = (subViewKey: string) => {
        if (isPartOfEnum(subViewKey, SubViewKey)) {
            setActiveSubView(subViewKey);
            return;
        }
        throw Error("unknown subViewKey");
    };

    const onPreviewDocumentationClick = () => {
        if (controller.currentId === 0) {
            throw Error("cannot preview documentation without props.previewDocumentation");
        }
        window.open(
            `${new Connection().endpoint}/documentations/${controller.currentId}/preview.pdf`,
            "Documentation Preview",
            "width=810,height=1000,top=80" // Browser ignored it and used the size of currentId window (Chrome, Mac OS)
        );
    };

    const onGeneratePDFLinkClick = () => {
        if (isNullish(controller.documentation)) {
            dataLayerService.emitClick({
                category: "documentation",
                action: "create_pdf-sharing",
                label: "failed",
                payload: "error",
            });
            return;
        }

        setOpenedModal("ModalGenerateSharingLink");
        controller
            .createDocumentationSharingHash({
                type: "documentation",
                documentationId: controller.documentation.id,
            })
            .then((response) => {
                dataLayerService.emitClick({
                    category: controller.documentation?.type ?? "documentation",
                    action: "create_pdf-sharing",
                    label: "success",
                    payload: response.result,
                });

                return response;
            })
            .catch(() =>
                dataLayerService.emitClick({
                    category: controller.documentation?.type ?? "documentation",
                    action: "create_pdf-sharing",
                    label: "failed",
                    payload: "error",
                })
            );
    };

    const onOpenModalCompleteDocumentationClick = () => {
        setOpenedModal("ModalCompleteDocumentation");
        history.push(`${url}${docuQueryWithoutQuotation}`);
    };

    const closeOpenedModal = () => {
        setOpenedModal(null);
        history.push(`${url}`);
    };

    //#endregion event listeners
    return (
        <MainPage
            title={
                controller.documentation !== undefined
                    ? isDefined(controller.documentation.name)
                        ? controller.documentation.name
                        : "Unbenannt"
                    : "Dokumentation..."
            }
            breadcrumb={breadcrumb}
            onTitleClick={() => history.push(`${url}/edit`)}
            pageType={controller.documentation?.type ?? "documentation"}
        >
            {/* Subrouting */}
            <Switch>
                <Route path={`${path}/edit`}>
                    <SideModal
                        className="uf-sideModal"
                        isOpen
                        onCloseClick={() => history.push(`${url}`)}
                        style={{
                            top: `${layout.header.height}px`,
                            height: `calc(100% - ${layout.header.height}px)`,
                        }}
                        views={[
                            {
                                key: "edit",
                                width: 750,
                                component: <Edit documentationId={documentationId} buildingId={buildingId} />,
                            },
                        ]}
                    />
                </Route>
                <Route path={`${path}/document`}>
                    <Document
                        onClose={() => {
                            history.push(`${url}`);
                        }}
                    />
                </Route>
            </Switch>
            <S.Header>
                {/* Tabs */}
                <S.NavigationWrapper>
                    <MainNavigation
                        key={controller.documentation?.id}
                        activeId={activeSubView}
                        onActiveChange={onNavigationItemClick}
                        items={[
                            {
                                id: SubViewKey.Articles,
                                text: "Produkte",
                            },
                            {
                                id: SubViewKey.Files,
                                text: "Übersichtsfotos",
                            },
                        ]}
                    />
                </S.NavigationWrapper>
                {/* Buttons */}
                {isLocked ? (
                    <S.ButtonWrapper>
                        <ButtonGeneratePdfLink onClick={onGeneratePDFLinkClick} />
                        <ButtonOpenPdf
                            href={pdfUrl}
                            onClick={() =>
                                dataLayerService.emitOpenPdf({
                                    category: controller.documentation?.type ?? "documentation",
                                    docuType: controller.documentation?.type ?? "maintenance",
                                    documentType: controller.lastDocumentTypeDocumentation?.type ?? "documentation",
                                })
                            }
                        />
                        <ButtonMaintenanceReminder
                            onClick={() => controller.sendMaintenanceReminder(controller.currentId)}
                        />
                    </S.ButtonWrapper>
                ) : (
                    <S.ButtonWrapper>
                        <ButtonPreview disabled={controller.currentId === 0} onClick={onPreviewDocumentationClick} />
                        <ButtonCompleteDocumentation
                            disabled={!controller.isCompleted}
                            onClick={onOpenModalCompleteDocumentationClick}
                        />
                    </S.ButtonWrapper>
                )}
            </S.Header>
            {isLocked && (
                <S.UnlockButtonRow>
                    <ButtonLockWithPopup onLockDocumentation={controller.reopenDocumentation.bind(controller)} />
                </S.UnlockButtonRow>
            )}

            {/* sub view */}
            {subViewMap.get(activeSubView)}

            {/* modals */}
            {openedModal === "ModalCompleteDocumentation" && isDefined(controller.documentation) && (
                <ModalCompleteDocumentation
                    docuType={controller.documentation.type}
                    pdfUrl={pdfUrl}
                    onCalendarButtonClick={() => {
                        controller.sendMaintenanceReminder(controller.currentId);
                        closeOpenedModal();
                        dataLayerService.emitClick({
                            category: dataLayerService.currentPage,
                            action: "next_maintenance_calendar",
                            label: `while_completion`,
                        });
                    }}
                    onClose={closeOpenedModal}
                />
            )}
            {openedModal === "ModalGenerateSharingLink" && <ModalGenerateSharingLink onClose={closeOpenedModal} />}
        </MainPage>
    );
};

export default observer(Documentation);

//#region buttons

const ButtonGeneratePdfLink = (props: { onClick: () => void }) => (
    <Button size="large" color="black">
        <button className="uf-generateSharingPdf" onClick={props.onClick}>
            PDF Link generieren
        </button>
    </Button>
);

const ButtonOpenPdf = (props: { href: string | undefined; onClick: () => void }) => (
    <Button disabled={props.href === undefined} size="large" color="black">
        <a href={props.href} className="uf-openPdf" target="_blank" rel="noreferrer" onClick={props.onClick}>
            PDF öffnen
        </a>
    </Button>
);

const ButtonMaintenanceReminder = (props: { onClick: () => void }) => {
    // ugly fix to force re-render, in order to close Popup when button is clicked
    const [clickedAt, setClickedAt] = useState(new Date());
    return (
        <Popup
            key={clickedAt.getTime()}
            popupStyle={{ zIndex: 2 }}
            position={{
                top: unitize(60),
                right: "0",
            }}
            toggleNode={
                <Button size="large" color="primary">
                    <button className="uf-openMaintenanceReminderModal">Wartungserinnerung</button>
                </Button>
            }
            popupContent={
                <>
                    <h4>Termin zusenden lassen</h4>
                    <p style={{ marginBottom: unitize(30) }}>
                        Lass Dir als Erinnerung per E-Mail einen Kalendereintrag für die nächste Wartung senden.
                    </p>
                </>
            }
            popupButton={{
                children: "per E-Mail erhalten",
                onClick: () => {
                    setClickedAt(new Date());
                    props.onClick();
                },
                color: "white",
            }}
        />
    );
};

const ButtonPreview = (props: { disabled: boolean; onClick: () => void }) => (
    <Button color="black" disabled={props.disabled}>
        <button className="uf-previewButton" onClick={props.onClick}>
            Vorschau
        </button>
    </Button>
);

const ButtonCompleteDocumentation = (props: { disabled: boolean; onClick: () => void }) => (
    <Button disabled={props.disabled} color="black">
        <button className="uf-completeButton" onClick={props.onClick}>
            Dokumentation abschließen
        </button>
    </Button>
);

const ButtonLockWithPopup = (props: { onLockDocumentation: () => void }) => (
    <Popup
        popupStyle={{ zIndex: 2 }}
        position={{
            top: unitize(24),
            left: unitize(24),
        }}
        toggleNode={
            <Button noPadding variant="outline" color="black">
                <button>
                    <IconLock />
                </button>
            </Button>
        }
        popupContent={
            <>
                <h4>Dokumentation gesperrt</h4>
                <p style={{ marginBottom: unitize(30) }}>
                    Diese Dokumentation ist abgeschlossen und damit für Änderungen gesperrt.
                </p>
            </>
        }
        popupButton={
            session.hasCompanyAdminRights
                ? {
                      children: "Entsperren",
                      onClick: props.onLockDocumentation,
                      color: "white",
                  }
                : undefined
        }
    />
);

//#endregion buttons

//#region styles
const S = {
    Header: styled.div`
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: ${unitize(20)};
        margin-top: ${unitize(20)};
        row-gap: ${unitize(10)};
        column-gap: ${unitize(20)};
        ${media("lg")} {
            margin-top: 0;
            align-items: center;
            flex-direction: row;
        }
    `,
    NavigationWrapper: styled.div`
        max-width: 100%;
        flex-basis: 0;
        flex-grow: 1;
    `,
    UnlockButtonRow: styled.div`
        margin-bottom: ${unitize(30)};
    `,
    ButtonWrapper: styled.div`
        display: flex;
        /* flex-wrap: wrap; */
        column-gap: ${unitize(10)};
        justify-content: flex-start;
        align-self: flex-end;
        ${media("lg")} {
            align-self: unset;
        }
    `,
};
//#endregion styles
