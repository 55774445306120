import { AuthApi } from "../api/AuthApi";
import { IUserPropsRead, IUserPropsWrite, userPropsRepo } from "../entities/UserProps";
import { DataService } from "./abstract/DataService";

class UserPropsService extends DataService<"updateUserProps", IUserPropsRead, IUserPropsWrite> {
    apiService = new AuthApi();
    repo = userPropsRepo;

    constructor() {
        super({ updateUserProps: false });
    }

    async updateUserProps(data: IUserPropsWrite) {
        return this.resolveAsAction({
            promise: () => this.apiService.put<IUserPropsRead, IUserPropsWrite>(`/user-props`, data),
            waitingForKey: "updateUserProps",
            action: (result) => {
                return result;
            },
        });
    }
}

export const userPropsService = new UserPropsService();
