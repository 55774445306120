import {
    Button,
    ColumnSizes,
    designTheme,
    FieldEmpty,
    IconPlus,
    IconProduct,
    ListSortable,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import FieldLoading from "../../../components/FieldLoading";
import { AddArticleController, SubViewKey } from "../../../components/ModalAddArticle/AddArticleController";
import ModalAddArticle from "../../../components/ModalAddArticle/ModalAddArticle";
import { installedArticleService } from "../../../services/InstalledArticleService";
import { getController } from "../../../stores/controller/ControllerFactory";
import { AreaController } from "../AreaController";
import CardArticle from "../components/CardArticle";

const listColumnSizes: ColumnSizes = { xs: [7, 5], md: [8, 4] };

const ViewProductManagement: FunctionComponent = () => {
    const { controller: modalController } = getController(AddArticleController);
    const { controller } = getController(AreaController);
    const params: {
        buildingAreaId: string;
    } = useParams();
    const buildingAreaId = parseInt(params.buildingAreaId);

    //#region states

    const [isModalAddArticleVisible, setIsModalAddArticleVisible] = useState(false);

    //#endregion states

    useEffect(() => {
        controller.loadArticles();
    }, [controller]);

    useEffect(() => {
        if (!isModalAddArticleVisible && modalController.isInPage === "buildingArea") {
            modalController.setSubView(SubViewKey.FallProtection);
        }
    }, [isModalAddArticleVisible]);

    //#region render

    return (
        <S.ViewProductManagement>
            {isModalAddArticleVisible && (
                <ModalAddArticle
                    isInPage="buildingArea"
                    buildingAreaId={buildingAreaId}
                    onCloseClick={() => setIsModalAddArticleVisible(false)}
                />
            )}
            <S.AddButton>
                <Button disabled={installedArticleService.waitingFor.all !== false}>
                    <button onClick={() => setIsModalAddArticleVisible(true)} className={"uf-createButton"}>
                        Produkt anlegen
                        <IconPlus color={designTheme.color.white} />
                    </button>
                </Button>
            </S.AddButton>

            {/*  List of Articles or placeholder */}
            {controller.waitingFor.loadArticles !== false ? (
                <FieldLoading text="Produkte werden geladen..." />
            ) : controller.articles.length === 0 ? (
                <FieldEmpty icon={<IconProduct height={48} width={48} />} text="Kein Produkt vorhanden" />
            ) : (
                <>
                    <ListSortable
                        headerStyle={{ padding: unitize(10) }}
                        listStyle={{ display: "grid", gridGap: unitize(5) }}
                        columnSizes={listColumnSizes}
                        items={controller.articles}
                        columns={[
                            {
                                title: "Name",
                                sortProperty: "name",
                            },
                            {
                                title: "Verbaut an Dachfläche",
                                sortFunction: (a, b) => {
                                    return a.installedArticles.length - b.installedArticles.length;
                                },
                            },
                        ]}
                    >
                        {(sortedList) => (
                            <>
                                {controller.waitingFor.createInstalledArticles !== false && (
                                    <FieldLoading height={unitize(110)} text="Dachfläche wird erstellt ..." />
                                )}
                                {sortedList.map((article) => (
                                    <CardArticle
                                        key={article.id}
                                        article={article}
                                        columnSizes={listColumnSizes}
                                        firstItem={article.id === controller.articles[0].id}
                                    />
                                ))}
                            </>
                        )}
                    </ListSortable>
                </>
            )}
        </S.ViewProductManagement>
    );

    //#endregion render
};

export default observer(ViewProductManagement);

//#region styles
const S = {
    ViewProductManagement: styled.div``,
    AddButton: styled.div`
        margin-bottom: ${unitize(30)};
    `,
};
//#endregion styles
