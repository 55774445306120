import { makeObservable, observable, when } from "mobx";
import { ApiResponse } from "../api/ApiService";
import { DocumentApi } from "../api/DocumentApi";
import { DocumentationApi } from "../api/DocumentationApi";
import { documentRepo, DocumentType, IDocumentRead } from "../entities/Document";
import { IDocumentationWrite } from "../entities/Documentation";
import { session } from "../session/Session";
import { isDefined } from "../utils/isDefined";
import { DataService } from "./abstract/DataService";

class DocumentService extends DataService<"all", IDocumentRead, IDocumentationWrite> {
    apiService = new DocumentApi();
    documentationApi = new DocumentationApi();
    repo = documentRepo;

    documentationToDocument: Record<number, number[]> = {};

    constructor() {
        super({
            all: false,
        });

        makeObservable(this, {
            documentationToDocument: observable,
        });
    }

    getDocumentsByDocumentation(documentationId: number, type: DocumentType): undefined | IDocumentRead[] {
        if (!this.documentationToDocument[documentationId]) return;

        return this.getMultiple(this.documentationToDocument[documentationId]).filter((item) => item.type === type);
    }

    getLastDocumentByDocumentation(documentationId: number, type: DocumentType): undefined | IDocumentRead {
        const matches = this.getDocumentsByDocumentation(documentationId, type);
        return matches?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0];
    }

    fetchByDocumentation(documentationId: number) {
        when(
            () => isDefined(session.currentUser),
            () => {
                const isAbsAdmin = session.hasAbsAdminRights;

                const promises: Promise<ApiResponse<IDocumentRead[]>>[] = [
                    this.documentationApi.get<IDocumentRead[]>(`/${documentationId}/documents`),
                ];

                if (isAbsAdmin) {
                    promises.push(this.documentationApi.get<IDocumentRead[]>(`/${documentationId}/certificates`));
                }

                return this.resolveAsAction({
                    promise: () => Promise.all(promises),
                    waitingForKey: "fetchList",
                    action: (response) => {
                        const results: IDocumentRead[] = response.reduce(
                            (combined: IDocumentRead[], res: ApiResponse<IDocumentRead[]>) => {
                                if (isDefined(res.result)) {
                                    combined = [...combined, ...Object.values(res.result)];
                                }

                                return combined;
                            },
                            []
                        );
                        this.mergeList(results);
                        this.documentationToDocument[documentationId] = results.map((item) => item.id);
                        return response;
                    },
                });
            }
        );
    }
}

export const documentService = new DocumentService();
