import {
    Button,
    ColumnSizes,
    designTheme,
    FieldEmpty,
    IconArea,
    IconPlus,
    ListSortable,
    ListSortableRef,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useRef } from "react";
import styled from "styled-components";
import FieldLoading from "../../../components/FieldLoading";
import { getController } from "../../../stores/controller/ControllerFactory";
import { BuildingController } from "../BuildingController";
import ListBuildingAreas from "../components/ListBuildingAreas";

const listColumnSizes: ColumnSizes = { xs: [6, 6] };

const BuildingAreas: FunctionComponent = () => {
    const { controller } = getController(BuildingController);

    const listSortableRef = useRef<ListSortableRef | null>(null);

    const onNewAreaClick = () => {
        listSortableRef.current?.clearSorting();

        const name = `Dachfläche ${controller.areas.length + 1}`;
        controller.createBuildingArea(name);
    };

    return (
        <>
            <S.AreasHeader>
                <Button
                    disabled={
                        controller.waitingFor.createBuildingArea !== false ||
                        controller.waitingFor.loadBuildingAreas !== false ||
                        controller.waitingFor.deleteBuildingArea !== false
                    }
                >
                    <button className={"uf-createButton"} onClick={onNewAreaClick}>
                        Dachfläche anlegen <IconPlus />
                    </button>
                </Button>
            </S.AreasHeader>

            {controller.isWaitingFor("loadBuildingAreas") || !controller.areas ? (
                <FieldLoading text="Dachflächen werden geladen..." />
            ) : controller.areas.length === 0 && !controller.isWaitingFor("createBuildingArea") ? (
                <FieldEmpty icon={<IconArea height={48} width={48} />} text="Keine Dachfläche vorhanden" />
            ) : (
                <ListSortable
                    ref={listSortableRef}
                    headerStyle={{ padding: unitize(10) }}
                    listStyle={{ display: "grid", gridGap: unitize(10) }}
                    columnSizes={listColumnSizes}
                    items={controller.areas}
                    columns={[
                        {
                            title: "Name",
                            sortProperty: "name",
                        },
                        {
                            title: "Letzte Änderung",
                            sortProperty: "updatedAt",
                        },
                    ]}
                >
                    {(sortedList) => <ListBuildingAreas sortedList={sortedList} columnSizes={listColumnSizes} />}
                </ListSortable>
            )}
        </>
    );
};
export default observer(BuildingAreas);

//#region styles

const SubHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${unitize(30)};
    > h2 {
        margin-right: ${unitize(20)};
    }
`;

const S = {
    AreasHeader: styled(SubHeader)``,
    FilesHeader: styled(SubHeader)`
        margin-top: ${unitize(50)};
    `,
    NothingFound: styled.div`
        padding: ${unitize(100)} 0;
        text-align: center;
        border: 1px solid ${designTheme.color.grey};
        border-radius: ${designTheme.borderRadius};
        color: ${designTheme.color.darkgrey};
        font-size: ${designTheme.typography.size.h4}rem;
    `,
    Header: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: ${unitize(20)};
    `,
};

//#endregion styles
