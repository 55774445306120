import { makeObservable, observable } from "mobx";
import { ApiResponse } from "../api/ApiService";
import { DocumentationApi } from "../api/DocumentationApi";
import { DocumentationItemAnswersApi } from "../api/DocumentationItemAnswersApi";
import {
    documentationItemAnswerRepo,
    IDocumentationItemAnswerRead,
    IDocumentationItemAnswerWrite,
    IMultipleQuestionAnswer,
} from "../entities/DocumentationItemAnswer";
import { DataService } from "./abstract/DataService";

class DocumentationItemAnswerService extends DataService<
    "all" | "answerQuestions",
    IDocumentationItemAnswerRead,
    IDocumentationItemAnswerWrite
> {
    apiService = new DocumentationItemAnswersApi();
    docuApi = new DocumentationApi();
    repo = documentationItemAnswerRepo;

    questionToAnswer: Record<number, string> = {};

    constructor() {
        super({
            all: false,
            answerQuestions: false,
        });

        makeObservable(this, {
            questionToAnswer: observable,
        });
    }

    answerQuestions(docuId: number, body: IMultipleQuestionAnswer[]): Promise<ApiResponse<unknown>> {
        return this.resolveAsAction({
            promise: () => this.apiService.answerMultipleArticleQuestions({ answers: body }),
            waitingForKey: ["update", "answerQuestions"],
        });
    }
}

export const documentationItemAnswerService = new DocumentationItemAnswerService();
