import { RepoStore } from "../stores/RepoStore";
import { IArticleDeliveryNoteRead } from "./Article";

class DeliveryNoteRepo extends RepoStore<IDeliveryNoteRead> {}

export const deliveryNoteRepo = new DeliveryNoteRepo();

interface IDeliveryNoteBase {
    id: number;
    barcode: string;
    documentNumber: number | null;
    documentDate: Date;
    currentPositionNumber: number;
    positionText: string | null;
    amount: number;
    articleNumber: string;
    description: string;
    createdAt: Date | null;
    article: IArticleDeliveryNoteRead;
}

export type IDeliveryNoteRead = Pick<
    IDeliveryNoteBase,
    "id" | "barcode" | "amount" | "articleNumber" | "description" | "positionText" | "article"
>;
