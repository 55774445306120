import { media, Modal, unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";
import Navigation from "./Navigation";

interface ModalTemplateProps {
    onCloseClick: () => void;
    children?: ReactNode;
}

const ModalTemplate: FunctionComponent<ModalTemplateProps> = (props: ModalTemplateProps) => (
    <Modal
        size="lg"
        isOpen
        onCloseClick={props.onCloseClick}
        style={{
            maxWidth: unitize(1234),
            padding: 0,
            overflow: "hidden",
        }}
    >
        <S.LayoutWrapper>
            <Navigation />
            <S.Content>{props.children}</S.Content>
        </S.LayoutWrapper>
    </Modal>
);

export default ModalTemplate;

//#region styles
const heightNavigation = 64;

const S = {
    LayoutWrapper: styled.div`
        display: flex;
        height: 100%;
        flex-direction: column;
        ${media("lg")} {
            flex-direction: row;
            flex: 1 1 auto;
        }
    `,
    Content: styled.div`
        flex: 1;
        padding: ${unitize(30)};
        display: flex;
        flex-direction: column;
        height: calc(100% - ${heightNavigation}px);
        ${media("lg")} {
            height: 100%;
            padding-top: ${unitize(56)};
        }
    `,
};
//#endregion styles
