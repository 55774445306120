import { IArticleRead } from "../entities/Article";
import { IBuildingAttachmentRead } from "../entities/BuildingAttachment";
import {
    IDocumentationCreateCertificate,
    IDocumentationRead,
    IDocumentationRemoveDocumentationItems,
    IDocumentationUpdate,
    IDocumentationWrite,
} from "../entities/Documentation";
import { IDocumentationItemDocumentationOverview, IDocumentationItemRead } from "../entities/DocumentationItem";
import { IDocumentationItemQuestionWithAnswer } from "../entities/DocumentationItemQuestion";
import { IDocumentationSharingHashRead } from "../entities/DocumentationSharingHash";
import { IInstalledArticleAdd, IInstalledArticleInstall, IInstalledArticleRead } from "../entities/InstalledArticle";
import { ApiResponse, ApiService } from "./ApiService";

export type IDocumentationItemOfArticleOverview = IDocumentationItemDocumentationOverview & IDocumentationItemRead;

export interface IArticleOverviewItemDocu extends IArticleRead {
    documentationItems: IDocumentationItemOfArticleOverview[];
}

export class DocumentationApi extends ApiService<IDocumentationRead, IDocumentationWrite | IDocumentationUpdate> {
    constructor() {
        super(`/documentations`);
    }

    reopenDocumentation(documentationId: number): Promise<ApiResponse<IDocumentationRead>> {
        return this.put(`/${documentationId}/reopen`, {});
    }

    completeDocumentation(documentationId: number): Promise<ApiResponse<IDocumentationRead>> {
        return this.post(`/${documentationId}/complete`);
    }

    getFiles(documentationId: number): Promise<ApiResponse<IBuildingAttachmentRead[]>> {
        return this.get<IBuildingAttachmentRead[]>(`/${documentationId}/attachments`);
    }

    getArticleOverview(documentationId: number): Promise<ApiResponse<IArticleOverviewItemDocu[]>> {
        return this.get<IArticleOverviewItemDocu[]>(`/${documentationId}/article-overview`);
    }

    updateSignature(
        documentationId: number,
        signature: IDocumentationRead["signature"],
        useUserSignature = false
    ): Promise<ApiResponse<IDocumentationRead>> {
        return this.put<IDocumentationRead, { signature: IDocumentationRead["signature"]; useUserSignature: boolean }>(
            `/${documentationId}`,
            {
                signature,
                useUserSignature,
            }
        );
    }

    deleteDocumentationItems(
        documentationId: number,
        body: IDocumentationRemoveDocumentationItems
    ): Promise<ApiResponse<Partial<IDocumentationItemRead>>> {
        return this.post<Partial<IDocumentationItemRead>, IDocumentationRemoveDocumentationItems>(
            `/${documentationId}/remove-documentation-items`,
            body
        );
    }

    getDocumentationItemQuestions(
        documentationId: number
    ): Promise<ApiResponse<IDocumentationItemQuestionWithAnswer[]>> {
        return this.get<IDocumentationItemQuestionWithAnswer[]>(`/${documentationId}/documentation-item-questions`);
    }

    postDocumentationCertificate(
        documentationId: number,
        body: IDocumentationCreateCertificate
    ): Promise<ApiResponse<IDocumentationRead>> {
        return this.post<IDocumentationRead, IDocumentationCreateCertificate>(`/${documentationId}/certificate`, body);
    }

    getDocumentationSharingHashes(documentationId: number): Promise<ApiResponse<IDocumentationSharingHashRead[]>> {
        return this.get<IDocumentationSharingHashRead[]>(`/${documentationId}/share`);
    }

    // TODO: test it
    /**
     * Create DocumentationItems, which are instances of an installedArticle assigned to a
     * certain Documentation.
     */
    addInstalledArticleToDocumentation(
        documentationId: number,
        body: IInstalledArticleAdd
    ): Promise<ApiResponse<IInstalledArticleRead>> {
        return this.post(`/${documentationId}/installed-articles`, body);
    }

    // TODO: test it
    /**
     * Create InstalledArticles AND DocumentationItems, from a certain Article (from catalog).
     * So this request does actually two things.
     */
    addArticleToDocumentation(
        documentationId: number,
        body: IInstalledArticleInstall
    ): Promise<ApiResponse<IInstalledArticleRead>> {
        return this.post(`/${documentationId}/articles`, body);
    }

    sendMaintenanceReminder(documentationId: number): Promise<ApiResponse<IDocumentationRead>> {
        return this.post(`/${documentationId}/reminder`, {});
    }

    sendDocumentationOfferMails(documentationId: number): Promise<ApiResponse<IDocumentationRead>> {
        return this.post(`/${documentationId}/offer`, {});
    }
}
