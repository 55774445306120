import { ButtonClose, Modal, Signature, unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { DocumentationType } from "../../../../entities/Documentation";
import { notificationHandler } from "../../../../session/NotificationHandler";
import { session } from "../../../../session/Session";
import { getController } from "../../../../stores/controller/ControllerFactory";
import { DocumentationController } from "../../DocumentationController";
import Step1CompleteDocumentation from "./Step1CompleteDocumentation";
import Step2CompleteDocumentation from "./Step2CompleteDocumentation";

interface ModalCompleteDocumentationProps {
    onClose: () => void;
    onCalendarButtonClick: () => void;
    docuType: DocumentationType;
    pdfUrl?: string;
}

const ModalCompleteDocumentation: FunctionComponent<ModalCompleteDocumentationProps> = (
    props: ModalCompleteDocumentationProps
) => {
    const { controller } = getController(DocumentationController);
    const [step, setStep] = useState(1);

    const onCompleteDocumentationStep1Click = async ({
        signature,
        useUserSignature = false,
    }: {
        signature?: Signature;
        useUserSignature: boolean;
    }) => {
        const isAbsAdmin = session.hasAbsAdminRights;

        try {
            if (!isAbsAdmin) {
                await controller.updateSignature({
                    signature,
                    useUserSignature,
                });
            }
            await controller.completeDocumentation();
            setStep(2);
        } catch (e) {
            notificationHandler.addNotification({
                type: "error",
                title: "Unerwarteter Fehler",
            });
            console.error(e);
        }
    };

    const onClose = () => {
        props.onClose();
    };

    return (
        <S.ModalWrapper className={"uf-modalWrapper"}>
            <Modal isOpen size="md" onCloseClick={onClose}>
                <S.ButtonClose>
                    <ButtonClose onClick={onClose} />
                </S.ButtonClose>
                {step === 1 && (
                    <Step1CompleteDocumentation
                        onClose={onClose}
                        openNextStep={(signature) => onCompleteDocumentationStep1Click(signature)}
                    />
                )}
                {step === 2 && (
                    <Step2CompleteDocumentation
                        onClose={onClose}
                        pdfUrl={props.pdfUrl}
                        docuType={props.docuType}
                        onCalendarButtonClick={props.onCalendarButtonClick}
                    />
                )}
            </Modal>
        </S.ModalWrapper>
    );
};
export default ModalCompleteDocumentation;

const S = {
    ModalWrapper: styled.div`
        position: absolute;
        width: 100vw;
        height: 100vw;
        max-width: 100%;
        max-height: 100%;
        top: 0;
        left: 0;
    `,
    ButtonClose: styled.div`
        position: absolute;
        right: ${unitize(30)};
        top: ${unitize(30)};
    `,
};
