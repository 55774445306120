import { Card, ColumnSizes, GridColumns, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { IArticleRead } from "../../../../entities/Article";
import { getArticleTypeName } from "../../../../utils/getArticleTypeName";
import { isDefined } from "../../../../utils/isDefined";
import { adminStore } from "../../AdminStore";

interface CardProductProps {
    article: IArticleRead;
    columnSizes: ColumnSizes;
    onClick: () => void;
}

const CardProduct: FunctionComponent<CardProductProps> = (props: CardProductProps) => {
    return (
        <Card className="card uf-areaCard" onClick={props.onClick}>
            <GridColumns columnSizes={props.columnSizes} style={{ height: unitize(28), alignItems: "center" }}>
                {/* Column */}
                <div className="overflow-hidden">
                    <p className="truncate">{props.article.name}</p>
                </div>

                {/* Column */}
                <p>{getArticleTypeName(props.article.type).singular}</p>

                {/* Column */}
                <p className="truncate">
                    {isDefined(props.article.categoryId) && adminStore.getArticleCategoryName(props.article.categoryId)}
                </p>
            </GridColumns>
        </Card>
    );
};

export default observer(CardProduct);
