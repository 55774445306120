/**
 * based on lock-book-docu-api: api/src/Entity/Article.php
 */

import { RepoStore } from "../stores/RepoStore";
import { IArticleQuestionRead } from "./ArticleQuestion";
import { IInstalledArticleRead } from "./InstalledArticle";

class ArticleRepo extends RepoStore<IArticleRead> {}

export const articleRepo = new ArticleRepo();

// related services
type ArticleCategory = unknown;
type CustomArticleCategory = unknown;
type User = unknown;

export const articleTypes = ["abs", "custom", "thirdparty", "misc", "climb", "drainage", "ventilation"] as const;
export type ArticleType = (typeof articleTypes)[number];

export interface IArticleBase {
    imageUrl?: string | null;
    id: number;
    name: string;
    /** It's more like a readable serial number or model number with letters (e.g. "L2-B") */
    number: string;
    comment?: string | null;
    imageFolderName?: string | null;
    imageFileName?: string | null;
    imageSize?: number | null;
    isActive: boolean;
    updatedAt?: Date | null;
    createdAt?: Date | null;

    questions: IArticleQuestionRead[];
    installedArticles: IInstalledArticleRead[];

    // many to one

    user: User;

    // getter

    type: ArticleType;

    // from DiscriminatorMap: (ArticleTypeABS, ArticleTypeCustom, ArticleTypeThirdParty)

    category?: ArticleCategory | CustomArticleCategory | null;
    imageFile?: File | null;
    /** depends which type. If type == 'abs', then it references ArticleCategory.id, otherwise CustomArticleCategory.id */
    categoryId?: number | null;
    /** if type === 'custom' || 'thirdparty' */
    categoryComment?: string | null;
    /** Company.id. If type === 'custom' */
    company?: number | null;
}

/** All Article properties from php entity file with `article:create` annotation. */
export type IArticleRead = Pick<
    IArticleBase,
    | "imageUrl"
    | "id"
    | "name"
    | "number"
    | "comment"
    | "isActive"
    | "updatedAt"
    | "createdAt"
    | "categoryId"
    | "type"
    | "categoryComment"
>;

/** All Article properties from php entity file with `delivery-note:read` annotation. */
export type IArticleDeliveryNoteRead = Pick<IArticleBase, "imageUrl" | "id" | "name" | "number" | "comment" | "type">;

/** All Article properties from php entity file with `article:write` annotation. */
export type IArticleWrite = Pick<IArticleBase, "name" | "number" | "comment" | "categoryId" | "type"> & {
    /** empty string in case in Update the existing image should be deleted */
    imageFile?: Blob | "";
};
